import React, { useRef } from "react";
import { Button } from "../button/Button";

export const Carousel = ({ children }) => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <div className="relative w-full overflow-hidden">
      <div className="hidden sm:flex gap-2">
        <Button
          onClick={scrollLeft}
          variant="tonal"
          icon={<i className="fa fa-arrow-left" />}
          aria-label="Scroll Left"
        />
        <Button
          onClick={scrollRight}
          variant="tonal"
          icon={<i className="fa fa-arrow-right" />}
          aria-label="Scroll Right"
        />
      </div>

      {/* Scrollable Content */}
      <div
        ref={scrollRef}
        className="flex overflow-x-auto scroll-smooth gap-4 w-full px-4 py-4 scrollbar-hide"
      >
        {children.map((child, index) => (
          <div key={index} className="min-w-[200px] flex-shrink-0">
            {child}
          </div>
        ))}
      </div>

      {/* Navigation Buttons (hidden on mobile) */}
    </div>
  );
};
