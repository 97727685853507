// BlogPreview.js
import React from "react";
import "./BlogPreview.css"; // Import the external CSS file

const BlogPreview = ({ toc, title, authors, content, maximized }) => {
  return (
    <div className="blog-preview-container">
      {/* TOC Section */}
      <aside className={`blog-toc ${maximized ? "blog-toc-maximized" : ""}`}>
        <h2 className="blog-toc-title">Content to Visit</h2>
        <ul className="blog-toc-list">
          {toc.map((item) => (
            <li key={item.id} className="blog-toc-item">
              <a
                href={`#${item.id}`}
                className="blog-toc-link"
                onClick={(e) => {
                  e.preventDefault();
                  const target = document.getElementById(item.id);
                  if (target) target.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      </aside>

      {/* Main Blog Content */}
      <div className="blog-content">
        {/* Title Section */}
        <div className="blog-title">
          <h1
            className={`blog-title-text ${
              maximized ? "blog-title-maximized" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: title }}
          ></h1>
        </div>
        <div className="blog-authors">
          {authors.length > 0 && (
            <span className="blog-authors-text">
              {authors.map((author, index) => (
                <React.Fragment key={author._id || index}>
                  {author.link ? (
                    <a
                      href={author.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="blog-author-link"
                    >
                      {author.name}
                    </a>
                  ) : (
                    <span className="blog-author-name">{author.name}</span>
                  )}
                  {index < authors.length - 2
                    ? ", "
                    : index === authors.length - 2
                    ? " and "
                    : ""}
                </React.Fragment>
              ))}
            </span>
          )}
        </div>

        {/* Content Section */}
        <div
          className="blog-content-text"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

export default BlogPreview;

// // Desc: This component is used to display the preview of the blog post in the admin panel.
// import React from "react";

// const BlogPreview = ({ toc, title, authors, content, maximized }) => {
//   return (
//     <div className="flex flex-col lg:flex-row bg-black text-on-surface rounded-lg max-h-screen font-display">
//       {/* TOC Section */}
//       <aside
//         className=" hidden lg:block lg:w-1/4  p-6 overflow-y-auto sticky top-0 max-h-screen"
//         style={{
//           flexShrink: 0, // Prevent shrinking of TOC
//           zIndex: 1,
//         }}
//       >
//         <h2
//           className="text-xl  font-bold mb-4"
//           style={{ fontSize: maximized ? "28px" : "16px", color: "white" }}
//         >
//           Content to Visit
//         </h2>
//         <ul className="space-y-2">
//           {toc.map((item) => (
//             <li key={item.id}>
//               <a
//                 href={`#${item.id}`}
//                 className="text-blue-400 hover:text-green-500"
//                 onClick={(e) => {
//                   e.preventDefault();
//                   const target = document.getElementById(item.id);
//                   if (target) target.scrollIntoView({ behavior: "smooth" });
//                 }}
//               >
//                 {item.text}
//               </a>
//             </li>
//           ))}
//         </ul>
//       </aside>

//       {/* Main Blog Content */}
//       <div className="flex-grow p-6 overflow-y-auto">
//         {/* Title Section */}
//         <div className="text-on-surface">
//           <h1
//             style={{ fontSize: maximized ? "30px" : "28px" }}
//             className="font-bold mb-4"
//             dangerouslySetInnerHTML={{
//               __html: title,
//             }}
//           ></h1>
//         </div>
//         <div className="mb-6">
//           {authors.length > 0 && (
//             <span className="text-white text-sm">
//               {authors.map((author, index) => (
//                 <React.Fragment key={author._id || index}>
//                   {author.link ? (
//                     <a
//                       href={author.link}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className="text-[#A8DF8E] hover:text-[#16FF00] transition-colors duration-200"
//                     >
//                       {author.name}
//                     </a>
//                   ) : (
//                     <span className="text-[#A8DF8E]">{author.name}</span>
//                   )}
//                   {index < authors.length - 2
//                     ? ", " // Add a comma for all but the last two authors
//                     : index === authors.length - 2
//                     ? " and " // Add "and" before the last author
//                     : ""}
//                 </React.Fragment>
//               ))}
//             </span>
//           )}
//         </div>

//         {/* Content Section */}
//         <div
//           className="space-y-2 text-justify text-on-surface-low"
//           dangerouslySetInnerHTML={{
//             __html: `
//                 <style>
//                 h2{ font-size: 24px; color: #f5f5f5; }
//                 h3{ font-size: 22px; color: #f5f5f5; }
//                 h4{ font-size: 20px; color: #f5f5f5; }
//                 h5{ font-size: 18px; color: #f5f5f5; }
//                 h6{ font-size: 16px; color: #f5f5f5; }
//                  p{ font-size: 16px; line-height: 1.7; color: white; padding:5px; margin-left:10px }
//                 a{ color: lightblue; text-decoration: none; }
//                 img{   max-width: 100%;
//           border-radius: 8px;
//           margin-top: 10px;
//           display: block;
//           margin-left: auto;
//           margin-right: auto; }
//                video{    max-width: 100%;
//           border-radius: 8px;
//           margin-top: 10px;
//           display: block;
//           margin-left: auto;
//           margin-right: auto;}

//  iframe {
//   width: 100%;
//   max-width: 100%;
//   border-radius: 8px;
//   margin-top: 10px;
//   margin-left: 0;
//   margin-right: 0;
//   display: block;
//   aspect-ratio: 16 / 9;
//   border: none;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
// }
//                 blockquote {
//                   border-left: 5px solid #9EC8B9;
//                   padding:10px;
//                   font-style: italic;
//                   color: white;
//                   background-color: #092635;
//                   border-radius: 4px;
//                   text-align:left;
//                 }
//                   ol {
//                        padding:5px;
//                      }
//                ol li {margin-bottom: 10px; margin-left:40px; margin-top:10px; color:white  }
//                 ul {
//                   padding-left: 5px;
//                    }
//                 ul li {margin-bottom: 10px; margin-left:40px; margin-top:10px; color:white }
//                 table {
//             width: 100%;
//             border-collapse: collapse;
//             margin-top: 10px;
//             margin-bottom: 10px;

//           }
//           table, th, td {
//             border: 1px solid #6EACDA;
//             text-align: center;
//           }
//           th, td {
//             padding: 10px;
//             color: white;
//           }
//           th {
//             background-color: #0B192C;
//             font-weight: bold;
//           }
//               </style>
//               ${content}
//             `,
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// export default BlogPreview;
