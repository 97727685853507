import React from "react";
import { Gauge, gaugeClasses } from "@mui/x-charts";

function NonHalal_IncomeIpo({ interestToRevenue }) {
  const nonHalalIncomePercentage = interestToRevenue * 100;

  return (
    <div className="flex flex-col md:flex-row items-center p-2 rounded-lg border border-[var(--on-surface)]">
      <div className="flex flex-col items-start w-full md:w-1/2 p-4 md:p-9">
        <div className="text-center p-2 w-full">
          <h3 className="text-lg md:text-xl font-semibold text-[var(--primary)] mb-4 md:mb-6">
            NON-COMPLIANT INCOME TO TOTAL INCOME
          </h3>
        </div>
        <div className="flex flex-col items-center border border-[var(--on-surface)] p-3 w-full rounded-lg">
          <h3 className="text-md md:text-xl font-semibold text-[var(--on-surface-variant)] text-center">
            Total Non-Compliant Income
          </h3>
          <p className="text-sm md:text-lg text-red-600 text-center">
            {nonHalalIncomePercentage.toFixed(2)}%
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center w-full md:w-1/2 p-4 text-[var(--on-surface)]">
        <Gauge
          value={nonHalalIncomePercentage}
          startAngle={0}
          endAngle={360}
          innerRadius="60%"
          outerRadius="100%"
          thickness={24}
          sx={(theme) => ({
            width: "200px", // Adjust the width
            height: "200px", // Adjust the height
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 28, // Adjust the font size
              color: "var(--on-surface)", // Value text color
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: "red", // Value arc color (non-halal income)
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: "green", // Track color
            },
          })}
        />
      </div>
    </div>
  );
}

export default NonHalal_IncomeIpo;
