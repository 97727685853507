// /add-blog/component/UnOrderedDialog.js (this blog is a modal used in add-blog/add page to add select the type of unorderedlist)

import React from "react";

export default function UnorderedListDialog({
  open,
  onClose,
  unorderedListType,
  setUnorderedListType,
  handleAddUnorderedList,
}) {
  return (
    <div
      className={`fixed z-50 inset-0 flex items-center justify-center text-white ${
        open ? "block" : "hidden"
      }`}
    >
      <div
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>
      <div className="relative bg-gray-800 text-white rounded-lg shadow-lg w-11/12 max-w-lg">
        <div className="px-6 py-4 border-b border-gray-700">
          <h2 className="text-lg font-semibold text-on-surface">
            Choose Unordered List Type
          </h2>
        </div>
        <div className="p-6 space-y-4">
          <div>
            <label className="block mb-1 text-sm font-medium text-white p-2">
              List Type
            </label>
            <select
              value={unorderedListType}
              onChange={(e) => setUnorderedListType(e.target.value)}
              className="w-full px-3 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring focus:ring-blue-500 cursor-pointer"
            >
              <option value="disc">Default (•)</option>
              <option value="circle">Circle (○)</option>
              <option value="square">Square (■)</option>
            </select>
          </div>
        </div>
        <div className="flex justify-end px-6 py-4 border-t border-gray-700 space-x-2">
          <button
            onClick={onClose}
            className="px-6 py-2 text-sm font-medium text-red-400 bg-gray-800 hover:bg-gray-700 hover:text-red-500 rounded-lg shadow-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            onClick={handleAddUnorderedList}
            className="px-6 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-500 rounded-lg shadow-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
