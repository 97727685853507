import { useEffect, useState } from "react";
import { Progression } from "../../components/composite/progression/Progression";
import { Box } from "../../components/functional/box/Box";
import { Section } from "../../components/functional/section/Section";
import { Text } from "../../components/ui/text/Text";
import Mockup1 from "../../images/mockup_1.png";
import Mockup2 from "../../images/mockup_2.png";
import Mockup3 from "../../images/mockup_3.png";

export const MockupSection = () => {
  const images = [Mockup1, Mockup2, Mockup3]; // Replace with your image imports
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleIndexChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Section>
      <Box variant="row">
        <Box alignItems="center">
          <div
            className="rounded-full py-8 px-16 overflow-hidden mb-4"
            style={{ backgroundColor: "var(--primary-container)" }}
          >
            <div className="flex relative w-[270px] h-[500px]">
              {images.map((image, index) => (
                <img
                  className="flex top-0 left-0"
                  key={index}
                  src={image}
                  alt={`Mockup ${index + 1}`}
                  style={{
                    position: "absolute",
                    width: "270px",
                    opacity: currentIndex === index ? 1 : 0,
                    transition: "opacity 0.5s ease-in-out",
                  }}
                />
              ))}
            </div>
          </div>
        </Box>
        <Box gap="2xl" paddingX="xl" justifyContent="center">
          <Text as="h2" variant="heading">
            Accurate, Ethical, and Actionable Recommendations
          </Text>
          <Progression items={itemsData} onIndexChange={handleIndexChange} />
        </Box>
      </Box>
    </Section>
  );
};

const itemsData = [
  {
    title: "Detailed Buy, Sell, Hold recommendations.",
  },
  {
    title: "Aligned with islamic investment principles.",
  },
  {
    title: "Simple, easy-to-follow action steps for users.",
  },
];
