import { Box } from "../../components/functional/box/Box";
import { Grid } from "../../components/functional/grid/Grid";
import { Section } from "../../components/functional/section/Section";
import { Button } from "../../components/ui/button/Button";
import { Card } from "../../components/ui/card/Card";
import { Carousel } from "../../components/ui/carousel/Carousel";
import { Icon } from "../../components/ui/icon/Icon";
import { Text } from "../../components/ui/text/Text";

export const ReviewsSection = () => {
  return (
    <Section>
      <Box variant="row" gap="md">
        <Box width="4-col">
          <Text as="h2" variant="heading" style={{ textAlign: "center" }}>
            What Our Users Are Saying
          </Text>
        </Box>
        <Box>
          <Carousel>
            {reviews.map((card, index) => (
              <ReviewCard
                rating={card.rating}
                text={card.text}
                name={card.name}
                designation={card.designation}
              />
            ))}
          </Carousel>
        </Box>
      </Box>
    </Section>
  );
};

const ReviewCard = ({ rating, text, name, designation }) => {
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - Math.ceil(rating);

    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <Icon color="primary" size="large" fill key={`full-${i}`}>
          star_rate
        </Icon>
      ); // Full star (★)
    }

    if (hasHalfStar) {
      stars.push(
        <Icon color="primary" size="large" key="half">
          star_rate_half
        </Icon>
      ); // Half star (☆)
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <Icon color="gray" size="large" key={`empty-${i}`}>
          star_rate
        </Icon>
      ); // Empty star (☆)
    }

    return stars;
  };

  return (
    <div
      className="flex flex-col gap-4 p-6 bg-[var(--surface-container-low)] text-[var(--on-surface)] rounded-lg shadow-lg"
      style={{ width: "100%", maxWidth: "400px" }}
    >
      {/* Star Rating */}
      <div className="flex flex-row items-center gap-2">
        {renderStars(rating)}
      </div>

      {/* Review Text */}
      <div
        className="flex flex-row overflow-x-auto gap-4"
        style={{
          padding: "0 1rem",
          lineHeight: "1.5",
        }}
      >
        <Text as="p" variant="body" color="variant">
          “{text}”
        </Text>
      </div>

      {/* Reviewer Name */}
      <div className="flex flex-col items-start">
        <Text
          as="label"
          variant="label"
          size="medium"
          color="primary"
          style={{ fontWeight: "600" }}
        >
          ~ {name}
        </Text>
        <Text
          as="span"
          variant="label"
          size="small"
          color="gray"
          style={{ fontSize: "0.875rem" }}
        >
          {designation}
        </Text>
      </div>
    </div>
  );
};

const reviews = [
  {
    rating: 4,
    text: "Mainne recently Finsha use karna shuru kiya hai for investing strategies, aur main sach mein impressed hoon by their work. Platform bohot user-friendly aur intuitive hai, aur innke strategies like portfolio diversification, amount allocation for stock, etc. financial planning ko bohot easy bana dete hain. Sab kuch clearly laid out hai, jo investments ko track karna simple bana deta hai. Plus, unka customer support bhi bohot responsive aur helpful hai. Highly recommend karta hoon!",
    name: "Farid Hasan",
    designation: "UI Designer, Dectox",
  },
  {
    rating: 5,
    text: "I extend my heartfelt appreciation to Finsha for their service and support. Their dedication to guiding clients through the complexities of stocks market investment is truly commendable. The platforms are user-friendly, intuitive, and packed with features that make investment decisions easy and timely. The investment advice provided by their expert team is not only insightful but also relevant to individual needs for achieving their financial goals. Thank you, Finsha, for setting the standard in financial advisory services!",
    name: "Rajorshi Bhattacharjee",
    designation: "UI Designer, Dectox",
  },
  {
    rating: 4,
    text: "Finsha has completely transformed the way I approach stock market investing. Their service is not only affordable but incredibly efficient. I opted for their QuickWin annual plan, which starts at just Rs. 125 per month. Right after subscribing, I received a confirmation email and WhatsApp message detailing their strategy and how they operate. If you’re looking for a reliable advisory service, Finsha is the way to go!",
    name: "Monak Mishra",
    designation: "UI Designer, Dectox",
  },
  {
    rating: 4,
    text: "I've been using Finsha's investment advisory services for a few months now, and I must say, I’m impressed! Their strategy is clear, and they send timely updates during market hours, including all the crucial details like target prices, stop losses, and holding periods. What really stands out is their personalized touch; if you add your capital investment on their website, they even suggest the exact number of shares to buy. It’s a service that truly caters to both beginners and seasoned investors.",
    name: "Abhilash Singh",
    designation: "UI Designer, Dectox",
  },
  {
    rating: 5,
    text: "Finsha’s stock market advisory service has been a game-changer for me. I subscribed to their QuickWin quarterly plan, and the value I’ve received is incredible. The subscription process was seamless, with multiple payment options, including cards and UPI. Right after subscribing, I got a detailed message explaining their approach to recommendations. They send precise buy ranges, targets, stop loss, and even the holding period directly to my WhatsApp and email. Really recommended to subscribe!",
    name: "Aadil Mirza",
    designation: "UI Designer, Dectox",
  },
  {
    rating: 4,
    text: "Finsha is hands down the best investment advisory service I’ve tried. What sets them apart is they calculate the number of shares I should buy based on my investment amount, which saves me a lot of time and effort. The instant updates on WhatsApp when a target is hit or a stop loss is triggered are just icing on the cake. Highly recommend Finsha!",
    name: "Ashwani Bokadia",
    designation: "UI Designer, Dectox",
  },
  {
    rating: 4,
    text: "Finsha’s service is nothing short of amazing. What I really appreciate is their advice based on my capital investment, helping me decide exactly how many shares to buy. Their immediate alerts when it’s time to close a position have saved me from potential losses more than once. Finsha is a must-try for anyone serious about stock market investing.",
    name: "SarfarazAhmed",
    designation: "UI Designer, Dectox",
  },
];
