
import React from 'react';


const FloatingSearchModel = ({ isOpen, onClose, children }) => {
    return (
        <div className={`fixed inset-0 bg-gray-900 bg-opacity-80 flex items-center justify-center z-50 transition-opacity duration-500 ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
            <div className={`relative bg-white p-3 md:p-8 rounded-lg max-w-md md:max-w-lg w-full shadow-2xl transform transition-transform duration-500 ${isOpen ? 'scale-100' : 'scale-90'}`}>
                <button 
                    className="absolute top-2 right-2 md:top-4 md:right-4 border-2 border-transparent bg-red-600 text-white rounded-full w-6 h-6 md:w-8 md:h-8 flex items-center justify-center shadow-md hover:bg-red-700 transition-colors duration-300"
                    onClick={onClose}
                >
                    X
                </button>
                <h2 className='text-xl md:text-2xl font-bold mb-2 md:mb-4 text-center text-gray-800 border-b pb-1 font-playfair'>Stock Details Search</h2>
                {children}
            </div>
        </div>
    );
};

export default FloatingSearchModel;



