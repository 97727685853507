import React, { useState, useRef } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { constaints } from "../../../../utils/constaints";

const UploadIpoFromExcel = () => {
  const SERVER_URL =constaints.REACT_APP_SERVER_URL;
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      handleOpen("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("ipoFile", file);

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${SERVER_URL}/admin/add-ipo-excel`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      handleOpen("IPOs added successfully from Excel!");
      setFile(null); // Reset file state
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the input field
      }
    } catch (error) {
      console.error("Error uploading Excel file:", error);
      handleOpen("Failed to add IPOs from Excel.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = (message) => {
    setModalMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-gradient-to-br from-gray-900 to-gray-800 shadow-lg rounded-lg">
      <h2 className="text-3xl font-semibold mb-6 text-white text-center">
        Upload IPOs from Excel
      </h2>

      <form onSubmit={handleFileUpload} className="space-y-6">
        <div>
          <label className="block text-gray-400 mb-2 text-lg">
            Select Excel File
          </label>
          <div className="border-2 border-dashed border-gray-600 rounded-lg p-4 hover:border-gray-500 transition">
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              className="w-full text-white cursor-pointer bg-transparent"
              ref={fileInputRef} // Add ref here
            />
            {file && <p className="mt-2 text-gray-300">{file.name}</p>}
          </div>
        </div>

        <button
          type="submit"
          className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-2 px-4 rounded-lg w-full text-lg font-semibold hover:bg-gradient-to-l hover:from-purple-600 hover:to-indigo-500 transition"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Upload IPOs from Excel"
          )}
        </button>
      </form>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="bg-gray-800 text-white p-6 rounded-lg shadow-lg max-w-sm mx-auto mt-24 text-center">
          <h2 id="modal-title" className="text-2xl mb-4 font-semibold">
            Message
          </h2>
          <p id="modal-description" className="mb-4">
            {modalMessage}
          </p>
          <button
            onClick={handleClose}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-full transition"
          >
            Close
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default UploadIpoFromExcel;
