import "./section.css";

export const Section = ({children, ...props}) => {
    return (
        <section className="section" {...props}>
            <div className="section__container">
            {children}
            </div>
        </section>
    )
}