import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./Watchlist.css";
import { AuthContext } from "../../Shared/context/auth-context";
import { FaRegListAlt } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { Skeleton } from "@mui/material";
import WatchlistTable from "../components/Watchlist Component/WatchlistTable";
import { constaints } from "../../utils/constaints";

function Watchlist(props) {
  const [watchlist, setWatchlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  const { user } = useContext(AuthContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchWatchlist = async () => {
      try {
        //send userId or user details to fetch corresponding watchlist
        //every person should have a unique watchlist
        const response = await axios.get(`${SERVER_URL}/finsha/watchlist`, {
          withCredentials: true,
        });
        setWatchlist(response.data);
        setLoading(false);
      } catch (error) {
        // console.error('Error fetching watchlist:', error);
      }
    };
    fetchWatchlist();
  }, [user]);

  const handleRemoveFromWatchlist = async (item) => {
    try {
      await axios.delete(`${SERVER_URL}/finsha/watchlist/${item._id}`);
      const updatedWatchlist = watchlist.filter(
        (watchlistItem) => watchlistItem._id !== item._id
      );
      setWatchlist(updatedWatchlist);
    } catch (error) {
      console.error("Error removing company from watchlist:", error);
    }
  };

  const getFinalStatusColor = (company) => {
    if (company["Business Permissiblity Status"] !== "Yes") return "red";
    if (
      company["Interest to Revenue"] < 0.05 &&
      company["Loan to Market Cap"] < 0.33 &&
      company["Investment to Market Cap"] < 0.33
    )
      return "green";

    return "red";
  };

  const getFinalStatusText = (company) => {
    if (company["Business Permissiblity Status"] !== "Yes")
      return "Non-Compliant";
    if (
      company["Interest to Revenue"] < 0.05 &&
      company["Loan to Market Cap"] < 0.33 &&
      company["Investment to Market Cap"] < 0.33
    )
      return "Compliant";

    return "Non-Compliant";
  };

  const WatchlistSkeleton = () => {
    return (
      <div className="finsha-watchlist-container">
        <h1 className="watchlist-heading" itemProp="name">
          My Watchlist
        </h1>
        <div
          className="finsha-watchlist-container"
          style={{
            backgroundColor: "white",
            marginTop: "2rem",
            border: "3px solid black",
            borderRadius: "10px",
          }}
        >
          <div className="skeleton-table">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                variant="text"
                width={200}
                height={70}
                style={{ margin: "0 10px" }}
              />
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                style={{ marginLeft: "500px" }}
              />
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                style={{ marginLeft: "300px" }}
              />
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                style={{ marginLeft: "300px" }}
              />
            </div>

            {/* Table Header */}
            <div
              className="skeleton-table-header"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              {[...Array(8)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  width={300}
                  height={60}
                  style={{ margin: "0 10px" }}
                />
              ))}
            </div>
            {/* Table Rows */}
            {[...Array(5)].map((_, rowIndex) => (
              <div
                key={rowIndex}
                className="skeleton-table-row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                {[...Array(8)].map((_, colIndex) => (
                  <Skeleton
                    key={colIndex}
                    variant="text"
                    width={300}
                    height={40}
                    style={{ margin: "0 8px" }}
                  />
                ))}
              </div>
            ))}
          </div>

          {/* Pagination Skeleton */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "20px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
          >
            {/* First Text Skeleton */}
            <Skeleton
              variant="text"
              width={90}
              height={50}
              style={{ marginRight: "10px" }}
            />
            {/* Second Text Skeleton */}
            <Skeleton
              variant="text"
              width={90}
              height={50}
              style={{ marginRight: "10px" }}
            />
            {/* Third Text Skeleton */}
            <Skeleton
              variant="text"
              width={120}
              height={50}
              style={{ marginRight: "10px" }}
            />
            {/* First Circle Skeleton */}
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              style={{ marginRight: "5px" }}
            />
            {/* Second Circle Skeleton */}
            <Skeleton variant="circular" width={40} height={40} />
          </div>
        </div>
      </div>
    );
  };

  const EmptyWatchlistSkeleton = () => {
    return (
      <div className="watchlist-empty-message">
        <Skeleton
          variant="circular"
          width={60}
          height={60}
          style={{ marginBottom: "20px" }}
        />{" "}
        <Skeleton
          variant="rectangular"
          height={40}
          width="80%"
          style={{ marginBottom: "10px" }}
        />{" "}
        <Skeleton
          variant="rectangular"
          height={20}
          width="60%"
          style={{ marginBottom: "10px" }}
        />{" "}
        <Skeleton
          variant="rectangular"
          height={20}
          width="70%"
          style={{ marginBottom: "10px" }}
        />{" "}
      </div>
    );
  };

  return (
    <>
      {loading && (
        <div>
          <WatchlistSkeleton />
          <EmptyWatchlistSkeleton />
        </div>
      )}

      <div className="finsha-watchlist-container">
        <Helmet>
          <title>Monitor Your Stocks With Our Watchlist Feature | Finsha</title>
          <meta
            name="description"
            content="Create a custom watchlist and stay updated on the performance of your favorite stocks."
          />
          <meta
            name="keywords"
            content="Watchlist, Stock Monitoring, Personalized Tracking"
          />
          <meta name="tags" content="Stock Watchlist, Investment Monitoring" />
        </Helmet>
        <div itemScope itemType="http://schema.org/WebPage">
          <h1 className="watchlist-heading__title" itemProp="name">
            My Watchlist
          </h1>

          <meta
            itemProp="description"
            content="Create a custom watchlist and stay updated on the performance of your favorite stocks."
          />
          {watchlist.length === 0 ? (
            <div className="flex flex-col items-center justify-center text-center mt-10">
              <FaRegListAlt size={60} className="text-gray-600 mb-4" />{" "}
              {/* Render the icon */}
              <h2 className="text-2xl font-semibold text-gray-800 mb-2 flex items-center">
                Your watchlist is currently empty{" "}
                <FaTrash className="ml-2 text-red-500" />
              </h2>
              <p className="text-gray-600 mb-1">
                Add some companies to your watchlist and they will appear here.
              </p>
              <p className="text-gray-600">
                By adding to watchlist you can analyse the stock for some days
                without searching.
              </p>
            </div>
          ) : (
            <div className="Main_Watchlist_Conatiner">
              <WatchlistTable
                watchlist={watchlist}
                handleRemoveFromWatchlist={handleRemoveFromWatchlist}
                getFinalStatusColor={getFinalStatusColor}
                getFinalStatusText={getFinalStatusText}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Watchlist;
