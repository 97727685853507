import { Icon } from "../icon/Icon"
import "./button.css"

export const Button = ({variant="filled", color="primary", label, icon, iconPos="start", size="medium", ...props}) => {
    switch (variant) {
        case "filled" : return (<FilledButton color={color} label={label} icon={icon} iconPos={iconPos} size={size} {...props} />)
        case "tonal" : return (<TonalButton color={color} label={label} icon={icon} iconPos={iconPos} size={size} {...props} />)
        case "ghost" : return (<GhostButton color={color} label={label} icon={icon} iconPos={iconPos} size={size} {...props} />)
        case "icon-filled" : return (<IconButton color={color} variant="filled" icon={icon} size={size} {...props} />)
        case "icon-tonal" : return (<IconButton color={color} variant="tonal" icon={icon} size={size} {...props} />)
        case "icon-ghost" : return (<IconButton color={color} variant="ghost" icon={icon} size={size} {...props} />)
        default: return (<FilledButton label={label} icon={icon} iconPos={iconPos} size={size} {...props} />)
    }
}

const FilledButton = ({label, color, icon, iconPos, size, ...props}) => {
    return (
        <button className={`filled-button filled-button-${color} filled-button--${size} filled-button--icon-${iconPos}`} {...props}>
            <div className="filled-button__state filled-button__state--opacity"></div>
            {icon&&<Icon color="inherit">{icon}</Icon>}
            <label className="filled-button__label">{label}</label>
        </button>
    )
}

const TonalButton = ({label, color, icon, iconPos, size, ...props}) => {
    return (
        <button className={`tonal-button tonal-button--${size} tonal-button--icon-${iconPos}`} {...props}>
            <div className="tonal-button__state tonal-button__state--opacity"></div>
            {icon&&<Icon color="inherit">{icon}</Icon>}
            <label className="tonal-button__label">{label}</label>
        </button>
    )
}

const GhostButton = ({label, color, icon, iconPos, size, ...props}) => {
    return (
        <button className={`ghost-button ghost-button--${size} ghost-button--icon-${iconPos}`} {...props}>
            {icon&&<Icon color="inherit">{icon}</Icon>}
            <label className="ghost-button__label">{label}</label>
        </button>
    )
}

const IconButton = ({variant, color, icon, size, ...props}) => {
    return (
        <button className={`icon-button icon-button--${color}-${variant} icon-button--${size}`} {...props}>
            <div className={`icon-button__state icon-button__state--${variant} icon-button__state--opacity`}></div>
            {icon&&<Icon color="inherit">{icon}</Icon>}
        </button>
    )
}