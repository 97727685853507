
// this modal is basically used int he add-blog/add paeg so that if we need any type of table content in the blog page so that we can add table in the blog

import React, { useState } from "react";

const TableModal = ({ open, onClose, handleAddTable }) => {
  const [rows, setRows] = useState(2);
  const [cols, setCols] = useState(2);

  const handleSubmit = () => {
    if (rows > 0 && cols > 0) {
      handleAddTable(rows, cols);
      onClose();
    }
  };

  if (!open) return null; // Render nothing if modal is not open

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 text-white">
      <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-md">
        <h2 className="text-xl font-bold mb-4 text-white">Add Table</h2>
        <div className="mb-4">
          <label
            htmlFor="rows"
            className="block text-sm font-medium text-on-surface-low mb-2"
          >
            Rows
          </label>
          <input
            id="rows"
            type="number"
            value={rows}
            onChange={(e) => setRows(parseInt(e.target.value, 10) || 0)}
            className="w-full p-2 rounded-md bg-gray-800 border border-gray-700 text-white placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            placeholder="Enter number of rows"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="columns"
            className="block text-sm font-medium text-on-surface-low mb-2"
          >
            Columns
          </label>
          <input
            id="columns"
            type="number"
            value={cols}
            onChange={(e) => setCols(parseInt(e.target.value, 10) || 0)}
            className="w-full p-2 rounded-md bg-gray-800 border border-gray-700 text-white placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            placeholder="Enter number of columns"
          />
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium bg-red-600 hover:bg-red-700 text-white rounded-md focus:ring-2 focus:ring-red-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium bg-blue-600 hover:bg-blue-700 text-white rounded-md focus:ring-2 focus:ring-blue-400"
          >
            Add Table
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableModal;
