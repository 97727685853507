import React, { useRef } from "react";
// import QuickWinStarterSubscription from "../Subscription-form/LandingPage/QuickWinStarterSubscription";
import LandingPageSubscription from "../Subscription-form/LandingPagePlan/page/LandingPagePlan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faPlay } from "@fortawesome/free-solid-svg-icons";
import circleArrowRight from "../images/circlearrow.png";
import shariahCertificate from "../images/Shariah-certificate.jpeg";
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import ParticlesBackground from "./ParticlesBackground";
import { trackEvent } from "../utils/analytics";

import { features } from "./util/userData";
import FeatureList from "./FeatureList";

import { CardData } from "./util/userData";
import CardFeatureList from "./CardFeatureList";

import { users } from "./util/userData";
import UserExperienceList from "./UserExperienceList";

import PortfolioStrategy from "./PortfolioStrategy";
import QuickFinshaFeatureList from "./QuickFinshaFeatureList";

const LandingPage = () => {
  const subscriptionRef = useRef(null);

  const handleButtonClick = () => {
    trackEvent("User", "Clicked Subscribe Button", "Pricing");
    if (subscriptionRef.current) {
      subscriptionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="max-w-screen overflow-hidden mx-auto font-roboto  ">
        <div
          className=" w-full mx-auto mb-[200px] relative z-10"
          style={{ zIndex: 1 }}
        >
          <div
            className=" bg-green-700 mx-auto h-[70px] flex mt-[72px] justify-center items-center z-50 fixed top-0  w-full"
            style={{ zIndex: 2 }}
          >
            <div className="flex md:gap-44 lg:gap-48  items-center max-sm:flex-shrink ">
              <p className="flex items-center text-xl text-white whitespace-nowrap font-semibold max-sm:text-[15px] max-sm:text-center max-sm:whitespace-normal px-1">
                Monthly Plan Starting at INR{" "}
                {/* <span className="line-through text-black mx-2 inline">7999</span> */}
                <span className="line-through text-lg font-medium text-gray-300 max-sm:mx-1 mx-2">
                  7999
                </span>
                <span className="bg-yellow-400 px-3 py-1 rounded-full font-bold text-green-950">
                  2,499/-
                </span>
              </p>
              <a onClick={handleButtonClick} className="scroll-smooth mr-1">
                <button className="bg-[#fec661] text-green-950 rounded-2xl px-6 py-2 whitespace-nowrap text-lg font-semibold max-sm:text-[14px] max-sm:px-2 hover:scale-105 hover:bg-yellow-300 hover:text-green-900 hover:shadow-lg transition-all duration-200 ease-in-out">
                  Subscribe Now
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className=" max-w-[1200px] mx-auto mb-[100px] max-sm:mb-[80px] box-border shadow-2xl rounded-lg">
          <div className="flex justify-center items-center ">
            <p className="inline text-4xl font-bold max-md:text-2xl max-sm:text-[23px] max-sm:text-center ">
              Welcome to{" "}
              <span className="bg-gradient-to-r from-pink-600 via-green-600 to-violet-600 bg-clip-text text-transparent font-extrabold animate-pulse">
                Finsha{" "}
              </span>
            </p>
          </div>

          <div className="text-center">
            <p className=" font-lato font-semibold text-2xl max-sm:text-lg">
              Introducing the
              <span className="mix-blend-hard-light ml-2">
                SmartHold Pro Plan
              </span>
            </p>
            <p className="text-2xl font-semibold max-md:text-[22px] max-sm:text-[19px] ">
              SEBI Registered Research Analyst (INH000014270)
            </p>
          </div>
        </div>

        <div className="xl:w-[1200px] md:w-[580px] lg:w-[650px] mx-auto mb-[100px]  max-sm:mb-[80px] box-border w-full max-sm:w-[95%]">
          <div className="flex items-center gap-4 justify-center flex-col xl:flex-row">
            <div className="flex flex-col gap-1 aspect-video">
              <div className="shadow-2xl shadow-gray-950 mx-auto">
                <iframe
                  className=""
                  width="500"
                  height="280"
                  src="https://www.youtube.com/embed/5juEmLPgvPw?si=9N3gVi8xGj0EaX2X&autoplay=1&mute=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
              <a
                className=" text-black text-xl bg-[#fec661] py-2 max-xl:w-[86%] max-xl:mx-auto text-center"
                href="https://www.youtube.com/embed/5juEmLPgvPw?si=9N3gVi8xGj0EaX2X&autoplay=1&mute=1"
              >
                <button className="hover:text-gray-700">
                  <span>
                    <FontAwesomeIcon icon={faPlay} />
                  </span>{" "}
                  Watch in Youtube
                </button>
              </a>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4 max-sm:w-[97%] max-md:mx-auto max-md:w-[80%]">
                <div>
                  <FeatureList features={features} />
                </div>
              </div>

              <div className="text-center text-2xl text-green-700 mx-auto">
                Grow your fund with SEBI Registered Experts
              </div>
            </div>
          </div>
        </div>

        <div className="w-full xl:w-[1200px] mx-auto mb-[60px]  box-border">
          <div className="text-center">
            <h3 className="font-extrabold font-lato text-4xl animate-pulse hover:animate-none max-lg:text-3xl max-md:text-2xl  bg-gradient-to-r from-pink-500 via-green-500 to-violet-500 bg-clip-text text-transparent">
              Why choose Finsha's SmartHold Pack?
            </h3>
            <h4 className="text-3xl max-md:text-xl max-lg:text-2xl ">
              SEBI Registered Research Analyst (INH000014270)
            </h4>
            <p className="text-[22px] max-lg:text-lg max-md:text-[16px] text-start w-full md:w-[80%] mx-auto px-2">
              <strong>SmartHold Pack </strong> is focusing on long-term
              investments to maximize your return with optimized stock
              recommendations. Enjoy maximizing your earnings while paying
              minimum tax.
            </p>
          </div>
        </div>

        <div>
          <QuickFinshaFeatureList />
        </div>

        <div>
          <PortfolioStrategy />
        </div>

        <div className="bg-[#286e34] mb-[65px]">
          <div className="text-center  text-4xl text-white py-4 max-md:text-3xl max-sm:text-2xl ">
            Our Past Performance
          </div>
        </div>

        <div className="w-full xl:w-[1200px] mx-auto mb-[75px] box-border">
          <div className="flex gap-8 justify-center items-center mb-[60px] max-sm:gap-6">
            <div className="text-[#286e34] text-5xl font-bold xl:w-[43%] max-md:text-4xl max-sm:text-3xl max-sm:ml-1">
              Average Holding period
            </div>
            <div className="text-[#e6c787] text-6xl font-bold whitespace-nowrap max-md:text-5xl max-sm:mr-1 ">
              {" "}
              8 days
            </div>
          </div>

          <div className="w-full lg:w-[1000px] mx-auto mb-[100px] box-border flex justify-between items-center flex-row-reverse">
            <div className="flex flex-col gap-2 justify-center items-center">
              <div className="text-[#535353] text-5xl max-md:text-4xl max-sm:text-[34px] ">
                Ideas closed: 10
              </div>
              <div className="text-stone-900 text-6xl max-md:text-5xl max-sm:text-4xl">
                Idea Hit Accuracy :{" "}
                <span className=" lg:bg-gradient-to-r from-blue-400 via-orange-300 to-lime-300 font-extrabold rounded-lg text-green-950">
                  90%
                </span>
              </div>
            </div>

            <div className="max-md:w-[33%] max-sm:w-[45%] lg:w-[24%] xl:w-[26%]">
              <Doughnut
                data={{
                  labels: ["Hit", "Miss"],
                  datasets: [
                    {
                      label: "Ideal Hit Accuracy",
                      data: [9, 1],
                      backgroundColor: [
                        "rgba(2, 196, 2, 1)",
                        "rgba(217, 63, 53, 1)",
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>

        <div
          ref={subscriptionRef}
          className="text-center text-3xl font-semibold mb-[10px] max-sm:text-2xl"
        >
          SEBI Registered Research Analyst (INH000014270)
        </div>

        {/* <div className="relative "> */}
        {/* Particles Background */}
        {/* <ParticlesBackground /> */}

        {/* Content Section */}
        {/* <div className="bg-gradient-to-r from-orange-400 via-white to-green-600"> */}
        {/* <div className="relative w-full lg:w-[800px] mx-auto">
  <div className="flex flex-col gap-3 justify-center items-center">
    <div className="font-semibold text-green-800 text-4xl mix-blend-multiply max-md:text-3xl max-sm:text-2xl text-center bg-gradient-to-r from-slate-300 via-slate-100 to-slate-300 px-2 rounded-md">
      Enjoy Exclusive Savings Every Day!
    </div>

    <div className="text-lg md:w-[500px] w-[90%] text-center leading-tight -mb-7 max-sm:-mb-12 max-md:-mb-9">
      Take advantage of our 12-Month Plan at a special price. Your journey to savings and growth starts here!
    </div>
  </div>
</div> */}

        <div className="relative mb-2 scroll-smooth p-2">
          {/* <QuickWinStarterSubscription /> */}
          <LandingPageSubscription />
        </div>

        {/* <div className="relative">
        <p className="text-3xl max-sm:text-xl max-lg:text-2xl text-stone-800 text-center mb-2">
            Offer valid for a limited time only!
          </p>
          <p className="text-3xl max-sm:text-xl max-lg:text-2xl text-stone-800 text-center mb-4">
            Subscribe today and take charge of your financial freedom.
          </p>
        </div>

        <div className="relative ">
          <hr className="bg-gradient-to-r from-red-900 via-stone-700 to-green-900 h-1 text-transparent"></hr>
        </div>
        {/* </div> */}
        {/* </div> */}

        <div className="mt-[70px] z-10">
          <div className="">
            <h1 className="text-[#286e34] mx-auto text-4xl text-center max-md:w-screen max-lg:w-[80%] max-md:text-3xl  max-sm:text-2xl">
              Benefits and Features Muslim Investor will get from
            </h1>
            <h2 className="text-center font-lato font-bold text-3xl max-md:text-2xl max-sm:text-xl text-lime-950">
              Finsha's SmartHold{" "}
              <span className="font-roboto font-normal text-[#286e34]">
                Plan
              </span>
            </h2>
            <h3 className="text-center text-3xl font-semibold mb-[10px] max-md:text-2xl max-sm:text-xl">
              SEBI Registered Research Analyst (INH000014270)
            </h3>
          </div>
          <div>
            <CardFeatureList CardData={CardData} />
          </div>
        </div>

        <div>
          <hr className="bg-gradient-to-r from-red-900 via-stone-700 to-green-900 h-1 text-transparent"></hr>
        </div>

        <div className=" xl:w-[1200px] mx-auto mb-[100px] max-sm:mb-[70px] box-border mt-[40px] z-10 ">
          <div className="text-[#286e34] mx-auto text-4xl text-center max-md:w-screen max-lg:w-[80%] max-md:text-3xl  max-sm:text-2xl ">
            Do You Wish To Be A Compliant Investor ?
          </div>
        </div>

        <div className=" w-full xl:w-[1200px] mx-auto mb-[100px] max-sm:mb-[70px]  box-border ">
          <div className="flex justify-center items-center gap-4 max-lg:flex-col max-lg:w-screen">
            <div className="flex flex-col w-[43%] gap-3 max-sm:w-w-screen max-md:w-[76%] max-lg:w-[52%]">
              <div className="flex justify-start items-center mb-2">
                <img
                  className="inline w-7 h-7 mr-2"
                  src={circleArrowRight}
                  alt="Arrow"
                ></img>
                <p className="inline text-xl max-sm:text-lg">
                  Do you struggle to find Compliant stock?
                </p>
              </div>
              <div className="flex justify-start items-center mb-2">
                <img
                  className="inline w-7 h-7 mr-2"
                  src={circleArrowRight}
                  alt="Arrow"
                ></img>
                <p className="inline text-xl max-sm:text-lg">
                  Are you confused while investing by following Islamic shariah?
                </p>
              </div>
              <div className="flex justify-start items-center mb-2">
                <img
                  className="inline w-7 h-7 mr-2"
                  src={circleArrowRight}
                  alt="Arrow"
                ></img>
                <p className="inline text-xl max-sm:text-lg ">
                  Do you feel you’re weak or helpless while finding Compliant
                  stock ?
                </p>
              </div>
              <div className="flex justify-start items-center">
                <img
                  className="inline w-7 h-7 mr-2"
                  src={circleArrowRight}
                  alt="Arrow"
                ></img>
                <p className="inline text-xl max-sm:text-lg">
                  Do you struggle in preparing your Compliant portfolio?
                </p>
              </div>

              <div className="flex justify-start items-center">
                <img
                  className="inline w-7 h-7 mr-2"
                  src={circleArrowRight}
                  alt="Arrow"
                ></img>
                <p className="inline text-xl max-sm:text-lg">
                  what is the purgable income from my investments?
                </p>
              </div>

              <div className="flex justify-start items-center">
                <img
                  className="inline w-7 h-7 mr-2"
                  src={circleArrowRight}
                  alt="Arrow"
                ></img>
                <p className="inline text-xl max-sm:text-lg">
                  Can I track the Compliant status of my investments in
                  real-time?
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="shadow-2xl shadow-gray-950 border-sky-300 border-2">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/qjkf0jw36Ss?si=4LQezBW2FzrJhu8A&autoplay=1&mute=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
              <a
                className=" text-black text-xl bg-[#fec661]  py-2 text-center"
                href="https://youtu.be/qjkf0jw36Ss?si=KGRt75TEd6C7VtCm&autoplay=1&mute=1"
              >
                <button className="hover:text-gray-700 text-center ">
                  {" "}
                  <span>
                    <FontAwesomeIcon icon={faPlay} />
                  </span>{" "}
                  Mufti Sb video link
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-[1200px] mx-auto mb-[35px]  box-border">
          <div className="text-[22px] text-center bg-[#fec661] w-full md:w-[75%] mx-auto px-2 py-3 max-sm:text-[20px]">
            If your answer to any of the above questions is "YES", then this
            subscription is designed exclusively for you !
          </div>
        </div>

        <div className="w-full xl:w-[1200px] mx-auto mb-[40px] box-border">
          <div className="mb-[40px]">
            <h1 className="text-4xl text-[#286e34] font-bold mx-auto max-sm:text-3xl text-center">
              Certified Shariah Compliant{" "}
            </h1>
          </div>
          <img
            src={shariahCertificate}
            alt="shariahCertificate"
            className="w-full"
          ></img>
        </div>

        <div className="bg-[#286e34] mb-[65px]">
          <div className="text-center  text-4xl text-white py-4 max-md:text-3xl max-sm:text-2xl">
            What Our Users Say
          </div>
        </div>

        <div className="mb-[40px]">
          <UserExperienceList users={users} />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
