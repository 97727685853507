import React, { useState, useEffect } from "react";
import axios from "axios";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Skeleton from "@mui/material/Skeleton";
import { constaints } from "../../../utils/constaints";

function PercentageGain({ symbol, staticPrice, className }) {
  const [netGain, setNetGain] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  useEffect(() => {
    let isMounted = true;

    const fetchRealTimePrice = async () => {
      try {
        const priceRes = await axios.get(
          `${SERVER_URL}/screener/current-price/${symbol}`,
          { withCredentials: true }
        );
        if (isMounted) {
          const fetchedPrice = priceRes.data.price;
          // Calculate the percentage gain
          const gain = ((fetchedPrice - staticPrice) / staticPrice) * 100;
          setNetGain(gain);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchRealTimePrice();

    return () => {
      isMounted = false;
    };
  }, [symbol, staticPrice, SERVER_URL]);

  return (
    <div className={className}>
      {loading && <Skeleton variant="rectangular" width={400} height={30} />}
      {error && <p>Error</p>}
      {!loading && !error && netGain !== null && (
        <h4 className={netGain >= 0 ? "positive" : "negative"}>
          {netGain >= 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}{" "}
          {netGain.toFixed(2)}%
        </h4>
      )}
    </div>
  );
}

export default PercentageGain;
