import { NavLink } from "react-router-dom";
import { Box } from "../../components/functional/box/Box";
import { Grid } from "../../components/functional/grid/Grid";
import { Section } from "../../components/functional/section/Section";
import { Button } from "../../components/ui/button/Button";
import { Card } from "../../components/ui/card/Card";
import { Icon } from "../../components/ui/icon/Icon";
import { Text } from "../../components/ui/text/Text";
import { AlignRight } from "react-feather";

export const ReasonSection = () => {
  return (
    <Section>
      <Box variant="row" gap="md">
        <Box>
          <Grid gap="md">
            {cardData.map((card, index) => (
              <ReasonCard title={card.title} icon={card.icon} />
            ))}
          </Grid>
        </Box>
        <Box gap="lg" paddingX="xl">
          <Text as="h2" variant="heading">
            Your Path to Ethical and Profitable Investing Starts Here
          </Text>
          <Text as="p" variant="body" color="variant">
            We combine expert research with values-driven strategies to guide
            you in the stock market.
          </Text>
          <Box variant="row">
            <NavLink to="/screener">
              <Button
                variant="tonal"
                label="Explore all"
                icon="arrow_forward"
                iconPos="end"
              />
            </NavLink>
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

const ReasonCard = ({ title, icon }) => {
  return (
    <Card gap="xs">
      <Icon size="large" color="primary">
        {icon}
      </Icon>
      <Text as="h3" variant="title" style={{ textAlign: "center" }}>
        {title}
      </Text>
    </Card>
  );
};

const cardData = [
  {
    title: "Free portfolio analysis",
    icon: "search_insights",
  },
  {
    title: "Buy, Sell, Hold insights",
    icon: "thumbs_up_down",
  },
  {
    title: "Trusted stock ideas",
    icon: "shield_with_heart",
  },
  {
    title: "High return investments",
    icon: "paid",
  },
];
