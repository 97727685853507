import { NavLink } from "react-router-dom";
import { Box } from "../../components/functional/box/Box";
import { Grid } from "../../components/functional/grid/Grid";
import { Section } from "../../components/functional/section/Section";
import { Button } from "../../components/ui/button/Button";
import { Card } from "../../components/ui/card/Card";
import { Icon } from "../../components/ui/icon/Icon";
import { Text } from "../../components/ui/text/Text";

export const ValuesSection = () => {
  return (
    <Section>
      <Box variant="row" gap="md">
        <Box>
          <Grid gap="md">
            {cardData.map((card, index) => (
              <ValuesCard title={card.title} icon={card.icon} />
            ))}
          </Grid>
        </Box>
        <Box gap="lg" paddingX="xl">
          <Text as="h2" variant="heading">
            Align Your Investments with Your Values
          </Text>
          <Text as="p" variant="body" color="variant">
            Finsha helps you grow your wealth while staying true to your values,
            with investments guided by Islamic principles and ethical practices.
          </Text>
          <Box variant="row">
            <NavLink to="screener">
              <Button
                variant="tonal"
                label="Discover Compliant Stocks"
                icon="arrow_forward"
                iconPos="end"
              />
            </NavLink>
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

const ValuesCard = ({ title, icon }) => {
  return (
    <Card gap="xs">
      <Icon size="large" color="primary">
        {icon}
      </Icon>
      <Text as="h3" variant="title" style={{ textAlign: "center" }}>
        {title}
      </Text>
    </Card>
  );
};

const cardData = [
  {
    title: "Invest with Your Values",
    icon: "diamond",
  },
  {
    title: "Ethical and Faith-Based Choices",
    icon: "editor_choice",
  },
  {
    title: "Clear and Honest process",
    icon: "volunteer_activism",
  },
  {
    title: "Focus on Growth and Impact",
    icon: "trending_up",
  },
];
