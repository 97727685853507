import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

const RatingStarComponent = ({ value, size = 'default' }) => {
  const normalizedValue = Math.min(10, Math.max(0, Number(value) || 0));
  const fullStars = Math.floor(normalizedValue / 2);
  const isHalfStar = normalizedValue % 2 !== 0;
  const outlineStars = 5 - fullStars - (isHalfStar ? 1 : 0);

  return (
    <div>
      {[...Array(fullStars)].map((_, i) => (
        <StarIcon
          key={`full-${i}`}
          style={{ color: "#06D001", fontSize: size }}
        />
      ))}
      {isHalfStar && (
        <StarHalfIcon style={{ color: "#06D001", fontSize: size }} />
      )}
      {[...Array(outlineStars)].map((_, i) => (
        <StarOutlineIcon
          key={`outline-${i}`}
          style={{ color: "orange", fontSize: size }}
        />
      ))}
    </div>
  );
};

export default RatingStarComponent;

