import React, { useState, useEffect, useContext } from "react";
import useTheme from "../../hooks/Theme";
import "./home.css";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import FinshaLogo from "../../images/name.png";
import IndexChart from "../component/indexchart";
import { Skeleton } from "@mui/material";
import SearchBar from "../../Shared/SearchBar/SearchBar";
import { AuthContext } from "../../Shared/context/auth-context";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { addCompanies } from "../../utils/companiesSlice";
import axios from "axios";
import { Button } from "../../components/ui/button/Button";
import { Text } from "../../components/ui/text/Text";
import { Section } from "../../components/functional/section/Section";
import { Box } from "../../components/functional/box/Box";

import { Screen } from "../../components/functional/screen/Screen";

import { HeroSection } from "../component/Hero";
import { ReasonSection } from "../component/Reason";
import { MarketSection } from "../component/Market";
import { MockupSection } from "../component/Mockup";
import { StepsSection } from "../component/Steps";
import { ValuesSection } from "../component/Values";
import { ReviewsSection } from "../component/Reviews";
import { FaqSection } from "../component/Faq";
import { constaints } from "../../utils/constaints";

function Home() {
  const [page, setPage] = useState(1);
  const [searchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [fontSize, setFontSize] = useState(getFontSize(window.innerWidth));
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const { user } = useContext(AuthContext);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  const companiess = useSelector((store) => store.companies.companies); //subscribing to the store
  const dispatch = useDispatch(); //writing data to store

  const { theme, toggleTheme } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, searchQuery]);

  useEffect(() => {
    const handleResize = () => {
      setFontSize(getFontSize(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getFontSize(width) {
    if (width <= 768) {
      return "0.8em";
    } else if (width <= 1024) {
      return "0.9em";
    } else {
      return "1em";
    }
  }

  const fetchCompanyNames = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/screener/companies?limit=7`
      );
      const data = response.data;
      // sorting in ascending order
      const sortedData = data.data.sort((a, b) => {
        if (a["Company Name"] < b["Company Name"]) {
          return -1;
        }
        if (a["Company Name"] > b["Company Name"]) {
          return 1;
        }
        return 0;
      });
      dispatch(addCompanies(sortedData));
    } catch (error) {
      console.error("Error fetching company suggestions:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCompanyNames(); // Fetch company names when the component mounts
  }, []);

  const history = useHistory();

  // replace"%" with "-"
  const handleSelectResult = (company) => {
    // Replace spaces with hyphens
    const companyNameWithHyphens = company["Company Name"]
      ? company["Company Name"].replace(/ /g, "-")
      : "";

    // Define the behavior for selecting a result
    history.push(
      `/company-name/${encodeURIComponent(companyNameWithHyphens)}`,
      {
        company: company,
      }
    );
  };

  useEffect(() => {
    if (user) {
      setShowLoginPrompt(false); // Hide prompt if user is logged in
      return;
    }

    const loginPromptClosedAt = localStorage.getItem("loginPromptClosedAt");
    const now = new Date().getTime();

    // Function to show login prompt
    const showLoginPromptIfNeeded = () => {
      if (!user) {
        setShowLoginPrompt(true);
      }
    };

    // Check if the prompt was closed within the last hour
    if (loginPromptClosedAt && now - loginPromptClosedAt < 60 * 60 * 1000) {
      // It's been less than 1 hour since the prompt was closed, set timer to show after 1 hour
      const delay = 60 * 60 * 1000 - (now - loginPromptClosedAt);
      const timer = setTimeout(showLoginPromptIfNeeded, delay);
      return () => clearTimeout(timer);
    } else {
      // Show immediately if not logged in
      showLoginPromptIfNeeded();
    }
  }, [user]);

  const handleCloseLoginPrompt = () => {
    const now = new Date().getTime(); // Current time in milliseconds
    localStorage.setItem("loginPromptClosedAt", now);
    setShowLoginPrompt(false);
  };

  const googleLogin = () => {
    window.open(`${SERVER_URL}/auth/login/federated/google`, "_self");
  };

  return (
    <>
      {loading ? (
        <div>
          <div
            className="Welcome_text_div_home"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "9rem",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={200}
              height={50}
              style={{ marginRight: "5px" }}
            />
            <Skeleton
              variant="trianglular"
              width={50}
              height={50}
              style={{ marginRight: "5px" }}
            />
            <Skeleton variant="rectangular" width={360} height={50} />
          </div>
          <div
            className="Home_page_search_div"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "4rem",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={600}
              height={60}
              style={{ borderRadius: "25px" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "10px",
              marginBottom: "5rem",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={300}
              height={30}
              style={{ marginRight: "10px" }}
            />
            {[...Array(5)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={200}
                height={30}
                style={{ borderRadius: "10px" }}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Compliant Stock Screener & Financial Indices | Finsha</title>
            <meta
              name="description"
              content="Explore Compliant and Non-Compliant stocks with our advanced screener. Stay updated with major indices like Nifty50 and Sensex30."
            />
            <meta
              name="keywords"
              content="Compliant Stocks, Stock Screener, Financial Indices"
            ></meta>
            <meta
              name="tags"
              content="Stock Screener, Compliant and Non-Compliant Stocks, Nifty50, Sensex30"
            />
          </Helmet>
          <Screen>
            <HeroSection />
            <ReasonSection />
            <MarketSection />
            <MockupSection />
            <StepsSection />
            <ValuesSection />
            <ReviewsSection />
            <FaqSection />
          </Screen>
        </>
      )}
      <IndexChart />
    </>
  );
}

export default Home;
