import { useContext, useRef } from "react";
import { AuthContext } from "../../Shared/context/auth-context";
import { Box } from "../../components/functional/box/Box";
import { Button } from "../../components/ui/button/Button";
import useTheme from "../../hooks/Theme";
import { Text } from "../../components/ui/text/Text";
import { useState } from "react";
import { NavLink, useHistory, Link, useLocation } from "react-router-dom";
import Logo from "../../icons/finsha_icon.svg";
import LogoDark from "../../icons/finsha_icon_dark.svg";
import "./Navbar.css";

export const Navbar = () => {
  const { theme, toggleTheme } = useTheme();
  const { isLoggedIn, user, logout } = useContext(AuthContext);

  const [isHovered, setIsHovered] = useState(false);

  const profileRef = useRef();

  const handleHover = () => {
    setIsHovered(true);
  };
  const handleUnhover = () => {
    setIsHovered(false);
  };

  return (
    <div className="navbar">
      {isHovered && (
        <DropDown
          handleHover={handleHover}
          handleUnhover={handleUnhover}
          items={navbarItems}
          logout={logout}
        />
      )}
      <NavLink to="/">
        <Box variant="row" style={{ gap: "4px", cursor: "pointer" }}>
          <img
            width={32}
            height={32}
            src={theme === "light" ? Logo : LogoDark}
            alt="Finsha Logo"
          ></img>
          <label className="navbar__logo-text" style={{ cursor: "pointer" }}>
            Finsha
          </label>
        </Box>
      </NavLink>
      <Box variant="row" gap="xl" justifyContent="end">
        <NavbarItem label="Home" href="/" />
        <NavbarItem label="Screener" href="/screener" />
        <NavbarItem label="FinView" href="/finview" />
        {/* <NavbarItem label="Pricing" href="/pricing" /> */}
        {isLoggedIn && <NavbarItem label="Stock Idea" href="/stock-idea" />}
        <Box variant="row" gap="md" width="fit">
          <Button
            variant="icon-ghost"
            size="small"
            color="neutral"
            icon={theme === "light" ? "dark_mode" : "light_mode"}
            onClick={toggleTheme}
          />
          {!isLoggedIn ? (
            <NavLink to="/auth">
              <Button size="small" icon="person" label="Login" />
            </NavLink>
          ) : (
            <Button
              onMouseEnter={handleHover}
              onMouseLeave={handleUnhover}
              variant="icon-tonal"
              size="small"
              icon="person"
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

const NavbarItem = ({ label, href }) => {
  const location = useLocation();

  const isSelected =
    href === "/"
      ? location.pathname === "/"
      : location.pathname.startsWith(href);

  return (
    <NavLink to={href}>
      <div className="nav-item">
        <Text
          as="a"
          variant="label"
          color={isSelected ? "primary" : "default"}
          width="fit"
        >
          {label}
        </Text>
        <div
          className={`nav-item__radio nav-item__radio--hovered ${
            isSelected ? "nav-item__radio--selected" : ""
          }`}
        ></div>
      </div>
    </NavLink>
  );
};

const DropDown = ({ items, handleHover, handleUnhover, logout }) => {
  return (
    <div
      className="navbar__dropdown"
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
    >
      <div className="dropdown__container">
        {items.map((item, index) => (
          <NavLink to={item.href} key={index}>
            <button className={`dropdown__button dropdown__button--neutral`}>
              <span className="dropdown-button__icon">{item.icon}</span>
              <label className="dropdown-button__label">{item.label}</label>
            </button>
          </NavLink>
        ))}
        <button
          className={`dropdown__button dropdown__button--error`}
          onClick={logout}
        >
          <span className="dropdown-button__icon">logout</span>
          <label className="dropdown-button__label">Logout</label>
        </button>
      </div>
    </div>
  );
};

const navbarItems = [
  {
    label: "Portfolio",
    icon: "business_center",
    href: "/user-portfolio",
  },
  {
    label: "Watclist",
    icon: "visibility",
    href: "/watchlist",
  },
  {
    label: "Account",
    icon: "account_circle",
    href: "/user-profile",
  },
];
