import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import "./Screener.css";
import { Helmet } from "react-helmet";
import SearchBar from "../../Shared/SearchBar/SearchBar";
import MUIDataTable from "mui-datatables";
import { Skeleton } from "@mui/material";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { constaints } from "../../utils/constaints";

function Screener() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery] = useState("");
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [count, setCount] = useState(0);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  const history = useHistory();

  useEffect(() => {
    getTotalCount();
  }, []);

  const getTotalCount = async () => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/screener/companies/total`
      );
      const { count } = response.data;
      setCount(count);
    } catch (error) {
      console.error("Error fetching total count:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCompanies();
  }, [page, searchQuery, sortField, sortOrder, limit]);

  const handleSortChange = (changedColumn) => {
    let newOrder = "desc";
    if (sortField === changedColumn) {
      newOrder = sortOrder === "desc" ? "asc" : "desc";
    }

    if (changedColumn === "Business Permissibility Status") {
      newOrder = sortOrder === "Yes" ? "No" : "Yes";
    }

    setSortField(changedColumn);
    setSortOrder(newOrder);
  };

  // Sorting function

  //updating hte fetch to axios for consistancy of the code
  const fetchCompanies = async () => {
    setLoading(true);

    const queryParams = new URLSearchParams({
      page,
      limit,
      search: searchQuery,
      sortField,
      sortOrder,
      fields:
        "Company Name,Business Permissiblity Status,Interest to Revenue,Loan to Market Cap,Investment to Market Cap",
    });

    try {
      const response = await axios.get(
        `${SERVER_URL}/screener/companies?${queryParams}`,
        {
          withCredentials: true,
        }
      );

      const { data, total: totalCompanies } = response.data;

      // Process and sort the fetched data
      const processedCompanies = data.map((company) => {
        let finalStatus;
        if (company["Business Permissiblity Status"] === "No") {
          finalStatus = "Non-Compliant";
        } else {
          finalStatus =
            company["Interest to Revenue"] < 0.05 &&
            company["Loan to Market Cap"] < 0.33 &&
            company["Investment to Market Cap"] < 0.33
              ? "Compliant"
              : "Non-Compliant";
        }
        return { ...company, finalStatus };
      });

      const sortedCompanies = sortByFinalStatus(processedCompanies);

      setCompanies(sortedCompanies);
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  };

  const sortByFinalStatus = (companies) => {
    return companies.sort((companyA, companyB) => {
      if (
        companyA.finalStatus === "Compliant" &&
        companyB.finalStatus === "Non-Compliant"
      ) {
        return -1;
      } else if (
        companyA.finalStatus === "Non-Compliant" &&
        companyB.finalStatus === "Compliant"
      ) {
        return 1;
      } else {
        return companyA["Company Name"].localeCompare(companyB["Company Name"]);
      }
    });
  };

  const handleSelectResult = (company) => {
    // Replace spaces with hyphens
    const companyNameWithHyphens = company["Company Name"].replace(/ /g, "-");
    // Define the behavior for selecting a result
    history.push(
      `/company-name/${encodeURIComponent(companyNameWithHyphens)}`,
      {
        company: company,
      }
    );
  };

  const columns = [
    {
      name: "Company Name",
      label: "Company Name",
      options: {
        filter: false,
        sort: false,

        setCellHeaderProps: () => ({
          style: { width: "18%" },
        }),
        customBodyRenderLite: (dataIndex) => {
          let companyName = companies[dataIndex]["Company Name"];
          let companyNameWithHyphens = companyName.replace(/ /g, "-");
          return (
            <div className="data-item">
              <Link
                to={`/company-name/${encodeURIComponent(
                  companyNameWithHyphens
                )}`}
                className="Screener_companyname_table "
              >
                {companyName}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "Business Permissiblity Status",
      label: (
        <>
          Permissibility
          <Tooltip title="Business Permissiblity">
            <InfoIcon
              style={{
                margin: 0,
                marginLeft: "10px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            />
          </Tooltip>
        </>
      ),
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { width: "22%" } }),
        customBodyRender: (value) => {
          const isYes = value === "Yes";
          return (
            <div
              style={{
                color: isYes ? "#06D001" : "#EB5B00",
                textAlign: "center",
              }}
              className="data-item_businessPermisibility_status"
            >
              {isYes ? (
                "✅ Yes"
              ) : (
                <>
                  <NotInterestedIcon
                    style={{ color: "#EB5B00", marginRight: 4 }}
                  />
                  No
                </>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "Interest to Revenue",
      label: (
        <>
          I/R
          <Tooltip title="Interest to Revenue">
            <InfoIcon
              style={{
                margin: 0,
                marginLeft: "10px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            />
          </Tooltip>
        </>
      ),
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { width: "15%" } }),
        customBodyRender: (value) => {
          const displayValue =
            isNaN(value) || value === null
              ? "00"
              : parseFloat(value).toFixed(2);
          return (
            <div className="data-item_interest_torevenue">{displayValue}</div>
          );
        },
      },
    },
    {
      name: "Loan to Market Cap",
      label: (
        <>
          L/MC
          <Tooltip title="Loan to Market Cap">
            <InfoIcon
              style={{ marginLeft: 10, cursor: "pointer", fontSize: "16px" }}
            />
          </Tooltip>
        </>
      ),
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { width: "15%" } }),
        customBodyRender: (value) => {
          const displayValue =
            isNaN(value) || value === null
              ? "00"
              : parseFloat(value).toFixed(2);
          return <div className="data-item_laon_toMarket">{displayValue}</div>;
        },
      },
    },
    {
      name: "Investment to Market Cap",
      label: (
        <>
          I/MC
          <Tooltip title="Investment to Market Cap">
            <InfoIcon
              style={{
                margin: 0,
                marginLeft: "10px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            />
          </Tooltip>
        </>
      ),
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { width: "20%" } }),
        customBodyRender: (value) => {
          const displayValue =
            isNaN(value) || value === null
              ? "00"
              : parseFloat(value).toFixed(2);
          return (
            <div className="data-item_investment_marketcap">{displayValue}</div>
          );
        },
      },
    },
    {
      name: "finalStatus",
      label: "STATUS",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { width: "15%" } }),
        customBodyRender: (value) => {
          let color, symbol, text;

          switch (value) {
            case "Compliant":
              color = "#06D001";
              symbol = "✅";
              text = "Compliant";
              break;
            case "Non-Compliant":
              color = "#EB5B00";
              symbol = (
                <NotInterestedIcon
                  style={{ color: "#EB5B00", marginRight: 4 }}
                />
              );
              text = "Non-Compliant";
              break;
            default:
              color = "gray";
              symbol = "❓";
              text = "Unknown";
              break;
          }
          return (
            <div
              className="data-item_final_status"
              style={{ color: color, textAlign: "center" }}
            >
              {symbol} {text}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "500px",
    print: false,
    download: false,
    search: false,
    viewColumns: false, // Set viewColumns option to false
    filter: false,
    serverSide: true,
    count: count, // Total number of rows
    page: page - 1, // Current page number
    rowsPerPage: limit,
    setTableProps: () => ({
      style: {
        backgroundColor: "var(--surface-container-high)", // Light blue background
        color: "var(--on-surface)",
        borderRadius: "8px", // Optional: Rounded corners for a modern look
        padding: "16px", // Optional: Add padding around the table
      },
    }),
    onChangePage: (currentPage) => {
      setPage(currentPage + 1);
    },
    onColumnSortChange: (changedColumn) => handleSortChange(changedColumn),

    onRowClick: (rowData, rowMeta) => {
      // Use rowMeta.dataIndex to get the index of the clicked row in the original data array
      const clickedRowIndex = rowMeta.dataIndex;
      const companyName = companies[clickedRowIndex]["Company Name"]; // Access the company name using the index
      // Replace spaces with hyphens
      const companyNameWithHyphens = companyName.replace(/ /g, "-");
      history.push(
        `/company-name/${encodeURIComponent(companyNameWithHyphens)}`
      );
    },
    onChangeRowsPerPage: (numberOfRows) => setLimit(numberOfRows),
    pagination: true,
    rowsPerPageOptions: [10, 20, 50, 100],
  };

  return (
    <>
      <Helmet>
        <title>Shariah Compliant Investment Screener | Finsha</title>
        <meta
          name="description"
          content="Filter through companies using our Shariah-Compliant investment screener, complete with detailed fundamental information."
        />
        <meta
          name="keywords"
          content="Shariah Investment, Screener, Companies List"
        />
        <meta
          name="tags"
          content="Shariah Compliant, Investment Screener, Fundamental Analysis, Technical analysis"
        />
      </Helmet>
      {loading ? (
        <div className="screener-page-container">
          <div
            className="Screener-title-text"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5rem",
            }}
          >
            <Skeleton variant="rectangular" width={700} height={50} />
          </div>
          <div
            className="Search_bar_screener_div"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "4rem",
            }}
          ></div>
          <div className="Screener_table_container">
            <h2 className="htwoscreenr">Compliant Status</h2>
            <div className="table-wrapper">
              <div className="skeleton-table">
                <div
                  className="skeleton-table-header"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  {[...Array(6)].map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="text"
                      width={300}
                      height={60}
                      style={{ margin: "0 10px" }}
                    />
                  ))}
                </div>
                {[...Array(10)].map((_, rowIndex) => (
                  <div
                    key={rowIndex}
                    className="skeleton-table-row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    {[...Array(6)].map((_, colIndex) => (
                      <Skeleton
                        key={colIndex}
                        variant="text"
                        width={300}
                        height={40}
                        style={{ margin: "0 8px" }}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "20px",
                marginRight: "10px",
                marginBottom: "20px",
              }}
            >
              <Skeleton
                variant="text"
                width={90}
                height={50}
                style={{ marginRight: "10px" }}
              />
              <Skeleton
                variant="text"
                width={90}
                height={50}
                style={{ marginRight: "10px" }}
              />
              <Skeleton
                variant="text"
                width={90}
                height={50}
                style={{ marginRight: "10px" }}
              />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="circular" width={40} height={40} />
            </div>
          </div>
        </div>
      ) : (
        <div className="screener-page-container">
          <div itemScope itemType="https://schema.org/WebPage">
            <h1 className="Screener-title-text" itemProp="name">
              Compliant Screener
            </h1>
            <meta
              itemProp="about"
              content="This screener tool helps you find Shariah-Compliant investment opportunities at Finsha.in."
            />
            <div className="Search_bar_screener_div">
              <SearchBar
                autoFocus={true}
                handleSelectResult={handleSelectResult}
              />
            </div>
          </div>
          <div
            className="Screener_table_container"
            style={{ backgroundColor: "var(--surface-container)" }}
          >
            <h2 className="htwoscreenr">Compliant Status Of Stocks</h2>
            <div className="table-wrapper">
              <MUIDataTable
                data={companies}
                columns={columns}
                options={options}
                className="Screener__page_table"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Screener;
