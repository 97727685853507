import { NavLink, useHistory } from "react-router-dom";
import { HeroSearch } from "../../components/composite/heroSearch/HeroSearch";
import { Box } from "../../components/functional/box/Box";
import { Section } from "../../components/functional/section/Section";
import { Button } from "../../components/ui/button/Button";
import { Text } from "../../components/ui/text/Text";
import SearchBar from "../../Shared/SearchBar/SearchBar";

export const HeroSection = () => {
  const history = useHistory();

  const handleSelectResult = (company) => {
    // Replace spaces with hyphens
    const companyNameWithHyphens = company["Company Name"]
      ? company["Company Name"].replace(/ /g, "-")
      : "";

    // Define the behavior for selecting a result
    history.push(
      `/company-name/${encodeURIComponent(companyNameWithHyphens)}`,
      {
        company: company,
      }
    );
  };

  // return (
  //   <Section>
  //     <Box variant="row" gap="md">
  //       <Box gap="lg">
  //         <Text as="h1" variant="display">
  //           Invest Smartly, Ethically, and{" "}
  //           <Text as="span" variant="display" highlight>
  //             Profitably
  //           </Text>
  //           {" "}with Finsha.
  //         </Text>
  //         <Text as="p" variant="body" color="variant">
  //           Buy, Sell, or Hold? Get Expert Stock Recommendations Tailored for
  //           Your Goals.
  //         </Text>
  //         <Box variant="row" gap="md">

  //           <NavLink to="/stock-idea"><Button label="Get started" icon="arrow_forward" iconPos="end" /></NavLink>
  //         </Box>
  //       </Box>
  //       <Box justifyContent="center">
  //       <SearchBar
  //               autoFocus={true}
  //               handleSelectResult={handleSelectResult}
  //             />
  //       </Box>
  //     </Box>
  //   </Section>
  // );

  return (
    <div className="flex flex-col md:flex-row w-full p-6 md:p-20 bg-[var(--surface)] text-[var(--on-surface)]">
      {/* Mobile Search Bar */}

      {/* Left Content */}
      <div className="w-full md:w-1/2 flex flex-col gap-6 px-4 md:px-0">
        <h1 className="text-3xl md:text-6xl font-bold leading-snug md:leading-tight">
          Invest Smartly, Ethically, and
          <span className="text-[var(--on-primary-container)] bg-[var(--primary-container)] rounded-md px-2 ml-2">
            Profitably
          </span>{" "}
          with Finsha.
        </h1>
        <div className="w-full flex justify-center items-center md:hidden mb-6 mt-4">
          <div className="w-full px-4">
            <SearchBar
              autoFocus={true}
              handleSelectResult={handleSelectResult}
            />
          </div>
        </div>

        <p className="text-base md:text-lg text-variant leading-relaxed">
          Buy, Sell, or Hold? Get Expert Stock Recommendations Tailored for Your
          Goals.
        </p>
        <div className="flex flex-row gap-4 mt-4">
          <NavLink to="/stock-idea">
            <Button label="Get started" icon="arrow_forward" iconPos="end" />
          </NavLink>
        </div>
      </div>

      {/* Right Content with Search Bar */}
      <div className="w-full md:w-1/2  justify-center items-center hidden md:flex">
        <div className="w-4/5 lg:w-3/5">
          <SearchBar autoFocus={true} handleSelectResult={handleSelectResult} />
        </div>
      </div>
    </div>
  );
};
