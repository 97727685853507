import { Box } from "../../components/functional/box/Box";
import { Section } from "../../components/functional/section/Section";
import { Text } from "../../components/ui/text/Text";
import { Card } from "../../components/ui/card/Card";
import { Grid } from "../../components/functional/grid/Grid";
import { Button } from "../../components/ui/button/Button";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../Shared/context/auth-context";

export const StepsSection = () => {
  const { isLoggedIn, user, logout } = useContext(AuthContext);

  return (
    <Section>
      <Box gap="xl">
        <Text as="h2" variant="heading" style={{ textAlign: "center" }}>
          Simple Steps to Start Investing with Finsha
        </Text>
        <Grid cols="3" gap="md">
          <Card gap="md">
            <Text as="h3" variant="title">
              Create an account and explore our platform.
            </Text>
            <NavLink to={isLoggedIn ? "/stock-idea" : "/auth"}>
              <Button
                variant="filled"
                size="small"
                label="Sign in"
                icon="arrow_forward"
                iconPos="end"
              />
            </NavLink>
          </Card>
          <Card gap="md">
            <Text as="h3" variant="title">
              Access detailed buy, sell, and hold insights.
            </Text>
            <NavLink to="/stock-idea">
              <Button
                variant="ghost"
                size="small"
                label="Discover Compliant Stocks"
                icon="arrow_forward"
                iconPos="end"
              />
            </NavLink>
          </Card>
          <Card gap="md">
            <Text as="h3" variant="title">
              Use our research to make confident, value-driven decisions.
            </Text>
            <NavLink to="/finview/research">
              <Button
                variant="ghost"
                size="small"
                label="Invest Smartly"
                icon="arrow_forward"
                iconPos="end"
              />
            </NavLink>
          </Card>
        </Grid>
      </Box>
    </Section>
  );
};

const steps = [
  {
    title: "Create an account and explore our platform.",
    label: "Sign up",
    href: "/auth",
  },
  {
    title: "Access detailed buy, sell, and hold insights.",
    label: "Discover Compliant Stocks",
    href: "/stock-idea",
  },
  {
    title: "Use our research to make confident, value-driven decisions.",
    label: "Invest Smartly",
    href: "/finview/research",
  },
];
