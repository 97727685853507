import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { debounce } from "lodash";
import "./SearchBar.css";
import SearchIcon from "@mui/icons-material/Search";
import { Text } from "../../components/ui/text/Text";
import { Button } from "../../components/ui/button/Button";
import { constaints } from "../../utils/constaints";

const SearchBar = ({
  handleSelectResult,
  initialValue,
  autoFocus,
  isOpen,
  inputStyle,
}) => {
  const [query, setQuery] = useState(initialValue || "");
  const [results, setResults] = useState([]);
  const [activeResultIndex, setActiveResultIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  const fetchResults = debounce(async (searchQuery) => {
    if (searchQuery.length === 0 || !showDropdown) {
      setResults([]);
      return;
    }
    try {
      const response = await axios.get(
        `${SERVER_URL}/search?query=${searchQuery}`,
        {
          withCredentials: true,
        }
      );
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  }, 300);

  useEffect(() => {
    fetchResults(query);
  }, [query]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  // Add this useEffect to clear the query when the modal is opened
  useEffect(() => {
    if (isOpen) {
      setQuery("");
    }
  }, [isOpen]);

  const searchBarRef = useRef();

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelect = (item) => {
    setQuery(item["Company Name"]);
    setResults([]);
    setShowDropdown(false);
    handleSelectResult(item);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setActiveResultIndex((prevIndex) =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      setActiveResultIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (e.key === "Enter") {
      if (activeResultIndex !== -1) {
        handleSelect(results[activeResultIndex]);
      } else if (results.length > 0) {
        handleSelect(results[0]);
      }
    }
  };

  useEffect(() => {
    setActiveResultIndex(-1);
  }, [query]);

  useEffect(() => {
    if (initialValue) {
      setQuery(initialValue);
      setShowDropdown(false);
    }
  }, [initialValue]);

  return (
    <div className="hero-search">
      <div
        className="hero-search__bar"
        ref={searchBarRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <span className="hero-search__search-icon">search</span>

        <input
          ref={inputRef}
          className="hero-search__input hero-search__input--font"
          type="text"
          value={query}
          onFocus={() => setShowDropdown(true)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          style={inputStyle}
          placeholder="Search stocks, equities"
        />
        <Button
          variant="icon-filled"
          color="neutral"
          icon="arrow_forward"
          onClick={() => handleSelect(results[0])}
        />
      </div>
      <div
        className={`hero-search__container ${
          isFocused ? "hero-search__container--expanded" : ""
        }`}
      >
        {query && results.length > 0 && (
          <ul className="hero-search__suggestions">
            {results.map((item, index) => (
              <li
                key={index}
                className={`hero-search__suggestions-item ${
                  index === activeResultIndex ? "selected" : ""
                }`}
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent blur
                  handleSelect(item);
                }}
              >
                <Text as="span" variant="label" color="variant" width="fit">
                  {item["Company Name"]}
                </Text>
                <Text as="span" variant="label" color="low" width="fit">
                  {item["NSE_Symbol_NS"]}
                </Text>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
