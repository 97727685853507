import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import "./CompanyDetails.css";
import FinancialCard from "../../Shared/UIElements/Cards/components/FinancialCard";
import StarRating from "../../Shared/StarRating";
import TvChart from "../components/TvChart";
import axios from "axios";
import { Helmet } from "react-helmet";
import compliantlogo from "../../images/Compliant.png";
import nonCompliantLogo from "../../images/Non-compliant.png";
import StockPrice from "../components/CurrentPrice";
import { AuthContext } from "../../Shared/context/auth-context";
import MarketCapitalisation from "../components/marketcap";
import PriceToEarning from "../components/PEratio";
import PriceToBook from "../components/PBratio";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import CompanySkelton from "../components/skeleton/CompanySkelton";
import { Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
import NewsComponent from "../../Home/component/News";
import SearchBar from "../../Shared/SearchBar/SearchBar";
import FloatingSearchModel from "../../Shared/SearchBar/FloatingSearchModel/FloatingSearchModel";
import KeyboardReturnSharpIcon from "@mui/icons-material/KeyboardReturnSharp";
import BarChartIcon from "@mui/icons-material/BarChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GradingIcon from "@mui/icons-material/Grading";
import VerifiedIcon from "@mui/icons-material/Verified";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { constaints } from "../../utils/constaints";

const useStyles = makeStyles({
  clearIndicator: {
    backgroundColor: "transparent !important",
  },
  popupIndicator: {
    backgroundColor: "transparent !important",
  },
});

function CompanyDetails(props) {
  const classes = useStyles();
  const [company, setCompany] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [inWatchlist, setInWatchlist] = useState(false);
  const [watchlistUpdated, setWatchlistUpdated] = useState(false);
  const [, setWatchlistAdded] = useState(false); // New stat
  const { id, symbol, name } = useParams();
  const [userWatchlist, setUserWatchlist] = useState([]);
  const [MyOptions, setMyOptions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [, setFilteredCompanies] = useState([]);
  const [setIsSearchOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useContext(AuthContext).user;
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  const [isFloatingSearchModalOpen, setIsFloatingSearchModalOpen] =
    useState(false);
  const searchBarRef = useRef();
  const [loginPrompt, setLoginPrompt] = useState(false);

  const seoKeywords = company
    ? `${company["Company Name"]}, Shariah Compliant Investment, Shariah Compliant Stocks, Islamic Finance, Compliant Investment, Compliant Stocks, FinSha Services`
    : "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (isFloatingSearchModalOpen && searchBarRef.current) {
      searchBarRef.current.focus();
    }
  }, [isFloatingSearchModalOpen]);

  useEffect(() => {
    setLoading(true);
    const getCompanies = async () => {
      try {
        // Define the fields you want to fetch
        const fields = [
          "_id",
          "Company Name",
          "NSE_Symbol_NS",
          "BSE_SYMBOL_COLLON",
          "BV PS",
          "Market Capitalisation",
          "Outstanding Shares",
          "Price to Equity",
          "Price to Book value",
          "EPS",
          "Debt to Equity",
          "Sales Growth",
          "Profit Growth",
          "Receivables turnover ratio",
          "Inventory Turnover Ratio",
          "FCFE",
          "FCFF",
          "FCFF (PS)",
          "CF (PS)",
          "Business Permissiblity Status",
          "Business Sector",
        ].join(",");

        // Making the request with Axios
        const response = await axios.get(`${SERVER_URL}/screener/companies`, {
          params: { fields },
          withCredentials: true,
        });

        const responseData = response.data; // Axios automatically parses the JSON response

        if (responseData && Array.isArray(responseData.data)) {
          // Mapping the data as before but using the filtered response
          setCompanies(responseData.data);
          setFilteredCompanies(responseData.data);
          let companyNames = responseData.data.map((company) => {
            const companyNameWithHyphens = company["Company Name"].replace(
              / /g,
              "-"
            );
            const shortcut = company["Company Name"]
              .match(/\b\w/g)
              .join("")
              .toUpperCase();
            return {
              label: company["Company Name"],
              id: company._id,
              shortcut: shortcut,
              url: `/company-name/${encodeURIComponent(
                companyNameWithHyphens
              )}`,
            };
          });
          setMyOptions(companyNames);
        } else {
          console.error(
            "Expected an array of companies, but received:",
            responseData
          );
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoading(false);
      }
    };
    getCompanies();
  }, []);

  // replace fetch with axios
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const cacheKey = `company-details-${id || name || symbol}`;
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        const cacheDuration = 1000 * 60 * 60; // For example, 1 hour
        if (new Date().getTime() - timestamp < cacheDuration) {
          setCompany(data);
          setLoading(false);
          return;
        }
      }

      try {
        setLoading(true);
        // Replace spaces with hyphens in the company name
        const nameWithHyphens = name ? name.replace(/ /g, "-") : "";
        // Construct the URL based on whether id, name, or symbol is available
        const url = id
          ? `${SERVER_URL}/screener/company/${id}`
          : `${SERVER_URL}/screener/company`;

        // Making the request using Axios
        const response = await axios.get(url, {
          params: {
            name: nameWithHyphens,
            symbol: symbol || "",
          },
        });

        const data = response.data;

        // Caching the data in local storage
        localStorage.setItem(
          cacheKey,
          JSON.stringify({ timestamp: new Date().getTime(), data })
        );

        setCompany(data);
      } catch (error) {
        console.error("Error fetching company details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyDetails();
  }, [id, name, symbol]);

  useEffect(() => {
    setLoading(true);
    //replace fetch with axios for code consistancy
    const fetchData = async (url, params = {}) => {
      try {
        const response = await axios.get(url, {
          params,
          withCredentials: true,
        });
        const data = response.data;
        setCompany(data);
      } catch (error) {
        console.error("Error fetching company data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData(`${SERVER_URL}/screener/company/${id}`);
    } else if (name && symbol) {
      const nameWithHyphens = name.replace(/ /g, "-");
      fetchData(`${SERVER_URL}/screener/company-details`, {
        name: nameWithHyphens,
        symbol: symbol,
      });
    } else if (name) {
      const nameWithHyphens = name.replace(/ /g, "-");
      fetchData(`${SERVER_URL}/screener/company`, {
        name: nameWithHyphens,
      });
    }
  }, [id, name, symbol]);

  useEffect(() => {
    setLoading(true);
    const fetchWatchlist = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/finsha/watchlist`, {
          withCredentials: true,
        });
        setUserWatchlist(response.data);
        // If company is in watchlist, set inWatchlist to true
        const isInWatchlist = response.data.some(
          (watchlistCompany) => watchlistCompany._id === company._id
        );
        setInWatchlist(isInWatchlist);
        setLoading(false);
      } catch (error) {
        // console.error('Error fetching watchlist:', error);
      }
    };
    fetchWatchlist();
  }, [user, inWatchlist]); // Add inWatchlist to effect dependencies

  const addToWatchlist = async () => {
    if (!user) {
      setLoginPrompt(true);
      return;
    }
    setLoading(true);
    setInWatchlist(true); // Set inWatchlist to true immediately when function starts
    setLoading(true); // start loading
    try {
      const cid = company._id;
      const userId = user._id;

      let res = await axios.post(`${SERVER_URL}/finsha/watchlist`, { cid });
      await res.data;
      setShowMessage(true);
      setWatchlistUpdated(!watchlistUpdated);
      setWatchlistAdded(true); // Set watchlistAdded to true after adding to watchlist
      setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    } catch (error) {
      // console.error('Error adding company to watchlist:', error);
      setInWatchlist(false); // If there is an error, set inWatchlist back to false
    } finally {
      setLoading(false); // stop loading
    }
  };

  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  if (!company) {
    return <div>Loading...</div>;
  }

  function handleBackdropClick() {
    const backdrop = document.querySelector(".backdrop");
    backdrop.style.display = "none";
  }

  const handleClose = () => {
    setShowMessage(false);
    setLoginPrompt(false); // Reset the login prompt flag
  };

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      setIsSearchOpen(false);
    }
  }

  const handleLoginRedirect = () => {
    history.push(
      `/auth?redirect=${encodeURIComponent(window.location.pathname)}`
    );
  };

  const companyNameWithHyphens =
    company && company["Company Name"]
      ? company["Company Name"].replace(/ /g, "-")
      : "";

  return (
    <div className="companydetails-container">
      {loading && <CompanySkelton />}
      <div className="pt-0 pl-0 pr-0 md:pl-0 md:pr-0 md:pt-0">
        <div className="fab-container">
          <button
            className="fab-button"
            onClick={() => setIsFloatingSearchModalOpen(true)}
          >
            <SearchIcon className="searchIcon_companydetails_flatingsearch" />
          </button>

          <FloatingSearchModel
            isOpen={isFloatingSearchModalOpen}
            onClose={() => setIsFloatingSearchModalOpen(false)}
          >
            <SearchBar
              autoFocus={isFloatingSearchModalOpen}
              handleSelectResult={(company) => {
                const companyNameWithHyphens = company["Company Name"].replace(
                  / /g,
                  "-"
                );
                history.push(
                  `/company-name/${encodeURIComponent(companyNameWithHyphens)}`,
                  { company }
                );
                setIsFloatingSearchModalOpen(false);
              }}
              isOpen={isFloatingSearchModalOpen} // Pass isOpen prop
            />
          </FloatingSearchModel>
        </div>

        <Helmet>
          <title>{`${company["Company Name"]} - Shariah Compliant Investment | FinSha`}</title>
          <meta
            name="description"
            content={`Detailed shariah compliance report and financial analysis of ${company["Company Name"]} for Islamic investment. Explore more with FinSha.`}
          />
          <meta name="keywords" content={seoKeywords} />
          <script type="application/ld+json">
            {`
          {
            "@context": "https://schema.org/",
            "@type": "Organization",
            "name": "FinSha",
            "url": "https://www.finsha.in",
            "logo": "URL_TO_YOUR_LOGO",
            "sameAs": [
              "YOUR_SOCIAL_MEDIA_LINKS"
            ]
          }
        `}
          </script>
        </Helmet>
        <div className="company-header">
          <div className="flex flex-col md:flex-row items-start md:items-center ml-3 ">
            <h1
              className="text-lg md:text-xl lg:text-2xl font-bold font-roboto text-[var(--on-surface)] md:mr-2 p-1 "
              key={id}
            >
              {company["Company Name"]}
            </h1>

            <p className="text-sm md:text-base lg:text-lg mt-1 md:mt-0 ml-3 font-bold text-[var(--primary)]">
              {company["Business Sector"]}
            </p>
          </div>

          <div className="mt-1 ml-3">
            <StockPrice symbol={company["NSE_Symbol_NS"]} />
          </div>

          <h4 className="text-lg font-semibold text-[--on-surface-variant] ml-3 p-2 mt-1">
            NSE : {company["NSE_Symbol_NS"]?.replace(".NS", "")}
          </h4>

          <div className="watchlist-button-container">
            {!userWatchlist.some(
              (watchlistCompany) => watchlistCompany._id === company._id
            ) &&
              !loading && (
                <button onClick={addToWatchlist} className="watchlist-button">
                  <AddCircleIcon
                    style={{
                      color: "var(--on-primary-variant)",
                      marginRight: "5px",
                    }}
                  />{" "}
                  Add to Watchlist
                </button>
              )}
            {userWatchlist.some(
              (watchlistCompany) => watchlistCompany._id === company._id
            ) && (
              <button
                disabled
                className="bg-[var( --surface)] text-[var(--on-surface-low)] border-2 border-[var(--on-surface-low)] rounded-full p-2 disabled:cursor-not-allowed"
              >
                <GradingIcon
                  style={{
                    color: "[var(--on-surface-low)]",
                    marginRight: "5px",
                  }}
                />{" "}
                Already Added to Watchlist
              </button>
            )}

            <Dialog open={showMessage && !loginPrompt} onClose={handleClose}>
              <DialogTitle className="flex items-center gap-2 text-lg font-semibold bg-[var(--surface)] text-[var(--on-surface)]">
                Success <VerifiedIcon className="text-green-500" />
              </DialogTitle>
              <DialogContent className="p-4 bg-[var(--surface)] rounded-lg text-base text-[var(--on-surface)] ">
                <p className="mb-4 leading-6">
                  <span className="font-bold">Congratulations!</span> The item
                  was successfully added to your watchlist. You can now easily
                  track its performance and receive updates. Explore more
                  options or continue browsing to add more items.
                  <span className="inline-flex ml-2">
                    <TaskAltIcon className="text-green-500" />
                  </span>
                </p>
                <button
                  onClick={handleClose}
                  className="px-4 py-2 bg-[var(--primary)] text-[var(--on-surface)] rounded-md font-semibold"
                >
                  OK
                </button>
              </DialogContent>
            </Dialog>

            <Dialog open={loginPrompt} onClose={handleClose}>
              <DialogTitle className="flex items-center gap-2 text-lg text-[var(--on-surface)] font-semibold bg-[var(--surface)]">
                <i className="material-icons text-[var(--on-surface)] text-xl">
                  lock
                </i>{" "}
                Login Required
              </DialogTitle>
              <DialogContent className="p-4 bg-[var(--surface)] rounded-lg text-base text-[var(--on-surface)]">
                <p className="mb-4 leading-6">
                  Please Login to Add Companies to Your Watchlist
                </p>
                <div className="flex gap-2 justify-end">
                  <button
                    onClick={handleClose}
                    className="px-4 py-2 bg-[var(--primary)] text-[var(--on-surface)] rounded-md font-semibold"
                  >
                    OK
                  </button>
                  <button
                    variant="contained"
                    color="primary"
                    onClick={handleLoginRedirect}
                    className="px-4 py-2 bg-[var(--primary-variant)] text-[var(--on-primary-variant))] rounded-md font-semibold"
                  >
                    Login
                  </button>
                </div>
              </DialogContent>
            </Dialog>

            <Dialog
              open={showMessage && loginPrompt}
              onClose={handleClose}
              className="bg-[var(--primary)]"
            >
              <DialogTitle className="flex items-center gap-2 text-lg font-semibold text-[var(--on-surface)] text-center">
                <i className="material-icons text-[var(--on-surface)] text-xl">
                  lock
                </i>{" "}
                Login Required
              </DialogTitle>
              <DialogContent className="p-4 bg-yellow-50 rounded-lg text-base text-yellow-900">
                <p className="mb-4 leading-6">
                  Please Login to Add Companies to Your Watchlist
                </p>
                <button
                  onClick={handleClose}
                  className="px-4 py-2 bg-[var(--primary-fixed)] text-[var(--on-primary-fixed)] rounded-md font-semibold"
                >
                  OK
                </button>
              </DialogContent>
            </Dialog>

            {/* <Dialog open={showMessage && !loginPrompt} onClose={handleClose}>
              <DialogTitle className="success-header_watchlist">
                Success <VerifiedIcon style={{ color: "green" }} />
              </DialogTitle>
              <DialogContent className="success-message_watchlist">
                <p className="watchlist_parah">
                  <span>Congratulations!</span> The item was successfully added
                  to your watchlist. You can now easily track its performance
                  and receive updates. Explore more options or continue browsing
                  to add more items.
                  <span className="success-icon">
                    <TaskAltIcon style={{ color: "green" }} />
                  </span>
                </p>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  className="Watchlist_ok-button"
                >
                  OK
                </Button>
              </DialogContent>
            </Dialog>
            <Dialog
              open={loginPrompt}
              onClose={handleClose}
            >
              <DialogTitle className="login-required-header">
                <i className="material-icons">lock</i> Login Required
              </DialogTitle>
              <DialogContent className="login-required-content">
                <p className="login-required-message">
                  Please Login to Add Companies to Your Watchlist
                </p>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  className="login-required-ok-button"
                  style={{ marginRight: "10px" }}
                >
                  OK
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoginRedirect}
                  className="login-required-login-button"
                >
                  Login
                </Button>
              </DialogContent>
            </Dialog>
            <Dialog open={showMessage && loginPrompt} onClose={handleClose}>
              <DialogTitle className="login-required-header">
                <i className="material-icons">lock</i> Login Required
              </DialogTitle>
              <DialogContent className="login-required-content">
                <p className="login-required-message">
                  Please Login to Add Companies to Your Watchlist
                </p>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  className="login-required-ok-button"
                >
                  OK
                </Button>
              </DialogContent>
            </Dialog> */}
          </div>
        </div>
        <div className="tradingview_chart_container p-2">
          {company && company["BSE_SYMBOL_COLLON"] ? (
            <TvChart symbol={company["BSE_SYMBOL_COLLON"]} />
          ) : (
            <div className="flex items-center p-4 bg-[var(--surface)] rounded-lg border border-gray-200 mt-4">
              <ErrorOutlineIcon
                fontSize="medium"
                className="text-[--on-surface-variant] mr-3"
              />
              <p className="text-[--on-surface-variant] text-sm">
                Currently, there is no trading chart available for this stock.
                The chart data may not be provided due to market inactivity or
                unavailability of trading information. Please check back later
                or select a different stock.
              </p>
            </div>
          )}
        </div>

        {/* ----------------------------------------------------------------------NEWS COMPONNET ------------------------------------------------------------------------------ */}

        {company && <NewsComponent name={company["Company Name"]} />}

        {/* ---------------------------------------------------------------------Company Business Sector Model ------------------------------------------------------------------- */}
        <div className="sector-container p-6  bg-[var(--surface)] shadow-md rounded-lg border border-gray-200">
          <h2 className="text-2xl font-bold text-[--on-surface] mb-4 border-b pb-2">
            Company Business Model
          </h2>
          <ol className="space-y-3 list-decimal list-inside">
            {company["Business 1"] && (
              <li className="text-[--on-surface-low] text-base leading-relaxed">
                {company["Business 1"]}
              </li>
            )}
            {company["Business 2"] && (
              <li className="text-[--on-surface-low] text-base leading-relaxed">
                {company["Business 2"]}
              </li>
            )}
            {company["Business 3"] && (
              <li className="text-[--on-surface-low] text-base leading-relaxed">
                {company["Business 3"]}
              </li>
            )}
          </ol>
        </div>

        {company["Investment Prospective"] && (
          <div className="companydetails_investment_prospective__container">
            <h2 className="companydetails__prospective-title">
              Investment Prospective
            </h2>
            <p className="companydetails_investment_prospective__parah">
              {company["Investment Prospective"]}
            </p>
          </div>
        )}

        <div className="p-1 bg-[var(--surface)] rounded-xl shadow-lg border border-gray-200">
          <p className="text-[--on-surface] text-sm sm:text-base bg-[var(--surface)] p-2 rounded-md border-l-4 border-blue-500">
            <span className="font-semibold text-[--on-surface]">Note:</span> The
            business model and relevant news on this page are generated using AI
            & ML technologies, which is still under development.
          </p>
        </div>

        <div className="shariahstatus_rating_compnydetails__container">
          <div
            className={`shariah__status-card ${
              company["Business Permissiblity Status"] === "Yes" &&
              company["Interest to Revenue"] < 0.05 &&
              company["Loan to Market Cap"] < 0.33 &&
              company["Investment to Market Cap"] < 0.33
                ? "halal-card"
                : "non-halal-card"
            }`}
          >
            <h2 className="shariah-status_title"> Compliant Status</h2>
            <div className="status-boxes">
              {company["Business Permissiblity Status"] === "Yes" &&
              company["Interest to Revenue"] < 0.05 &&
              company["Loan to Market Cap"] < 0.33 &&
              company["Investment to Market Cap"] < 0.33 ? (
                <div className="status-box halal">
                  <img
                    src={compliantlogo}
                    alt="Compliant"
                    className="status-image"
                  />
                </div>
              ) : (
                <div className="status-box haram">
                  <img
                    src={nonCompliantLogo}
                    alt="Non-Compliant"
                    className="status-image"
                  />
                </div>
              )}
            </div>
            <h3
              className={`final-status ${
                company["Business Permissiblity Status"] === "Yes" &&
                company["Interest to Revenue"] < 0.05 &&
                company["Loan to Market Cap"] < 0.33 &&
                company["Investment to Market Cap"] < 0.33
                  ? "halal-status"
                  : "non-halal-status"
              }`}
            >
              {company["Business Permissiblity Status"] === "Yes" &&
              company["Interest to Revenue"] < 0.05 &&
              company["Loan to Market Cap"] < 0.33 &&
              company["Investment to Market Cap"] < 0.33
                ? "Compliant"
                : "Non-Compliant"}
            </h3>
          </div>

          <div className="companydetails__rating-container">
            <h2 className="companydetails_rating__title">Finsha Rating </h2>
            <div className="compnydetails_stars_div">
              <StarRating
                rating={company["Ratings"]}
                className="stars_classname"
              />
            </div>
          </div>

          <div className="companydetails_details_shariahbutton_container">
            <Link
              to={{
                // pathname: `/shariah/${company["Company Name"]}`,
                pathname: `/shariah/${companyNameWithHyphens}`,
                state: {
                  title: `${company["Company Name"]} - Shariah Page`,
                  company: company, // pass entire company object as prop
                },
              }}
            >
              <button className="Shariah_details_butoon_companydetais">
                <DonutLargeIcon className="shariahdetails_button_doughnuticon" />
                <BarChartIcon className="shariahdetails_button_barcharticon" />
                <span>Compliance Detail </span>
              </button>
            </Link>
          </div>
        </div>

        {/* Company Fundamentals */}

        <div className="companydetails__Fundamenatal-data">
          <h2 className="Fundametal_data-title">Company Fundamentals </h2>
          <div className="FinancialCardsGrid">
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Market Capitlisation
              </h3>
              <MarketCapitalisation
                className="CompanyDetails-ddd"
                symbol={company["NSE_Symbol_NS"]}
                outstandingShares={company["Outstanding Shares"]}
              />
            </FinancialCard>

            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Shares Outstanding (cr){" "}
              </h3>
              <p className="CompanyDetails-ddd">
                {company["Outstanding Shares"] || "0"} cr
              </p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Price to Earning Ratio
              </h3>
              <PriceToEarning
                className="CompanyDetails-ddd"
                symbol={company["NSE_Symbol_NS"]}
                earningPerShare={company["EPS"]}
              />
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Price to Book Ratio
              </h3>
              {/* <p className='CompanyDetails-ddd'>{company["Price to Book value"]|| '0' } Times</p> */}
              <PriceToBook
                className="CompanyDetails-ddd"
                symbol={company["NSE_Symbol_NS"]}
                bookValuePerShare={company["BV PS"]}
              />
            </FinancialCard>

            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Book Value Per Share
              </h3>
              <p className="CompanyDetails-ddd">
                ₹{" "}
                {company["BV PS"]
                  ? parseFloat(company["BV PS"]).toFixed(2)
                  : "0.00"}
              </p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Earnings Per share
              </h3>
              <p className="CompanyDetails-ddd">₹ {company["EPS"] || "0"}</p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Debt to Equity
              </h3>
              <p className="CompanyDetails-ddd">
                {company["Debt to Equity"]
                  ? parseFloat(company["Debt to Equity"]).toFixed(2)
                  : "null"}{" "}
                Times
              </p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Sales Growth
              </h3>
              <p className="CompanyDetails-ddd">
                {company["Sales Growth"]
                  ? company["Sales Growth"].toFixed(2)
                  : "0"}{" "}
                %
              </p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Profit Growth
              </h3>
              <p className="CompanyDetails-ddd">
                {company["Profit Growth"]
                  ? company["Profit Growth"].toFixed(2)
                  : "0"}{" "}
                %
              </p>
            </FinancialCard>

            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Receivables Turnover Ratio
              </h3>
              <p className="CompanyDetails-ddd">
                {company["Receivables turnover ratio"]
                  ? parseFloat(company["Receivables turnover ratio"]).toFixed(2)
                  : "0.00"}{" "}
                Times
              </p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Inventory Turnover Ratio
              </h3>
              <p className="CompanyDetails-ddd">
                {company["Inventory Turnover Ratio"]
                  ? parseFloat(company["Inventory Turnover Ratio"]).toFixed(2)
                  : "0.00"}{" "}
                Times
              </p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Free Cash Flow to Equity (FCFE)
              </h3>
              <p className="CompanyDetails-ddd">
                ₹ {company["FCFE"] || "0"} cr
              </p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Free cash Flow to the Firm (FCFF)
              </h3>
              <p className="CompanyDetails-ddd">
                ₹ {company["FCFF"] || "0"} cr
              </p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                FCFF per share
              </h3>
              <p className="CompanyDetails-ddd">
                ₹{" "}
                {company["FCFF (PS)"]
                  ? parseFloat(company["FCFF (PS)"]).toFixed(2)
                  : "0.00"}
              </p>
            </FinancialCard>
            <FinancialCard>
              <h3 className="CompanyDetails-Fundamental_data__title">
                Cash Flow Per share
              </h3>
              <p className="CompanyDetails-ddd">
                ₹{" "}
                {company["CF (PS)"]
                  ? parseFloat(company["CF (PS)"]).toFixed(2)
                  : "0.00"}
              </p>
            </FinancialCard>
          </div>
        </div>
        <div className="compnydetails_button_up_back_container">
          <button
            className="companydetails_goBack__button"
            onClick={handleClick}
          >
            <KeyboardReturnSharpIcon
              style={{ color: "white", fontSize: "24px" }}
            />
          </button>
          <button onClick={handleScrollToTop} className="scroll-top-button">
            <ArrowCircleUpIcon className="gototop_button_icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
