import React, { useState, useContext, useEffect } from "react";
import { useForm } from "../Shared/Form-Hooks/Form-hooks";
import { AuthContext } from "../Shared/context/auth-context";
import "./Auth.css";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./Pages/Toast";
import LoginForm from "../Shared/UIElements/Cards/pages/LoginForm";
import SignupForm from "../Shared/UIElements/Cards/pages/SignUpForm";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "tailwindcss/tailwind.css";
import { constaints } from "../utils/constaints";

const customDecode = (url) => {
  return url ? url.replace(/-/g, "/") : "/";
};

const Auth = (props) => {
  const [userData, setUserData] = useState(null);
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const history = useHistory();
  const location = useLocation();
  const [otpSent, setOtpSent] = useState(false);
  const [isOtpValidated, setIsOtpValidated] = useState(false); // New state for OTP validation
  const [showOtpSentToast, setShowOtpSentToast] = useState(false);
  const [showOtpValidatedToast, setShowOtpValidatedToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
      fname: {
        value: "",
        isValid: false,
      },
      lname: {
        value: "",
        isValid: false,
      },
      phone: {
        value: "",
        isValid: false,
      },
      otp: {
        value: "",
        isValid: false,
      },
    },
    isLoginMode
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          fname: "",
          lname: "",
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          fname: {
            value: "",
            isValid: false,
          },
          lname: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };

  // replace fetch with axios
  const authSubmitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!isLoginMode) {
      try {
        // Check if the email is already registered
        const emailCheckResponse = await axios.post(
          `${SERVER_URL}/auth/is-registered`,
          {
            email: formState.inputs.email.value,
            phone: formState.inputs.phone.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const emailCheckResponseData = emailCheckResponse.data;
        if (!emailCheckResponse.status === 200) {
          throw new Error(emailCheckResponseData.message);
        }

        // If the email is already registered, throw an error
        if (emailCheckResponseData.isRegistered) {
          const errorMessage =
            emailCheckResponseData.type === "email"
              ? "This email is already registered!"
              : "This phone number is already registered!";
          throw new Error(errorMessage);
        }

        if (!otpSent) {
          setIsLoading(true);
          const response = await axios.post(
            `${SERVER_URL}/auth/register`,
            {
              fname: formState.inputs.fname.value,
              lname: formState.inputs.lname.value,
              email: formState.inputs.email.value,
              password: formState.inputs.password.value,
              phone: formState.inputs.phone.value,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );

          const responseData = response.data;
          if (response.status !== 200) {
            throw new Error(responseData.message);
          }
          setIsLoading(false);
          setUserData(responseData);
          setOtpSent(true);
          setToastMessage(responseData.message);
          setShowOtpSentToast(true);
          setTimeout(() => setShowOtpSentToast(false), 2000);
        } else {
          setIsLoading(true);
          const otpResponse = await axios.post(
            `${SERVER_URL}/auth/email/validate-otp`,
            {
              fname: formState.inputs.fname.value,
              lname: formState.inputs.lname.value,
              email: formState.inputs.email.value,
              phone: formState.inputs.phone.value,
              password: formState.inputs.password.value,
              otp: formState.inputs.otp.value,
              referralCode: formState.inputs.referralCode
                ? formState.inputs.referralCode.value
                : undefined,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );

          const userResponse = await axios.get(`${SERVER_URL}/auth/user/data`, {
            headers: {
              Accept: "application/json",
            },
            withCredentials: true,
          });

          const userResponseData = userResponse.data;
          if (userResponse.status === 200) {
            auth.login(userResponseData.user);
          }

          setIsLoading(false);
          setIsOtpValidated(true);

          setShowOtpValidatedToast(true);
          setTimeout(() => setShowOtpValidatedToast(false), 2000);
          const queryParams = new URLSearchParams(location.search);
          const redirectPath = queryParams.get("redirect");
          if (redirectPath) {
            history.push(redirectPath);
          } else {
            history.push("/");
          }
        }
      } catch (err) {
        setIsLoading(false);
        setError(
          err.message || "Something went wrong. Please try again later."
        );
      }
    } else {
      try {
        const response = await axios.post(
          `${SERVER_URL}/auth/login/local`,
          {
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        const responseData = response.data;
        if (response.status !== 200) {
          if (response.status === 401) {
            // Handle specific error codes from the backend
            switch (responseData.code) {
              case "USER_NOT_FOUND":
                setError(
                  responseData.message ||
                    "Invalid email or password. For New User: Please SWITCH TO SIGNUP"
                );
                break;
              case "INVALID_CREDENTIALS":
                setError(
                  responseData.message ||
                    "Invalid email or password. Please try again."
                );
                break;
              default:
                setError(responseData.message || "Unauthorized access.");
                break;
            }
          } else {
            setError(
              responseData.message ||
                "Something went wrong. Please try again later."
            );
          }
          setIsLoading(false);
          return;
        }

        const userResponse = await axios.get(`${SERVER_URL}/auth/user/data`, {
          headers: {
            Accept: "application/json",
          },
          withCredentials: true,
        });

        const userResponseData = userResponse.data;
        if (userResponse.status === 200) {
          localStorage.setItem("token", responseData.token);
          auth.login(userResponseData.user);
        }
        setIsLoading(false);
        const queryParams = new URLSearchParams(location.search);
        const redirectPath = customDecode(queryParams.get("redirect"));
        if (redirectPath) {
          history.push(redirectPath);
        } else {
          history.push("/");
        }

        // Add an entry to the history stack that redirects to home if back is pressed
        window.history.pushState({}, "", "/");
      } catch (err) {
        setIsLoading(false);
        setError(
          err.message || "Something went wrong. Please try again later."
        );
      }
    }
  };

  const errorHandler = () => {
    setError(null);
  };

  const googleLogin = () => {
    window.open(`${SERVER_URL}/auth/login/federated/google`, "_self");
  };

  return (
    <>
      {error && (
        <Toast header="Error" message={error} onClose={() => setError(null)}>
          {error === "Please set a local password before logging in." && (
            <div className="flex justify-center">
              <button
                onClick={() => history.push("/forgot")}
                className="bg-green-500 hover:bg-green-600 text-white py-1 px-4 rounded transition duration-300 mt-2"
              >
                Set Password
              </button>
            </div>
          )}
        </Toast>
      )}

      <div
        className={`loginandsignup-container ${isLoading ? "blur-effect" : ""}`}
      >
        <Helmet>
          <title>Finsha - Login or Register</title>
          <meta
            name="description"
            content="Login or Register to Finshat, your trusted platform for Shariah-Compliant investments."
          />
          <meta
            name="keywords"
            content="Finsha, Login, Register, Shariah, Investments, Shariah-Compliant"
          />

          {/* Schema.org tags */}
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Login or Register",
              "description": "Login or Register to Finsha, your trusted platform for Shariah-Compliant investments."
            }
          `}
          </script>
        </Helmet>

        {showOtpSentToast && (
          <Toast header="OTP Notification" message={toastMessage} />
        )}

        {showOtpValidatedToast && (
          <Toast header="OTP Notification" message={toastMessage} />
        )}

        <div className="auth-container">
          {isLoginMode ? (
            <LoginForm
              isLoginMode={isLoginMode}
              formState={formState}
              inputHandler={inputHandler}
              authSubmitHandler={authSubmitHandler}
              switchModeHandler={switchModeHandler}
              otpSent={otpSent}
              isOtpValidated={isOtpValidated}
              setIsOtpValidated={setIsOtpValidated}
            />
          ) : (
            <SignupForm
              isLoginMode={isLoginMode}
              formState={formState}
              inputHandler={inputHandler}
              authSubmitHandler={authSubmitHandler}
              switchModeHandler={switchModeHandler}
              otpSent={otpSent}
              isOtpValidated={isOtpValidated}
              setIsOtpValidated={setIsOtpValidated}
            />
          )}

          <p className="choose-signup-login">
            {isLoginMode
              ? "Don't have an account ? "
              : "Already have an account ? "}
            <span
              onClick={switchModeHandler}
              className="SignUp-or-login"
              style={{ color: isLoginMode ? "red" : "green" }}
            >
              {isLoginMode ? "Sign Up" : "Log In"}
            </span>
          </p>

          <button className=" google-login-btn " onClick={googleLogin}>
            <i
              className="fab fa-google fa-lg"
              style={{ marginRight: "1rem" }}
            ></i>
            <span style={{ padding: "0 0.5rem" }}>Sign in with Google</span>
          </button>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <CircularProgress style={{ fontSize: "30" }} />
        </div>
      )}
    </>
  );
};

export default Auth;
