import React, { useEffect, useState } from "react";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

function StarRating({ rating, className = "" }) {
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const ratingValue = parseFloat(rating);
    let filledStars = Math.floor(ratingValue);
    const hasHalfStar = ratingValue % 1 >= 0.5;
    const starColor = ratingValue <= 2 ? "red" : "#06D001";

    const starElements = [];
    for (let i = 0; i < filledStars; i++) {
      starElements.push(<StarRateIcon key={i} style={{ color: starColor }} />);
    }

    if (hasHalfStar) {
      starElements.push(
        <StarHalfIcon key={filledStars} style={{ color: starColor }} />
      );
      filledStars += 1;
    }

    while (starElements.length < 5) {
      starElements.push(
        <StarOutlineIcon
          key={starElements.length}
          style={{ color: starColor }}
        />
      );
    }

    setStars(starElements);
  }, [rating]);

  return <div className={`rating ${className}`}>{stars}</div>;
}

export default StarRating;
