import React, { useState, useEffect } from 'react';

const AnimatedHeading = (props) => {
  const text = props.heading;
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;

    if (isDeleting) {
      timer = setTimeout(() => {
        setDisplayedText((prev) => prev.slice(0, -1));
        setIndex((prev) => prev - 1);
      }, 100);
    } else {
      if (index < text.length) {
        timer = setTimeout(() => {
          setDisplayedText((prev) => prev + text[index]);
          setIndex((prev) => prev + 1);
        }, 150);
      } else {
        timer = setTimeout(() => setIsDeleting(true), 1000);
      }
    }

    if (isDeleting && index === 0) {
      setIsDeleting(false);
    }

    return () => clearTimeout(timer);
  }, [displayedText, index, isDeleting]);

  return (
    <h3 className="text-3xl md:text-4xl font-semibold mb-4 slide-in-left flex items-center">
      {displayedText}
    </h3>
  );
};

export default AnimatedHeading;
