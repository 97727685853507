import React, { useState } from 'react';
import AddCompanyData from "../component/AddCompanyData";
import UpdateCompany from '../component/UpdateCompany';
import ManageCompany from "../component/ManageCompanies/ManageComany";
import ClearIcon from '@mui/icons-material/Clear';

function MainCompanyPage() {
  const [activeForm, setActiveForm] = useState(null);

  const handleButtonClick = (formName) => {
    setActiveForm(formName);
  };

  const handleCloseClick = () => {
    setActiveForm(null);
  };

  const buttons = [
    {
      id: 'addCompany',
      title: 'Add a new company',
      description: 'Add a new company with all necessary details.',
      gradient: 'bg-gradient-to-r from-green-400 to-green-700 hover:from-green-700 hover:to-green-400',
      buttonText: 'Add Company',
    },
    {
      id: 'updateCompany',
      title: 'Update an existing company',
      description: 'Search and update company details in the database.',
      gradient: 'bg-gradient-to-r from-purple-600 to-blue-600 hover:from-blue-500 hover:to-purple-600',
      buttonText: 'Update Company',
    },
    {
      id: 'ReplaceDelete',
      title: 'Replace or Delete a company',
      description: 'Search and delete or replace an existing company.',
      gradient: 'bg-gradient-to-r from-red-700 to-pink-500 hover:from-pink-700 hover:to-red-700',
      buttonText: 'Replace/Delete',
    },
  ];

  return (
    <div className="flex flex-col p-6 bg-gradient-to-b from-gray-900 via-gray-800 to-black min-h-screen">
    <div>
    <h1 className='text-left mb-4 text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600 tracking-wider drop-shadow-lg'>Company Management Dashboard</h1>
    </div>
      {!activeForm ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">

          {buttons.map((button) => (
            <div 
              key={button.id} 
              className="bg-gray-800 text-white p-6 rounded-lg shadow-lg flex flex-col justify-between"
            >
              <div>
                <h2 className="text-lg font-semibold mb-2 text-center">{button.title}</h2>
                <p className="text-sm text-gray-400 mb-4 text-center">{button.description}</p>
              </div>
              <button
                onClick={() => handleButtonClick(button.id)}
                className={`${button.gradient} text-white font-medium py-2 px-4 rounded-full shadow-md transform hover:scale-105 transition-transform`}
                style={{
                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.15)',
                }}
              >
                {button.buttonText}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="flex justify-end mb-4">
            <button
              onClick={handleCloseClick}
              className="mt-1 bg-gradient-to-r from-red-500 to-pink-500 hover:from-red-600 hover:to-pink-600 text-white font-semibold px-4 py-2 rounded-full shadow-lg transform hover:scale-105 transition-transform duration-300 ease-out"
              style={{
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.25)',
              }}
            >
              <ClearIcon style={{ fontSize: 20 }} />
            </button>
          </div>
          {activeForm === 'addCompany' && <AddCompanyData />}
          {activeForm === 'updateCompany' && <UpdateCompany />}
          {activeForm === 'ReplaceDelete' && <ManageCompany />}
        </div>
      )}
    </div>
  );
}

export default MainCompanyPage;

