import React from "react";
import { Chart as ChartJS } from "chart.js/auto";
import { Pie } from "react-chartjs-2";

const PortfolioStrategy = () => {
  return (
    <div className="max-w-[1200px] mx-auto font-roboto">
      <h1 className="text-4xl bg-green-700 text-white py-2 mb-[30px] rounded-md text-center">
        Portfolio Strategy
      </h1>
      <div className="mx-auto w-[89%] text-center text-2xl max-md:text-lg text-[var(--on-surface)]">
        Our portfolio is designed to capitalize on stock market movements,
        offering structured opportunities to grow your investment.
      </div>
      <div className="lg:flex max-w-[2000px] lg:justify-center lg:items-center text-[var(--on-surface)]">
        <div className="max-w-[1200px] max-md:w-[51%] w-[35%] l:w-[41%] xl:w-[35%] mx-auto max-sm:w-[68%]">
          <Pie
            data={{
              labels: ["BTSM", "BTSF", "BTSY", "BTSFY"],
              datasets: [
                {
                  label: "Capital",
                  data: [15, 25, 25, 35],
                  backgroundColor: [
                    "rgba(255, 205, 86, 0.4)",
                    "rgba(75, 192, 192, 0.4)",
                    "rgba(59, 89, 255, 0.4)",
                    "rgba(153, 102, 255, 0.4)",
                  ],
                  borderColor: [
                    "rgb(255, 205, 86)",
                    "rgb(75, 192, 192)",
                    "rgb(54, 162, 235)",
                    "rgb(153, 102, 255)",
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: "rgba(75, 192, 192, 1)", // Set the text color for the labels
                    font: {
                      size: 14, // Adjust the font size
                      family: "Arial", // Set font family
                    },
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const label = context.label || "";
                      const value = context.raw || 0;
                      return `${label}: ${value}%`;
                    },
                  },
                },
              },
            }}
          />
        </div>

        <div className="max-w-[1200px]">
          <div className="mx-auto w-[70%] relative my-[20px]">
            <hr className="h-1 bg-gradient-to-r from-red-500 to-sky-500" />
          </div>
          <div className="flex flex-col gap-3 items-start w-[65%] xl:w-[70%] max-sm:w-[90%] mx-auto font-lato">
            <div className="flex justify-evenly gap-5 items-center">
              <h1 className="text-3xl max-md:text-2xl max-sm:text-[22px] text-[var(--primary)]">
                BTSM
              </h1>
              <h3 className="text-xl max-sm:text-lg text-[var(--on-surface-variant)]">
                Buy Today Sell Next Month - Small-Mid Cap Stocks (15% Capital)
              </h3>
            </div>
            <div className="flex justify-evenly gap-5 items-center">
              <h1 className="text-3xl max-md:text-2xl max-sm:text-[22px] text-[var(--primary)]">
                BTSF
              </h1>
              <h3 className="text-xl max-sm:text-lg text-[var(--on-surface-variant)]">
                Buy Today Sell in Few Months - Small-Mid Cap Stocks (25%
                Capital)
              </h3>
            </div>
            <div className="flex justify-evenly gap-5 items-center">
              <h1 className="text-3xl max-md:text-2xl max-sm:text-[22px] text-[var(--primary)]">
                BTSY
              </h1>
              <h3 className="text-xl max-sm:text-lg text-[var(--on-surface-variant)]">
                Buy Today Sell Next Year - Mid/Large Cap (25% Capital)
              </h3>
            </div>
            <div className="flex justify-evenly gap-5 items-center">
              <h1 className="text-3xl max-md:text-2xl max-sm:text-[22px] text-[var(--primary)]">
                BTSFY
              </h1>
              <h3 className="text-xl max-sm:text-lg text-[var(--on-surface-variant)]">
                Buy Today Sell in Few Years - Mid/Large Cap Stocks (35% Capital)
              </h3>
            </div>
          </div>
          <div className="mx-auto w-[70%] relative my-[20px]">
            <hr className="h-1 bg-gradient-to-r from-red-500 to-sky-500" />
          </div>
        </div>
      </div>
      <h5 className="text-[var(--on-surface-variant)] mt-4 mb-[30px] text-3xl max-md:text-[22px] max-sm:text-lg text-center">
        We will clearly indicate the type of recommendation alongside each
        stock.
      </h5>
    </div>
  );
};

export default PortfolioStrategy;
