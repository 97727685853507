import React from "react";
import { FaStar } from "react-icons/fa";

const UserExperience = ({ user }) => {
  // Function to render stars based on the rating
  const renderStars = (rating) => {
    const totalStars = 5;
    const filledStars = Math.floor(rating); // Full stars
    const halfStar = rating % 1 !== 0; // Check if there's a half star
    const emptyStars = totalStars - filledStars - (halfStar ? 1 : 0);

    return (
      <>
        {/* Filled stars */}
        {Array(filledStars)
          .fill()
          .map((_, index) => (
            <FaStar key={index} className="text-green-500" />
          ))}
        {/* Half star */}
        {halfStar && <FaStar className="text-gray-300" />}
        {/* Empty stars */}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <FaStar key={index} className="text-gray-300" />
          ))}
      </>
    );
  };

  return (
    <div className="flex  mt-2 flex-col items-center shadow-slate-600 shadow-2xl rounded-lg p-4 min-w-[400px] font-roboto mb-4 max-sm:min-w-[95%]">
      {/* <img className="w-24 h-24 rounded-full mb-4" src={user.image} alt={user.username} /> */}
      <h2 className="text-xl font-bold mb-2 text-center text-[var(--primary)]">
        {user.username}
      </h2>
      <p className="text-[var(--on-surface-low)] text-balance text-sm mb-2 line-clamp-6 hover:line-clamp-none">
        {user.description}
      </p>
      <div className="flex">{renderStars(user.rating)}</div>
    </div>
  );
};

export default UserExperience;
