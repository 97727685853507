import React, { useState, useEffect } from "react";
import axios from "axios";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Skeleton from "@mui/material/Skeleton";
import { constaints } from "../../utils/constaints";

function StockPrice({ symbol, className }) {
  const [price, setPrice] = useState(null);
  const [prevClose, setPrevClose] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchPrice = async () => {
      setLoading(true);
      try {
        const [priceRes, prevCloseRes] = await Promise.all([
          axios.get(`${SERVER_URL}/screener/current-price/${symbol}`, {
            withCredentials: true,
          }),
          axios.get(`${SERVER_URL}/screener/previous-close/${symbol}`, {
            withCredentials: true,
          }),
        ]);
        setPrice(priceRes.data.price);
        setPrevClose(prevCloseRes.data.prevClosePrice);
        setLastUpdated(new Date().toISOString());
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPrice();
  }, [symbol]);

  const priceChange = price && prevClose ? price - prevClose : 0;
  const priceChangePercent = prevClose ? (priceChange / prevClose) * 100 : 0;
  const changeColor = priceChange >= 0 ? "text-green-600" : "text-red-600";
  const ChangeIcon = priceChange >= 0 ? ArrowDropUpIcon : ArrowDropDownIcon;

  return (
    <div className={`stock-price ${className ? className : ""} p-3 `}>
      <div className="price-container flex items-center space-x-2.5">
        {loading ? (
          <Skeleton
            variant="text"
            width={140}
            height={30}
            className="ml-2 mr-2"
          />
        ) : (
          price && (
            <p className="text-2xl font-bold text-[var(--on-surface)]">
              ₹ {price}
            </p>
          )
        )}
        {loading ? (
          <Skeleton variant="text" width={140} height={30} />
        ) : (
          price &&
          prevClose && (
            <p className="text-sm font-medium mt-2">
              <span className={`${changeColor} flex items-center`}>
                <ChangeIcon fontSize="small" />₹{" "}
                {Math.abs(priceChange.toFixed(2))} (
                {Math.abs(priceChangePercent.toFixed(2))}%)
              </span>
            </p>
          )
        )}
      </div>

      {loading ? (
        <Skeleton variant="text" width={280} height={30} />
      ) : (
        lastUpdated && (
          <p className="text-xs mt-2 text-gray-500">
            Last Updated:{" "}
            {new Date(lastUpdated).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
            })}{" "}
            {new Date(lastUpdated)
              .toLocaleTimeString("en-GB", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })
              .toLowerCase()}
          </p>
        )
      )}
      {error && <p className="text-red-500">---</p>}
    </div>
  );
}

export default StockPrice;
