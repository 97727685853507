import React, { useState, useCallback, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { initializeGA, trackPageView, trackEvent } from "./utils/analytics"; // Import GA utility functions
import MainNavigation from "./Shared/Navigations/MainNavigation";
import Home from "./Home/Pages/home";
import Shariah from "./Shariah/pages/Shariah";
import About from "./About/pages/about";
import Methology from "./Methology/Methology";
import Screener from "./Screener/pages/Screener";
import Auth from "./Auth/Auth";
import QandA from "./Footer/pages/QandA";
import Portfolio from "./DashBoard/Pages/Portfolio";
import Account from "./DashBoard/Pages/Account";
import { AuthContext } from "./Shared/context/auth-context";
import CompanyDetails from "./Screener/pages/CompanyDetails";
import CsurfTutorial from "./csrf";
import StockIdeas from "./DashBoard/Pages/StockIdeas";
import FinView from "./Report/Pages/FinView";
import ForgotPassword from "./Auth/Pages/Forgot";
import InvestorsGrievancesPortal from "./Footer/pages/InvestorsGrievancesPortal";
import TermsandCondition from "./Footer/pages/TermsandCondition";
import Disclaimer from "./Footer/pages/Disclaimer";
import Watchlist from "./DashBoard/Pages/Watchlist";
import PrivacyPolicy from "./Footer/pages/PrivacyPolicy";
import PolicyConflict from "./Footer/pages/Policies-conflict";
import FreePlanUI from "./Landing-Page/FreePlanUI";
import FullArticle from "./Report/component/MarketView/FullArticle";
import ReportDetails from "./Report/component/ResearchReport/ReportDetails";
import IpoDetailData from "./Report/component/IPO/IpoDetailData";
import AdminPanel from "./AdminPanel/Pages/AdminPanel";
import withAdminAuth from "./AdminPanel/Pages/withAdminAuth";
import "./App.css";
import LandingPage from "./Landing-Page/LandingPage";
import UnSubscribeEmail from "./EmailUnsubscribeMessage/UnSubscribeEmail";
import UnSubscribeMarketviewEmail from "./EmailUnsubscribeMessage/unsubscribemarketEmail";
import UnSubscribeResearchViewEmail from "./EmailUnsubscribeMessage/UnSubscribeResearchViewEmail";
import UnSubscribeStockIdeaEmail from "./EmailUnsubscribeMessage/UnSubscribeStockIdeaEmail";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "./NotFound";
import axios from "axios";
import Footer from "./Footer/Footer";
import { Provider } from "react-redux";
import appStore from "./utils/appStore";
import { useLocation } from "react-router-dom"; // Import useLocation hook
// import { Navbar } from "./Shared/Navigations/Navbar";
// import { MainNavigation } from "./Shared/Navigations/MainNavigation";
import PostMarketView from "../src/AdminPanel/Components/MarketView/component/PostMarketView";
import { constaints } from "./utils/constaints";

// Component to handle page tracking
const PageTracker = () => {
  const location = useLocation(); // Correctly use useLocation inside Router

  useEffect(() => {
    const currentPath = location.pathname;
    const title =
      location.pathname.split("/").filter(Boolean).join(" - ") || "Home"; // Dynamically set the title based on the pathname
    trackPageView(currentPath, title); // Track page view on route change
  }, [location]);

  return null; // This component does not render anything
};

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  useEffect(() => {
    initializeGA(); // Initialize Google Analytics when the app loads
  }, []);

  const updateUser = (userData) => {
    setUser(userData);
  };

  const login = useCallback((userData) => {
    setIsLoggedIn(true);
    setUser(userData);
  }, []);

  const doUserLogOut = async () => {
    try {
      await axios.post(
        `${SERVER_URL}/auth/logout`,
        {},
        {
          withCredentials: true,
        }
      );
      setUser(null);
      return true;
    } catch (error) {
      return false;
    }
  };

  const logout = useCallback(() => {
    doUserLogOut();
    setIsLoggedIn(false);
    setUser(null);
  }, [doUserLogOut]);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/auth/user/data`, {
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.user) {
          setIsLoggedIn(true);
          setUser(response.data.user);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [SERVER_URL]);

  let routes;

  if (isLoggedIn) {
    routes = (
      <Switch>
        <Route path="/csrf" component={CsurfTutorial} />
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/finview" component={FinView} />
        <Route path="/methodology" component={Methology} />
        <Route path="/screener" component={Screener} />
        <Route path="/QandA" component={QandA} />
        <Route path="/terms&condition" component={TermsandCondition} />
        {/* <Route path="/disclaimer" component={Disclaimer} /> */}
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        {/* <Route path="/policy-to-address-conflict" component={PolicyConflict} /> */}
        <ProtectedRoute path="/watchlist" user={user} component={Watchlist} />
        <ProtectedRoute path="/user-portfolio" component={Portfolio} />
        <ProtectedRoute path="/stock-idea" component={StockIdeas} />
        <ProtectedRoute path="/admin/add-blog" component={PostMarketView} />
        <ProtectedRoute exact path="/user-profile" component={Account} />
        <Route exact path="/company-name/:name" component={CompanyDetails} />
        <Route path="/investorsportal" component={InvestorsGrievancesPortal} />
        <Route exact path="/company/:id" component={CompanyDetails} />
        <Route path="/shariah/:name" component={Shariah} />
        {/* <Route path="/pricing" component={FreePlanUI} /> */}
        <Route path="/market/:slug" component={FullArticle} />
        <Route
          path="/report-details/name/:companyName"
          component={ReportDetails}
        />
        <Route
          path="/ipo/company/detail/:companyName"
          component={IpoDetailData}
        />
        <Route path="/landing-page" component={LandingPage} />
        <Route path="/unsubscibe-email/:token" component={UnSubscribeEmail} />
        <Route
          path="/unsubscibe-email-marketview/:token"
          component={UnSubscribeMarketviewEmail}
        />
        <Route
          path="/unsubscibe-email-researchview/:token"
          component={UnSubscribeResearchViewEmail}
        />
        <Route
          path="/unsubscribe-email-stockidea/:token"
          component={UnSubscribeStockIdeaEmail}
        />
        <ProtectedRoute path="/admin" component={withAdminAuth(AdminPanel)} />
        <Route component={NotFound} />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/csrf" component={CsurfTutorial} />
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/finview" component={FinView} />
        <Route path="/methodology" component={Methology} />
        <Route path="/screener" component={Screener} />
        <Route path="/QandA" component={QandA} />
        <Route exact path="/company/:id" component={CompanyDetails} />
        <Route exact path="/company-name/:name" component={CompanyDetails} />
        <Route path="/terms&condition" component={TermsandCondition} />
        {/* <Route path="/disclaimer" component={Disclaimer} /> */}
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        {/* <Route path="/policy-to-address-conflict" component={PolicyConflict} /> */}
        <Route path="/investorsportal" component={InvestorsGrievancesPortal} />
        <Route path="/shariah/:name" component={Shariah} />
        <Route path="/auth" component={Auth} />
        <Route path="/forgot" component={ForgotPassword} />
        {/* <Route path="/pricing" component={FreePlanUI} /> */}
        <Route path="/market/:slug" component={FullArticle} />
        <Route
          path="/report-details/name/:companyName"
          component={ReportDetails}
        />
        <Route
          path="/ipo/company/detail/:companyName"
          component={IpoDetailData}
        />
        <Route path="/landing-page" component={LandingPage} />
        <ProtectedRoute path="/stock-idea" component={StockIdeas} />
        <ProtectedRoute exact path="/user-profile" component={Account} />
        <ProtectedRoute path="/watchlist" user={user} component={Watchlist} />
        <ProtectedRoute path="/user-portfolio" component={Portfolio} />
        <Route component={NotFound} />
      </Switch>
    );
  }

  return (
    <Provider store={appStore}>
      <AuthContext.Provider
        value={{
          isLoggedIn,
          isLoading,
          login,
          logout,
          user,
          updateUser,
        }}
      >
        <div className="flex flex-col">
          <Router>
            <PageTracker />
            <MainNavigation />
            {/* <Navbar /> */}
            <div className="flex-grow">
              <main>{routes}</main>
            </div>
            <Footer />
          </Router>
        </div>
      </AuthContext.Provider>
    </Provider>
  );
}

export default App;
