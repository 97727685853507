import "./box.css";

export const Box = ({
  variant = "col",
  width = "full",
  gap = "none",
  padding = "none",
  paddingX = "none",
  justifyContent = "start",
  alignItems = "stretch",
  children,
  ...props
}) => {
  return (
    <div
      className={`box box--${variant} width-${width} gap-${gap} padding-${padding} padding-x-${paddingX} justify-content-${justifyContent} align-items-${alignItems}`}
      {...props}
    >
      {children}
    </div>
  );
};
