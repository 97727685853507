// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Skeleton from "@mui/material/Skeleton";

// function PotentialLeft({ symbol, targetPrice, className }) {
//   const [currentPrice, setCurrentPrice] = useState(null);
//   const [percentageDifference, setPercentageDifference] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//const SERVER_URL = constaints.REACT_APP_SERVER_URL;

//   useEffect(() => {
//     let isMounted = true;

//     const fetchPriceAndCalculatePotential = async () => {
//       try {
//         const response = await axios.get(
//           `${SERVER_URL}/finsha/calculate-potential-left/${symbol}/${targetPrice}`,
//           { withCredentials: true }
//         );

//         if (isMounted) {
//           const { currentPrice, percentageDifference } = response.data;
//           setCurrentPrice(currentPrice);
//           setPercentageDifference(Number(percentageDifference)); // Ensure percentageDifference is a number
//           setLoading(false);
//         }
//       } catch (err) {
//         if (isMounted) {
//           setError(err.message);
//           setLoading(false);
//         }
//       }
//     };

//     fetchPriceAndCalculatePotential();

//     return () => {
//       isMounted = false;
//     };
//   }, [symbol, targetPrice, SERVER_URL]);

//   return (
//     <div className={`potential-container ${className}`}>
//       {loading && <Skeleton variant="rectangular" width={700} height={30} />}
//       {error && <p className="potential-error">Error: {error}</p>}
//       {!loading && !error && percentageDifference !== null && (
//         <h4 className=" text-green-950 max-sm:text-sm font-bold">
//           Potential Left:{" "}
//           <span className="text-green-950 max-sm:text-sm font-normal">{`(${percentageDifference.toFixed(
//             2
//           )}%)`}</span>
//         </h4>
//       )}
//     </div>
//   );
// }

// export default PotentialLeft;

import React from "react";

function PotentialLeft({ potentialLeft, className }) {
  return (
    <div className={`potential-container ${className}`}>
      {potentialLeft !== null ? (
        <h4 className="text-green-950 max-sm:text-sm font-bold">
          Potential Left:{" "}
          <span className="text-green-950 max-sm:text-sm font-normal">
            {`(${potentialLeft.toFixed(2)}%)`}
          </span>
        </h4>
      ) : (
        <p className="potential-error">Potential Left data not available</p>
      )}
    </div>
  );
}

export default PotentialLeft;
