import React from 'react';
import "./LandingSubscriptionPlan.css"
import StarRateIcon from '@mui/icons-material/StarRate';

const SubscriptionPlan = ({ plan, onSelectPlan, currentSubscription }) => {
  // Check if the current plan is subscribed and not yet expired
  const isCurrentlySubscribed = currentSubscription &&
    currentSubscription.tier === plan.tier &&
    currentSubscription.duration === plan.period &&
    currentSubscription.activated &&
    new Date(currentSubscription.expiryDate) > new Date();

  return (
    <div
      className="flex flex-col border-2 border-slate-500 p-8 py-10 pb-6 rounded-xl justify-center items-center shadow-slate-500 shadow-2xl"
      style={{ backgroundColor: plan.price === 14999 ? '#286e34' : 'white', color: plan.price !== 14999 ? '#1e293b' : 'white' }}
    >
      {/* <h6 
  className="text-[30px] bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent font-semibold mb-2"
>
  {plan.tier1}
</h6> */}

<h6
    className={`text-[30px] font-semibold mb-2 ${
      plan.price === 14999
        ? 'text-white'
        : 'bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent'
    }`}
  >
    {plan.tier1}
  </h6>

<div className="inline-block border-2 border-blue-500 rounded-lg p-2 bg-white bg-opacity-20 backdrop-blur-sm">
  <h6 
    className="text-[20px] font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent"
  >
    {plan.monthlyPlan}
  </h6>
</div>

      <h6 className='font-bold text-[30px] py-2 mt-2'>₹{plan.price}/-</h6>
      <h6 className='text-[16px] text-center'>
        {plan.description.map((desc, index) => (
          <React.Fragment key={index}>{desc}</React.Fragment>
        ))}
      </h6>
      <h6 className='text-[23px] py-2'>{plan.savings}</h6>
      <div>
        <button
          style={{ backgroundColor: plan.price === 14999 ? 'white' : '#286e34', color: plan.price === 14999 ? "black" : "white" }}
          className={`subscription__button ${isCurrentlySubscribed ? 'disabled' : ''} px-5 rounded-lg hover:scale-105 z-50`}
          onClick={(e) => {
            if (!isCurrentlySubscribed) {
              onSelectPlan(plan);
            }
          }}
        >
          {isCurrentlySubscribed ? "Subscribed" : "Subscribe Now"}
        </button>
      </div>
    </div>
  );
};

export const withPromotedLabel = (SubscriptionPlan) => {
  return (props) => {
    return (
      <div className="relative">
        <div className="text-lg left-1/2 transform -translate-x-1/2 text-[#286e34] font-semibold absolute -top-4 mx-auto p-2 bg-orange-100 whitespace-nowrap rounded-lg">
          {" "} Best Value Pack {" "}
        </div>
        <div className="absolute right-1 top-2 p-2">
  <button
    className="flex items-center justify-center  shadow-lg transform transition-transform hover:scale-110 hover:rotate-12"
    style={{ border: "none", outline: "none" }}
  >
    <StarRateIcon style={{ fontSize: "35px", color: "gold"}} />
  </button>
</div>

        <SubscriptionPlan {...props} />
      </div>
    );
  };
};

export default SubscriptionPlan;
