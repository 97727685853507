import "./text.css";

export const Text = ({
  as: Tag = "p",
  variant = "display",
  color = "default",
  size = "medium",
  margin = "none",
  width = "full",
  highlight,
  children,
  ...props
}) => {
  return (
    <Tag
      className={`text--color-${color} text--${variant}-${size} text--margin-${margin} ${
        highlight ? "text--highlight" : ""
      } width-${width}`}
      {...props}
    >
      {children}
    </Tag>
  );
};
