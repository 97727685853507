import React, { useState } from "react";
import axios from "axios";
import SearchStockIdeas from "../updateStockidea/SearchStockIdeas";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { constaints } from "../../../../../utils/constaints";

const ManualStockUpdate = () => {
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const [stockIdeas, setStockIdeas] = useState([]);
  const [currentStockData, setCurrentStockData] = useState({
    companyName: "",
    netGain: "",
    potentialLeft: "",
    visibility: "",
  });
  const [emailSubject, setEmailSubject] = useState("Weekly Recommendation");
  const [messageTitle, setMessageTitle] = useState(
    "📊 Recommendation Weekly 📊"
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleSelectIdea = (idea) => {
    if (idea) {
      setCurrentStockData({
        companyName: idea["Company Name"],
        netGain: "",
        potentialLeft: "",
        visibility: idea.Visibility,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentStockData({ ...currentStockData, [name]: value });
  };

  const handleEmailSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleMessageTitleChange = (e) => {
    setMessageTitle(e.target.value);
  };

  const handleAddAnotherStock = () => {
    if (currentStockData.companyName) {
      if (editIndex !== null) {
        const updatedStockIdeas = [...stockIdeas];
        updatedStockIdeas[editIndex] = currentStockData;
        setStockIdeas(updatedStockIdeas);
        setEditIndex(null);
      } else {
        setStockIdeas([...stockIdeas, currentStockData]);
      }

      setCurrentStockData({
        companyName: "",
        netGain: "",
        potentialLeft: "",
        visibility: "",
      });
    }
  };

  const handleEditStock = (index) => {
    setCurrentStockData(stockIdeas[index]);
    setEditIndex(index);
  };

  const handleDeleteStock = (index) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    setTimeout(() => {
      const updatedStockIdeas = stockIdeas.filter((_, i) => i !== index);
      setStockIdeas(updatedStockIdeas);
      setIsSubmitting(false);
    }, 1000); // Simulating a delay
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const data = {
        manualStockData: stockIdeas.concat(currentStockData),
        emailSubject,
        messageTitle,
      };

      await axios.post(`${SERVER_URL}/admin/manual-stock-update`, data);

      // Show success modal
      setSuccessModalOpen(true);
      clearForm();
    } catch (error) {
      setSuccessModalOpen(true); // Show modal on error as well
    } finally {
      setIsSubmitting(false);
    }
  };

  const clearForm = () => {
    setStockIdeas([]);
    setCurrentStockData({
      companyName: "",
      netGain: "",
      potentialLeft: "",
      visibility: "",
    });
    setEmailSubject("Recommendation Weekly");
    setMessageTitle("📊 Weekly Recommendation 📊");
    setEditIndex(null);
  };

  const isFirstFormValid = () => {
    return (
      currentStockData.companyName &&
      currentStockData.netGain &&
      currentStockData.potentialLeft &&
      currentStockData.visibility
    );
  };

  const handleModalClose = () => {
    setSuccessModalOpen(false);
  };

  return (
    <div className="p-4">
      <div className="p-2 font-bold">
        <h1 className="text-2xl text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500">
          Weekly/Monthly Stock Update
        </h1>
      </div>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-2">
          <label className="block text-white">Company Name:</label>
          <SearchStockIdeas
            onSelectIdea={handleSelectIdea}
            onClearSearch={() =>
              setCurrentStockData({ ...currentStockData, companyName: "" })
            }
            searchValue={currentStockData.companyName}
            onSearchValueChange={(value) =>
              setCurrentStockData({ ...currentStockData, companyName: value })
            }
          />
        </div>
        <div className="mb-2">
          <label className="block text-white">Visibility:</label>
          <input
            type="text"
            name="visibility"
            value={currentStockData.visibility}
            onChange={handleInputChange}
            className="w-full p-2 border rounded text-black"
            readOnly
          />
        </div>
        {stockIdeas.length === 0 && (
          <>
            <div className="mb-2">
              <label className="block text-white">Email Subject:</label>
              <input
                type="text"
                name="emailSubject"
                value={emailSubject}
                onChange={handleEmailSubjectChange}
                className="w-full p-2 border rounded text-black"
                required
              />
            </div>
            <div className="mb-2">
              <label className="block text-white">Message Title:</label>
              <input
                type="text"
                name="messageTitle"
                value={messageTitle}
                onChange={handleMessageTitleChange}
                className="w-full p-2 border rounded text-black"
                required
              />
            </div>
          </>
        )}
        <div className="mb-2">
          <label className="block text-white">Net Gain:</label>
          <input
            type="text"
            name="netGain"
            value={currentStockData.netGain}
            onChange={handleInputChange}
            className="w-full p-2 border rounded text-black"
            placeholder="e.g:0.5"
            required
          />
        </div>
        <div className="mb-2">
          <label className="block text-white">Potential Left:</label>
          <input
            type="text"
            name="potentialLeft"
            value={currentStockData.potentialLeft}
            onChange={handleInputChange}
            className="w-full p-2 border rounded text-black"
            placeholder="e.g:0.5"
            required
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          {isFirstFormValid() && (
            <button
              type="button"
              onClick={handleAddAnotherStock}
              disabled={isSubmitting}
              style={{
                backgroundColor: isSubmitting ? "#888" : "#508C9B",
                color: "#ffffff",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: isSubmitting ? "not-allowed" : "pointer",
                marginRight: "10px",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) =>
                !isSubmitting &&
                (e.currentTarget.style.backgroundColor = "#1e90ff")
              }
              onMouseOut={(e) =>
                !isSubmitting &&
                (e.currentTarget.style.backgroundColor = "#508C9B")
              }
            >
              {editIndex !== null ? "Save Changes" : " + Add New Stock"}
            </button>
          )}
          <button
            type="submit"
            disabled={isSubmitting}
            style={{
              backgroundColor: isSubmitting ? "#888" : "#32cd32",
              color: "#ffffff",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: isSubmitting ? "not-allowed" : "pointer",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) =>
              !isSubmitting &&
              (e.currentTarget.style.backgroundColor = "#228b22")
            }
            onMouseOut={(e) =>
              !isSubmitting &&
              (e.currentTarget.style.backgroundColor = "#32cd32")
            }
          >
            {isSubmitting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Post Recommendation"
            )}
          </button>
        </div>
      </form>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        {stockIdeas.map((idea, index) => (
          <div
            key={index}
            className="p-3 bg-black rounded shadow-lg text-white"
          >
            <h3 className="text-2 font-bold text-green-600">
              {index + 1}. {idea.companyName}
            </h3>
            <p className="text-sm">Net Gain: {idea.netGain}</p>
            <p className="text-sm">Potential Left: {idea.potentialLeft}</p>
            <p className="text-sm">Visibility: {idea.visibility}</p>
            <div className="flex justify-between mt-2">
              <button
                type="button"
                onClick={() => handleEditStock(index)}
                className="bg-green-500 text-black px-4 py-2 rounded hover:bg-green-700"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Edit"
                )}
              </button>
              <button
                type="button"
                onClick={() => handleDeleteStock(index)}
                className="bg-red-500 text-black px-4 py-2 rounded hover:bg-red-700"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        ))}
      </div>

      <Modal
        open={successModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1e1e1e",
            color: "white",
            border: "2px solid #333",
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#4caf50" }}
          >
            {successModalOpen ? "Success" : "Error"}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {successModalOpen
              ? "Stock update sent successfully!"
              : "Failed to send stock update"}
          </Typography>
          <Button
            onClick={handleModalClose}
            sx={{
              mt: 3,
              bgcolor: "#4caf50",
              "&:hover": {
                bgcolor: "#388e3c",
              },
              color: "white",
              width: "100%",
            }}
            variant="contained"
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ManualStockUpdate;
