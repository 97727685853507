import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import "./ResearchView.css";
import RatingStarComponent from "../RatingStar/RatingStarComponent";
import CurrentPrice from "../../../Screener/components/stock-price";
import PricePercentage from "../../../Screener/components/price-percentage";
import { FaBoxOpen, FaWrench, FaLightbulb, FaChartLine } from "react-icons/fa";
import { AuthContext } from "../../../Shared/context/auth-context";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { format } from "date-fns"; // Importing date-fns
import { constaints } from "../../../utils/constaints";

function ResearchView() {
  const history = useHistory();
  const { user } = useContext(AuthContext); //get user data from the auth context
  const [reports, setReports] = useState([]);
  const [isUserSubscribed, setIsUserSubscribed] = useState(false); //state to check whether user is subscibed to QuickWin or not
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  // this state check if user is subscibed to QuickWin, SmartHold, SmartElite, if not subscibed  then they will not be able to see the research report
  const userSubscriptionTier = user?.subscription?.tier ?? null;
  const hasAccess =
    isUserSubscribed &&
    ["QuickWin Starter", "SmartHold Starter", "SmartElite Starter"].includes(
      userSubscriptionTier
    );
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  // Scrolls the window to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Checks if the user's subscription is activated and updates the state accordingly when the user data changes
  useEffect(() => {
    if (user?.subscription?.activated) {
      setIsUserSubscribed(true); // Set state to indicate the user is subscribed
    }
  }, [user]); // Dependency array includes user, so this effect runs whenever user changes

  // Fetches reports from the server, sorts them by creation date, and updates the state with the sorted reports
  useEffect(() => {
    // Make a GET request to fetch reports from the server
    axios
      .get(`${SERVER_URL}/finview/reports`)
      .then((response) => {
        // Sort the reports by creation date, newest first
        const sortedReports = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        // Update the state with the sorted reports
        setReports(sortedReports);
        // Set loading state to false indicating that the data fetching is complete
        setLoading(false);
      })
      .catch((error) => {
        // Log any errors that occur during the fetch
        console.error("There was an error fetching the reports: ", error);
        // Set loading state to false indicating that the data fetching is complete (even if there was an error)
        setLoading(false);
      });
  }, [SERVER_URL]); // Dependency array includes SERVER_URL, so this effect runs whenever SERVER_URL changes

  // Handles the click event on a report item
  const handleReportClick = (report) => {
    if (hasAccess) {
      // If the user has access, format the company name by replacing spaces with hyphens
      const companyName = report.companyName.replace(/ /g, "-");
      // Navigate to the report details page with the formatted company name in the URL
      history.push(`/report-details/name/${companyName}`);
    } else {
      // If the user does not have access, open the access restriction modal
      setOpenModal(true);
    }
  };

  // Checks if a report is new by comparing its creation date to the current date
  const isNewReport = (createdAt) => {
    const now = new Date(); // Get the current date and time
    const reportDate = new Date(createdAt); // Convert the report's creation date string to a Date object
    const diffTime = Math.abs(now - reportDate); // Calculate the absolute difference in time between now and the report date
    const diffDays = diffTime / (1000 * 60 * 60 * 24); // Convert the difference from milliseconds to days
    return diffDays <= 7; // Return true if the report is 7 days old or less, otherwise return false
  };

  return (
    <div className="Research_and_marketNews_container">
      {/* Modal for access restriction notification */}
      <Modal
        open={openModal} // Controls whether the modal is open
        onClose={() => setOpenModal(false)} // Function to close the modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          {/* Modal content styling */}
          <div className="bg-[var(--surface)] p-6 rounded-lg shadow-lg max-w-md w-full">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-2xl font-bold text-center mb-4 text-red-500 p-4"
            >
              Access Restricted
            </Typography>
            <Typography
              id="modal-modal-description"
              className="text-center mb-4 text-[var(--on-surface)]"
            >
              Subscription required to view report details.
              {/* Modal description */}
            </Typography>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setOpenModal(false)}
                className="mt-4  hover:bg-red-400 text-[var(--on-surface)] font-bold py-2 px-6 rounded-md "
              >
                Close
              </button>
              <button
                component={Link}
                to="/pricing" // Link to the subscription page
                className="mt-4 bg-[var(--primary-variant)] hover:bg-green-600 text-white font-bold py-2 px-6 rounded-full"
              >
                Subscribe Now
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {loading ? (
        <div>Loading...</div> // Display loading message while data is being fetched
      ) : reports.length > 0 ? (
        reports.map((report) => (
          <div
            key={report._id} // Unique key for each report item
            className="text-decoration-none"
            onClick={() => handleReportClick(report)} // Handle report click event
          >
            <div className="report-container">
              {isNewReport(report.createdAt) && (
                <div className="new-report-ribbon">New</div> // Display "New" ribbon for new reports for upto 7 days
              )}
              <div className="report-header">
                <h4
                  className={`reportView_companyName ${
                    !hasAccess ? "blur-content" : ""
                  }`}
                >
                  {report.companyName}
                </h4>
              </div>
              <div className={`price-info ${!hasAccess ? "blur-content" : ""}`}>
                <CurrentPrice symbol={report.symbol} className="report_price" />
                <PricePercentage
                  symbol={report.symbol}
                  className="report_price_percentage"
                />
              </div>
              <div className="status-rating">
                <div
                  className={`shariah-status ${
                    report.ShariahStatus === "Compliant"
                      ? "green-text"
                      : "red-text"
                  }`}
                >
                  {report.ShariahStatus}
                </div>
                <div className="research-star-rating">
                  <RatingStarComponent value={report.rating} />
                </div>
              </div>
              <div className="research_view_Readmore_text_div">
                <p className="research_view_readmoretext">Read More......</p>
              </div>
              <div className="report-date_researchView">
                Posted on: {format(new Date(report.createdAt), "dd-MM-yyyy")}{" "}
                {format(new Date(report.createdAt), "HH:mm:ss")}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="no-reports-container">
          {/* if there is noo report available then it will show this message if  user visit to this page  */}
          <div className="no-reports-message">
            <FaWrench className="no-reports-wrench-icon" />
            <p>
              We're currently updating our research reports to bring you the
              most accurate and up-to-date information.
            </p>
            <p>
              Please check back soon for the latest insights into the market.
            </p>
            <p>
              In the meantime, explore our other resources for more financial
              analysis and investment opportunities.
            </p>
          </div>
          <div className="no-reports-icon">
            <FaBoxOpen className="no-reports-box-icon" />
          </div>
          <div className="no-reports-suggestion">
            <FaLightbulb className="no-reports-lightbulb-icon" />
            <p>Stay informed with our real-time market updates!</p>
          </div>
          <div className="no-reports-suggestion">
            <FaChartLine className="no-reports-chart-icon" />
            <p>Check our analysis section for recent market trends.</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResearchView;
