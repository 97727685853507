// SignupForm.js
import React, { useState, useEffect } from "react";
import { CircularProgress, Typography, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Toast from "../../../../Auth/Pages/Toast";
import EmailIcon from "@mui/icons-material/Email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { constaints } from "../../../../utils/constaints";

const SignupForm = ({
  formState,
  inputHandler,
  authSubmitHandler,
  otpSent,
}) => {
  const [showResendButton, setShowResendButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showReferralInput, setShowReferralInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState("");
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const [initialResendDelayPassed, setInitialResendDelayPassed] =
    useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    header: "",
    message: "",
    icon: null,
  });
  const [resendLoading, setResendLoading] = useState(false);

  const toggleReferralInput = () => {
    setShowReferralInput(!showReferralInput);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    let interval;
    if (otpSent) {
      const otpSentTime = new Date(otpSent).getTime();
      const now = new Date().getTime();
      const timeElapsed = now - otpSentTime;

      if (timeElapsed >= otpDuration) {
        setShowResendButton(true);
        setIsResendDisabled(false);
        setCountdown(0);
      } else {
        const remainingTime = otpDuration - timeElapsed;
        setCountdown(remainingTime / 1000);
        setShowResendButton(true);
        setIsResendDisabled(true);

        interval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1) {
              clearInterval(interval);
              setIsResendDisabled(false);
              return 0;
            }
            return prevCountdown - 1;
          });
        }, 1000);
      }
    }

    return () => clearInterval(interval);
  }, [otpSent]);

  const otpDuration = 1 * 60 * 1000; // 2 minutes

  useEffect(() => {
    let delayTimer;
    if (otpSent) {
      setShowResendButton(false); // Initially hide the button
      delayTimer = setTimeout(() => {
        setInitialResendDelayPassed(true);
        setShowResendButton(true); // Show the button after 30 seconds
      }, 30000); // 30 seconds delay
    } else {
      setInitialResendDelayPassed(false); // Reset on OTP not sent
    }

    return () => clearTimeout(delayTimer);
  }, [otpSent]);

  const resendOtpHandler = async (email, phone) => {
    setResendLoading(true);
    try {
      const response = await axios.post(`${SERVER_URL}/auth/resend-otp`, {
        email,
        phone,
      });
      if (response.status === 200) {
        setShowResendButton(false);
        setIsResendDisabled(true);
        setCountdown(otpDuration / 1000); // Reset countdown
        setToastConfig({
          header: "Success",
          message: "OTP regenerated successfully!",
          icon: "🎉",
        });
        setShowToast(true);
        const interval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1) {
              clearInterval(interval);
              setIsResendDisabled(false);
              return 0;
            }
            return prevCountdown - 1;
          });
        }, 1000);
      } else {
        setToastConfig({
          header: "Error",
          message:
            "Failed to regenerate OTP. Please check the details and try again.",
          icon: "❌",
        });
        setShowToast(true);
      }
    } catch (error) {
      console.error(error);
      setToastConfig({
        header: "Error",
        message: "Failed to regenerate OTP. Please try again later.",
        icon: "❌",
      });
      setShowToast(true);
    } finally {
      setResendLoading(false);
    }
  };

  const handlePhoneInputChange = (value) => {
    const fullPhoneNumber = `91${value}`;
    if (fullPhoneNumber.length < 12) {
      // Considering "91" + 10 digits
      setPhoneError("Phone number must be at least 10 digits");
    } else {
      setPhoneError("");
    }
    inputHandler("phone", fullPhoneNumber);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    if (newPassword.length < 5) {
      setPasswordError("Password must be at least 5 characters");
    } else {
      setPasswordError("");
    }
    inputHandler("password", newPassword);
    if (confirmPassword !== newPassword) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    if (newConfirmPassword !== formState.inputs.password.value) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    if (!validateEmail(newEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
    inputHandler("email", newEmail);
  };

  const handleOtpChange = (event) => {
    const otpValue = event.target.value.replace(/\s+/g, ""); // Remove any spaces
    inputHandler("otp", otpValue);
  };

  return (
    <div className="flex flex-col justify-center items-center p-4 rounded-lg sm:p-2">
      <h2 className="text-2xl md:text-2xl lg:text-3xl p-2 text-center font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-purple-700">
        Join Us Today! Please Register
      </h2>
      {showToast && (
        <Toast
          header={toastConfig.header}
          message={toastConfig.message}
          onClose={() => setShowToast(false)}
          icon={toastConfig.icon}
        />
      )}
      <form
        onSubmit={(event) => authSubmitHandler(event)}
        className="w-full max-w-md flex flex-col bg-white shadow-lg p-4 sm:p-6 md:p-8 rounded-lg"
      >
        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label
              htmlFor="fname"
              className="block text-sm font-medium text-black mb-1"
            >
              First Name
            </label>
            <input
              type="text"
              id="fname"
              value={formState.inputs.fname.value}
              onChange={(event) => inputHandler("fname", event.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="lname"
              className="block text-sm font-medium text-black mb-1"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lname"
              value={formState.inputs.lname.value}
              onChange={(event) => inputHandler("lname", event.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="mb-4">
          <div className="flex items-center mb-1">
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="mr-2 text-green-500"
            />
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-black"
            >
              Whatsapp Number
            </label>
          </div>
          <div className="relative">
            <input
              type="text"
              id="phone"
              value={
                formState.inputs.phone
                  ? formState.inputs.phone.value.substring(2)
                  : ""
              }
              onChange={(event) => handlePhoneInputChange(event.target.value)}
              required
              className={`w-full px-3 py-2 border ${
                phoneError ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              placeholder="+91"
            />
          </div>
          {phoneError && (
            <span className="text-red-500 text-sm">{phoneError}</span>
          )}
        </div>
        <div className="mb-4">
          <div className="flex items-center mb-1">
            <EmailIcon className="mr-2" />
            <label
              htmlFor="email"
              className="block text-sm font-medium text-black"
            >
              E-Mail
            </label>
          </div>
          <input
            type="email"
            id="email"
            value={formState.inputs.email.value}
            onChange={handleEmailChange}
            required
            autoComplete="username"
            className={`w-full px-3 py-2 border ${
              emailError ? "border-red-500" : "border-gray-300"
            } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
          />
          {emailError && (
            <span className="text-red-500 text-sm">{emailError}</span>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-black mb-1"
          >
            Create Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={formState.inputs.password.value}
              onChange={handlePasswordChange}
              required
              autoComplete="new-password"
              className={`w-full px-3 py-2 border ${
                passwordError ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            <button
              type="button"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
          </div>
          {passwordError && (
            <span className="text-red-500 text-sm">{passwordError}</span>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-black mb-1"
          >
            Confirm Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              autoComplete="new-password"
              className={`w-full px-3 py-2 border ${
                confirmPasswordError ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            <button
              type="button"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
          </div>
          {confirmPasswordError && (
            <span className="text-red-500 text-sm">{confirmPasswordError}</span>
          )}
        </div>
        {otpSent && (
          <>
            <div className="mb-4">
              <label
                htmlFor="otp"
                className="block text-sm font-medium text-black mb-1"
              >
                OTP
              </label>
              <input
                type="text"
                id="otp"
                value={formState.inputs.otp.value}
                onChange={handleOtpChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            {showReferralInput && (
              <div className="mb-4">
                <label
                  htmlFor="referralCode"
                  className="block text-sm font-medium text-black mb-1"
                >
                  Referral Code
                </label>
                <input
                  type="text"
                  id="referralCode"
                  value={
                    formState.inputs.referralCode
                      ? formState.inputs.referralCode.value
                      : ""
                  }
                  onChange={(event) =>
                    inputHandler("referralCode", event.target.value)
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}
            <Typography
              color="primary"
              style={{ cursor: "pointer", color: "black" }}
              onClick={toggleReferralInput}
            >
              Have a referral code?{" "}
              <span className="text-green-400">Click here.</span>
            </Typography>
          </>
        )}
        <div className="flex flex-row justify-center items-center space-x-4">
          {otpSent ? (
            <>
              {initialResendDelayPassed && (
                <button
                  onClick={() =>
                    resendOtpHandler(
                      formState.inputs.email.value,
                      formState.inputs.phone.value
                    )
                  }
                  className="w-48 py-2 mt-2 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-full hover:from-green-500 hover:to-blue-600 transition duration-200"
                  disabled={isResendDisabled}
                >
                  {resendLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : isResendDisabled ? (
                    `Resend OTP (${countdown}s)`
                  ) : (
                    "Resend OTP"
                  )}
                </button>
              )}
              <button
                type="submit"
                className="w-48 py-2 mt-2 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-full hover:from-green-500 hover:to-blue-600 transition duration-200"
              >
                Validate OTP
              </button>
            </>
          ) : (
            <button
              type="submit"
              className="w-48 py-2 mt-2 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-full hover:from-green-500 hover:to-blue-600 transition duration-200"
              onClick={() => {
                setIsResendDisabled(true);
                setCountdown(otpDuration / 1000); // Reset countdown
                const interval = setInterval(() => {
                  setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                      clearInterval(interval);
                      setIsResendDisabled(false);
                      return 0;
                    }
                    return prevCountdown - 1;
                  });
                }, 1000);
              }}
            >
              Generate OTP
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default SignupForm;

// import React, { useState, useEffect } from "react";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import Grid from "@mui/material/Grid";
// import "./SignUpForm.css";
// import InputAdornment from "@mui/material/InputAdornment";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import Typography from "@mui/material/Typography";
// import axios from "axios";
// import Toast from "../../../../Auth/Pages/Toast";
// import CircularProgress from "@mui/material/CircularProgress";

// const SignupForm = ({
//   formState,
//   inputHandler,
//   authSubmitHandler,
//   otpSent,
// }) => {
//   const [showResendButton, setShowResendButton] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const [confirmPassword, setConfirmPassword] = useState("");
// const [confirmPasswordError, setConfirmPasswordError] = useState("");
//   const [showReferralInput, setShowReferralInput] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [phoneError, setPhoneError] = useState("");
//   const [passwordError, setPasswordError] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [isResendDisabled, setIsResendDisabled] = useState(false);
//   const [countdown, setCountdown] = useState("");
//    const SERVER_URL = constaints.REACT_APP_SERVER_URL
//   const [initialResendDelayPassed, setInitialResendDelayPassed] =
//     useState(false);
//   const [showToast, setShowToast] = useState(false);
//   const [toastConfig, setToastConfig] = useState({
//     header: "",
//     message: "",
//     icon: null,
//   });
//   const [resendLoading, setResendLoading] = useState(false);

//   const toggleReferralInput = () => {
//     setShowReferralInput(!showReferralInput);
//   };

//   const handleClickShowPassword = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   useEffect(() => {
//     let interval;
//     if (otpSent) {
//       const otpSentTime = new Date(otpSent).getTime();
//       const now = new Date().getTime();
//       const timeElapsed = now - otpSentTime;

//       if (timeElapsed >= otpDuration) {
//         setShowResendButton(true);
//         setIsResendDisabled(false);
//         setCountdown(0);
//       } else {
//         const remainingTime = otpDuration - timeElapsed;
//         setCountdown(remainingTime / 1000);
//         setShowResendButton(true);
//         setIsResendDisabled(true);

//         interval = setInterval(() => {
//           setCountdown((prevCountdown) => {
//             if (prevCountdown <= 1) {
//               clearInterval(interval);
//               setIsResendDisabled(false);
//               return 0;
//             }
//             return prevCountdown - 1;
//           });
//         }, 1000);
//       }
//     }

//     return () => clearInterval(interval);
//   }, [otpSent]);

//   const otpDuration = 1 * 60 * 1000; // 2 minutes

//   useEffect(() => {
//     let delayTimer;
//     if (otpSent) {
//       setShowResendButton(false); // Initially hide the button
//       delayTimer = setTimeout(() => {
//         setInitialResendDelayPassed(true);
//         setShowResendButton(true); // Show the button after 30 seconds
//       }, 30000); // 30 seconds delay
//     } else {
//       setInitialResendDelayPassed(false); // Reset on OTP not sent
//     }

//     return () => clearTimeout(delayTimer);
//   }, [otpSent]);

//   const resendOtpHandler = async (email, phone) => {
//     setResendLoading(true);
//     try {
//       const response = await axios.post(`${SERVER_URL}/auth/resend-otp`, {
//         email,
//         phone,
//       });
//       if (response.status === 200) {
//         setShowResendButton(false);
//         setIsResendDisabled(true);
//         setCountdown(otpDuration / 1000); // Reset countdown
//         setToastConfig({
//           header: "Success",
//           message: "OTP regenerated successfully!",
//           icon: "🎉", // Optional, add any suitable icon you have
//         });
//         setShowToast(true);
//         const interval = setInterval(() => {
//           setCountdown((prevCountdown) => {
//             if (prevCountdown <= 1) {
//               clearInterval(interval);
//               setIsResendDisabled(false);
//               return 0;
//             }
//             return prevCountdown - 1;
//           });
//         }, 1000);
//       } else {
//         setToastConfig({
//           header: "Error",
//           message:
//             "Failed to regenerate OTP. Please check the details and try again.",
//           icon: "❌",
//         });
//         setShowToast(true);
//       }
//     } catch (error) {
//       console.error(error);
//       setToastConfig({
//         header: "Error",
//         message: "Failed to regenerate OTP. Please try again later.",
//         icon: "❌",
//       });
//       setShowToast(true);
//     } finally {
//       setResendLoading(false);
//     }
//   };

//   const handlePhoneInputChange = (value) => {
//     const fullPhoneNumber = `91${value}`;
//     if (fullPhoneNumber.length < 12) {
//       // Considering "91" + 10 digits
//       setPhoneError("Phone number must be at least 10 digits");
//     } else {
//       setPhoneError("");
//     }
//     inputHandler("phone", fullPhoneNumber);
//   };

//   // const handlePasswordChange = (event) => {
//   //   const newPassword = event.target.value;
//   //   if (newPassword.length < 5) {
//   //     setPasswordError("Password must be at least 5 characters");
//   //   } else {
//   //     setPasswordError("");
//   //   }
//   //   inputHandler("password", newPassword);
//   // };

//   const handlePasswordChange = (event) => {
//     const newPassword = event.target.value;
//     if (newPassword.length < 5) {
//       setPasswordError("Password must be at least 5 characters");
//     } else {
//       setPasswordError("");
//     }
//     inputHandler("password", newPassword);
//     if (confirmPassword !== newPassword) {
//       setConfirmPasswordError("Passwords do not match");
//     } else {
//       setConfirmPasswordError("");
//     }
//   };

//   const handleConfirmPasswordChange = (event) => {
//     const newConfirmPassword = event.target.value;
//     setConfirmPassword(newConfirmPassword);
//     if (newConfirmPassword !== formState.inputs.password.value) {
//       setConfirmPasswordError("Passwords do not match");
//     } else {
//       setConfirmPasswordError("");
//     }
//   };

//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   const handleEmailChange = (event) => {
//     const newEmail = event.target.value;
//     if (!validateEmail(newEmail)) {
//       setEmailError("Please enter a valid email address.");
//     } else {
//       setEmailError("");
//     }
//     inputHandler("email", newEmail);
//   };

//   const handleOtpChange = (event) => {
//     const otpValue = event.target.value.replace(/\s+/g, ""); // Remove any spaces
//     inputHandler("otp", otpValue);
//   };

//   return (
//     <div className="signup-form-container">
//       <h2 className="signup-heading-title"> Join Us Today! Please Register</h2>
//       {showToast && (
//         <Toast
//           header={toastConfig.header}
//           message={toastConfig.message}
//           onClose={() => setShowToast(false)}
//           icon={toastConfig.icon}
//         />
//       )}
//       <form
//         onSubmit={(event) => authSubmitHandler(event)}
//         className="signup-form"
//       >
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               id="fname"
//               label="First Name"
//               type="text"
//               variant="outlined"
//               margin="normal"
//               value={formState.inputs.fname.value}
//               onChange={(event) => inputHandler("fname", event.target.value)}
//               required
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               id="lname"
//               label="Last Name"
//               type="text"
//               variant="outlined"
//               margin="normal"
//               value={formState.inputs.lname.value}
//               onChange={(event) => inputHandler("lname", event.target.value)}
//               required
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               id="phone"
//               label="Whatsapp Number"
//               type="text"
//               variant="outlined"
//               margin="normal"
//               value={
//                 formState.inputs.phone
//                   ? formState.inputs.phone.value.substring(2)
//                   : ""
//               }
//               onChange={(event) => handlePhoneInputChange(event.target.value)}
//               required
//               error={phoneError.length > 0}
//               helperText={phoneError}
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">+91</InputAdornment>
//                 ),
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               id="email"
//               label="E-Mail"
//               type="email"
//               variant="outlined"
//               margin="normal"
//               value={formState.inputs.email.value}
//               onChange={handleEmailChange}
//               required
//               autoComplete="username"
//               error={emailError.length > 0}
//               helperText={emailError}
//               className="MuiInputBase-input MuiOutlinedInput-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input"
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} style={{ marginTop: 20 }}>
//         <Grid item xs={12} sm={otpSent ? 6 : 6}>
//     <TextField
//       fullWidth
//       id="password"
//       label="Create Passsword"
//       type={showPassword ? "text" : "password"}
//       variant="outlined"
//       value={formState.inputs.password.value}
//       onChange={handlePasswordChange}
//       required
//       autoComplete="current-password"
//       error={passwordError.length > 0}
//       helperText={passwordError}
//       InputProps={{
//         endAdornment: (
//           <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
//             {showPassword ? <VisibilityOff /> : <Visibility />}
//           </InputAdornment>
//         ),
//       }}
//     />
//   </Grid>
//   <Grid item xs={12} sm={6}>
//     <TextField
//       fullWidth
//       id="confirmPassword"
//       label="Confirm Password"
//       type={showPassword ? "text" : "password"}
//       variant="outlined"
//       value={confirmPassword}
//       onChange={handleConfirmPasswordChange}
//       required
//       autoComplete="new-password"
//       error={confirmPasswordError.length > 0}
//       helperText={confirmPasswordError}
//       InputProps={{
//         endAdornment: (
//           <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
//             {showPassword ? <VisibilityOff /> : <Visibility />}
//           </InputAdornment>
//         ),
//       }}
//     />
//   </Grid>

//           {otpSent && (
//             <>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   fullWidth
//                   id="otp"
//                   label="OTP"
//                   type="text"
//                   variant="outlined"
//                   value={formState.inputs.otp.value}
//                   onChange={handleOtpChange}
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography
//                   color="primary"
//                   style={{ cursor: "pointer", marginTop: 30 }}
//                   onClick={toggleReferralInput}
//                 >
//                   Have a referral code? Click here.
//                 </Typography>

//                 {showReferralInput && (
//                   <TextField
//                     fullWidth
//                     id="referralCode"
//                     label="Referral Code"
//                     type="text"
//                     margin="normal"
//                     value={
//                       formState.inputs.referralCode
//                         ? formState.inputs.referralCode.value
//                         : ""
//                     }
//                     onChange={(event) =>
//                       inputHandler("referralCode", event.target.value)
//                     }
//                     style={{ marginTop: 10 }}
//                   />
//                 )}
//               </Grid>
//             </>
//           )}
//         </Grid>

//         <Grid
//           container
//           spacing={2}
//           style={{ marginTop: 10, justifyContent: "center" }}
//         >
//           {otpSent ? (
//             <>
//               <Grid
//                 item
//                 xs={12}
//                 sm={5}
//                 style={{
//                   display: "flex",
//                   justifyContent: "flex-end",
//                   paddingRight: "10px",
//                 }}
//               >
//                 {initialResendDelayPassed && (
//                   <Grid item xs={12} sm={5}>
//                     <Button
//                       onClick={() =>
//                         resendOtpHandler(
//                           formState.inputs.email.value,
//                           formState.inputs.phone.value
//                         )
//                       }
//                       variant="contained"
//                       className="resend-otp-button"
//                       disabled={isResendDisabled}
//                     >
//                       {resendLoading ? (
//                         <CircularProgress size={24} color="inherit" />
//                       ) : isResendDisabled ? (
//                         `Resend OTP (${countdown}s)`
//                       ) : (
//                         "Resend OTP"
//                       )}
//                     </Button>
//                   </Grid>
//                 )}
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 sm={4}
//                 style={{
//                   display: "flex",
//                   justifyContent: "flex-start",
//                   paddingLeft: "10px",
//                 }}
//               >
//                 <Button
//                   type="submit"
//                   variant="contained"
//                   color="primary"
//                   className="submit-button"
//                 >
//                   Validate OTP
//                 </Button>
//               </Grid>
//             </>
//           ) : (
//             <Grid
//               item
//               xs={12}
//               style={{ display: "flex", justifyContent: "center" }}
//             >
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 className="submit-button"
//                 onClick={() => {
//                   setIsResendDisabled(true);
//                   setCountdown(otpDuration / 1000); // Reset countdown
//                   const interval = setInterval(() => {
//                     setCountdown((prevCountdown) => {
//                       if (prevCountdown <= 1) {
//                         clearInterval(interval);
//                         setIsResendDisabled(false);
//                         return 0;
//                       }
//                       return prevCountdown - 1;
//                     });
//                   }, 1000);
//                 }}
//               >
//                 Generate OTP
//               </Button>
//             </Grid>
//           )}
//         </Grid>
//       </form>
//     </div>
//   );
// };

// export default SignupForm;
