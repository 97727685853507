import React, { useState } from 'react';
import PostIpo from "../component/PostIpo";
import SearchAndUpdateIpo from '../component/SearchAndUpdateIpo';
import ClearIcon from '@mui/icons-material/Clear';

function MainAdminIpo() {
  const [activeForm, setActiveForm] = useState(null);

  const handleButtonClick = (formName) => {
    setActiveForm(formName);
  };

  const handleCloseClick = () => {
    setActiveForm(null);
  };

  const buttons = [
    {
      id: 'add',
      title: 'Add a new IPO',
      description: 'This button allows you to post a new IPO with all necessary details manually as well as by file.',
      gradient: 'bg-gradient-to-r from-orange-400 to-red-500 hover:from-orange-500 hover:to-red-600',
      buttonText: 'Post',
    },
    {
      id: 'update',
      title: 'Update an existing IPO',
      description: 'This button lets you update the details of an existing IPO.',
      gradient: 'bg-gradient-to-r from-green-400 to-teal-500 hover:from-green-500 hover:to-teal-600',
      buttonText: 'Update',
    },
  ];

  return (
    <div className='flex flex-col p-6 bg-gradient-to-b from-gray-900 via-gray-800 to-black min-h-screen'>
     <div>
    <h1 className='text-left mb-4 text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600 tracking-wider drop-shadow-lg'>IPO Dashboard</h1>
    </div>
      {!activeForm ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {buttons.map((button) => (
            <div 
              key={button.id} 
              className="bg-gray-800 text-white p-6 rounded-lg shadow-lg flex flex-col justify-between"
            >
              <div>
                <h2 className="text-lg font-semibold mb-2 text-center">{button.title}</h2>
                <p className="text-sm text-gray-400 mb-4 text-center">{button.description}</p>
              </div>
              <button
                onClick={() => handleButtonClick(button.id)}
                className={`${button.gradient} text-white font-medium py-2 px-4 rounded-full shadow-md transform hover:scale-105 transition-transform`}
                style={{
                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.15)',
                }}
              >
                {button.buttonText}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              onClick={handleCloseClick}
              className="mt-1 bg-gradient-to-r from-red-400 to-pink-500 hover:from-red-500 hover:to-pink-600 text-white font-bold px-3 py-1 rounded-full shadow-lg transform hover:scale-105 transition-transform"
              style={{
                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
              }}
            >
              <ClearIcon style={{ fontWeight: 800 }} />
            </button>
          </div>
          {activeForm === 'add' && <PostIpo />}
          {activeForm === 'update' && <SearchAndUpdateIpo />}
        </div>
      )}
    </div>
  );
}

export default MainAdminIpo;
