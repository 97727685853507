import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Shared/context/auth-context";
import "./ProtectedRoutes.css";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

const customEncode = (url) => url.replace(/\//g, '-');

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return (
      <div className="loading-container" role="status" aria-live="polite">
        <div className="loading-content">
          {['F', 'I', 'N', 'S', 'H', 'A'].map((letter, index) => (
            <span key={index} className="loading-text">{letter}</span>
          ))}
          <div className="icon-animation">
            <HourglassTopIcon />
            <HourglassFullIcon />
            <HourglassBottomIcon />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/auth?redirect=${customEncode(props.location.pathname)}`} />
        )
      }
    />
  );
};

export default ProtectedRoute;
