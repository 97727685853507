import React, { useState, useEffect, useRef } from "react";
import "./Shariah.css";
import { useLocation } from "react-router-dom";
import ShariaCompliantInvestmentChartComponent from "../components/Charts";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import compliantLogo from "../../images/Compliant.png";
import nonCompliantLogo from "../../images/Non-compliant.png";
import SearchIcon from "@material-ui/icons/Search";
import FloatingSearchModel from "../../Shared/SearchBar/FloatingSearchModel/FloatingSearchModel";
import SearchBar from "../../Shared/SearchBar/SearchBar";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import axios from "axios";
import { constaints } from "../../utils/constaints";

const getHyphenatedName = (name) => {
  return name ? name.replace(/ /g, "-") : "";
};

function Shariah() {
  const location = useLocation();
  const { title, company } = location.state;
  const companyName = title.split(" - ")[0];
  const id = company.id;
  const history = useHistory();
  const [, setRating] = useState(null);
  const [, setLoading] = useState(false);
  const [companies] = useState([]);
  const [setIsSearchOpen] = useState(false);
  const [isFloatingSearchModalOpen, setIsFloatingSearchModalOpen] =
    useState(false);
  const searchBarRef = useRef();
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  function handleClick() {
    history.goBack();
  }

  useEffect(() => {
    if (isFloatingSearchModalOpen && searchBarRef.current) {
      searchBarRef.current.focus();
    }
  }, [isFloatingSearchModalOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // replace the fetch with axios
  useEffect(() => {
    const fetchShariahRating = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/screener/company/shariah/${companyName}`,
          {
            withCredentials: true,
          }
        );
        const data = response.data;
        setRating(data);
      } catch (error) {
        console.error("Error fetching Shariah rating:", error);
      }
    };

    fetchShariahRating();
  }, [id, companyName]);

  if (!company) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="Shariah-page-container">
        <Helmet>
          <title>
            Shariah Compliant Investment Services - {companyName} | Finsha.in
          </title>
          <meta
            name="description"
            content={`Explore Shariah Compliant investment options and services provided by ${companyName} on Finsha.in. We provide detailed analysis and compliance status for your secure investment.`}
          />
          <script type="application/ld+json">
            {`
  {
    "@context": "https://schema.org",
    "@type": "Corporation",
    "name": "${companyName}",
    "description": "Specific unique description about ${companyName}",
    "url": "https://finsha.in/shariah/20-Microns-Ltd.",
    "logo": "../../images/only logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-111-111-1111",
      "contactType": "customer service"
    },
    "sameAs": [
      "URL of the company's Facebook page",
      "URL of the company's Twitter profile",
      "URL of the company's LinkedIn profile"
    ]
  }
`}
          </script>
        </Helmet>
        <div className="shariah__page__secondcontainer">
          <div className="fab-container">
            <button
              className="fab-button"
              onClick={() => setIsFloatingSearchModalOpen(true)}
            >
              <SearchIcon className="searchIcon_companydetails_flatingsearch" />
            </button>

            <FloatingSearchModel
              isOpen={isFloatingSearchModalOpen}
              onClose={() => setIsFloatingSearchModalOpen(false)}
            >
              <SearchBar
                handleSelectResult={(company) => {
                  const companyNameWithHyphens = getHyphenatedName(
                    company["Company Name"]
                  );
                  history.push(
                    `/shariah/${encodeURIComponent(companyNameWithHyphens)}`,
                    {
                      title: `${company["Company Name"]} - Shariah Page`,
                      company: company,
                    }
                  );
                  setIsFloatingSearchModalOpen(false);
                }}
                autoFocus={isFloatingSearchModalOpen} // Pass autoFocus prop
                isOpen={isFloatingSearchModalOpen} // Pass isOpen prop
              />
            </FloatingSearchModel>
          </div>
          <h1 className="shiarah_company__name" key={id}>
            {company["Company Name"]}
          </h1>
          {/*------------------------------------------------------------------------- IF PERMISSIBILITY IS NO THEN BELOW DATA SHOULD EB SHOWN */}
          {company["Business Permissiblity Status"] === "No" && (
            <>
              <div className="permissibility__conatiner">
                <div className="buinessmodel__conatiner">
                  <div className="buinessmodel__title__div">
                    <h2 className="business__model__text">
                      Company Business Model
                    </h2>
                  </div>
                  {company["Business 1"] && (
                    <div className="businessmodel_point_one">
                      <h3 className="point__one__businessmodel">
                        1. {company["Business 1"]}
                      </h3>
                    </div>
                  )}
                  {company["Business 2"] && (
                    <div className="businessmodel_point_two">
                      <h3 className="point__two__businessmodel">
                        2. {company["Business 2"]}
                      </h3>
                    </div>
                  )}
                  {company["Business 3"] && (
                    <div className="businessmodel_point_three">
                      <h3 className="point__three__businessmodel">
                        3. {company["Business 3"]}
                      </h3>
                    </div>
                  )}
                </div>
              </div>

              <div className="compliance-container">
                <div className="compliance-card">
                  <h1 className="compliance-title">Compliance Status</h1>
                  <div className="compliance-content">
                    <div className="compliance-status">
                      {company["Business Permissiblity Status"] === "Yes" &&
                      company["Interest to Revenue"] < 0.05 &&
                      company["Loan to Market Cap"] < 0.33 &&
                      company["Investment to Market Cap"] < 0.33 ? (
                        <div className="compliant-container">
                          <img
                            src={compliantLogo}
                            alt="Compliant"
                            className="compliant-logo"
                          />
                        </div>
                      ) : (
                        <div className="non-compliant-container">
                          <img
                            src={nonCompliantLogo}
                            alt="Non-Compliant"
                            className="non-compliant-logo"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="financial-screening-container">
                  <div className="business-permissibility-container_no_business-permissibility">
                    <h2 className="business-permissibility-title__no">
                      Business Permissibility (as per Islamic guidelines)
                    </h2>
                    {company["Business Permissibility Status"] === "Yes" ? (
                      <h3 className="business-permissibility-status_no yes">
                        Yes
                      </h3>
                    ) : (
                      <h3 className="business-permissibility-status_no no">
                        No
                      </h3>
                    )}
                  </div>

                  <div className="reson__conatiner">
                    <h2 className="title__resaon">Reason</h2>
                    <p className="reseaonparah">{company["Reason (If No)"]}</p>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* -------------------------------------------IF PERMISSIBILITY YES THEN IT WILL SHOW THE BELOW DATA --------------------------------------------------------- */}
          {company["Business Permissiblity Status"] === "Yes" && (
            <>
              <div className="buinessmodel__conatiner">
                <div className="buinessmodel__title__div">
                  <h2 className="business__model__text">
                    Company Business Model
                  </h2>
                </div>
                {company["Business 1"] && (
                  <div className="businessmodel_point_one">
                    <h3 className="point__one__businessmodel">
                      1. {company["Business 1"]}
                    </h3>
                  </div>
                )}
                {company["Business 2"] && (
                  <div className="businessmodel_point_two">
                    <h3 className="point__two__businessmodel">
                      2. {company["Business 2"]}
                    </h3>
                  </div>
                )}
                {company["Business 3"] && (
                  <div className="businessmodel_point_three">
                    <h3 className="point__three__businessmodel">
                      3. {company["Business 3"]}
                    </h3>
                  </div>
                )}
              </div>
              <div className="business-permissibility-container">
                <h2 className="business-permissibility-title">
                  Business Permissiblity (as per Islamic guidelines) ::
                </h2>
                {company["Business Permissiblity Status"] === "Yes" ? (
                  <h3 className="business-permissibility-status yes">Yes</h3>
                ) : (
                  <h3 className="business-permissibility-status no">No</h3>
                )}
              </div>
              <div className="compliance-container">
                <div className="compliance-card">
                  <h1 className="compliance-title">Compliance Status</h1>
                  <div className="compliance-content">
                    <div className="compliance-status">
                      {company["Business Permissiblity Status"] === "Yes" &&
                      company["Interest to Revenue"] < 0.05 &&
                      company["Loan to Market Cap"] < 0.33 &&
                      company["Investment to Market Cap"] < 0.33 ? (
                        <div className="compliant-container">
                          <img
                            src={compliantLogo}
                            alt="Compliant"
                            className="compliant-logo"
                          />
                        </div>
                      ) : (
                        <div className="non-compliant-container">
                          <img
                            src={nonCompliantLogo}
                            alt="Non-Compliant"
                            className="non-compliant-logo"
                          />
                        </div>
                      )}
                    </div>
                    <div className="compliance-details">
                      <div className="compliance-check">
                        <p className="text-medium">
                          Non-Compliant to Total Income
                        </p>
                        {company["Interest to Revenue"] < 0.05 ? (
                          <VerifiedOutlinedIcon style={{ color: "#06D001" }} />
                        ) : (
                          <ReportOutlinedIcon style={{ color: "#EB5B00" }} />
                        )}
                      </div>
                      <div className="compliance-check">
                        <p className="text-medium">Loan to Market Cap</p>
                        {company["Loan to Market Cap"] < 0.33 ? (
                          <VerifiedOutlinedIcon style={{ color: "#06D001" }} />
                        ) : (
                          <ReportOutlinedIcon style={{ color: "#EB5B00" }} />
                        )}
                      </div>
                      <div className="compliance-check">
                        <p className="text-medium">Investment to Market Cap</p>
                        {company["Investment to Market Cap"] < 0.33 ? (
                          <VerifiedOutlinedIcon style={{ color: "#06D001" }} />
                        ) : (
                          <ReportOutlinedIcon style={{ color: "#EB5B00" }} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="financial-screening-container">
                  {company["Business Permissiblity Status"] === "Yes" &&
                    ((company["Interest to Revenue"] !== 0 &&
                      company["Loan to Market Cap"] !== 0 &&
                      company["Investment to Market Cap"] !== 0 &&
                      company["Interest to Revenue"] < 0.05 &&
                      company["Loan to Market Cap"] < 0.33 &&
                      company["Investment to Market Cap"] < 0.33) ||
                      (company["Interest to Revenue"] !== 0 &&
                        company["Loan to Market Cap"] !== 0) ||
                      (company["Loan to Market Cap"] !== 0 &&
                        company["Investment to Market Cap"] !== 0) ||
                      (company["Investment to Market Cap"] !== 0 &&
                        company["Interest to Revenue"] !== 0)) && (
                      <div className="financial-info-card">
                        <h2 className="financial-info-title">
                          Financial Condition Screening
                        </h2>
                        <div className="financial-chart-container">
                          <ShariaCompliantInvestmentChartComponent
                            companyName={companyName}
                            key={companyName}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex items-center justify-end mt-4">
          <button
            onClick={handleClick}
            className="flex items-center justify-center w-[100px] h-10 bg-[var(--primary)] text-[var(--on-primary)] rounded-full shadow-md transition-all duration-300 hover:bg-[var(--primary-fixed)] hover:text-[var(--on-primary-fixed)] hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[var(--primary-fixed)]"
          >
            <KeyboardBackspaceSharpIcon className="text-lg" />
          </button>
        </div>
      </div>
    </>
  );
}

export default Shariah;
