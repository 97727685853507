import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./FullArticle.css";
import axios from "axios";
import MobileToc from "./MobileToc";
import { Snackbar, Alert } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArticleRatings from "./ArticleRatings";
import { Article } from "@mui/icons-material";
import ReadOnlyStarRating from "./ReadOnlyStarRating";
import { constaints } from "../../../utils/constaints";

function FullArticle() {
  const { slug } = useParams(); // Get the slug from the route parameters
  const [article, setArticle] = useState(null);
  const [isCopied, setIsCopied] = useState(false); // State to show "Copied"
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const [activeTocId, setActiveTocId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [comments, setComments] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    rating: 0,
    comment: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Fetch article and comments
  useEffect(() => {
    axios
      .get(`${SERVER_URL}/finview/articles/slug/${slug}`, {
        withCredentials: true,
      })
      .then((response) => {
        setArticle(response.data); // Set the article data
        const articleId = response.data._id; // Extract the article ID

        // Fetch comments using the article ID
        axios
          .get(`${SERVER_URL}/finview/articles/${articleId}/ratings`, {
            withCredentials: true,
          })
          .then((res) => {
            setComments(res.data.ratings || []);
          })
          .catch((error) => {
            console.error("Error fetching comments:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching article:", error);
      });
  }, [slug]);

  const handleTocClick = (id) => {
    setActiveTocId(id);

    // Add "heading-" prefix to match the rendered content IDs
    const element = document.getElementById(`heading-${id}`);
    if (element) {
      const offset = 90; // Adjust based on your layout
      const elementPosition = element.offsetTop - offset;

      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    } else {
      console.error(`Element with ID heading-${id} not found.`);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    const contentElements = document.querySelectorAll("[id]");
  }, [article]);

  const handleCopyLink = () => {
    const articleUrl = window.location.href;
    navigator.clipboard
      .writeText(articleUrl)
      .then(() => {
        setIsCopied(true); // Set "Copied" state
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy link:", err);
      });
  };

  const handleShare = () => {
    const articleUrl = window.location.href;
    const shareData = {
      title: article?.title || "Check out this article",
      text: article?.metaDescription || "Amazing article content here!",
      url: articleUrl,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => {
          setSnackbar({
            open: true,
            message: "Article shared successfully!",
            type: "success",
          });
        })
        .catch((err) => {
          console.error("Failed to share:", err);
          setSnackbar({
            open: true,
            message: "Sharing failed.",
            type: "error",
          });
        });
    } else {
      setSnackbar({
        open: true,
        message: "Sharing is not supported on this browser.",
        type: "warning",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.rating || !formData.comment) {
      setSnackbar({
        open: true,
        message: "All fields are required.",
        type: "error",
      });
      return;
    }

    const articleId = article?._id;
    if (!articleId) {
      setSnackbar({
        open: true,
        message: "Unable to submit your comment.",
        type: "error",
      });
      return;
    }

    axios
      .post(`${SERVER_URL}/finview/articles/${articleId}/ratings`, formData, {
        withCredentials: true,
      })
      .then((response) => {
        setComments((prev) => [...prev, response.data.rating]);
        setFormData({ name: "", email: "", rating: 0, comment: "" });
        setSnackbar({
          open: true,
          message: "Thank you for your feedback!",
          type: "success",
        });
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: "Failed to submit feedback.",
          type: "error",
        });
      });
  };

  const averageRating = comments.length
    ? comments.reduce((acc, curr) => acc + curr.rating, 0) / comments.length
    : 0;

  if (!article) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          fontSize: "1.25rem",
          color: "var(--on-surface)",
          backgroundColor: "var(--surface)",
        }}
      >
        Article not found
      </div>
    );
  }

  return (
    <div className="flex w-full justify-center bg-[var(--surface)] text-[var(--on-surface)]">
      <div className="flex w-full max-w-6xl lg:gap-8 gap-4 lg:pt-24 pt-16 lg:pb-24 pb-16 px-4 ">
        {article?.toc && article?.toc.length > 0 && (
          <div className="lg:hidden">
            {" "}
            {/* Ensure it is hidden on larger screens */}
            <MobileToc toc={article?.toc} onTocClick={handleTocClick} />
          </div>
        )}
        {/* Table of Contents */}

        {article?.toc && article?.toc.length > 0 && (
          <aside
            className="hidden lg:block w-1/5 mt-20 p-2"
            style={{
              position: "sticky",
              top: "96px",
              height: "calc(100vh - 96px)",
              overflowY: "auto",
            }}
          >
            <h2 className="text-lg font-bold mb-4 text-[var(--on-surface)] text-center">
              Content to Visit
            </h2>
            <ul className="space-y-2">
              {article?.toc.map((item, index) => {
                // Check if the current item is numbered
                const hasNumbering = /^[\d]+\./.test(item.text); // Matches "1.", "1.1", etc.
                const isSubItem = /^[\d]+\.[\d]/.test(item.text); // Matches "1.1", "2.1", etc.

                // Determine if the current item is the first title before any numbered list
                const isTitleBeforeNumbering =
                  index === 0 &&
                  article?.toc[1] &&
                  /^[\d]+\./.test(article?.toc[1].text);

                // Alignment logic
                const alignmentClass = isSubItem
                  ? "ml-4" // Sub-items like "1.1"
                  : hasNumbering || isTitleBeforeNumbering
                  ? "ml-0" // Titles before numbering or numbered items
                  : "ml-8"; // Default for other titles or non-numbered items

                // Check if the item is active
                const isActive = activeTocId === item.id;

                return (
                  <li key={item._id} className={`text-sm ${alignmentClass}`}>
                    <a
                      href={`#${item.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleTocClick(item.id);
                      }}
                      className={`${
                        isActive
                          ? "text-green-500 font-semibold" // Active item styles
                          : "text-[var(--on-surface)]" // Default inactive styles
                      } hover:text-green-500`}
                    >
                      {item.text}
                    </a>
                  </li>
                );
              })}
            </ul>
          </aside>
        )}

        {/* article Content */}
        <div
          className={`${
            article?.toc && article?.toc.length > 0 ? "lg:w-4/5" : "w-full"
          } ml-auto text-[var(--on-surface)]`}
        >
          {/* article Title */}
          <div className="mt-20">
            <h1
              className={`text-large font-display text-[var(--on-surface)] font-bold`}
              style={{
                fontSize: "30px",
              }}
              dangerouslySetInnerHTML={{
                __html: article?.title.replace(
                  /<a\b([^>]*)>/g,
                  `<a $1 class="text-[var(--on-surface)]  transition-colors duration-300 ">`
                ),
              }}
            />

            {/* Author Information */}
            <div className=" p-2 rounded-md shadow-sm flex flex-col gap-2">
              {/* Authors Section */}
              <div className="flex flex-wrap items-center mt-0">
                {/* "Written by" Label */}
                <span className="text-[var(--on-surface)] text-sm font-light">
                  Written by:
                </span>

                {/* Authors List */}
                {article?.authors.length > 0 && (
                  <span className="ml-2 text-[var(--on-surface)] font-medium text-sm">
                    {article?.authors.map((author, index) => (
                      <React.Fragment key={author._id}>
                        <a
                          href={author.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[var(--on-surface)] hover:text-[var(--primary-fixed)] transition duration-150"
                        >
                          {author.name}
                        </a>
                        {index < article?.authors.length - 2
                          ? ", " // Add a comma for all but the last two authors
                          : index === article?.authors.length - 2
                          ? " and " // Add "and" before the last author
                          : ""}
                      </React.Fragment>
                    ))}
                  </span>
                )}
              </div>

              {/* Creation Date */}
              <div className="mt-0">
                <p className="text-[var(--on-surface-variant)] text-sm font-light">
                  Posted on:{" "}
                  <span className="text-[var(--on-surface-low)] font-medium">
                    {new Date(article?.createdAt).toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </p>
              </div>

              <div className="mt-0 flex items-center justify-between flex-wrap gap-4">
                {/* Left: Average Rating */}
                <div className="flex items-center gap-2">
                  <p className="text-sm text-[var(--on-surface-variant)]">
                    Average Rating:
                  </p>
                  <ReadOnlyStarRating rating={averageRating} />
                  <span className="text-sm text-[var(--on-surface-low)]">
                    ({averageRating.toFixed(1)} out of 5)
                  </span>
                </div>

                {/* Center: Views */}
                <div className="flex items-center justify-center">
                  <p className="text-sm text-[var(--on-surface-low)]">
                    Views:{" "}
                    <span className="font-semibold text-[var(--primary-variant)]">
                      {article.views}
                    </span>
                  </p>
                </div>

                {/* Right: Copy and Share Buttons */}
                <div className="flex gap-4">
                  {/* Copy Link Button */}
                  <button
                    className="flex items-center gap-2 text-[var(--on-surface-variant)] hover:text-[var(--on-surface-low)] transition text-sm"
                    onClick={handleCopyLink}
                  >
                    <ContentCopyIcon /> Copy Link
                    {isCopied && (
                      <span className="text-green-500 font-semibold">
                        Copied
                      </span>
                    )}
                  </button>

                  {/* Share Button */}
                  <button
                    className="flex items-center gap-2 text-[var(--on-surface-variant)] hover:text-[var(--on-surface-low)] transition text-sm"
                    onClick={handleShare}
                  >
                    <ShareIcon /> Share Link
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="mt-2" />

          <div className="mt-2">
            <div
              className={`font-medium article-content text-justify text-on-surface-low`}
              dangerouslySetInnerHTML={{
                __html: `
        <style>
               .article-content h2{ font-size: 24px; color: var(--on-surface); }
              .article-content  h3{ font-size: 22px; color: var(--on-surface); }
              .article-content  h4{ font-size: 20px; color: var(--on-surface); }
              .article-content  h5{ font-size: 18px; color: var(--on-surface); }
              .article-content  h6{ font-size: 16px; color: var(--on-surface); }
              .article-content   p{ font-size: 16px; line-height: 1.7; color: var(--on-surface-variant); padding:5px; margin-left:10px }
              .article-content  a{ color: var(--primary-fixed); text-decoration: none; }
              .article-content  img{   max-width: 100%;
          border-radius: 8px;
          margin-top: 10px;
          display: block;
          margin-left: auto;
          margin-right: auto; }
              .article-content video{    max-width: 100%;
          border-radius: 8px;
          margin-top: 10px;
          display: block;
          margin-left: auto;
          margin-right: auto;}

.article-content iframe {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 0;
  margin-right: 0;
  display: block;
  aspect-ratio: 16 / 9;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

              .article-content  blockquote {
                  border-left: 5px solid #9EC8B9;
                  padding:10px;
                  font-style: italic;
                  color: var(--on-surface);
                  background-color: #092635;
                  border-radius: 4px;
                  text-align:left;
                }

                 .article-content ol {
                       padding:5px;
                     }
              .article-content ol li {margin-bottom: 10px; margin-left:40px; margin-top:10px; color:var(--on-surface)  }
               .article-content ul {
                  padding-left: 5px;
                   }
               .article-content ul li {margin-bottom: 10px; margin-left:40px; margin-top:10px; color:var(--on-surface)  }
.article-content table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 10px;
            margin-bottom: 10px;
           
          }
         .article-content table, th, td {
            border: 1px solid #6EACDA;
            text-align: center;
          }
        .article-content th, td {
            padding: 10px;
            color: var(--on-surface);
          }
        .article-content th {
            background-color: #0B192C;
            font-weight: bold;
          }
            @media (max-width: 768px) {
          .article-content table {
            display: block;
            overflow-x: auto; 
          }
          .article-content table::-webkit-scrollbar {
            height: 6px;
          }
          .article-content table::-webkit-scrollbar-thumb {
            background: #6EACDA; 
            border-radius: 3px;
          }
        }
              </style>
      ${article?.content}
    `,
              }}
            />

            <hr className="mt-20" />

            <div className="mt-8 bg-[--surface] shadow-lg rounded-lg p-6">
              <h2 className="text-xl font-semibold mb-6 text-[var(--on-surface)]">
                Leave a Comment
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                {/* Name Input */}
                <div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-[var(--on-surface)] bg-[var(--surface)] rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
                  />
                </div>

                {/* Email Input */}
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-[var(--on-surface)] bg-[var(--surface)] rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
                  />
                </div>

                {/* Rating Component */}
                <div>
                  <label className="block text-sm font-medium text-[var(--on-surface)] mb-2">
                    Rate this article:
                  </label>
                  <ArticleRatings
                    rating={formData.rating}
                    onChange={(newRating) =>
                      setFormData((prev) => ({ ...prev, rating: newRating }))
                    }
                  />
                </div>

                {/* Comment Input */}
                <div>
                  <textarea
                    name="comment"
                    placeholder="Your Comment"
                    value={formData.comment}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-[var(--on-surface)] bg-[var(--surface)] rounded focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 transition"
                    rows="4"
                    required
                  ></textarea>
                </div>

                {/* Submit Button */}
                <div>
                  <button
                    type="submit"
                    className="w-full bg-[var(--primary-fixed)] text-[var(--on-surface)] py-3 rounded-md font-medium hover:bg-[var(--primary)] transition"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

            <Snackbar
              open={snackbar.open}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }} // Centered position
            >
              <div
                className={`flex items-center justify-between gap-4 p-4 rounded shadow-md ${
                  snackbar.type === "success"
                    ? "bg-green-500 text-white"
                    : "bg-white text-red-500 border border-red-500"
                }`}
                style={{ width: "300px" }}
              >
                <p className="flex-1 text-sm">{snackbar.message}</p>
              </div>
            </Snackbar>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FullArticle;
