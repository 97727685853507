import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./about.css";
import { constaints } from "../../utils/constaints";

function About() {
  const [aboutUs, setAboutUs] = useState({ title: "", content: [] });
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //remove the fetch and update the axios
  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/finsha/aboutus`);
        const jsonResponse = response.data;

        setAboutUs(jsonResponse);
      } catch (error) {
        console.error("Failed to fetch About Us data:", error);
        // Optionally, handle the error by showing a message or using fallback data
      }
    };

    fetchAboutUs();
  }, [SERVER_URL]);

  return (
    <div className="about-page-container">
      <Helmet>
        <title>Finsha – Your Trusted Halal Investing Guide</title>
        <meta
          name="description"
          content="Learn more about Finsha, your ultimate platform for successful Halal and Shariah Compliant investing. Discover our journey and values."
        />
        <meta
          name="keywords"
          content="Finsha, Halal Investing, Stock Market Guide"
        />
        <meta name="tags" content="About Us, Halal Investing, Finsha" />
      </Helmet>
      <div className="about-content-container">
        <h1 className="about-page-title">{aboutUs.title}</h1>
        {aboutUs.content.map((section, index) => (
          <div
            key={index}
            className={`about-section-container ${
              index % 2 === 0 ? "fade-in-left" : "fade-in-right"
            }`}
          >
            <h2 className="about-section-heading">{section.heading}</h2>
            {section.paragraphs.map((paragraph, pIndex) => (
              <p key={pIndex} className="about-section-paragraph">
                {paragraph}
              </p>
            ))}
          </div>
        ))}
        <div className="about-footer-text">
          <p>
            Finsha has partnered with TradingView for its charts. A
            comprehensive trading and investment platform, offering an
            exceptional charting experience. It empowers traders and investors
            with cutting-edge analytical tools including{" "}
            <a href="https://www.tradingview.com/screener/">Screener</a> and{" "}
            <a href="https://www.tradingview.com/economic-calendar/">
              Economic Calendar
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
