import React, { useState, useEffect } from "react";
import axios from "axios";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { constaints } from "../../utils/constaints";

function PricePercentage({ symbol, className }) {
  const [price, setPrice] = useState(null);
  const [prevClose, setPrevClose] = useState(null);
  const [error, setError] = useState(null);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const [priceRes, prevCloseRes] = await Promise.all([
          axios.get(`${SERVER_URL}/screener/current-price/${symbol}`, {
            withCredentials: true,
          }),
          axios.get(`${SERVER_URL}/screener/previous-close/${symbol}`, {
            withCredentials: true,
          }),
        ]);
        setPrice(priceRes.data.price);
        setPrevClose(prevCloseRes.data.prevClosePrice);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchPrice();
  }, [symbol]);

  const priceChange = price - prevClose;
  const priceChangePercent = ((price - prevClose) / prevClose) * 100;

  const changeColor = priceChange >= 0 ? "#06D001" : "red";
  const changeSymbol =
    priceChange >= 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;

  return (
    <div className={className}>
      {price && prevClose && (
        <p>
          <span style={{ color: changeColor }}>
            {changeSymbol}₹{priceChange.toFixed(2)} ({changeSymbol}
            {priceChangePercent.toFixed(2)}%)
          </span>
        </p>
      )}
      {error && <p>---</p>}
    </div>
  );
}

export default PricePercentage;
