
import React, { useState } from 'react';
import { Modal, Box} from '@mui/material';
import GiftIcon from '@mui/icons-material/CardGiftcard';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";

function FreeSubscribeMessage() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
         <div 
  className="relative w-full inline-block bg-gradient-to-r from-green-500 to-green-300 py-2 px-4 text-center text-black font-bold cursor-pointer hover:shadow-lg before:content-[''] before:absolute before:top-0 before:left-0 before:h-full before:w-4 before:bg-green-700 before:skew-y-6 after:content-[''] after:absolute after:top-0 after:right-0 after:h-full after:w-4 after:bg-green-700 after:-skew-y-6"
  onClick={handleOpen}
>
  <div className="relative flex items-center justify-center space-x-2">
    <p className="text-black text-base font-bold hover:text-white transition-colors duration-300 ">Click to Unlock Your</p>  
    <span className="text-black text-base font-bold transition-colors duration-300 hover:text-white hover:underline">Surprise</span> 
    <GiftIcon className="ml-1 text-red-500" />
  </div>
</div>

            <Modal
                open={open}
                onClose={handleClose}
                className="flex items-center justify-center p-4"
            >
                <Box 
                    className="bg-gradient-to-br from-white via-gray-50 to-gray-100 rounded-xl shadow-2xl p-8 max-w-lg w-full text-center relative"
                    style={{ outline: 'none' }}
                >
                    <div onClick={handleClose} className="absolute top-3 right-3 text-gray-500 hover:text-red-500 transition-all">
                        <CloseIcon />
                    </div>
                    <div className="mb-2 ">
                        <h2 className="text-xl font-extrabold text-gray-800 mb-2">📢 Subscribe to access Stock-Idea!</h2>
                        <p className="text-gray-700 text-sm font-medium mt-4">📈 Subscribe to get professional stock picks and stay ahead in the market!</p>
                    </div>
                    <div className="mb-4 p-2 bg-gradient-to-r from-green-100 to-green-200 rounded-lg shadow-md">
    <p className="text-green-800 text-base font-semibold leading-relaxed">
        💸 Special Price Just for You! Save up to 41%!
    </p>
</div>

                    <Link to="/landing-page">
                        <button 
                            className="px-4 py-2 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-600 hover:to-blue-600 text-white font-semibold shadow-lg transform hover:scale-105 transition-transform"
                        >
                            Subscribe Now
                        </button>
                    </Link>
                </Box>
            </Modal>
        </>
    );
}

export default FreeSubscribeMessage;
