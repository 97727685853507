// eslint-disable-next-line
import "./footer.css";

import { Box } from "../components/functional/box/Box";
import { Text } from "../components/ui/text/Text";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import name from "../images/name.png";
import { Icon } from "../components/ui/icon/Icon";
import { Pill } from "../components/ui/pill/Pill";

import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import LogoDark from "../icons/finsha_icon_dark.svg";
import { AuthContext } from "../Shared/context/auth-context";

function Footer() {
  const { isLoggedIn, user, logout } = useContext(AuthContext);

  return (
    <footer itemScope itemType="https://schema.org/WPFooter">
      <Box
        gap="xl"
        padding="2xl"
        paddingX="2xl"
        style={{ backgroundColor: "var(--surface-inverse)" }}
      >
        <Box variant="row">
          <Box gap="lg">
            <div className="flex flex-row items-center gap-2 sm:gap-4">
              <img
                className="w-8 h-8 sm:w-10 sm:h-10"
                src={LogoDark}
                alt="Finsha Logo"
              />
              <span className="text-base sm:text-lg font-semibold footer__logo-text">
                Finsha
              </span>
            </div>

            <ul className="flex flex-col gap-4 w-[90%]">
              <Text as="li" variant="label" color="inverse">
                A platform delivering stock market education and insights,
                fostering financial literacy and informed decision-making.
              </Text>
            </ul>
            <ul
              className="flex gap-8"
              style={{ color: "var(--on-surface-inverse)" }}
            >
              <li>
                <a
                  href="https://www.facebook.com/help.finsha"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF size={20} />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/finsha_rs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter size={20} />
                </a>
              </li>
              <li>
                <a
                  href="https://in.linkedin.com/in/finsha"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn size={20} />
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/+a0WustyIyls4OTE1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegramPlane size={20} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@finsha_r"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube size={20} />
                </a>
              </li>
            </ul>
          </Box>

          {/* <Box gap="lg">
            <Text as="h3" variant="body" color="inverse">
              Contact
            </Text>
            <ul className="flex flex-col gap-4">
              <li>
                <Box variant="row" gap="xs" alignItems="center">
                  <Icon size="small" color="inverse">
                    phone
                  </Icon>
                  <Text
                    as="a"
                    variant="label"
                    href="tel: +91 60025 11778"
                    color="inverse"
                  >
                    +91 60025 11778
                  </Text>
                </Box>
              </li>
              <li>
                <Box variant="row" gap="xs" alignItems="center">
                  <Icon size="small" color="inverse">
                    alternate_email
                  </Icon>
                  <Text
                    as="a"
                    variant="label"
                    href="mailto:support@finsha.in"
                    color="inverse"
                  >
                    support@finsha.in
                  </Text>
                </Box>
              </li>
              <li>
                <Box variant="row" gap="xs" alignItems="center">
                  <Icon size="small" color="inverse">
                    location_on
                  </Icon>
                  <Text as="p" variant="label" color="inverse">
                    Sarbanandapur, Assam 788163
                  </Text>
                </Box>
              </li>
            </ul>
          </Box> */}
          <Box gap="lg">
            <div className="flex flex-col gap-6 ">
              {/* Heading */}
              <h3 className="text-lg font-medium text-white mt-4">Contact</h3>

              {/* Contact List */}
              <ul className="flex flex-col gap-4">
                {/* Phone */}
                <li>
                  <div className="flex flex-row items-center gap-2">
                    <span className="material-icons text-white text-lg">
                      phone
                    </span>
                    <a
                      href="tel:+916002511778"
                      className="text-sm font-medium text-white hover:underline"
                    >
                      +91 60025 11778
                    </a>
                  </div>
                </li>

                {/* Email */}
                <li>
                  <div className="flex flex-row items-center gap-2">
                    <span className="material-icons text-white text-lg">
                      alternate_email
                    </span>
                    <a
                      href="mailto:support@finsha.in"
                      className="text-sm font-medium text-white hover:underline"
                    >
                      support@finsha.in
                    </a>
                  </div>
                </li>

                {/* Address */}
                <li>
                  <div className="flex flex-row items-center gap-2">
                    <span className="material-icons text-white text-lg">
                      location_on
                    </span>
                    <p className="text-sm font-medium text-white">
                      Fakirtila, Silchar, Assam 788010
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </Box>

          <Box gap="lg">
            <Text as="h3" variant="body" color="inverse">
              Quick Links
            </Text>
            <ul className="flex flex-col gap-4">
              {quickLinksdata.map((link, index) => (
                <FooterLink label={link.label} href={link.href} />
              ))}
            </ul>
          </Box>

          <Box gap="lg">
            <Text as="h3" variant="body" color="inverse">
              Legal Info
            </Text>
            <ul className="flex flex-col gap-4">
              {legalInfoData.map((link, index) => (
                <FooterLink label={link.label} href={link.href} />
              ))}
            </ul>
          </Box>
        </Box>
        <Box gap="md">
          <Text as="h3" variant="body" color="inverse">
            Ready to Start?{" "}
            <Text
              as="a"
              variant="body"
              style={{ color: "var(--primary-70)" }}
              href={isLoggedIn ? "/stock-idea" : "/auth"}
            >
              Sign Up
            </Text>{" "}
            for Free and Explore Ethical Stock Investing with Finsha!
          </Text>
          <a href="https://dectox.com/">
            <Pill
              icon="copyright"
              label="Finsha 2024 - Developed by Dectox IT Solutions"
            />
          </a>
        </Box>
      </Box>
    </footer>
  );
}

export default Footer;

const FooterLink = ({ label, icon, href }) => {
  return (
    <Box variant="row" gap="xs" alignItems="center">
      {icon && (
        <Icon color="inverse" size="small">
          {icon}
        </Icon>
      )}
      <Text as="li" variant="label" color="inverse">
        <Link to={href}>{label}</Link>
      </Text>
    </Box>
  );
};

const contactData = [
  {
    label: "support@finsha.in",
    icon: "alternate_email",
    href: "mailto:support@finsha.in",
  },
  {
    label: "Sarbanandapur, Assam 788163",
    icon: "location_on",
    href: "",
  },
];

const quickLinksdata = [
  {
    label: "About Us",
    href: "/about",
  },
  // {
  //   label: "Disclaimer",
  //   href: "/disclaimer",
  // },
  {
    label: "Our Methodology",
    href: "/methodology",
  },
  {
    label: "Q&A",
    href: "/QandA",
  },
];

const legalInfoData = [
  // {
  //   label: "Investors grievances portal",
  //   href: "investorsportal",
  // },
  // {
  //   label: "Policies to address conflicts",
  //   href: "policy-to-address-conflict",
  // },
  {
    label: "Terms & conditions",
    href: "terms&condition",
  },
  {
    label: "Privacy Policy",
    href: "privacy-policy",
  },
];
