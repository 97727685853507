import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FaTrash } from 'react-icons/fa';
import MUIDataTable from 'mui-datatables';
import './SoldPortfolio.css';

// replace the % with "-" , so that when user click on the compny name inplace of the space it shows the % so from now it will shwo "_"
const getHyphenatedName = (name) => {
  return name ? name.replace(/ /g, '-') : '';
};

function SoldPortfolioTable({ sold, deleteSoldEntry }) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [loadingIndex, setLoadingIndex] = useState(null);

  const handleOpenDeleteDialog = (index) => {
    setDeleteIndex(index);
    setShowDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
    setDeleteIndex(null);
  };

  const confirmDelete = () => {
    if (deleteIndex !== null) {
      setLoadingIndex(deleteIndex);
      deleteSoldEntry(deleteIndex).finally(() => {
        setLoadingIndex(null);
        handleCloseDeleteDialog();
      });
    }
  };

  const columns = [
    'Sl No.',
    {
      name: 'Stock Name',
      options: {
        customBodyRender: (value) => {
          const companyNameWithHyphens = getHyphenatedName(value);
          return (
            <Link className="portfolioNamecom-link" to={`/company-name/${encodeURIComponent(companyNameWithHyphens)}`}>
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: 'Buy Price',
      options: {
        customBodyRender: (value) => {
          return <div className="buy-price-value">{value}</div>;
        },
      },
    },
    {
      name: 'Quantity',
      options: {
        customBodyRender: (value) => {
          return <div className="quantity-value">{value}</div>;
        },
      },
    },
    {
      name: 'Sold Price',
      options: {
        customBodyRender: (value) => {
          return <div className="sold-price-value">{value}</div>;
        },
      },
    },
    {
      name: 'Profit/Loss',
      options: {
        customBodyRender: (value) => {
          return (
            <div className={`profit-loss ${value >= 0 ? 'green' : 'red'}`}>
              {value >= 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              {Math.abs(value).toFixed(2)}
            </div>
          );
        },
      },
    },
    {
      name: 'Purgable Income',
      options: {
        customBodyRender: (value) => {
          return <div className="purgable-income-value">{parseFloat(value).toFixed(2)}</div>;
        },
      },
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          return (
            <button
              onClick={() => handleOpenDeleteDialog(rowIndex)}
              className={`flex items-center space-x-1 px-3 py-1 bg-red-500 text-white font-semibold rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:bg-red-600 ${
                loadingIndex === rowIndex ? 'cursor-not-allowed opacity-50' : 'hover:scale-105'
              }`}
              disabled={loadingIndex === rowIndex}
            >
              {loadingIndex === rowIndex ? (
                <span className="deletingText">Deleting...</span>
              ) : (
                <>
                  <FaTrash className="mr-1" /> Delete
                </>
              )}
            </button>
          );
        },
      },
    },
  ];

  const data = sold.map((entry, index) => {
    return [
      index + 1,
      entry.companyName,
      entry.companyPrice,
      entry.quantity,
      entry.sellPrice,
      entry.profitLoss,
      entry.purgableIncome,
      // Actions column will be handled by customBodyRender
    ];
  });

  const options = {
    filterType: 'dropdown',
    responsive: 'standard', // Update this based on your intended responsive behavior
    selectableRows: 'none',
    fixedHeader: true,
    fixedSelectColumn: true,
    search: false,
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    tableBodyHeight: 'auto',
    setTableProps: () => {
      return {
        className: 'custom-mui-table', // Add a custom class to the MUIDataTable
      };
    },
  };

  return (
    <div className="sold_portfolio">
      <MUIDataTable title={<div className="sold-portfolio-title">Sold Portfolio</div>} data={data} columns={columns} options={options} />
      {showDeleteDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md mx-auto">
            <p className="text-lg font-semibold text-gray-800 mb-4">Are you sure you want to delete this stock?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCloseDeleteDialog}
                className="px-4 py-2 bg-gray-300 text-gray-800 font-semibold rounded-lg shadow-md hover:bg-gray-400 transition duration-300 ease-in-out"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition duration-300 ease-in-out"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SoldPortfolioTable;
