import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../Shared/context/auth-context";
import axios from "axios";
import { constaints } from "../../../utils/constaints";

function AdminDashboard() {
  const { user, isLoading } = useContext(AuthContext);
  const [userCount, setUserCount] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/admin/users`);
        const users = response.data;
        setUserCount(users.length);

        // Filter users to count only those with an active subscription
        const activeUsers = users.filter((user) => user.subscription.activated);
        setActiveUserCount(activeUsers.length);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserCount();
  }, []);

  return (
    <div className="p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-black min-h-screen flex flex-col items-center justify-center">
      <h1 className="text-white text-5xl font-bold text-center mb-6 animate__animated animate__fadeInDown">
        Hello, {user.fname} {user.lname}
      </h1>
      <div className="text-gray-300 text-lg mb-8 animate__animated animate__fadeIn">
        Welcome to the Admin Panel Dashboard
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 w-full max-w-4xl">
        <div className="bg-gradient-to-r from-blue-500 to-blue-700 text-white p-8 rounded-lg shadow-2xl hover:from-blue-600 hover:to-blue-800 transition-transform duration-300 transform hover:scale-105 animate__animated animate__zoomIn">
          <h2 className="text-4xl font-semibold mb-4 animate__animated animate__pulse animate__infinite">
            Total Users
          </h2>
          <p className="text-6xl font-bold animate__animated animate__flash animate__delay-3s">
            {userCount}
          </p>
        </div>

        <div className="bg-gradient-to-r from-green-500 to-green-700 text-white p-8 rounded-lg shadow-2xl hover:from-green-600 hover:to-green-800 transition-transform duration-300 transform hover:scale-105 animate__animated animate__zoomIn">
          <h2 className="text-4xl font-semibold mb-4 animate__animated animate__pulse animate__infinite">
            Active Users
          </h2>
          <p className="text-6xl font-bold animate__animated animate__flash animate__delay-3s">
            {activeUserCount}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
