import React, { useState } from "react";
import CompanySearchAndDownload from "./CompanySearchAndDownload";
import UpdateEditedCompanies from "./UpdateEditedCompanies";

const ManageCompanies = () => {
  const [view, setView] = useState(''); // State to control which component is displayed

  const handleBack = () => {
    setView(''); // Reset view to show the main cards
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-extrabold mb-5 text-center tracking-wide text-white">Download & Replace Companies</h1>

      {view === '' && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="group relative p-5 bg-gradient-to-br from-purple-700 to-pink-700 rounded-2xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
            <div className="absolute inset-0 bg-gradient-to-tl from-white to-transparent opacity-20 rounded-2xl pointer-events-none"></div>
            <h2 className="text-2xl font-bold mb-2 text-center">Download Companies</h2>
            <p className="mb-8 text-gray-200 text-center text-base">
            Search for companies, select the ones you need, and download their details in an Excel file.
            </p>
            <button
              onClick={() => setView('searchDownload')}
              className="relative z-10 bg-green-600 text-white py-3 px-5 rounded-full shadow-md w-full text-base transition-all transform hover:scale-105 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Search and Download
            </button>
          </div>

          <div className="group relative p-5 bg-gradient-to-br from-purple-700 to-pink-700 rounded-2xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
            <div className="absolute inset-0 bg-gradient-to-tl from-white to-transparent opacity-20 rounded-2xl pointer-events-none"></div>
            <h2 className="text-2xl font-bold mb-2 text-center">Upload Updated Companies</h2>
            <p className="mb-8 text-gray-200 text-center text-base">
            Upload an Excel file with updated company information, including existing company IDs. The system will match these IDs to replace the corresponding companies' data with the updated information.
            </p>
            <button
              onClick={() => setView('upload')}
              className="relative z-10 bg-green-600 text-white py-3 px-6 rounded-full shadow-md w-full text-base transition-all transform hover:scale-105 hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-300"
            >
              Upload Data
            </button>
          </div>
        </div>
      )}
      {view === 'searchDownload' && (
  <div className="mt-10">
    <button
      onClick={handleBack}
      className="mb-6  bg-gradient-to-r from-red-700 to-purple-700 text-white py-2 px-5 rounded-full shadow-lg transform transition-all hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-pink-400 text-base"
    >
      Back
    </button>
    <CompanySearchAndDownload />
  </div>
)}

{view === 'upload' && (
  <div className="mt-10">
    <button
      onClick={handleBack}
      className="mb-6 bg-gradient-to-r from-red-700 to-purple-500 text-white py-2 px-5 rounded-full shadow-lg transform transition-all hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-pink-400 text-base"
    >
      Back
    </button>
    <UpdateEditedCompanies />
  </div>
)}

    </div>
  );
};

export default ManageCompanies;
