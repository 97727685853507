import React, { useState } from "react";
import axios from "axios";
import { FaUpload } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { constaints } from "../../../../../utils/constaints";

const UpdateEditedCompanies = () => {
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${SERVER_URL}/admin/upload-updated-companies`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setModalMessage(response.data.message);
      setOpen(true);
    } catch (error) {
      console.error("Error uploading file:", error);
      setModalMessage("Error uploading file");
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-gradient-to-b from-gray-700 via-gray-800 to-gray-900 rounded-2xl shadow-2xl">
      <h2 className="text-2xl text-white font-bold mb-6 text-center">
        Upload Updated Companies
      </h2>

      <div className="flex flex-col items-center space-y-6">
        <input
          type="file"
          onChange={handleFileChange}
          className="w-full text-gray-300 bg-gray-800 border-2 border-dashed border-gray-600 rounded-xl p-4 focus:outline-none focus:ring-4 focus:ring-blue-500 transition duration-300 ease-in-out hover:bg-gray-700"
          aria-label="Upload file"
        />

        <button
          onClick={handleUpload}
          className={`w-full flex items-center justify-center bg-gradient-to-r from-green-400 to-blue-500 text-white py-3 rounded-xl shadow-lg hover:from-green-500 hover:to-blue-600 focus:outline-none focus:ring-4 focus:ring-green-300 transition-transform transform-gpu duration-300 ease-out hover:scale-105 ${
            !file ? "opacity-60 cursor-not-allowed" : ""
          }`}
          disabled={!file}
          aria-disabled={!file}
        >
          <FaUpload className="mr-2" />
          Upload File
        </button>
      </div>

      {/* Modal for success or error messages */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="bg-gray-800 text-white p-8 rounded-2xl shadow-xl max-w-sm mx-auto mt-20">
          <h2 id="modal-title" className="text-2xl font-bold mb-4">
            Upload Status
          </h2>
          <p id="modal-description" className="mb-6 ">
            {modalMessage}
          </p>
          <Button
            onClick={handleClose}
            className="w-full bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-xl focus:outline-none focus:ring-4 focus:ring-red-300 transition-transform transform-gpu duration-300 ease-out hover:scale-105"
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateEditedCompanies;
