import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import "./FinView.css";
import MarketView from "../component/MarketView/MarketView";
import ResearchView from "../component/ResearchReport/ResearchView";
import IpoView from "../component/IPO/IpoView";
import { FaChartLine, FaBuilding, FaLock, FaUnlock } from "react-icons/fa";
import { MdDescription } from "react-icons/md";

function FinView() {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation(); // Get the current location

  // Initialize state
  const [lockedView, setLockedView] = useState(
    localStorage.getItem("lockedView")
  );

  // Handle navigation and active view update
  const handleNavigation = (view) => {
    history.push(`${url}/${view}`);
  };

  const toggleLock = () => {
    if (lockedView === location.pathname.split("/").pop()) {
      setLockedView(null);
      localStorage.removeItem("lockedView");
    } else {
      setLockedView(location.pathname.split("/").pop());
      localStorage.setItem("lockedView", location.pathname.split("/").pop());
    }
  };

  return (
    <>
      <div className="report-page-container">
        <div className="finview_main__button-container mb-0">
          <button
            onClick={() => handleNavigation("market")}
            className={`button-market ${
              location.pathname.includes("market") ? "active" : ""
            }`}
          >
            <FaChartLine /> Market View
          </button>
          <button
            onClick={() => handleNavigation("research")}
            className={`button-research ${
              location.pathname.includes("research") ? "active" : ""
            }`}
          >
            <MdDescription /> Research View
          </button>
          <button
            onClick={() => handleNavigation("ipo")}
            className={`button-ipo ${
              location.pathname.includes("ipo") ? "active" : ""
            }`}
          >
            <FaBuilding /> IPO View
          </button>
        </div>

        {/* Lock message and icon */}
        <div className="flex items-center bg-[var(--surface)] p-2 shadow-md text-lg">
          <span className=" text-[var(--on-surface-low)] text-sm">
            Note: You can set this screen as your default view by clicking the
            lock icon
          </span>
          {lockedView === location.pathname.split("/").pop() ? (
            <FaLock
              onClick={toggleLock}
              className="ml-3 text-green-600 cursor-pointer hover:text-[06D001] transition-colors duration-300"
              title="Unlock this view"
            />
          ) : (
            <FaUnlock
              onClick={toggleLock}
              className="ml-3 text-[var(--on-surface-low)] cursor-pointer hover:text-gray-500 transition-colors duration-300"
              title="Lock this view"
            />
          )}
        </div>

        <div className="columns-container">
          <Switch>
            <Route exact path={`${path}/market`} component={MarketView} />
            <Route exact path={`${path}/research`} component={ResearchView} />
            <Route exact path={`${path}/ipo`} component={IpoView} />
            <Redirect exact from={path} to={`${path}/market`} />
          </Switch>
        </div>
      </div>
    </>
  );
}

export default FinView;
