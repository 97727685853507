import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { constaints } from "../../../../utils/constaints";

const AddIpo = () => {
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [formData, setFormData] = useState({
    companyName: "",
    symbol: "",
    openingDate: "",
    closeDate: "",
    listingDate: "",
    issueSize: "",
    lotSize: 0,
    minimumRetailInvestment: 0,
    issuePriceMax: 0,
    exchange: "NSE",
    price: "",
    expectedListingProfit: "",
    industry: "",
    rating: 0,
    businessModel: {
      businessModel1: "",
      businessModel2: "",
      businessModel3: "",
    },
    investmentProspective: "",
    shariahStatus: "Compliant",
    shariahCompliance: {
      businessPermissibilityStatus: "Yes",
      interestToRevenue: 0,
      loanToMarketCap: 0,
      investmentToMarketCap: 0,
    },
  });

  const handleOpen = (message) => {
    setModalMessage(message);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes("businessModel") || name.includes("shariahCompliance")) {
      const [field, subField] = name.split(".");
      setFormData({
        ...formData,
        [field]: {
          ...formData[field],
          [subField]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${SERVER_URL}/admin/add-ipo`,
        formData
      );

      handleOpen("IPO added successfully!");
      setFormData({
        companyName: "",
        symbol: "",
        openingDate: "",
        closeDate: "",
        listingDate: "",
        issueSize: "",
        lotSize: 0,
        minimumRetailInvestment: 0,
        issuePriceMax: 0,
        exchange: "NSE",
        price: "",
        expectedListingProfit: "",
        industry: "",
        rating: 0,
        businessModel: {
          businessModel1: "",
          businessModel2: "",
          businessModel3: "",
        },
        investmentProspective: "",
        shariahStatus: "Compliant",
        shariahCompliance: {
          businessPermissibilityStatus: "Yes",
          interestToRevenue: 0,
          loanToMarketCap: 0,
          investmentToMarketCap: 0,
        },
      });
    } catch (error) {
      console.error("Error posting IPO:", error);
      handleOpen("Failed to add IPO.");
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="p-4 max-w-full mx-auto bg-gradient-to-r from-gray-800 to-black shadow-md rounded"
      >
        <h2 className="text-2xl font-bold mb-4 text-white">Add New IPO</h2>

        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-gray-300">Company Name</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4 relative">
            <label className="block text-gray-300">
              Symbol
              <Tooltip
                title="The symbol of the stock, e.g., TATA.NS"
                placement="top"
                arrow
                style={{
                  marginLeft: "8px",
                  cursor: "pointer",
                  color: "#b0b0b0",
                }}
              >
                <span
                  style={{
                    marginLeft: "8px",
                    cursor: "pointer",
                    color: "#b0b0b0",
                  }}
                >
                  &#9432; {/* This is an info icon (ℹ️) */}
                </span>
              </Tooltip>
            </label>
            <input
              type="text"
              name="symbol"
              value={formData.symbol}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Industry</label>
            <input
              type="text"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Exchange</label>
            <select
              name="exchange"
              value={formData.exchange}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            >
              <option value="NSE">NSE</option>
              <option value="BSE">BSE</option>
              <option value="NSE&BSE">NSE&BSE</option>
              <option value="SME-NSE">SME-NSE</option>
              <option value="SME-BSE">SME-BSE</option>
              <option value="SME-NSE&BSE">SME-NSE&BSE</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Opening Date</label>
            <input
              type="date"
              name="openingDate"
              value={formData.openingDate}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Closing Date</label>
            <input
              type="date"
              name="closeDate"
              value={formData.closeDate}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Listing Date</label>
            <input
              type="date"
              name="listingDate"
              value={formData.listingDate}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Issue Size</label>
            <input
              type="text"
              name="issueSize"
              value={formData.issueSize}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Lot Size</label>
            <input
              type="number"
              name="lotSize"
              value={formData.lotSize}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">
              Minimum Retail Investment
            </label>
            <input
              type="number"
              name="minimumRetailInvestment"
              value={formData.minimumRetailInvestment}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Issue Price Max</label>
            <input
              type="number"
              name="issuePriceMax"
              value={formData.issuePriceMax}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Price</label>
            <input
              type="text"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">
              Expected Listing Profit
            </label>
            <input
              type="text"
              name="expectedListingProfit"
              value={formData.expectedListingProfit}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Rating</label>
            <input
              type="number"
              name="rating"
              value={formData.rating}
              onChange={handleChange}
              required
              min="0" // Minimum value
              max="10" // Maximum value
              step="0.1" // Step for fractional values
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Business Model 1</label>
            <textarea
              name="businessModel.businessModel1"
              value={formData.businessModel.businessModel1}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
              rows="4" // Adjust the number of rows as needed
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Business Model 2</label>
            <textarea
              name="businessModel.businessModel2"
              value={formData.businessModel.businessModel2}
              onChange={handleChange}
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
              rows="4" // Adjust the number of rows as needed
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Business Model 3</label>
            <textarea
              name="businessModel.businessModel3"
              value={formData.businessModel.businessModel3}
              onChange={handleChange}
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
              rows="4" // Adjust the number of rows as needed
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">
              Investment Prospective
            </label>
            <textarea
              name="investmentProspective"
              value={formData.investmentProspective}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
              rows="4"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Shariah Status</label>
            <select
              name="shariahStatus"
              value={formData.shariahStatus}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            >
              <option value="Compliant">Compliant</option>
              <option value="Non-Compliant">Non-Compliant</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">
              Business Permissibility Status
            </label>
            <select
              name="shariahCompliance.businessPermissibilityStatus"
              value={formData.shariahCompliance.businessPermissibilityStatus}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Interest to Revenue</label>
            <input
              type="number"
              name="shariahCompliance.interestToRevenue"
              value={formData.shariahCompliance.interestToRevenue}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">Loan to Market Cap</label>
            <input
              type="number"
              name="shariahCompliance.loanToMarketCap"
              value={formData.shariahCompliance.loanToMarketCap}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300">
              Investment to Market Cap
            </label>
            <input
              type="number"
              name="shariahCompliance.investmentToMarketCap"
              value={formData.shariahCompliance.investmentToMarketCap}
              onChange={handleChange}
              required
              className="mt-1 p-2 border w-full bg-gray-700 text-white"
            />
          </div>
        </div>

        <button
          type="submit"
          className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-2 rounded hover:bg-gradient-to-l hover:from-purple-600 hover:to-blue-500 w-full"
        >
          Post IPO
        </button>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="bg-gray-800 text-white p-6 rounded shadow-lg max-w-sm mx-auto mt-24">
          <h2 id="modal-title" className="text-2xl mb-4 font-semibold">
            Success
          </h2>
          <p id="modal-description" className="mb-4">
            {modalMessage}
          </p>
          <button
            onClick={handleClose}
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 w-full"
          >
            Close
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default AddIpo;
