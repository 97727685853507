import React, { useState, useEffect, useRef } from "react";
import CheckIcon from "@mui/icons-material/CheckRounded";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaLink,
  FaImage,
  FaVideo,
} from "react-icons/fa";
import { MdFormatListBulleted, MdFormatListNumbered } from "react-icons/md";
import LinkModal from "../../../../Shared/Models/LinkModal";
import BlogPreview from "../component/BlogPreview";
import { Snackbar, Alert } from "@mui/material";
import BlogImageModal from "../../../../Shared/Models/BlogImageModal"; // Import the BlogImageModal component
import BlogVideoModal from "../../../../Shared/Models/BlogVideoModal"; // Import the BlogVideoModal component
import OrderedListDialog from "../../../../Shared/Models/OrderedListDialog"; // Import the OrderedListDialog component
import UnorderedListDialog from "../../../../Shared/Models/UnorderedListDialog"; // Import the UnorderedListDialog component
import { AiOutlineExpand, AiOutlineCompress } from "react-icons/ai";
import { FaInfoCircle } from "react-icons/fa";
import TableModal from "../../../../Shared/Models/TableModal"; // Import the TableModal component
import { MdTableChart } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { constaints } from "../../../../utils/constaints";

// Reusable Modal Component

export default function PostMarketView() {
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(""); // Blog content
  const [authors, setAuthors] = useState([{ name: "", link: "" }]);
  const [isUnsaved, setIsUnsaved] = useState(false); // Flag for unsaved changes
  const [toc, setToc] = useState([]); // Table of Contents data
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaOgImage, setMetaOgImage] = useState("");
  // model states
  const titleInputRef = useRef(null);
  const [openTitleModal, setOpenTitleModal] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [altText, setAltText] = useState("");
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [openOrderedListModal, setOpenOrderedListModal] = useState(false);
  const [openUnorderedListModal, setOpenUnorderedListModal] = useState(false);
  const [orderedListType, setOrderedListType] = useState("decimal"); // Default type
  const [unorderedListType, setUnorderedListType] = useState("disc"); // Default type
  const [orderedListStart, setOrderedListStart] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // success, error, warning, info
  const [viewMode, setViewMode] = useState("split");
  const [openTableModal, setOpenTableModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // Validation states
  const [videoUrlError, setVideoUrlError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [altTextError, setAltTextError] = useState(false);

  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
    setIsUnsaved(true); // Mark as unsaved when user makes changes
  };

  // Warn user before leaving if there are unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isUnsaved) {
        e.preventDefault();
        e.returnValue = ""; // Required for the browser to show the confirmation dialog
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isUnsaved]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const wrapSelectedText = (tag, extraAttributes = "") => {
    const textarea = document.getElementById("blogContent");
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    const selectedText = content.substring(startPos, endPos);

    if (selectedText) {
      let wrappedText = "";

      if (["h2", "h3", "h4", "h5", "h6"].includes(tag)) {
        // Generate a sanitized ID based on the text
        const sanitizedId = selectedText
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
          .replace(/^-+|-+$/g, ""); // Trim hyphens from start and end

        wrappedText = `<${tag} id="heading-${sanitizedId}" ${extraAttributes}>${selectedText}</${tag}>`;
      } else {
        // For other tags like blockquote or paragraph, no ID is added
        wrappedText = `<${tag} ${extraAttributes}>${selectedText}</${tag}>`;
      }

      const newText =
        content.substring(0, startPos) +
        wrappedText +
        content.substring(endPos, content.length);

      setContent(newText);
    }
  };

  const removeTagFromSelection = () => {
    const textarea = document.getElementById("blogContent");
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    if (startPos === endPos) {
      // Replace alert with Snackbar
      setSnackbarMessage("Please select some text or tag to remove!");
      setSnackbarSeverity("warning"); // Set severity to warning
      setSnackbarOpen(true);
      return;
    }

    const selectedText = content.substring(startPos, endPos);

    if (selectedText) {
      let updatedContent = content;
      let tagRemoved = false;

      // Check if the selection includes a full tag (e.g., <h2>...</h2>)
      const fullTagRegex = /<([a-z][a-z0-9]*)[^>]*>(.*?)<\/\1>/gi;
      let match;

      while ((match = fullTagRegex.exec(content)) !== null) {
        const fullTag = match[0]; // Full matched tag (e.g., <h2>Hello world</h2>)
        const innerContent = match[2]; // Inner content (e.g., Hello world)

        // Check if the selected text matches the full tag or its inner content
        if (fullTag.includes(selectedText) || innerContent === selectedText) {
          updatedContent = updatedContent.replace(fullTag, innerContent); // Remove the tag
          tagRemoved = true;
          break; // Stop after the first match
        }
      }

      if (tagRemoved) {
        setContent(updatedContent);
        // Show success message
        setSnackbarMessage("Tag removed successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        // Show warning message if no tag was found
        setSnackbarMessage("No tag found to remove in the selected text!");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
      }
    }
  };

  // this handel function triggers to add table in blog
  const handleAddTable = (rows, cols) => {
    const textarea = document.getElementById("blogContent");
    const startPos = textarea.selectionStart;

    // Generate the HTML table
    let tableHTML =
      "<table border='1' style='border-collapse: collapse; width: 100%;'>";

    // Add the header row
    tableHTML += "<thead><tr>";
    for (let j = 0; j < cols; j++) {
      tableHTML += `<th style='padding: 8px;'>Header ${j + 1}</th>`;
    }
    tableHTML += "</tr></thead>";

    // Add the body rows
    tableHTML += "<tbody>";
    for (let i = 1; i < rows; i++) {
      // Start from row 1 to skip header row
      tableHTML += "<tr>";
      for (let j = 0; j < cols; j++) {
        tableHTML += `<td style='padding: 8px;'>Row ${i}, Col ${j + 1}</td>`;
      }
      tableHTML += "</tr>";
    }
    tableHTML += "</tbody></table>";

    const newText =
      content.substring(0, startPos) + tableHTML + content.substring(startPos);

    setContent(newText);
  };

  // this handel function is user for the author states
  const handleAuthorChange = (index, field, value) => {
    const updatedAuthors = [...authors];
    updatedAuthors[index][field] = value;
    setAuthors(updatedAuthors);
  };

  // too add mulitple author this function is triggered
  const addAuthorField = () => {
    setAuthors([...authors, { name: "", link: "" }]);
  };

  // remove the author field this function is triggered
  const removeAuthorField = (index) => {
    setAuthors(authors.filter((_, i) => i !== index));
  };

  // to add link to the full title or  any portion of the title this button handel function is used to add backlinks to any speciifc  title
  const addTitleBacklink = (url) => {
    if (!url) return;

    const input = titleInputRef.current;
    if (!input) return;

    const start = input.selectionStart;
    const end = input.selectionEnd;

    if (start === end) {
      // alert("Please select some text to add a hyperlink!");
      setSnackbarMessage("Please select some text to add a hyperlink!");
      setSnackbarSeverity("warning"); // Set severity to warning
      setSnackbarOpen(true);
      return;
    }

    const selectedText = title.substring(start, end);
    const linkedText = `<a href="${url}" target="_blank">${selectedText}</a>`;

    // Replace the selected text with the linked text
    const updatedTitle =
      title.substring(0, start) + linkedText + title.substring(end);

    setTitle(updatedTitle);
    setOpenTitleModal(false);
    // Show success message
    setSnackbarMessage("Hyperlink added successfully!");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  // when add ordered list button is click this function is beign triggered and it fucntion is to make the text in ordere list
  const handleAddOrderedList = () => {
    const textarea = document.getElementById("blogContent");
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    // Extract the selected text
    const selectedText = content.substring(startPos, endPos);

    // Split the text into lines
    const listItems = selectedText
      .split(/\r?\n/) // Split by newlines (\n or \r\n)
      .map((line) => line.trim()) // Trim extra spaces
      .filter((line) => line !== "") // Ignore empty lines
      .map((line) => `<li>${line}</li>`) // Wrap each line in <li>
      .join(""); // Join the list items into a single string

    if (listItems) {
      // Generate the ordered list
      const olTag = `<ol style="list-style-type: ${orderedListType};" ${
        orderedListStart > 1 ? `start="${orderedListStart}"` : ""
      }>${listItems}</ol>`;

      // Replace the selected text with the ordered list
      const newText =
        content.substring(0, startPos) +
        olTag +
        content.substring(endPos, content.length);

      setContent(newText);
    }

    // Close the modal and reset defaults
    setOpenOrderedListModal(false);
    setOrderedListType("decimal");
    setOrderedListStart(1);
  };

  // when unordered list is being triggered on that time this function is being triggred which arrange the data in an unorderelist list
  const handleAddUnorderedList = () => {
    const textarea = document.getElementById("blogContent");
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    const selectedText = content.substring(startPos, endPos);

    const listItems = selectedText
      ? selectedText
          .replace(/<br>/g, "\n") // Replace <br> tags with newlines for processing
          .split(/\n|•|\t/) // Split by newlines, bullet points, or tabs
          .map((item) => item.trim()) // Trim extra spaces
          .filter((item) => item !== "") // Remove empty lines
          .map((item) => `<li>${item}</li>`) // Wrap each item in <li>
          .join("") // Join all list items
      : "<li>List item 1</li><li>List item 2</li><li>List item 3</li>";

    const ulTag = `<ul style="list-style-type: ${unorderedListType};">${listItems}</ul>`;

    const newText =
      content.substring(0, startPos) +
      ulTag +
      content.substring(endPos, content.length);

    setContent(newText);
    setOpenUnorderedListModal(false); // Close modal
    setUnorderedListType("disc"); // Reset type
  };

  const addLink = (url) => {
    // when link is click it is used to add a backelink to any text words or sentence in a blog content
    if (!url) return;
    wrapSelectedText("a", `href="${url}" target="_blank"`);
  };

  // when user click on Add iamge button then a model is open where user enters the url and the alt tag and that fucntion is beign handel by this handler
  const handleAddImage = () => {
    let hasError = false;

    // Validate image URL
    if (!imageUrl) {
      setUrlError(true);
      hasError = true;
    } else {
      setUrlError(false);
    }

    // Validate alt text
    if (!altText) {
      setAltTextError(true);
      hasError = true;
    } else {
      setAltTextError(false);
    }

    if (hasError) {
      return; // Don't proceed if validation fails
    }

    const textarea = document.getElementById("blogContent");
    const startPos = textarea.selectionStart;

    const imageTag = `<img src="${imageUrl}" alt="${altText}" />`;
    const newText =
      content.substring(0, startPos) +
      imageTag +
      content.substring(startPos, content.length);

    setContent(newText);

    // Reset the modal fields and close modal
    setImageUrl("");
    setAltText("");
    setOpenImageModal(false);
  };

  // this function triggeres wehn user click on add video
  const handleAddVideo = () => {
    let hasError = false;

    // Validate video URL
    if (!videoUrl) {
      setVideoUrlError(true);
      hasError = true;
    } else {
      setVideoUrlError(false);
    }

    // Validate alt text
    if (!altText) {
      setAltTextError(true);
      hasError = true;
    } else {
      setAltTextError(false);
    }

    if (hasError) {
      return; // Don't proceed if validation fails
    }

    const textarea = document.getElementById("blogContent");
    const startPos = textarea.selectionStart;

    let videoTag = "";

    // Check if it's a YouTube URL
    if (videoUrl.includes("youtube.com") || videoUrl.includes("youtu.be")) {
      const embedUrl = videoUrl
        .replace("watch?v=", "embed/")
        .replace("youtu.be/", "youtube.com/embed/");
      videoTag = `<iframe src="${embedUrl}" alt="${altText}" frameborder="0" allowfullscreen></iframe>`;
    } else {
      // Generic video URL
      videoTag = `<video src="${videoUrl}" alt="${altText}" controls></video>`;
    }

    const newText =
      content.substring(0, startPos) +
      videoTag +
      content.substring(startPos, content.length);

    setContent(newText);

    // Reset the modal fields and close modal
    setVideoUrl("");
    setAltText("");
    setOpenVideoModal(false);
  };

  // this handel function wraps the text based on the headings , like h1, h2 , h3 ... etc tags
  const handleHeadingChange = (e) => {
    const tag = e.target.value; // Get the selected tag
    wrapSelectedText(tag); // Call the function to wrap the selected text
    e.target.value = ""; // Reset the select back to default
  };

  // Generate TOC dynamically from content
  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const headings = Array.from(doc.querySelectorAll("h2, h3, h4, h5, h6"));
    const tocData = headings.map((heading) => ({
      text: heading.textContent,
      id: heading.id,
    }));
    setToc(tocData);
  }, [content]);

  // Function to handle content submission
  // const handleSubmit = async () => {
  //   if (loading) return; // Prevent multiple submissions

  //   setLoading(true); // Start loading
  //   // Validation
  //   if (authors.length === 0 || !title || !content) {
  //     setSnackbarMessage("Author, Title, and Content are required!");
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //     setLoading(false); // Stop loading
  //     return;
  //   }

  //   if (!metaTitle.trim()) {
  //     setSnackbarMessage("Meta Title is required!");
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //     setLoading(false); // Stop loading
  //     return;
  //   }

  //   if (!metaDescription.trim()) {
  //     setSnackbarMessage("Meta Description is required!");
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //     setLoading(false); // Stop loading
  //     return;
  //   }

  //   if (!metaKeywords.trim()) {
  //     setSnackbarMessage("Meta Keywords are required!");
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //     setLoading(false); // Stop loading
  //     return;
  //   }

  //   const blogData = {
  //     authors,
  //     title,
  //     content,
  //     metaTitle: metaTitle || null, // Optional field
  //     metaDescription: metaDescription || null, // Optional field
  //     metaKeywords: metaKeywords ? metaKeywords.split(",") : null,
  //     metaOgImage: metaOgImage || null,
  //     toc,
  //   };

  //   try {
  //     const response = await fetch(`${SERVER_URL}/admin/market-view`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(blogData),
  //     });

  //     if (response.ok) {
  //       const result = await response.json();
  //       setIsUnsaved(false);
  //       setSnackbarMessage("Blog submitted successfully!");
  //       setSnackbarSeverity("success");
  //       setSnackbarOpen(true);

  //       // Reset fields after successful submission
  //       setAuthors([{ name: "", link: "" }]);
  //       setTitle("");
  //       setContent("");
  //       setMetaTitle("");
  //       setMetaDescription("");
  //       setMetaKeywords("");
  //       setMetaOgImage("");
  //       setToc([]);
  //     } else {
  //       const error = await response.json();

  //       setSnackbarMessage(
  //         "Failed to submit the blog: " + (error.error || "Unknown error")
  //       );
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //     }
  //   } catch (error) {
  //     setSnackbarMessage("An unexpected error occurred. Please try again.");
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //   } finally {
  //     setLoading(false); // Stop loading
  //   }
  // };

  const handleSubmit = async () => {
    if (loading) {
      return; // Prevent multiple submissions
    }

    setLoading(true); // Start loading

    // Validation
    if (authors.length === 0 || !title || !content) {
      setSnackbarMessage("Author, Title, and Content are required!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false); // Stop loading
      return;
    }

    if (!metaTitle.trim()) {
      setSnackbarMessage("Meta Title is required!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false); // Stop loading
      return;
    }

    if (!metaDescription.trim()) {
      setSnackbarMessage("Meta Description is required!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false); // Stop loading
      return;
    }

    if (!metaKeywords.trim()) {
      setSnackbarMessage("Meta Keywords are required!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false); // Stop loading
      return;
    }

    const blogData = {
      authors,
      title,
      content,
      metaTitle: metaTitle || null, // Optional field
      metaDescription: metaDescription || null, // Optional field
      metaKeywords: metaKeywords ? metaKeywords.split(",") : null,
      metaOgImage: metaOgImage || null,
      toc,
    };

    try {
      const response = await axios.post(
        `${SERVER_URL}/admin/market-view`,
        blogData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        const result = response.data;

        setIsUnsaved(false);
        setSnackbarMessage("Blog submitted successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Reset fields after successful submission
        setAuthors([{ name: "", link: "" }]);
        setTitle("");
        setContent("");
        setMetaTitle("");
        setMetaDescription("");
        setMetaKeywords("");
        setMetaOgImage("");
        setToc([]);
      } else {
        setSnackbarMessage(
          "Failed to submit the blog: " +
            (response.data.error || "Unknown error")
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
      setSnackbarMessage("An unexpected error occurred. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex pt-[88px] h-screen bg-black">
      {/* Left Section */}
      {viewMode !== "preview" && (
        <div
          className={`${
            viewMode === "editor" ? "w-full" : "w-1/2"
          } overflow-auto flex flex-col p-8 gap-5 bg-surface-container-lowest`}
        >
          <div className="flex justify-between items-center">
            {/* main title of the Add Blog which is static  */}
            <h1 className={`text-2xl font-bold text-white`}>Add new blog</h1>

            {/* Maximize/Minimize Button */}
            {viewMode !== "editor" && (
              <button
                onClick={() => toggleViewMode("editor")}
                className="p-2 bg-green-600 text-white rounded-full shadow-sm hover:bg-blue-700 transition-colors focus:ring-2 focus:ring-blue-400 focus:outline-none"
                title="Maximize Editor"
              >
                <AiOutlineExpand className="w-5 h-5" />
              </button>
            )}
            {viewMode === "editor" && (
              <button
                onClick={() => toggleViewMode("split")}
                className="p-2 bg-gray-600 text-white rounded-full shadow-sm hover:bg-gray-700 transition-colors focus:ring-2 focus:ring-gray-400 focus:outline-none"
                title="Minimize Editor"
              >
                <AiOutlineCompress className="w-5 h-5" />
              </button>
            )}
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 bg-gray-900 p-4 rounded-lg shadow-md border border-gray-800">
              {/* Blog Title section */}
              <div className="flex items-center gap-2">
                <label className="text-base text-white font-medium">
                  Blog Title*
                </label>
                <div className="relative group">
                  <button
                    className="text-gray-400 hover:text-gray-200 focus:outline-none"
                    aria-label="Info about authors"
                  >
                    <FaInfoCircle className="w-5 h-5" />
                  </button>
                  <div className="absolute left-6 top-0 w-[500px] text-wrap px-3 py-2 text-xs text-gray-200 bg-gray-800 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                    To make the title a link, select the text, click Link, enter
                    the URL, and submit. For multiple links, repeat the process
                    for each selection.
                  </div>
                </div>
              </div>
              {/* blog link section input */}
              <div className="flex gap-3 items-center">
                <input
                  type="text"
                  ref={titleInputRef}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter blog title"
                  className="flex-1 p-4 bg-gray-800 text-white text-lg rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none placeholder-gray-500"
                />
                <button
                  onClick={() => setOpenTitleModal(true)}
                  className="px-4 py-2 bg-blue-600 text-sm text-white font-medium rounded-md shadow-md hover:bg-blue-700 transition-colors focus:ring-2 focus:ring-blue-400 focus:outline-none"
                >
                  Link
                </button>
              </div>
            </div>
            {/* Author Input */}
            <div className="flex flex-col gap-4 bg-gray-900 p-2 rounded-lg shadow-md border border-gray-800">
              <div className="flex items-center gap-2">
                <label className="text-base text-white font-medium">
                  Authors*
                </label>
                <div className="relative group">
                  <button
                    className="text-gray-400 hover:text-gray-200 focus:outline-none"
                    aria-label="Info about authors"
                  >
                    <FaInfoCircle className="w-5 h-5" />
                  </button>
                  <div className="absolute left-6 top-0 w-[500px] text-wrap px-3 py-2 text-xs text-gray-200 bg-gray-800 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                    Enter the author's full name in the Author Name field. If
                    you wish to add a link for the author, provide the URL in
                    the Author Link field. Click Add Author to include
                    additional authors. To remove an author, simply click the
                    Remove button.
                  </div>
                </div>
              </div>

              {authors.map((author, index) => (
                <div
                  key={index}
                  className="flex flex-col sm:flex-row items-center gap-2 p-3 bg-gray-800 rounded-md shadow-sm"
                >
                  <div className="flex flex-col w-full sm:w-1/2">
                    <label className="text-white text-xs mb-1">
                      Author Name
                    </label>
                    <input
                      type="text"
                      value={author.name}
                      onChange={(e) =>
                        handleAuthorChange(index, "name", e.target.value)
                      }
                      placeholder="Enter name"
                      className="p-2 bg-gray-700 text-gray-200 text-sm rounded-md border border-gray-600 focus:ring-1 focus:ring-blue-500 focus:outline-none"
                    />
                  </div>
                  <div className="flex flex-col w-full sm:w-1/2">
                    <label className="text-white text-xs mb-1">
                      Author Link (URL)
                    </label>
                    <input
                      type="url"
                      value={author.link}
                      onChange={(e) =>
                        handleAuthorChange(index, "link", e.target.value)
                      }
                      placeholder="Enter link (optional)"
                      className="p-2 bg-gray-700 text-gray-200 text-sm rounded-md border border-gray-600 focus:ring-1 focus:ring-blue-500 focus:outline-none"
                    />
                  </div>
                  <button
                    onClick={() => removeAuthorField(index)}
                    className="px-3 py-2 bg-red-600 text-xs text-white rounded-md shadow-sm hover:bg-red-700 transition-colors focus:ring-1 focus:ring-red-400 focus:outline-none"
                  >
                    Remove
                  </button>
                </div>
              ))}

              <div className="flex justify-end">
                <button
                  onClick={addAuthorField}
                  className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 transition-colors focus:ring-2 focus:ring-blue-400 focus:outline-none"
                >
                  Add Author
                </button>
              </div>
            </div>

            {/* MEta Title  Input sections */}
            <div className="flex flex-col gap-4 bg-gray-900 p-4 rounded-lg shadow-md border border-gray-800">
              <div className="flex flex-col gap-3">
                <label className="text-sm text-white font-medium">
                  Meta Title*
                </label>
                <input
                  type="text"
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  placeholder="Enter meta title (required*)"
                  className="p-3 bg-gray-800 text-gray-200 text-sm rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none placeholder-gray-500"
                />
              </div>
              {/* Meta description section */}
              <div className="flex flex-col gap-3">
                <label className="text-sm text-white font-medium">
                  Meta Description*
                </label>
                <textarea
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="Enter meta description (required*)"
                  className="p-3 bg-gray-800 text-gray-200 text-sm rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none placeholder-gray-500 h-32 resize-none"
                />
              </div>
              {/* Meta KeyWords section */}
              <div className="flex flex-col gap-3">
                <label className="text-sm text-white font-medium">
                  Meta Keywords*
                </label>
                <input
                  type="text"
                  value={metaKeywords}
                  onChange={(e) => setMetaKeywords(e.target.value)}
                  placeholder="Enter meta keywords, separated by commas (required*)"
                  className="p-3 bg-gray-800 text-gray-200 text-sm rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none placeholder-gray-500"
                />
              </div>
              <div className="flex flex-col gap-3">
                <label className="text-sm text-white font-medium">
                  Meta OpenGraph Image url
                </label>
                <input
                  type="text"
                  value={metaOgImage}
                  onChange={(e) => setMetaOgImage(e.target.value)}
                  placeholder="Enter Meta OpenGraph Image url (required*)"
                  className="p-3 bg-gray-800 text-gray-200 text-sm rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none placeholder-gray-500"
                />
              </div>
            </div>
            {/* the content section is the main section of the blog where all the thing will be added and edited  */}
            <div className="flex flex-col gap-6 bg-gray-900 p-4 rounded-lg shadow-md border border-gray-800">
              <div className="flex items-center gap-2">
                <label className="text-base text-white font-medium">
                  Content*
                </label>
                <div className="relative group">
                  <button
                    className="text-gray-400 hover:text-gray-200 focus:outline-none"
                    aria-label="Info about authors"
                  >
                    <FaInfoCircle className="w-5 h-5" />
                  </button>
                  <div className="absolute left-6 top-0 w-[500px] px-3 py-2 text-sm text-gray-200 bg-gray-800 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                    Select text and click the desired format button to apply it
                  </div>
                </div>
              </div>
              {/* Dropdown for Heading Selection */}
              <div className="flex flex-wrap items-center gap-3 mt-2">
                {/* Dropdown for Heading Selection */}
                <select
                  className="p-3 bg-gray-800 text-white text-sm border border-gray-600 rounded-md cursor-pointer hover:bg-gray-700 transition-all focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  onChange={handleHeadingChange}
                  defaultValue=""
                >
                  <option value="" disabled>
                    headings
                  </option>
                  <option value="h2">Heading 2</option>
                  <option value="h3">Heading 3</option>
                  <option value="h4">Heading 4</option>
                  <option value="h5">Heading 5</option>
                  <option value="h6">Heading 6</option>
                  <option value="p">Paragraph</option>
                  <option value="blockquote">Blockquote</option>
                </select>
                {/* Formatting Buttons */}
                {/* bold button to make the text bold */}
                <button
                  onClick={() => wrapSelectedText("b")}
                  className="p-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 transition-all focus:ring-2 focus:ring-blue-300"
                  aria-label="Bold"
                  title="To make the selected text bold"
                >
                  <FaBold />
                </button>
                {/* italic button to make the text italic */}
                <button
                  onClick={() => wrapSelectedText("i")}
                  className="p-2 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 transition-all focus:ring-2 focus:ring-green-300"
                  aria-label="Italic"
                  title="To make the selected text italic"
                >
                  <FaItalic />
                </button>
                {/* underline this button is used to make the text underline */}
                <button
                  onClick={() => wrapSelectedText("u")}
                  className="p-2 bg-yellow-500 text-black rounded-md shadow-sm hover:bg-yellow-600 transition-all focus:ring-2 focus:ring-yellow-300"
                  aria-label="Underline"
                  title="To make the selected text as underline"
                >
                  <FaUnderline />
                </button>
                {/* add link button this button is used to add a link to the selected text */}
                <button
                  onClick={() => setOpenLinkModal(true)}
                  className="p-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-700 transition-all focus:ring-2 focus:ring-red-300"
                  aria-label="Add Link"
                  title="To add a link to the selected text"
                >
                  <FaLink />
                </button>
                {/* add image button is a button that is used to open a modal and in the modal we can add the image url as weel as the alt tag of that image */}
                <button
                  onClick={() => setOpenImageModal(true)}
                  className="p-2 bg-purple-600 text-white rounded-md shadow-sm hover:bg-purple-700 transition-all focus:ring-2 focus:ring-purple-300"
                  aria-label="Add Image"
                  title="To add Image"
                >
                  <FaImage />
                </button>
                {/* this add video button is same like the image which on click open a modal where we can enter the url of the video we want */}
                <button
                  onClick={() => setOpenVideoModal(true)}
                  className="p-2 bg-orange-600 text-white rounded-md shadow-sm hover:bg-orange-700 transition-all focus:ring-2 focus:ring-orange-300"
                  aria-label="Add Video"
                  title="To add Video"
                >
                  <FaVideo />
                </button>

                {/* this button is used to add orderelist  */}
                <button
                  onClick={() => setOpenOrderedListModal(true)}
                  className="p-2 bg-teal-600 text-white rounded-md shadow-sm hover:bg-teal-700 transition-all focus:ring-2 focus:ring-teal-300"
                  aria-label="Add Ordered List"
                  title="Orderedlist (note: do not write all the list in a single line do add the list in seperate line)"
                >
                  <MdFormatListNumbered />
                </button>

                {/* this button is used to add unordered list  */}
                <button
                  onClick={() => setOpenUnorderedListModal(true)}
                  className="p-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 transition-all focus:ring-2 focus:ring-indigo-300"
                  aria-label="Add Unordered List"
                  title="Add Unordered List(same as the orderelist list)"
                >
                  <MdFormatListBulleted />
                </button>
                <button
                  onClick={() => setOpenTableModal(true)}
                  className="p-2 bg-teal-600 text-white rounded-md shadow-sm hover:bg-teal-700 transition-all focus:ring-2 focus:ring-teal-300"
                  aria-label="Add Table"
                  title="Add Table"
                >
                  <MdTableChart className="w-5 h-5" />
                </button>

                {/* this button is used to remove a tag  */}
                <button
                  onClick={removeTagFromSelection}
                  className="p-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-700 transition-all focus:ring-2 focus:ring-red-300"
                  aria-label="Remove Tag"
                  title="Remove any exisitng tag from the selected text"
                >
                  Remove Tag
                </button>
              </div>
              {/* content text area where all the blog data will be added  */}
              <textarea
                id="blogContent"
                value={content}
                onChange={handleChange(setContent)}
                onKeyDown={(e) => {
                  // Allow undo (Ctrl+Z or Cmd+Z) and redo (Ctrl+Y or Cmd+Y)
                  if (
                    (e.ctrlKey || e.metaKey) &&
                    (e.key === "z" || e.key === "y")
                  ) {
                    return; // Let the browser handle undo/redo
                  }

                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default newline behavior

                    const textarea = e.target;
                    const startPos = textarea.selectionStart;
                    const endPos = textarea.selectionEnd;

                    // Add <br> and break to the next line
                    const newText =
                      content.substring(0, startPos) +
                      "<br>\n" +
                      content.substring(endPos);

                    setContent(newText);

                    // Move cursor to the next line after <br>
                    setTimeout(() => {
                      textarea.selectionStart = textarea.selectionEnd =
                        startPos + 5; // 5 = "<br>\n".length
                    }, 0);
                  }
                }}
                onPaste={(e) => {
                  e.preventDefault(); // Prevent default paste behavior

                  // Get the pasted text from clipboard
                  const pastedText = e.clipboardData.getData("text");

                  // Check if the pasted content contains any HTML tags
                  const isHTML = /<[^>]+>/.test(pastedText);

                  let formattedText;
                  if (isHTML) {
                    // If HTML is detected, keep the existing logic
                    formattedText = pastedText
                      .split("\n") // Split by newlines
                      .map((line) => (line.trim() === "" ? "<br>" : line)) // Replace empty lines with <br>
                      .join("\n"); // Join the lines back
                  } else {
                    // If plain text, handle normalization for MS Word/Notepad
                    formattedText = pastedText
                      .split(/\r?\n/) // Split text by newlines (handles both \n and \r\n)
                      .map((line) =>
                        line.trim() === "" ? "<br>" : `${line}<br>`
                      ) // Add <br> to each line
                      .join(""); // Join the lines into a single string
                  }

                  const textarea = e.target;
                  const startPos = textarea.selectionStart;
                  const endPos = textarea.selectionEnd;

                  // Insert the formatted content into the existing content
                  const newText =
                    content.substring(0, startPos) +
                    formattedText +
                    content.substring(endPos);

                  setContent(newText);

                  // Move cursor to the end of the pasted content
                  setTimeout(() => {
                    textarea.selectionStart = textarea.selectionEnd =
                      startPos + formattedText.length;
                  }, 0);
                }}
                placeholder="Start writing your content"
                className="w-full h-[300px] p-4 bg-gray-800 text-gray-200 text-sm rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none placeholder-gray-500 "
              />
            </div>
            {/* Submit Button */}
            <div className="mb-4">
              <div className="flex gap-4 justify-end">
                <button
                  onClick={handleSubmit}
                  disabled={loading} // Disable button during loading
                  className={`flex items-center justify-center px-6 py-2 text-sm font-medium text-white rounded-lg shadow-md transition ${
                    loading
                      ? "cursor-not-allowed bg-blue-400"
                      : "bg-blue-600 hover:bg-blue-700"
                  }`}
                >
                  {loading ? (
                    <CircularProgress
                      size={20}
                      style={{
                        color: "white",
                        marginRight: "8px",
                      }}
                    />
                  ) : (
                    <CheckIcon className="mr-2" />
                  )}
                  {loading ? "Submitting..." : "Submit Blog"}
                </button>
              </div>
            </div>

            {/* add link to the title */}
            <LinkModal
              open={openTitleModal}
              handleClose={() => setOpenTitleModal(false)}
              label="Add Backlink to Selected Title Text"
              onSubmit={addTitleBacklink}
            />
            {/* modal to add link to any text */}
            <LinkModal
              open={openLinkModal}
              handleClose={() => setOpenLinkModal(false)}
              label="Add Hyperlink"
              onSubmit={addLink}
            />
            {/* add image with alt tag modal */}
            <BlogImageModal
              open={openImageModal}
              onClose={() => setOpenImageModal(false)}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              altText={altText}
              setAltText={setAltText}
              urlError={urlError}
              altTextError={altTextError}
              handleAddImage={handleAddImage}
            />
            {/* add video url with alt tag modal */}
            <BlogVideoModal
              open={openVideoModal}
              onClose={() => setOpenVideoModal(false)}
              videoUrl={videoUrl}
              setVideoUrl={setVideoUrl}
              altText={altText}
              setAltText={setAltText}
              videoUrlError={videoUrlError}
              altTextError={altTextError}
              handleAddVideo={handleAddVideo}
            />
            {/* orderelist model */}
            <OrderedListDialog
              open={openOrderedListModal}
              onClose={() => setOpenOrderedListModal(false)}
              orderedListType={orderedListType}
              setOrderedListType={setOrderedListType}
              orderedListStart={orderedListStart}
              setOrderedListStart={setOrderedListStart}
              handleAddOrderedList={handleAddOrderedList}
            />
            {/* Unordered List Modal */}
            <UnorderedListDialog
              open={openUnorderedListModal}
              onClose={() => setOpenUnorderedListModal(false)}
              unorderedListType={unorderedListType}
              setUnorderedListType={setUnorderedListType}
              handleAddUnorderedList={handleAddUnorderedList}
            />
            <TableModal
              open={openTableModal}
              onClose={() => setOpenTableModal(false)}
              handleAddTable={handleAddTable}
            />

            {/* snackbar */}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000} // Closes after 6 seconds
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        </div>
      )}

      {/* right section where all the blgo preview will be shown  */}
      {viewMode !== "editor" && (
        <div
          className={`${
            viewMode === "preview" ? "w-full" : "w-1/2"
          } p-4 bg-black rounded-lg overflow-y-auto max-h-screen flex flex-col`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-blue-400 text-center">
              Preview
            </h2>

            {/* Maximize/Minimize Button */}
            {viewMode !== "preview" && (
              <button
                onClick={() => toggleViewMode("preview")}
                className="p-2 bg-green-600 text-white rounded-full shadow-sm hover:bg-blue-700 transition-colors focus:ring-2 focus:ring-blue-400 focus:outline-none"
                title="Maximize Preview"
              >
                <AiOutlineExpand className="w-5 h-5" />
              </button>
            )}
            {viewMode === "preview" && (
              <button
                onClick={() => toggleViewMode("split")}
                className="p-2 bg-gray-600 text-white rounded-full shadow-sm hover:bg-gray-700 transition-colors focus:ring-2 focus:ring-gray-400 focus:outline-none"
                title="Minimize Preview"
              >
                <AiOutlineCompress className="w-5 h-5" />
              </button>
            )}
          </div>

          {/* BlogPreview Component */}
          <BlogPreview
            toc={toc}
            title={title}
            authors={authors}
            content={content}
            maximized={viewMode === "preview"}
          />
        </div>
      )}
    </div>
  );
}

// import React, { useState } from "react";
// import axios from "axios";
// import { Modal, Box, Typography, Button } from "@mui/material";

// const PostMarketView = () => {
//   const SERVER_URL = constaints.REACT_APP_SERVER_URL;
//   const [formData, setFormData] = useState({
//     title: "",
//     summary: "",
//     content: "",
//     videoUrl: "",
//     imageUrl: "",
//   });
//   const [open, setOpen] = useState(false);
//   const [modalMessage, setModalMessage] = useState("");

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post(`${SERVER_URL}/admin/market-view`, formData);
//       setModalMessage("Article posted successfully");
//       setOpen(true);
//       setFormData({
//         title: "",
//         summary: "",
//         content: "",
//         videoUrl: "",
//         imageUrl: "",
//       });
//     } catch (error) {
//       console.error("There was an error posting the article!", error);
//       setModalMessage("Failed to post article");
//       setOpen(true);
//     }
//   };

//   const handleClose = () => setOpen(false);

//   return (
//     <div className="post-article-form max-w-3xl mx-auto mt-10 p-8 bg-gray-900 text-white rounded-lg shadow-lg">
//       <h1 className="text-2xl font-bold mb-6 text-center text-indigo-400">
//         Post a New Article
//       </h1>
//       <form onSubmit={handleSubmit} className="space-y-6">
//         <div>
//           <label
//             htmlFor="title"
//             className="block text-sm font-medium text-gray-300"
//           >
//             Title
//           </label>
//           <input
//             type="text"
//             id="title"
//             name="title"
//             value={formData.title}
//             onChange={handleChange}
//             className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
//             required
//           />
//         </div>
//         <div>
//           <label
//             htmlFor="summary"
//             className="block text-sm font-medium text-gray-300"
//           >
//             Summary
//           </label>
//           <input
//             type="text"
//             id="summary"
//             name="summary"
//             value={formData.summary}
//             onChange={handleChange}
//             className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
//             required
//           />
//         </div>
//         <div>
//           <label
//             htmlFor="content"
//             className="block text-sm font-medium text-gray-300"
//           >
//             Content
//           </label>
//           <textarea
//             id="content"
//             name="content"
//             value={formData.content}
//             onChange={handleChange}
//             className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500 h-40"
//             required
//           />
//         </div>
//         <div>
//           <label
//             htmlFor="videoUrl"
//             className="block text-sm font-medium text-gray-300"
//           >
//             Video URL
//           </label>
//           <input
//             type="text"
//             id="videoUrl"
//             name="videoUrl"
//             value={formData.videoUrl}
//             onChange={handleChange}
//             className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
//           />
//         </div>
//         <div>
//           <label
//             htmlFor="imageUrl"
//             className="block text-sm font-medium text-gray-300"
//           >
//             Image URL
//           </label>
//           <input
//             type="text"
//             id="imageUrl"
//             name="imageUrl"
//             value={formData.imageUrl}
//             onChange={handleChange}
//             className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
//           />
//         </div>
//         <div className="flex justify-end">
//           <button
//             type="submit"
//             className="ml-auto py-2 px-6 bg-gradient-to-r from-green-600 to-green-600 hover:from-purple-500 hover:to-indigo-500 text-white font-semibold rounded-full shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-indigo-300 transition-all duration-300 transform hover:scale-105"
//           >
//             Post Article
//           </button>
//         </div>
//       </form>

//       <Modal open={open} onClose={handleClose}>
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: 300,
//             bgcolor: "background.paper",
//             boxShadow: 24,
//             p: 4,
//             borderRadius: 2,
//             textAlign: "center",
//           }}
//         >
//           <Typography variant="h6" component="h2">
//             {modalMessage}
//           </Typography>
//           <Button
//             onClick={handleClose}
//             sx={{ mt: 2 }}
//             variant="contained"
//             color={modalMessage.includes("successfully") ? "primary" : "error"}
//           >
//             Close
//           </Button>
//         </Box>
//       </Modal>
//     </div>
//   );
// };

// export default PostMarketView;
