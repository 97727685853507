import React from "react";
import { Doughnut } from "react-chartjs-2";
import "./NonHalalIncomeChart.css";

function NonHalalIncomeChart({ data }) {
  const nonHalalIncome = data.datasets[0].data[0]; // Assuming the first value is non-halal income
  const totalIncome = data.datasets[0].data.reduce((a, b) => a + b, 0); // Sum of all values in the data array
  const nonHalalIncomePercentage = (nonHalalIncome / totalIncome) * 100;

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "left",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 15,
            weight: "bold",
            color: "black",
          },
        },
      },
      tooltip: {
        // Add this section to configure tooltips
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed !== null) {
              label += `${context.parsed.toFixed(1)}%`; // Formats the tooltip value to 1 decimal place
            }
            return label;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="chart-and-data-container mt-2">
    <div className="chart-section_dougnut_chart">
      <Doughnut data={data} options={options} />
    </div>
    <div className="data-section">
    <h2 className="chart-title_shariahdetailspage">
        Non-Compliant Income To Total Income
      </h2>
      <p className="text-black">{nonHalalIncomePercentage.toFixed(1)}%</p>
    </div>
  
  </div>


  );
}

export default NonHalalIncomeChart;
