import React, { useState, useEffect, useContext } from "react"; // Import the useState, useEffect, and useContext hooks
import { NavLink } from "react-router-dom"; // Import NavLink from react-router-dom
import { AuthContext } from "../../Shared/context/auth-context"; // Import the AuthContext from the auth-context file
import useTheme from "../../hooks/Theme"; // Import the useTheme hook from the Theme.js file
import Logo from "../../icons/finsha_icon.svg"; // Import the light mode logo image
import LogoDark from "../../icons/finsha_icon_dark.svg"; // Import the dark mode logo image
import { constaints } from "../../utils/constaints";

export const MobileNavbar = () => {
  // Create a functional component called MobileNavbar
  const SERVER_URL = constaints.REACT_APP_SERVER_URL; // Set the SERVER_URL variable to the value of the REACT_APP_SERVER_URL environment variable
  const { theme, toggleTheme } = useTheme(); // Destructure the theme and toggleTheme variables from the useTheme hook
  const { isLoggedIn, user, logout } = useContext(AuthContext); // Destructure the isLoggedIn, user, and logout variables from the AuthContext
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Create a state variable called isMenuOpen and set its initial value to false

  /**
   * Toggles the mobile menu open/closed by changing the state of isMenuOpen
   */
  const toggleMenu = () => {
    // Create a function called toggleMenu
    setIsMenuOpen((prev) => !prev); // Toggle the value of isMenuOpen when the function is called
  };

  // useEffect hook to handle scroll behavior when the mobile menu is open/closed
  useEffect(() => {
    if (isMenuOpen) {
      // If the menu is open
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      // If the menu is closed
      document.body.style.overflow = ""; // Enable scroll
    }
    return () => {
      // Cleanup function to reset scroll behavior
      document.body.style.overflow = ""; // Enable scroll
    };
  }, [isMenuOpen]); // Run this effect when the isMenuOpen state changes

  // Return the JSX for the MobileNavbar component

  return (
    <div className="w-full bg-[var(--surface-container-low)] p-4">
      {" "}
      <div className="flex items-center justify-between">
        {" "}
        <button
          className="flex items-center justify-center w-10 h-10 text-xl font-medium text-[var(--on-surface)]"
          onClick={toggleMenu}
        >
          {" "}
          {!isLoggedIn ? (
            isMenuOpen ? (
              "✖"
            ) : (
              "☰"
            )
          ) : user?.profileImageURL ? (
            <img
              src={`${SERVER_URL}/${user.profileImageURL}`}
              alt="User Avatar"
              className="w-full h-full rounded-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center rounded-full bg-blue-500 text-white font-semibold">
              {user?.fname?.charAt(0).toUpperCase() || "U"}
            </div>
          )}
        </button>
        <NavLink to="/">
          <div className="flex items-center space-x-2">
            <img
              width={32}
              height={32}
              src={theme === "light" ? Logo : LogoDark}
              alt="Finsha Logo"
            />
            <span className="text-3xl font-semibold text-[var(--on-surface)]">
              insha
            </span>
          </div>
        </NavLink>
        <div></div>
      </div>
      {isMenuOpen && (
        <div
          className="fixed top-0 left-0 w-72 h-full bg-[var(--surface-container-low)] text-var(--on-surface); p-6 z-50 shadow-lg transform transition-transform duration-300 ease-in-out"
          style={{
            transform: isMenuOpen ? "translateX(0)" : "translateX(-100%)",
          }}
        >
          <div className="flex items-center space-x-2">
            <img
              width={32}
              height={32}
              src={theme === "light" ? Logo : LogoDark}
              alt="Finsha Logo"
            />
            <span className="text-3xl font-semibold text-[var(--on-surface)] ">
              insha
            </span>
          </div>

          <div className="flex items-center space-x-4 mb-4 mt-6">
            <span className="text-lg text-[var(--on-surface)]">
              {theme === "light" ? "Light Mode" : "Dark Mode"}
            </span>
            <button
              onClick={toggleTheme}
              className={`relative w-12 h-6 flex items-center bg-gray-300 dark:bg-gray-600 rounded-full p-1 transition-colors duration-300`}
            >
              <div
                className={`absolute w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                  theme === "light" ? "translate-x-1" : "translate-x-6"
                }`}
              ></div>
            </button>
          </div>

          <hr />
          <div className="mt-4 font-semibold text-[var(--on-surface)]">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `block text-lg ${
                  isActive
                    ? "text-blue-500 font-bold"
                    : "text-[var(--on-surface)]"
                } hover:text-blue-500 mb-4`
              }
              onClick={toggleMenu}
            >
              Home
            </NavLink>

            <NavLink
              to="/screener"
              className={({ isActive }) =>
                `block text-lg ${
                  isActive
                    ? "text-blue-500 font-bold"
                    : "text-[var(--on-surface)]"
                } hover:text-blue-500 mb-4`
              }
              onClick={toggleMenu}
            >
              Screener
            </NavLink>
            <NavLink
              to="/finview"
              className={({ isActive }) =>
                `block text-lg ${
                  isActive
                    ? "text-blue-500 font-bold"
                    : "text-[var(--on-surface)]"
                } hover:text-blue-500 mb-4`
              }
              onClick={toggleMenu}
            >
              FinView
            </NavLink>
            {/* <NavLink
              to="/pricing"
              className={({ isActive }) =>
                `block text-lg ${
                  isActive
                    ? "text-blue-500 font-bold"
                    : "text-[var(--on-surface)]"
                } hover:text-blue-500 mb-4`
              }
              onClick={toggleMenu}
            >
              Pricing
            </NavLink> */}
            {isLoggedIn && (
              <>
                <NavLink
                  to="/stock-idea"
                  className={({ isActive }) =>
                    `block text-lg ${
                      isActive
                        ? "text-blue-500 font-bold"
                        : "text-[var(--on-surface)]"
                    } hover:text-blue-500 mb-4`
                  }
                  onClick={toggleMenu}
                >
                  Stock Idea
                </NavLink>
                <NavLink
                  to="/user-portfolio"
                  className={({ isActive }) =>
                    `block text-lg ${
                      isActive
                        ? "text-blue-500 font-bold"
                        : "text-[var(--on-surface)]"
                    } hover:text-blue-500 mb-4`
                  }
                  onClick={toggleMenu}
                >
                  Portfolio
                </NavLink>
                <NavLink
                  to="/watchlist"
                  className={({ isActive }) =>
                    `block text-lg ${
                      isActive
                        ? "text-blue-500 font-bold"
                        : "text-[var(--on-surface)]"
                    } hover:text-blue-500 mb-4`
                  }
                  onClick={toggleMenu}
                >
                  Watchlist
                </NavLink>
                <NavLink
                  to="/user-profile"
                  className={({ isActive }) =>
                    `block text-lg ${
                      isActive
                        ? "text-blue-500 font-bold"
                        : "text-[var(--on-surface)]"
                    } hover:text-blue-500 mb-4`
                  }
                  onClick={toggleMenu}
                >
                  Account
                </NavLink>
              </>
            )}
            {isLoggedIn ? (
              <button
                onClick={logout}
                className="block w-full text-left text-lg text-red-500 hover:text-red-700"
              >
                Logout
              </button>
            ) : (
              <NavLink
                to="/auth"
                className="block text-lg text-green-500 hover:text-green-600"
                onClick={toggleMenu}
              >
                Login
              </NavLink>
            )}
          </div>
        </div>
      )}
      {/* Background Overlay */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 z-40 cursor-pointer"
          onClick={toggleMenu}
        ></div>
      )}
    </div>
  );
};
