

// LoginForm.js

import React from 'react';
import { Link } from "react-router-dom";
import { FaLockOpen } from 'react-icons/fa';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
// import "./LoginForm.css";

const LoginForm = ({ formState, inputHandler, authSubmitHandler }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailError, setEmailError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 5;
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    if (!validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
    inputHandler('email', newEmail);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    if (!validatePassword(newPassword)) {
      setPasswordError('Password must be at least 5 characters.');
    } else {
      setPasswordError('');
    }
    inputHandler('password', newPassword);
  };

  return (
    <div className="flex flex-col justify-center items-center p-4 rounded-lg sm:p-2">
    <h2 className="text-2xl md:text-2xl lg:text-3xl p-2 text-center font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-purple-700">
  Welcome back to Finsha! Please Login
</h2>

      <form onSubmit={(event) => authSubmitHandler(event)} className="w-full max-w-md flex flex-col bg-white shadow-lg p-4 sm:p-6 md:p-8 rounded-lg">
        <div className="mb-4">
        <div className="flex items-center mb-1">
  <EmailIcon className="mr-2" />
  <label htmlFor="email" className="block text-sm font-medium text-black">
    E-Mail
  </label>
</div>
          <input
            type="email"
            id="email"
            value={formState.inputs.email.value}
            onChange={handleEmailChange}
            required
            autoComplete="username"
            className={`w-full px-3 py-2 border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
          />
          {emailError && <span className="text-red-500 text-sm">{emailError}</span>}
        </div>
  
        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-black mb-1">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={formState.inputs.password.value}
              onChange={handlePasswordChange}
              required
              autoComplete="current-password"
              className={`w-full px-3 py-2 border ${passwordError ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
          </div>
          {passwordError && <span className="text-red-500 text-sm">{passwordError}</span>}
        </div>
  
        <button
  type="submit"
  className="w-48 py-2 mt-2 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-full hover:from-green-500 hover:to-blue-600 transition duration-200 self-center"
>
  LOGIN
</button>
 <div className="mt-4 text-center text-black">
        <FaLockOpen className="inline mr-2" /> Forgot Password/Create Password? <Link to="/forgot" className="text-red-500 hover:text-red-600 font-semibold">Click here</Link>
      </div>
      </form>
     
    </div>
  );
  
}  

export default LoginForm;



// import React from 'react';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import { Link } from "react-router-dom";
// import IconButton from '@mui/material/IconButton';
// import InputAdornment from '@mui/material/InputAdornment';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import { FaLockOpen } from 'react-icons/fa';
// import "./LoginForm.css"

// const LoginForm = ({ isLoginMode, formState, inputHandler, authSubmitHandler, otpSent , isOtpValidated }) => {
//   const [showPassword, setShowPassword] = React.useState(false);
//   const [emailError, setEmailError] = React.useState('');
// const [passwordError, setPasswordError] = React.useState('');


//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };
  
//   const validatePassword = (password) => {
//     return password.length >= 5; // Assuming the password needs to be at least 5 characters
//   };

//   const handleEmailChange = (event) => {
//     const newEmail = event.target.value;
//     if (!validateEmail(newEmail)) {
//       setEmailError('Please enter a valid email address.');
//     } else {
//       setEmailError('');
//     }
//     inputHandler('email', newEmail);
//   };
  
//   const handlePasswordChange = (event) => {
//     const newPassword = event.target.value;
//     if (!validatePassword(newPassword)) {
//       setPasswordError('Password must be at least 5 characters.');
//     } else {
//       setPasswordError('');
//     }
//     inputHandler('password', newPassword);
//   };

//   return (
//     <div className="login-form-container">
//       <h2 className='login-heading'> Welcome back to Finsha! Please Login</h2>
//       <form onSubmit={(event) => authSubmitHandler(event)} className="login-form">
//       <TextField
//   fullWidth
//   id="email"
//   label="E-Mail"
//   type="email"
//   variant="outlined"
//   margin="normal"
//   value={formState.inputs.email.value}
//   onChange={handleEmailChange}
//   required
//   autoComplete="username"
//   error={emailError.length > 0}
//   helperText={emailError}
//   className="MuiInputBase-input MuiOutlinedInput-input"
//   style={{ marginBottom: '20px' }}
// />
// <TextField
//   fullWidth
//   id="password"
//   label="Password"
//   type={showPassword ? "text" : "password"}
//   variant="outlined"
//   margin="normal"
//   value={formState.inputs.password.value}
//   onChange={handlePasswordChange}
//   required
//   autoComplete="current-password"
//   InputProps={{
//     endAdornment: (
//       <InputAdornment position="end">
//         <IconButton
//           aria-label="toggle password visibility"
//           onClick={() => setShowPassword(!showPassword)}
//           onMouseDown={handleMouseDownPassword}
//           edge="end"
//         >
//           {showPassword ? <VisibilityOff /> : <Visibility />}
//         </IconButton>
//       </InputAdornment>
//     ),
//   }}
//   error={passwordError.length > 0}
//   helperText={passwordError}
// />

//         <Button
//           type="submit"
//           variant="contained"
//           color="primary"
//           className="submit-button"
//           fullWidth
//           style={{ marginTop: 20 }}
//         >
//           LOGIN
//         </Button>
//       </form>
//       <div className="forgot-password-link">

//     <FaLockOpen style={{marginRight:"10px"}} /> Forgot Password/Create Password ? <Link to="/forgot">Click here</Link>
// </div>

//     </div>
//   );
// };

// export default LoginForm;
