import realTime from "../../images/real-time.png";
import targetStock from "../../images/target-stock.png";
import whatsapp from "../../images/whatsapp.png";
import investGrow from "../../images/invest-grow.jpeg";

import incomeCal from "../../images/income-calc.png";
import mosque from "../../images/mosque.png";
import halalInvest from "../../images/halal-invest.png";
import portfolioSync from "../../images/portfolio-sync.png";
import customAlerts from "../../images/alerts-cus.png";
import ethical from "../../images/ethical.png";

// export const users = [
//   {
//     image: "https://source.unsplash.com/150x150/?profile-pic",
//     username: "ChristopherJohnson",
//     description:
//       "Finsha is a very useful app with detailed information. The user interface is easy to use and has good features. It’s a much-needed service in Compliant finance.",
//     rating: 4.5,
//   },
//   {
//     image: "https://source.unsplash.com/150x150/?profile",
//     username: "CompliantInvestor",
//     description:
//       "Finsha has made investing so much easier for me. I love that it aligns with my faith and provides comprehensive Compliant stock . The Shariah compliance ranking is a game-changer!",
//     rating: 5.0,
//   },
// ];
export const users = [
  {
    // image: "https://source.unsplash.com/150x150/?profile-pic",
    username: "FaridHasan",
    description:
      "Mainne recently Finsha use karna shuru kiya hai for investing strategies, aur main sach mein impressed hoon by their work. Platform bohot user-friendly aur intuitive hai, aur innke strategies like portfolio diversification, amount allocation for stock, etc. financial planning ko bohot easy bana dete hain. Sab kuch clearly laid out hai, jo investments ko track karna simple bana deta hai. Plus, unka customer support bhi bohot responsive aur helpful hai. Highly recommend karta hoon!",
    rating: 4.8,
  },
  {
    // image: "https://source.unsplash.com/150x150/?profile",
    username: "RajorshiBhattacharjee",
    description:
      "I extend my heartfelt appreciation to Finsha for their service and support. Their dedication to guiding clients through the complexities of stocks market investment is truly commendable. The platforms are user-friendly, intuitive, and packed with features that make investment decisions easy and timely. The investment advice provided by their expert team is not only insightful but also relevant to individual needs for achieving their financial goals. Thank you, Finsha, for setting the standard in financial advisory services!",
    rating: 5.0,
  },
  {
    // image: "https://source.unsplash.com/150x150/?profile",
    username: "MonakMishra",
    description:
      "Finsha has completely transformed the way I approach stock market investing. Their service is not only affordable but incredibly efficient. I opted for their QuickWin annual plan, which starts at just Rs. 125 per month. Right after subscribing, I received a confirmation email and WhatsApp message detailing their strategy and how they operate. If you’re looking for a reliable advisory service, Finsha is the way to go!",
    rating: 4.7,
  },
  {
    // image: "https://source.unsplash.com/150x150/?user",
    username: "AbhilashSingh",
    description:
      "I've been using Finsha's investment advisory services for a few months now, and I must say, I’m impressed! Their strategy is clear, and they send timely updates during market hours, including all the crucial details like target prices, stop losses, and holding periods. What really stands out is their personalized touch; if you add your capital investment on their website, they even suggest the exact number of shares to buy. It’s a service that truly caters to both beginners and seasoned investors.",
    rating: 4.9,
  },
  {
    // image: "https://source.unsplash.com/150x150/?profile",
    username: "AadilMirza",
    description:
      "Finsha’s stock market advisory service has been a game-changer for me. I subscribed to their QuickWin quarterly plan, and the value I’ve received is incredible. The subscription process was seamless, with multiple payment options, including cards and UPI. Right after subscribing, I got a detailed message explaining their approach to recommendations. They send precise buy ranges, targets, stop loss, and even the holding period directly to my WhatsApp and email. Really recommended to subscribe!",
    rating: 5.0,
  },
  {
    // image: "https://source.unsplash.com/150x150/?user",
    username: "AshwaniBokadia",
    description:
      "Finsha is hands down the best investment advisory service I’ve tried. What sets them apart is they calculate the number of shares I should buy based on my investment amount, which saves me a lot of time and effort. The instant updates on WhatsApp when a target is hit or a stop loss is triggered are just icing on the cake. Highly recommend Finsha!",
    rating: 4.8,
  },
  {
    // image: "https://source.unsplash.com/150x150/?profile",
    username: "SarfarazAhmed",
    description:
      "Finsha’s service is nothing short of amazing. What I really appreciate is their advice based on my capital investment, helping me decide exactly how many shares to buy. Their immediate alerts when it’s time to close a position have saved me from potential losses more than once. Finsha is a must-try for anyone serious about stock market investing.",
    rating: 4.9,
  },
];


export const features = [
  {
    id: 1,
    text: "Predefined Entry, Target and Stoploss",
    imgSrc: targetStock,
    altText: "targetStock",
  },
  {
    id: 2,
    text: "Instant WhatsApp Alerts",
    imgSrc: whatsapp,
    altText: "whatsapp",
  },
  {
    id: 3,
    text: "Get-Buy-Hold-Sell Directions",
    imgSrc: investGrow,
    altText: "invest & grow",
  },
  {
    id: 4,
    text: "Real-time StockIdeas Tracking",
    imgSrc: realTime,
    altText: "realTime",
  },
];

export const CardData = [
  {
    imgSrc: mosque,
    altText: "mosque",
    title: "Sharia-Compliant Investments",
    description:
      "Receive recommendations for stocks that adhere strictly to Islamic financial principles.",
  },
  {
    imgSrc: incomeCal,
    altText: "incomeCal",
    title: "Automated Purgable Income Calculation",
    description:
      "Easily calculate the amount that should be purified from investment income to maintain shariah compliance.",
  },
  {
    imgSrc: halalInvest,
    altText: "CompliantInvest",
    title: "Compliant Investment Tracking",
    description:
      "Access the Compliant status of each investment through the 'StockIdea' section of our website.",
  },
  {
    imgSrc: portfolioSync,
    altText: "portfolioSync",
    title: "Portfolio Synchronization",
    description:
      "Update your portfolio with actual investments to accurately reflect your Compliant financial status.",
  },
  {
    imgSrc: customAlerts,
    altText: "customAlerts",
    title: "Customizable Alerts",
    description:
      "Set target and stop loss prices to help you make timely decisions based on financial insight and religious guidelines.",
  },
  {
    imgSrc: ethical,
    altText: "ethical",
    title: "Dedicated Ethical Guidance",
    description:
      "Benefit from advice from experts familiar with the Sharia-Compliant investing.",
  },
];
