import React, { useState } from "react";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";

export default function MobileToc({ toc, onTocClick }) {
  const [isTocOpen, setIsTocOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsTocOpen(open);
  };

  return (
    <div>
      {/* Conditional Rendering for Menu Button */}
      {!isTocOpen && (
        <button
          aria-label="Open Table of Contents"
          onClick={toggleDrawer(true)}
          className="fixed top-[90px] left-1 z-[1000px] bg-[var(--primary-variant)] text-[var(--on-primary-variant)] rounded-full p-2 shadow-lg hover:bg-gray-600 hover:scale-105 transition-all "
        >
          <MenuIcon />
        </button>
      )}

      {/* Drawer */}
      {isTocOpen && (
        <div className="fixed top-0 left-0 w-[350px] h-screen bg-[var(--surface)] text-[var(--on-surface)] z-[1000] shadow-xl flex flex-col ">
          {/* Drawer Header */}
          <div className="flex items-center justify-between px-6 py-2 border-b border-gray-700">
            <h2 className="text-xl font-bold">Content to Visit</h2>
            <button
              aria-label="Close Table of Contents"
              onClick={toggleDrawer(false)}
              className="text-[var(--on-surface)] hover:text-[var(--on-surface-variant)] transition-all"
            >
              <CloseIcon />
            </button>
          </div>

          {/* Drawer List */}
          <ul className="flex-1 overflow-y-auto">
            {toc.map((item) => (
              <li key={item.id} className="border-b border-gray-700">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    onTocClick(item.id);
                    setIsTocOpen(false); // Close drawer after clicking
                  }}
                  className="w-full text-left px-6 py-3 text-[var(--on-surface)] hover:bg-gray-700 hover:text-[var(--on-surface-variant)] transition-all"
                >
                  {item.text}
                </button>
              </li>
            ))}
          </ul>

          {/* Footer */}
          <div className="px-6 py-4 border-t border-gray-700">
            <p className="text-sm text-[var(--on-surface-low)]">
              Use the menu to navigate through sections of the article.
            </p>
          </div>
        </div>
      )}

      {/* Overlay */}
      {isTocOpen && (
        <div
          className="fixed inset-0 bg-[var(--surface)] bg-opacity-50 z-30"
          onClick={toggleDrawer(false)}
        ></div>
      )}
    </div>
  );
}
