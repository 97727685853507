import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./IpoDetailData.css";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShariahComplianceCalculator from "./ShariahComplianceStatus";
import RatingStarComponent from "../RatingStar/RatingStarComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NonHalal_IncomeIpo from "./IpoShariahDetails/NonHalal_IncomeIpo";
import TotalLoanIpo from "./IpoShariahDetails/TotalLoanIpo";
import TotalInvestmentIpo from "./IpoShariahDetails/TotalInvestmentIpo";
import InfoIcon from "@mui/icons-material/Info";
import EventIcon from "@mui/icons-material/Event";
import axios from "axios";
import { constaints } from "../../../utils/constaints";

// import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";

function IpoDetailData() {
  const { companyName } = useParams();
  const [company, setCompany] = useState(null);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const [activeComponent, setActiveComponent] = useState("NonHalal_IncomeIpo");
  const [showTooltip, setShowTooltip] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the view is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Mobile view if width is less than 768px
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Update on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup event listener
    };
  }, []);

  const isActive = (buttonName) => {
    return activeComponent === buttonName;
  };

  const getStatusClass = (status) => {
    if (status.toLowerCase() === "yes") {
      return "bg-gradient-to-r from-green-400 to-green-600 text-white font-semibold rounded-lg p-1 w-[100px] text-center";
    } else {
      return "bg-gradient-to-r from-red-400 to-red-600 text-white font-semibold rounded-lg p-2  w-[100px] text-center";
    }
  };

  //replace fetch with axios
  useEffect(() => {
    const formattedCompanyName = companyName.replace(/-/g, " ");
    axios
      .get(`${SERVER_URL}/finview/ipo/name/${formattedCompanyName}`, {
        withCredentials: true,
      })
      .then((response) => {
        setCompany(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [companyName]);

  // share link funtion
  const shareIpo = async () => {
    if (navigator.share) {
      try {
        // Get the current page URL
        let modifiedUrl = window.location.href.replace(/%20/g, " ");
        // Replace %20 with _ using a regular expression
        await navigator.share({
          title: `Ipo details of ${companyName}`, // Optional: You can set a title for the shared content
          url: modifiedUrl, // Share the modified page URL
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert("Your browser doesn't support the Web Share API");
    }
  };

  // copu clipbord to copy and shae the link of the report which he/she visited
  const copyIpoLink = async () => {
    try {
      // Get the current page URL without replacing %20 with _
      let url = window.location.href;

      await navigator.clipboard.writeText(url);
      setCopied(true); // Set copied state to true
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (error) {
      console.error(error);
    }
  };

  if (!company) {
    return <div className="ipo_container">Loading...</div>;
  }

  function formatDate(isoDateString) {
    if (!isoDateString) return "Date not available"; // Check if the date string is present
    const date = new Date(isoDateString);
    if (isNaN(date.getTime())) return "Invalid Date"; // Check if the date is valid
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(/\//g, "-");
  }

  const formatPostDate = (isoDateString) => {
    if (!isoDateString) return "Date and time not available";
    const date = new Date(isoDateString);
    if (isNaN(date.getTime())) return "Invalid Date and Time";

    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate.replace(/,/, "")} at ${formattedTime}`;
  };

  function formatDate(isoDateString) {
    if (!isoDateString) return "Date not available"; // Check if the date string is present
    const date = new Date(isoDateString);
    if (isNaN(date.getTime())) return "Invalid Date"; // Check if the date is valid
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(/ /g, " ");
  }

  return (
    <div className="bg-[var(--surface)]">
      <div className="absolute mt-[130px] text-white flex justify-center gap-6 top-0 right-10">
        {navigator.share && (
          <ShareIcon
            className="text-[var(--primary-variant)] hover:text-[var(--primary)] cursor-pointer"
            onClick={shareIpo}
          />
        )}
        {copied ? (
          <CheckCircleIcon className="text-green-500" />
        ) : (
          <ContentCopyIcon
            className="text-[var(--primary-variant)] hover:text-[var(--primary)] cursor-pointer"
            onClick={copyIpoLink}
          />
        )}
      </div>

      <div className="bg-[var(--surface)] p-2">
        {company ? (
          <div className="bg-[var(--surface)] shadow-md rounded-lg p-4">
            <div className=" pb-4 mb-4">
              <h3 className="text-2xl font-bold text-[var(--on-surface)]">
                {company.companyName}
              </h3>
              <p className="text-[var(--primary)] text-semibold">
                {company.industry}
              </p>
              <div className="flex flex-col text-[var(--on-surface-low)] text-sm space-y-2">
                <div>
                  <p>Posted on: {formatPostDate(company.createdAt)}</p>
                </div>
                <div className="w-full flex justify-center sm:justify-start">
                  <p className="bg-green-500 text-white px-2 py-1 rounded font-semibold max-w-xs">
                    {company.exchange}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap justify-between border-b border-[var(--on-surface)] text-[var(--on-surface)] text-sm mt-4">
              <div className="text-center w-1/2 sm:w-1/3 lg:w-1/5 p-2">
                <h4 className="font-semibold text-[var(--primary)]">
                  Issue Price (Max)
                </h4>
                <p className="text-[var(--on-surface-variant)]">
                  ₹ {company.issuePriceMax}
                </p>
              </div>
              <div className="text-center w-1/2 sm:w-1/3 lg:w-1/5 p-2">
                <h4 className="font-semibold text-[var(--primary)]">
                  Min. Investment
                </h4>
                <p className="text-[var(--on-surface-variant)]">
                  {company.minimumRetailInvestment}
                </p>
              </div>
              <div className="text-center w-1/2 sm:w-1/3 lg:w-1/5 p-2">
                <h4 className="font-semibold text-[var(--primary)]">
                  Issue Size
                </h4>
                <p className="text-[var(--on-surface-variant)]">
                  {company.issueSize}
                </p>
              </div>

              <div className="text-center w-1/2 sm:w-1/3 lg:w-1/5 p-2">
                <h4 className="font-semibold text-[var(--primary)]">
                  Lot Size
                </h4>
                <p className="text-[var(--on-surface-variant)]">
                  {company.lotSize}
                </p>
              </div>
              <div className="text-center w-1/2 sm:w-1/3 lg:w-1/5 p-2 relative">
                <div className="flex items-center justify-center">
                  <h4 className="font-semibold text-[var(--primary)]">GMP</h4>
                  <InfoIcon
                    className="ml-2 cursor-pointer text-[var(--primary)]"
                    onClick={() => setShowTooltip(!showTooltip)}
                    style={{ fontSize: "18px" }}
                  />
                </div>
                {showTooltip && (
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-48 p-2 bg-[var(--primary)] text-[var(--on-primary)] text-xs rounded shadow-lg">
                    GMP stands for Grey Market Premium
                  </div>
                )}
                <p className="text-[var(--on-surface-low)]">
                  {company.expectedListingProfit}
                </p>
              </div>
            </div>

            <div className="mt-2 rounded-lg p-4 border-b border-[var(--on-surface)]">
              <h3 className="text-xl font-semibold mb-4 flex items-center text-[var(--on-surface)]">
                <EventIcon className="mr-2 text-[var(--on-surface)]" /> IPO Date
              </h3>
              <div
                className={`flex ${
                  isMobile ? "flex-col" : "flex-row"
                } items-center justify-between text-[var(--on-surface)]`}
              >
                {/* Opening Date */}
                <div className="flex flex-col items-center w-32 md:w-48">
                  <h4 className="font-semibold w-32 text-[var(--on-surface)] text-center">
                    Opening Date
                  </h4>
                  <p className="text-[var(--on-surface-low)] text-center">
                    {company.openingDate
                      ? formatDate(company.openingDate)
                      : "N/A"}
                  </p>
                </div>

                {/* Timeline */}
                <div
                  className={`relative flex ${
                    isMobile ? "flex-col" : "flex-row"
                  } items-center mx-4 ${
                    isMobile ? "h-32" : "h-auto w-full"
                  } mt-4 md:mt-0`}
                >
                  {/* Start Dot */}
                  <div
                    className={`absolute ${
                      isMobile ? "top-0" : "left-0"
                    } flex items-center justify-center`}
                  >
                    <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse"></div>
                  </div>

                  {/* Line */}
                  <div
                    className={`${
                      isMobile ? "w-1 h-full" : "w-full h-1"
                    } bg-green-300`}
                  ></div>

                  {/* End Dot */}
                  <div
                    className={`absolute ${
                      isMobile ? "bottom-0" : "right-0"
                    } flex items-center justify-center`}
                  >
                    <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse"></div>
                  </div>
                </div>

                {/* Closing Date */}
                <div className="flex flex-col items-center w-32 md:w-48">
                  <h4 className="font-semibold w-32 text-[var(--on-surface)] text-center">
                    Closing Date
                  </h4>
                  <p className="text-[var(--on-surface-low)]">
                    {company.closeDate ? formatDate(company.closeDate) : "N/A"}
                  </p>
                </div>

                {/* Timeline */}
                <div
                  className={`relative flex ${
                    isMobile ? "flex-col" : "flex-row"
                  } items-center mx-4 ${
                    isMobile ? "h-32" : "h-auto w-full"
                  } mt-4 md:mt-0`}
                >
                  {/* Start Dot */}
                  <div
                    className={`absolute ${
                      isMobile ? "top-0" : "left-0"
                    } flex items-center justify-center`}
                  >
                    <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse"></div>
                  </div>

                  {/* Line */}
                  <div
                    className={`${
                      isMobile ? "w-1 h-full" : "w-full h-1"
                    } bg-green-300`}
                  ></div>

                  {/* End Dot */}
                  <div
                    className={`absolute ${
                      isMobile ? "bottom-0" : "right-0"
                    } flex items-center justify-center`}
                  >
                    <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse"></div>
                  </div>
                </div>

                {/* Listing Date */}
                <div className="flex flex-col items-center w-32 md:w-48">
                  <h4 className="font-semibold w-32 text-[var(--on-surface)] text-center">
                    Listing Date
                  </h4>
                  <p className="text-[var(--on-surface-low)]">
                    {company.listingDate
                      ? formatDate(company.listingDate)
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-2 p-4 border-b border-[var(--on-surface)]">
              <h3 className="text-xl font-semibold mb-4 text-[var(--on-surface)]">
                Business Model
              </h3>
              {company.businessModel.businessModel1 && (
                <p className="mb-2 text-[var(--on-surface-variant)]">
                  {company.businessModel.businessModel1}
                </p>
              )}
              {company.businessModel.businessModel2 && (
                <p className="mb-2 text-[var(--on-surface-variant)]">
                  {company.businessModel.businessModel2}
                </p>
              )}
              {company.businessModel.businessModel3 && (
                <p className="mb-2 text-[var(--on-surface-variant)]">
                  {company.businessModel.businessModel3}
                </p>
              )}
            </div>

            {company.investmentProspective && (
              <div className="mt-1 p-2">
                <h3 className="text-xl font-semibold mb-4 text-[var(--on-surface)]">
                  Investment Prospective
                </h3>
                <p className="text-[var(--on-surface-variant)]">
                  {company.investmentProspective}
                </p>
              </div>
            )}

            <div className="mt-2 flex flex-col md:flex-row justify-between items-start space-x-0 md:space-x-1 ">
              <div className="flex flex-col flex-1 mt-1 ml-2 md: space-y-2 w-full">
                <div className="flex flex-col items-center p-8 border border-[var(--on-surface)] rounded-lg w-full">
                  <h6 className="text-[22px] font-semibold text-[var(--on-surface)]">
                    Finsha Rating
                  </h6>
                  <div className="p-4">
                    <RatingStarComponent value={company.rating} size="40px" />
                  </div>
                </div>
                <div className="flex flex-col items-center p-8 border border-[var(--on-surface)] rounded-lg w-full">
                  <h2 className="text-xl font-semibold mb-4 text-[var(--on-surface)]">
                    Business Permissibility
                  </h2>
                  <h3
                    className={`text-lg ${getStatusClass(
                      company.shariahCompliance.businessPermissibilityStatus
                    )}`}
                  >
                    {company.shariahCompliance.businessPermissibilityStatus}
                  </h3>
                </div>
              </div>

              <div
                className="flex flex-col items-center flex-1 w-full space-y-4"
                style={{
                  padding: "4px", // Tailwind class "p-1"
                  marginTop: "4px", // Tailwind class "mt-1"
                  marginLeft: "0", // Tailwind class "md:ml-0"
                }}
              >
                <ShariahComplianceCalculator
                  shariahCompliance={company.shariahCompliance}
                />
              </div>
            </div>

            <div className="container mx-auto mt-4 p-4 flex flex-col md:flex-row gap-6">
              {/* Sidebar Buttons */}
              <div className="w-full md:w-1/4">
                <div className="flex flex-col space-y-4">
                  <button
                    className={`w-full px-3 py-2 text-center text-xs sm:text-sm md:text-base font-semibold rounded-full shadow-md transition-all duration-300 ${
                      isActive("NonHalal_IncomeIpo")
                        ? "bg-[var(--primary-variant)] text-[var(--on-primary-variant)] scale-105"
                        : "bg-[var(--neutral)] text-[var(--on-neutral)] border-2 border-[var(--on-surface)]"
                    }`}
                    onClick={() => setActiveComponent("NonHalal_IncomeIpo")}
                  >
                    NON-COMPLIANT INCOME
                  </button>
                  <button
                    className={`w-full px-3 py-2 text-center text-xs sm:text-sm md:text-base font-semibold rounded-full shadow-md transition-all duration-300 ${
                      isActive("TotalLoanIpo")
                        ? "bg-[var(--primary-variant)] text-[var(--on-primary-variant)] scale-105"
                        : "bg-[var(--neutral)] text-[var(--on-neutral)] border-2 border-[var(--on-surface)]"
                    }`}
                    onClick={() => setActiveComponent("TotalLoanIpo")}
                  >
                    TOTAL LOAN
                  </button>
                  <button
                    className={`w-full px-3 py-2 text-center text-xs sm:text-sm md:text-base font-semibold rounded-full shadow-md transition-all duration-300 ${
                      isActive("TotalInvestmentIpo")
                        ? "bg-[var(--primary-variant)] text-[var(--on-primary-variant)] scale-105"
                        : "bg-[var(--neutral)] text-[var(--on-neutral)] border-2 border-[var(--on-surface)]"
                    }`}
                    onClick={() => setActiveComponent("TotalInvestmentIpo")}
                  >
                    TOTAL INVESTMENT
                  </button>
                </div>
              </div>

              {/* Content Area */}
              <div className="w-full md:w-3/4 bg-[var(--surface)] shadow-lg rounded-lg ">
                {activeComponent === "NonHalal_IncomeIpo" && (
                  <NonHalal_IncomeIpo
                    interestToRevenue={
                      company.shariahCompliance.interestToRevenue
                    }
                  />
                )}
                {activeComponent === "TotalLoanIpo" && (
                  <TotalLoanIpo
                    loanToMarketCap={company.shariahCompliance.loanToMarketCap}
                  />
                )}
                {activeComponent === "TotalInvestmentIpo" && (
                  <TotalInvestmentIpo
                    investmentToMarketCap={
                      company.shariahCompliance.investmentToMarketCap
                    }
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <p>Company not found.</p>
        )}
      </div>
    </div>
  );
}

export default IpoDetailData;
