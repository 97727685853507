import React from "react";
import compliantLogo from "../../../images/Compliant.png";
import nonCompliantLogo from "../../../images/Non-compliant.png";
import undefinedImage from "../../../images/excalmatorymark.png"; // Adjust the path

function ShariahComplianceCalculator({ shariahCompliance }) {
  const calculateComplianceStatus = () => {
    if (
      !shariahCompliance ||
      shariahCompliance.businessPermissibilityStatus !== "Yes"
    ) {
      return {
        status: "Non-Compliant",
        color: "orange",
        image: nonCompliantLogo,
      };
    }

    const { interestToRevenue, loanToMarketCap, investmentToMarketCap } =
      shariahCompliance;

    if (
      (interestToRevenue === 0 &&
        loanToMarketCap === 0 &&
        investmentToMarketCap === 0) ||
      (interestToRevenue === 0 && loanToMarketCap === 0) ||
      (interestToRevenue === 0 && investmentToMarketCap === 0) ||
      (loanToMarketCap === 0 && investmentToMarketCap === 0)
    ) {
      return { status: "Undefined", color: "blue", image: undefinedImage };
    } else if (
      interestToRevenue < 0.05 &&
      loanToMarketCap < 0.33 &&
      investmentToMarketCap < 0.33
    ) {
      return { status: "Compliant", color: "green", image: compliantLogo };
    } else {
      return {
        status: "Non-Compliant",
        color: "orange",
        image: nonCompliantLogo,
      };
    }
  };

  const { status, color, image } = calculateComplianceStatus();

  return (
    <div
      className={`p-2 rounded-lg w-[100%] ${
        status === "Compliant"
          ? "border-[var(--on-surface)]"
          : status === "Non-Compliant"
          ? "border-[var(--on-surface)]"
          : "border-[var(--on-surface)]"
      }`}
      style={{
        borderWidth: "1px",
      }}
    >
      <h6
        className="text-xl font-semibold text-center mt-0 p-2 text-[var(--on-surface)]"
        style={{
          marginTop: "0",
        }}
      >
        Compliance Status
      </h6>
      <div
        className="mt-2"
        style={{
          marginTop: "8px",
        }}
      >
        <img
          className="mx-auto"
          src={image}
          alt={status}
          style={{
            width: "200px", // Set the width for w-25
            height: "200px", // Set the height for h-25
          }}
        />
      </div>

      <div
        className="flex justify-center items-center mt-2"
        style={{
          marginTop: "8px",
        }}
      >
        <p
          className={`border-2 px-2 py-1 rounded inline-block ${
            status === "Compliant"
              ? "text-[var(--primary-variant)] border-green-500"
              : status === "Non-Compliant"
              ? "text-orange-500 border-orange-500"
              : "text-blue-500 border-blue-500"
          }`}
        >
          {status}
        </p>
      </div>
    </div>
  );
}

export default ShariahComplianceCalculator;
