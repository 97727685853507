import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { Modal, Box, Typography } from "@mui/material";
import { constaints } from "../../../../utils/constaints";

const AddCompanyData = () => {
  const [mode, setMode] = useState(""); // State to determine which mode (manual or file) is active
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    "Company Name": "",
    NSE_symbol: "",
    NSE_Symbol_NS: "",
    "BSE scrip id": "",
    BSE_SYMBOL_COLLON: "",
    "Outstanding Shares": 0,
    EPS: 0,
    "BV PS": 0,
    "Debt to Equity": 0,
    "Sales Growth": 0,
    "Profit Growth": 0,
    "Receivables turnover ratio": 0,
    "Inventory Turnover Ratio": 0,
    FCFE: 0,
    FCFF: 0,
    "FCFF (PS)": 0,
    "CF (PS)": 0,
    "Business Permissiblity Status": "",
    "Business Sector": "",
    "Business 1": "",
    "Business 2": "",
    "Business 3": "",
    "Interest to Revenue": 0,
    "Loan to Market Cap": 0,
    "Investment to Market Cap": 0,
    "Final Status": "",
    Ratings: 0,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const SERVER_URL =constaints.REACT_APP_SERVER_URL;

  // Handle switching between modes
  const switchMode = (newMode) => {
    setMode(newMode);
    setUploadMessage("");
    setFormData({
      "Company Name": "",
      NSE_symbol: "",
      NSE_Symbol_NS: "",
      "BSE scrip id": "",
      BSE_SYMBOL_COLLON: "",
      "Outstanding Shares": 0,
      EPS: 0,
      "BV PS": 0,
      "Debt to Equity": 0,
      "Sales Growth": 0,
      "Profit Growth": 0,
      "Receivables turnover ratio": 0,
      "Inventory Turnover Ratio": 0,
      FCFE: 0,
      FCFF: 0,
      "FCFF (PS)": 0,
      "CF (PS)": 0,
      "Business Permissiblity Status": "",
      "Business Sector": "",
      "Business 1": "",
      "Business 2": "",
      "Business 3": "",
      "Interest to Revenue": 0,
      "Loan to Market Cap": 0,
      "Investment to Market Cap": 0,
      "Final Status": "",
      Ratings: 0,
    });
    setFile(null);
  };

  useEffect(() => {
    if (uploadMessage) {
      setIsModalOpen(true);
    }
  }, [uploadMessage]);

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Handle file change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setUploadMessage("Please select a file to upload.");
      return;
    }

    setIsUploading(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(sheet);

      try {
        const response = await axios.post(
          `${SERVER_URL}/admin/companies/upload`,
          jsonData
        );
        if (response.status === 200) {
          setUploadMessage(
            `Success! ${response.data.successCount} companies added. ${response.data.duplicateCount} duplicates were found.`
          );
        }
      } catch (error) {
        console.error("Error uploading data:", error);
        if (error.response && error.response.status === 400) {
          setUploadMessage(`Error: ${error.response.data.message}`);
        } else {
          setUploadMessage(
            `Error uploading data: ${
              error.response?.data?.message || error.message
            }`
          );
        }
      } finally {
        setIsUploading(false);
        setFile(null); // Clear the file input after upload
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    try {
      const response = await axios.post(
        `${SERVER_URL}/admin/companies/upload`,
        [formData]
      );
      if (response.status === 200) {
        setUploadMessage("Company added successfully!");
      }
    } catch (error) {
      console.error("Error adding company:", error);
      if (error.response && error.response.status === 400) {
        setUploadMessage(error.response.data.message);
      } else {
        setUploadMessage(
          `Error adding company: ${
            error.response?.data?.message || error.message
          }`
        );
      }
    } finally {
      setIsUploading(false);
      setFormData({
        "Company Name": "",
        NSE_symbol: "",
        NSE_Symbol_NS: "",
        "BSE scrip id": "",
        BSE_SYMBOL_COLLON: "",
        "Outstanding Shares": 0,
        EPS: 0,
        "BV PS": 0,
        "Debt to Equity": 0,
        "Sales Growth": 0,
        "Profit Growth": 0,
        "Receivables turnover ratio": 0,
        "Inventory Turnover Ratio": 0,
        FCFE: 0,
        FCFF: 0,
        "FCFF (PS)": 0,
        "CF (PS)": 0,
        "Business Permissiblity Status": "",
        "Business Sector": "",
        "Business 1": "",
        "Business 2": "",
        "Business 3": "",
        "Interest to Revenue": 0,
        "Loan to Market Cap": 0,
        "Investment to Market Cap": 0,
        "Final Status": "",
        Ratings: 0,
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-2 rounded-lg shadow-lg text-white bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 mt-4">
      <h2 className="text-2xl font-bold mb-6">Add New Companies</h2>
      <div className="flex justify-center space-x-4 mb-6">
        <button
          onClick={() => switchMode("manual")}
          className={`px-4 py-2 rounded-full font-semibold transition-all duration-300 ${
            mode === "manual"
              ? "bg-gradient-to-r from-blue-600 to-blue-800 text-white"
              : "bg-gradient-to-r from-blue-400 to-blue-600 text-white hover:from-blue-500 hover:to-blue-700"
          } shadow-xl focus:outline-none transform hover:scale-105`}
        >
          Add Manually
        </button>
        <button
          onClick={() => switchMode("file")}
          className={`px-4 py-2 rounded-full font-semibold transition-all duration-300 ${
            mode === "file"
              ? "bg-gradient-to-r from-green-600 to-green-800 text-white"
              : "bg-gradient-to-r from-green-400 to-green-600 text-white hover:from-green-500 hover:to-green-700"
          } shadow-xl focus:outline-none transform hover:scale-105`}
        >
          Upload Excel File
        </button>
      </div>

      {mode === "file" && (
        <div className="w-full max-w-lg bg-gray-900 p-6 rounded-lg shadow-md transform hover:shadow-xl transition-shadow duration-300">
          <label
            htmlFor="file-upload"
            className="block text-sm font-medium text-gray-400 mb-2"
          >
            Choose an Excel file (.xlsx, .xls)
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            className="mb-4 w-full p-3 border border-gray-700 rounded-lg bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
            accept=".xlsx, .xls"
            aria-label="File upload input"
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              onClick={handleFileUpload}
              className={`w-auto px-4 py-2 mt-2 rounded-full font-semibold text-white transition-all duration-300 ${
                isUploading
                  ? "bg-gray-700 cursor-not-allowed"
                  : "bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              } shadow-lg transform hover:scale-105 focus:scale-105`}
              disabled={isUploading}
              aria-disabled={isUploading}
            >
              {isUploading ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 mr-2 inline-block text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Uploading...
                </>
              ) : (
                "Upload File"
              )}
            </button>
          </div>
        </div>
      )}

      {mode === "manual" && (
        <form
          onSubmit={handleFormSubmit}
          className="w-full bg-gray-900 p-2 rounded-lg shadow-md transform hover:shadow-xl transition-shadow duration-300"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {Object.keys(formData).map((key) => (
              <div key={key} className="mb-4">
                <label
                  className="block text-gray-400 text-sm font-medium mb-2"
                  htmlFor={key}
                >
                  {key.replace(/_/g, " ")}
                </label>
                <input
                  type={typeof formData[key] === "number" ? "number" : "text"}
                  id={key}
                  value={formData[key]}
                  onChange={(e) =>
                    setFormData({ ...formData, [key]: e.target.value })
                  }
                  className="w-full p-2 border border-gray-700 rounded-lg bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                  required
                />
              </div>
            ))}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              type="submit"
              className={`w-auto px-4 py-2 rounded-full font-semibold text-white transition-all duration-300 ${
                isUploading
                  ? "bg-gray-700 cursor-not-allowed"
                  : "bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              } shadow-lg transform hover:scale-105 focus:scale-105`}
              disabled={isUploading}
              aria-disabled={isUploading}
            >
              {isUploading ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 mr-2 inline-block text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Adding...
                </>
              ) : (
                "+ Add Company"
              )}
            </button>
          </div>
        </form>
      )}

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="upload-message-title"
        aria-describedby="upload-message-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 360,
            maxWidth: "90%",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
            borderRadius: 3,
            textAlign: "center",
          }}
        >
          <Typography
            id="upload-message-title"
            variant="h5"
            component="h2"
            sx={{
              color: "#1976d2",
              fontWeight: "bold",
              mb: 2,
            }}
          >
            Upload Status
          </Typography>
          <Typography
            id="upload-message-description"
            sx={{
              color: "#555",
              fontSize: "1rem",
              mb: 3,
            }}
          >
            {uploadMessage}
          </Typography>
          <button
            onClick={handleCloseModal}
            style={{
              backgroundColor: "#1976d2",
              color: "white",
              padding: "10px 20px",
              borderRadius: "8px",
              border: "none",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#1565c0")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#1976d2")
            }
          >
            Close
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default AddCompanyData;
