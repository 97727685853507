import React, { useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const ArticleRatings = ({ rating, onChange }) => {
  const [hoveredRating, setHoveredRating] = useState(0); // Track hovered stars

  const handleStarClick = (index) => {
    onChange(index + 1); // Update the rating to 1-based index
  };

  const handleStarHover = (index) => {
    setHoveredRating(index + 1); // Highlight stars on hover
  };

  const resetStarHover = () => {
    setHoveredRating(0); // Reset hover state
  };

  return (
    <div className="flex gap-2">
      {[...Array(5)].map((_, index) => (
        <button
          key={index}
          type="button"
          onClick={() => handleStarClick(index)}
          onMouseEnter={() => handleStarHover(index)}
          onMouseLeave={resetStarHover}
          className="p-0 bg-transparent border-none cursor-pointer transition-transform hover:scale-125"
          aria-label={`Rate ${index + 1} stars`}
        >
          {index < (hoveredRating || rating) ? (
            <StarIcon style={{ color: "#FFD700", fontSize: "30px" }} />
          ) : (
            <StarBorderIcon style={{ color: "#E0E0E0", fontSize: "30px" }} />
          )}
        </button>
      ))}
    </div>
  );
};

export default ArticleRatings;
