import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./IpoView.css";
import RatingStarComponent from "../RatingStar/RatingStarComponent";
import { FaBoxOpen, FaCalendarAlt } from "react-icons/fa";
import axios from "axios";
import { constaints } from "../../../utils/constaints";

function IpoView() {
  const [ipos, setIpos] = useState([]);
  const [filteredIpos, setFilteredIpos] = useState([]);
  const [filterBSE, setFilterBSE] = useState(false);
  const [filterNSE, setFilterNSE] = useState(false);
  const [filterExchange, setFilterExchange] = useState("");
  const [sortLatest, setSortLatest] = useState(false);
  const [sortOldest, setSortOldest] = useState(false);
  const [sortPriceHigh, setSortPriceHigh] = useState(false);
  const [sortPriceLow, setSortPriceLow] = useState(false);
  const [sortAZ, setSortAZ] = useState(false);
  const [sortZA, setSortZA] = useState(false);
  const [sortGainHigh, setSortGainHigh] = useState(false);
  const [sortGainLow, setSortGainLow] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const filtersRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // replace fetch with axios
  useEffect(() => {
    const fetchIpos = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/finview/ipos`, {
          withCredentials: true,
        });

        const data = response.data;
        setIpos(data);
        setFilteredIpos(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchIpos();
  }, [SERVER_URL]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (filtersRef.current && !filtersRef.current.contains(event.target)) {
        setShowFilters(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filtersRef]);

  const isNewIpo = (closeDate) => {
    const now = new Date();
    const ipoCloseDate = new Date(closeDate);
    return now <= ipoCloseDate;
  };

  function formatDate(isoDateString) {
    if (!isoDateString) return "Date not available";
    const date = new Date(isoDateString);
    if (isNaN(date.getTime())) return "Invalid Date";
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(/\//g, "-");
  }

  const formatPostDate = (isoDateString) => {
    if (!isoDateString) return "Date and time not available";
    const date = new Date(isoDateString);
    if (isNaN(date.getTime())) return "Invalid Date and Time";

    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate.replace(/,/, "")} at ${formattedTime}`;
  };

  useEffect(() => {
    let filteredList = [...ipos];

    if (filterExchange) {
      filteredList = filteredList.filter(
        (company) => company.exchange === filterExchange
      );
    }

    if (sortLatest) {
      filteredList = filteredList.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    }

    if (sortOldest) {
      filteredList = filteredList.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    }

    if (sortPriceHigh) {
      filteredList = filteredList.sort((a, b) => b.price - a.price);
    }

    if (sortPriceLow) {
      filteredList = filteredList.sort((a, b) => a.price - b.price);
    }

    if (sortAZ) {
      filteredList = filteredList.sort((a, b) =>
        a.companyName.localeCompare(b.companyName)
      );
    }

    if (sortZA) {
      filteredList = filteredList.sort((a, b) =>
        b.companyName.localeCompare(a.companyName)
      );
    }

    if (sortGainHigh) {
      filteredList = filteredList.sort((a, b) => {
        const gainA = parseFloat(
          a.expectedListingProfit.replace("%", "").split("-")[1] || 0
        );
        const gainB = parseFloat(
          b.expectedListingProfit.replace("%", "").split("-")[1] || 0
        );
        return gainB - gainA;
      });
    }

    if (sortGainLow) {
      filteredList = filteredList.sort((a, b) => {
        const gainA = parseFloat(
          a.expectedListingProfit.replace("%", "").split("-")[0] || 0
        );
        const gainB = parseFloat(
          b.expectedListingProfit.replace("%", "").split("-")[0] || 0
        );
        return gainA - gainB;
      });
    }

    setFilteredIpos(filteredList);
  }, [
    filterExchange,
    sortLatest,
    sortOldest,
    sortPriceHigh,
    sortPriceLow,
    sortAZ,
    sortZA,
    sortGainHigh,
    sortGainLow,
    ipos,
  ]);

  const resetFilters = () => {
    setFilterExchange("");
    setSortLatest(false);
    setSortOldest(false);
    setSortPriceHigh(false);
    setSortPriceLow(false);
    setSortAZ(false);
    setSortZA(false);
    setSortGainHigh(false);
    setSortGainLow(false);
  };

  return (
    <div className="IpoComponent font-roboto max-h-[85dvh] overflow-y-scroll no-scrollbar">
      <button
        className="block md:hidden p-2 bg-[var(--primary-variant)] text-[var(--on-primary-variant)] rounded "
        onClick={() => setShowFilters(!showFilters)}
      >
        Toggle Filters
      </button>

      <div
        ref={filtersRef}
        className={`whitespace-nowrap max-sm:z-50 flex flex-col p-4 bg-[var(--surface)] shadow-xl border-[var(--on-surface)] border-2 gap-2 rounded-lg top-14 left-0 absolute md:sticky md:mt-16 w-[226px] max-sm:w-[200px] md:float-left ${
          showFilters ? "block" : "hidden md:block"
        }`}
      >
        <div className="space-x-3">
          <label className="font-bold text-[var(--on-surface)]">
            Date Posted
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={sortLatest}
              onChange={(e) => {
                setSortLatest(e.target.checked);
                setSortOldest(false);
              }}
            />
            <span className="text-[var(--on-surface-variant)]">
              Latest First
            </span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={sortOldest}
              onChange={(e) => {
                setSortOldest(e.target.checked);
                setSortLatest(false);
              }}
            />
            <span className="text-[var(--on-surface-variant)]">
              Oldest First
            </span>
          </label>
        </div>
        <div className="space-x-3">
          <label className="font-bold text-[var(--on-surface)]">Price</label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={sortPriceHigh}
              onChange={(e) => {
                setSortPriceHigh(e.target.checked);
                setSortPriceLow(false);
              }}
            />
            <span className="text-[var(--on-surface-variant)]">
              High To Low
            </span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={sortPriceLow}
              onChange={(e) => {
                setSortPriceLow(e.target.checked);
                setSortPriceHigh(false);
              }}
            />
            <span className="text-[var(--on-surface-variant)]">
              Low To High
            </span>
          </label>
        </div>
        <div className="space-x-3">
          <label className="font-bold text-[var(--on-surface)]">
            Expected Gain
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={sortGainHigh}
              onChange={(e) => {
                setSortGainHigh(e.target.checked);
                setSortGainLow(false);
              }}
            />
            <span className="text-[var(--on-surface-variant)]">
              High To Low
            </span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={sortGainLow}
              onChange={(e) => {
                setSortGainLow(e.target.checked);
                setSortGainHigh(false);
              }}
            />
            <span className="text-[var(--on-surface-variant)]">
              Low To High
            </span>
          </label>
        </div>
        <div className="space-x-3">
          <label className="font-bold text-[var(--on-surface)]">
            Lexicographically
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={sortAZ}
              onChange={(e) => {
                setSortAZ(e.target.checked);
                setSortZA(false);
              }}
            />
            <span className="text-[var(--on-surface-variant)]">A-Z</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={sortZA}
              onChange={(e) => {
                setSortZA(e.target.checked);
                setSortAZ(false);
              }}
            />
            <span className="text-[var(--on-surface-variant)]">Z-A</span>
          </label>
        </div>
        <div className="flex gap-3 items-center">
          <label className="font-bold text-[var(--on-surface)]">Exchange</label>
          <select
            value={filterExchange}
            onChange={(e) => setFilterExchange(e.target.value)}
            className="bg-[var(--surface)] text-[var(--on-surface)] w-[100px] border border-[var(--outline-color)] rounded-md px-1 py-1 text-base focus:border-[var(--primary-color)] focus:outline-none transition-colors"
          >
            <option value="">All</option>
            <option value="NSE">NSE</option>
            <option value="BSE">BSE</option>
            <option value="NSE&BSE">NSE&BSE</option>
            <option value="SME-NSE">SME-NSE</option>
            <option value="SME-BSE">SME-BSE</option>
            <option value="SME-NSE&BSE">SME-NSE&BSE</option>
          </select>
        </div>

        <button
          className="mt-4 p-2 bg-red-500 text-white rounded"
          onClick={resetFilters}
        >
          Reset Filters
        </button>
      </div>

      <div className="flex flex-col  mb-4 justify-center gap-6 mt-4 md:w-[65%] lg:w-[74%] mx-auto items-center">
        {filteredIpos.length > 0 ? (
          filteredIpos.map((company, index) => (
            <div
              key={company._id}
              className="ipo_little_detail flex-wrap items-center justify-center max-xl:w-[83%] xl:w-[85%] max-sm:w-[97%] max-md:w-[82%] max-lg:w-[95%] border-[var(--on-surface)] border-2 rounded-3xl font-roboto mx-auto shadow-2xl shadow-slate-700 max-sm:text-sm overflow-hidden"
            >
              <Link
                key={company._id}
                to={`/ipo/company/detail/${company.companyName.replace(
                  / /g,
                  "-"
                )}`}
                className="ipo_little_detail_link text-[var(--primary-variant)] cursor-pointer"
              >
                {isNewIpo(company.closeDate) && (
                  <div className="new-ipo-ribbon">
                    <span className="">New</span>
                  </div>
                )}
                <div className="hidden lg:block p-3 whitespace-nowrap overflow-hidden no-scrollbar relative">
                  <div className="flex justify-between">
                    <h4 className="font-bold text-lg text[var(--on-surface)]">
                      {company.companyName}
                    </h4>
                    <RatingStarComponent value={company.rating} />
                  </div>
                  <div className="flex items-center justify-between text-[var(--on-surface)]">
                    <div className="flex flex-col justify-between relative">
                      <p className="pl-6 relative before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-2 before:h-2 before:bg-green-500 before:rounded-full before:border before:border-green-500 after:content-[''] after:absolute after:left-1 after:top-[70%] after:w-px after:h-8 after:bg-gray-300">
                        <span className="font-bold">Opening Date:</span>{" "}
                        {formatDate(company.openingDate)}
                      </p>
                      <p className="pl-6 relative before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-2 before:h-2 before:bg-green-500 before:rounded-full before:border before:border-green-500 after:content-[''] after:absolute after:left-1 after:top-[70%] after:w-px after:h-8 after:bg-gray-300">
                        <span className="font-bold">Closing Date:</span>{" "}
                        {formatDate(company.closeDate)}
                      </p>
                      <p className="pl-6 relative before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-2 before:h-2 before:bg-green-500 before:rounded-full before:border before:border-green-500">
                        <span className="font-bold">Listing Date:</span>{" "}
                        {formatDate(company.listingDate)}
                      </p>
                    </div>
                    <div className="flex flex-col justify-between text-[var(--on-surface)]">
                      <p>
                        <span className="font-bold">Price:</span>{" "}
                        {company.price}
                      </p>
                      <p>
                        <span className="font-bold">Expected Gain:</span>{" "}
                        {company.expectedListingProfit}
                      </p>
                      <p>
                        <span className="font-bold">Exchange:</span>{" "}
                        {company.exchange}
                      </p>
                    </div>
                    <div className="flex flex-col justify-between text-[var(--on-surface)]">
                      <p>
                        <span className="font-bold">Industry:</span>{" "}
                        {company.industry}
                      </p>
                      <p>
                        <span className="font-bold">Status:</span>{" "}
                        {company.shariahStatus}
                      </p>
                    </div>
                  </div>
                  <div className="absolute inset-0 z-0 before:absolute before:opacity-25 before:top-0 before:content-[''] before:bottom-0 before:right-0 before:left-0  before:bg-[var(--primary)] before:transform before:skew-x-[-45deg] before:w-[50%] before:h-[96%] before:mx-auto  before:opacity:30 before:my-auto  before:-z-50 "></div>
                </div>

                <div className=" relative block lg:hidden p-2">
                  {" "}
                  {/* Visible on smaller screens */}
                  <div className="absolute inset-0 z-0 before:absolute before:opacity-25 before:top-0 before:content-[''] before:bottom-0 before:right-0 before:left-0  before:bg-[var(--primary)] before:transform before:skew-x-[-45deg] before:-skew-y-3 before:w-[65%] before:h-[90%] before:mx-auto before:rounded-full  "></div>
                  <div className="flex justify-between font-bold mt-3">
                    <h4 className="line-clamp-2 hover:line-clamp-none text-[var(--primary)] ">
                      {company.companyName}
                    </h4>
                    <div className="px-2 ipo_detail_rating_view">
                      <RatingStarComponent value={company.rating} />
                    </div>
                  </div>
                  <div className="flex justify-between max-sm:flex-wrap text-[var(--on-surface)]">
                    <div>
                      <span className="text-[var(--on-surface)] font-bold">
                        Price:
                      </span>
                      <span className="text-[var(--on-surface)]">
                        {company.price}
                      </span>
                    </div>
                    <div>
                      <span className="font-bold text-[var(--on-surface)] ">
                        Expected Gain:
                      </span>
                      <span className="text-[var(--on-surface)]">
                        {company.expectedListingProfit}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between max-sm:flex-wrap text-[var(--on-surface)]">
                    <div>
                      <span className="font-bold text-[var(--on-surface)]">
                        Status:
                      </span>
                      <span className="text-[var(--on-surface)]">
                        {company.shariahStatus}
                      </span>
                    </div>
                    <div>
                      <span className="text-[var(--on-surface)] font-bold">
                        Opening Date:
                      </span>
                      <span className="text-[var(--on-surface)]">
                        {formatDate(company.openingDate)}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between max-sm:flex-wrap text-[var(--on-surface)]">
                    <div>
                      <span className="text-[var(--on-surface)] font-bold">
                        Exchange:
                      </span>
                      <span className="text-[var(--on-surface)]">
                        {company.exchange}
                      </span>
                    </div>
                    <div>
                      <span className="font-bold text-[var(--on-surface)]">
                        Listing Date:
                      </span>
                      <span className="text-[var(--on-surface)]0">
                        {formatDate(company.listingDate)}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between max-sm:flex-wrap ">
                    <div>
                      <span className="font-bold text-[var(--on-surface)]">
                        Industry:
                      </span>
                      <span className="text-[var(--on-surface)]">
                        {company.industry}
                      </span>
                    </div>
                    <div>
                      <span className="font-bold text-[var(--on-surface)]">
                        Closing Date:
                      </span>
                      <span className="text-[var(--on-surface)]">
                        {formatDate(company.closeDate)}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="no-ipos-container">
            <div className="no-ipos-message">
              <FaCalendarAlt className="no-ipos-calendar-icon" />
              <p>We are currently working on the upcoming IPOs.</p>
              <p>
                Please check back later for updates on new investment
                opportunities with their shariah Compliant status.
              </p>
              <p>
                Stay informed about the latest market trends and investment
                tips.
              </p>
            </div>
            <div className="no-ipos-icon">
              <FaBoxOpen className="no-ipos-box-icon" />
              <p>No data matching your filters.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default IpoView;
