import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FaSearch, FaTimes } from "react-icons/fa";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Typography, Paper } from "@mui/material";
import "./QandA.css";

const QandA = () => {
  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const staticData = [
    {
      question: "Q: What is a Shariah-compliant investment?",
      answer:
        "A: A Shariah-compliant investment is an investment that adheres to Islamic principles and guidelines, including avoiding interest (riba), gambling, and investing in businesses that engage in impermissible activities according to Islamic law.",
    },
    {
      question:
        "Q: Why is it important to screen stocks for Shariah compliance?",
      answer:
        "A: Screening stocks for Shariah compliance ensures that Muslim investors can confidently invest in the stock market while adhering to their Islamic values and principles.",
    },
    {
      question:
        "Q: Who oversees the Shariah-compliant stock screening methodology?",
      answer:
        "A: Our Shariah-compliant stock screening methodology is developed and overseen by a dedicated team of Islamic scholars specialized in equity investment, Islamic finance, and Shariah law. They form a Shariah board, consisting of a chairman and two members, ensuring our methodology aligns with Islamic finance principles.",
    },
    {
      question:
        "Q: How do you determine if a company's business activities are Shariah-compliant?",
      answer:
        "A: We assess the three P's of the business: Purpose, Process, and Product. The company's main objective, methods, and final product or service must all adhere to Islamic principles.",
    },
    {
      question: "Q: What are some examples of non-Shariah-compliant sectors?",
      answer:
        "A: Non-Shariah-compliant sectors include conventional banking, insurance, media, entertainment, alcohol, and weapon production.",
    },
    {
      question:
        "Q: How does the Finsha rate the companies on Shariah compliance?",
      answer:
        "A: We rate companies from one to five stars, with five stars denoting the highest level of Shariah compliance. Ratings are based on how close the company is to the non-Shariah-compliant threshold.",
    },
    {
      question: "Q: What financial ratios are used in the screening process?",
      answer:
        "A: We consider three financial ratios: Non-Permissible Income Ratio, Interest-Based Investment Ratio, and Interest-Based Loan Ratio. These ratios help determine if a company meets the required Shariah-compliant standards.",
    },
    {
      question: "Q: What is income purification, and why is it necessary?",
      answer:
        "A: Income purification is the process of donating a portion of the non-permissible income earned from your investments to charity with the intention of purification. This ensures that your investment gains are in line with Islamic principles.",
    },
    {
      question:
        "Q: How do I calculate the purging amount for income purification?",
      answer:
        "A: The purging amount can be calculated using the following formula: (Non-Permissible Income / Total Income) x (Dividend + Capital Gain). This represents the portion of your investment income that must be donated to charity.",
    },
    {
      question:
        "Q: What are the fees associated with using the Finsha platform and services?",
      answer:
        "A: Our Shariah-compliant stock screener and purging amount calculation services are available at no cost. For access to Shariah-based recommendations from Finsha, you can subscribe to our paid services. Please visit our pricing page for more details on the available plans and features.",
    },
    {
      question:
        "Q: Can I invest in international stocks through the Finsha platform?",
      answer:
        "A: Our platform currently focuses on the Indian stock market. However, we are continuously working to expand our services and may include international stocks in the future. Stay tuned for updates.",
    },
    {
      question:
        "Q: How often are the stocks and ratings updated on the Finsha platform?",
      answer:
        "A: Our platform updates the stock listings and ratings regularly to ensure the most accurate and up-to-date information is available to our users. The frequency of updates depends on the availability of new financial data and any changes in Shariah-compliance standards.",
    },
    {
      question:
        "Q: Can non-Muslims also benefit from investing in Shariah-compliant stocks?",
      answer:
        "A: Yes, non-Muslims can also invest in Shariah-compliant stocks. These stocks follow ethical guidelines that may appeal to socially responsible investors. Moreover, Shariah-compliant companies often have lower debt levels, which can be beneficial in terms of financial stability and risk management.",
    },
    {
      question:
        "Q: How can I get started with investing in Shariah-compliant stocks using the Finsha platform?",
      answer:
        "A: To begin investing in Shariah-compliant stocks, first, sign up for an account on our platform. After registration, you can access our free Shariah-compliant stock screener and purging amount calculation services. For additional features, such as buy-hold-sell recommendations, consider subscribing to our paid services.",
    },
    {
      question:
        "Q: Do you provide support or guidance for new investors who are unfamiliar with the stock market?",
      answer:
        "A: Yes, we offer educational resources, articles, and videos to help new investors understand the basics of stock market investing and Shariah-compliant investing principles. Additionally, our platform provides tools and services designed to simplify the investment process for users with varying levels of experience.",
    },
    {
      question:
        "Q: Is there any minimum investment required to start investing in Shariah-compliant stocks?",
      answer:
        "A: There is no specific minimum investment required for investing in Shariah-compliant stocks. The minimum investment would depend on the stock price and the brokerage account you use. However, it is essential to diversify your investments to reduce risk, so consider building a portfolio of multiple stocks.",
    },
    {
      question:
        "Q: How do I choose the best Shariah-compliant stocks to invest in?",
      answer:
        "A: When selecting Shariah-compliant stocks, consider factors such as the company's business fundamentals, growth prospects, financial health, and valuation. Additionally, use our platform's rating system and recommendations to help identify attractive investment opportunities based on Shariah compliance and overall potential.",
    },
    {
      question:
        "Q: Can I invest in Shariah-compliant stocks through a Systematic Investment Plan (SIP)?",
      answer:
        "A: While you cannot invest directly in stocks through a SIP, you can opt for a Shariah-compliant mutual fund or ETF that offers SIP options. This allows you to invest a fixed amount at regular intervals, which can be a more disciplined approach to investing. We may provide these services in the near future.",
    },
    {
      question:
        "Q: What are the tax implications of investing in Shariah-compliant stocks?",
      answer:
        "A: The tax implications for investing in Shariah-compliant stocks are generally the same as for investing in any other stocks. In India, you may be subject to capital gains tax and dividend income tax. You can consult with us to understand the specific tax implications based on your individual circumstances and country of residence.",
    },
    {
      question:
        "Q: How can I stay updated with the latest news and trends in Shariah-compliant investing?",
      answer:
        "A: Our platform provides market insights, news, and analysis related to Islamic finance and Shariah-compliant stocks. You can also subscribe to our social media platforms to receive regular updates and stay informed about the latest developments in the industry.",
    },
    {
      question:
        "Q: Are there any risks associated with investing in Shariah-compliant stocks?",
      answer:
        "A: As with any investment, there are inherent risks involved in investing in Shariah-compliant stocks. Market fluctuations, company-specific factors, and global economic conditions can impact stock performance. It is essential to thoroughly research and understand the risks before making any investment and to diversify your portfolio to mitigate risk.",
    },
    {
      question:
        "Q: How frequently do Finsha publish research reports and recommendations on Shariah-compliant stocks?",
      answer:
        "A: We regularly publish research reports and recommendations on Sharia-compliant stocks to provide our subscribers with the most relevant and up-to-date information. The frequency of these reports may vary based on market conditions, company news, and other factors that can impact stock performance. For more details, you can check out our research report section of the website.",
    },
    {
      question:
        "Q: Can I request a customized research report or stock analysis on a specific company?",
      answer:
        "A: Yes, our platform offers customized research reports and stock analysis for subscribers who request specific information on a particular company. This service is available as part of our premium subscription plans. Please contact us for any kind of help or visit our pricing page for more information on the available plans and features.",
    },
    {
      question:
        "Q: What types of investment strategies do Finsha recommend for Shariah-compliant investing?",
      answer:
        "A: We recommend a diverse range of investment strategies tailored to individual risk tolerance, investment goals, and time horizon. These may include value investing, growth investing, and dividend investing, among others. Our research reports and recommendations consider various factors to help users identify the most suitable investment strategies based on their unique needs.",
    },
    {
      question:
        "Q: How can I track the performance of my stock portfolio on the Finsha platform/website?",
      answer:
        "A: Our website (www.finsha.in) provides tools and features that enable users to monitor and track the performance of their Shariah-compliant stock portfolio. Users can access real-time performance and comprehensive analytics to evaluate the performance of their investments and make informed decisions accordingly.",
    },
    {
      question:
        "Q: Do Finsha provide any resources to help investors understand the complexities of Islamic finance and Shariah-compliant investing?",
      answer:
        "A: Yes, we offer a range of educational resources, including articles, videos, and webinars, to help users deepen their understanding of Islamic finance and Shariah-compliant investing. These resources cover a variety of topics, from the basics of Islamic finance to more advanced concepts and strategies.",
    },
    {
      question:
        "Q: Can I access the Finsha platform and services through a mobile app?",
      answer:
        "A: While our platform is currently web-based, we are working on developing a mobile app to provide our users with a more convenient and seamless experience. Stay tuned for updates on the availability of our mobile app for iOS and Android devices.",
    },
    {
      question:
        "Q: How secure is my personal and financial information on the Finsha platform?",
      answer:
        "A: We take the security and privacy of your personal and financial information very seriously. Our platform employs advanced security measures and encryption technologies to protect your data from unauthorized access, ensuring that your information remains secure and confidential.",
    },
    {
      question:
        "Q: What is the process for cancelling my subscription to the Finsha services?",
      answer:
        "A: To cancel your subscription, simply visit the account settings page on our platform and follow the instructions to cancel your plan. If you need assistance with cancelling your subscription, please feel free to contact our customer support team for help.",
    },
    {
      question:
        "Q: What kind of customer support can I expect from the Finsha platform?",
      answer:
        "A: We strive to provide excellent customer support to our users. Our team is available to assist you with any questions, concerns, or issues you may encounter while using our platform. You can reach out to us via email, phone, or through our website's queries feature for prompt and helpful support.",
    },
    {
      question:
        "Q: Do you provide any tools or calculators to help investors make informed decisions?",
      answer:
        "A: Yes, our platform offers various tools and calculators designed to assist investors in making informed decisions. These include financial ratio calculators, purging amount calculators, and portfolio analysis tools, business model overviews, among others. These resources can help users evaluate their investment choices and optimize their Shariah-compliant stock portfolios.",
    },
    {
      question:
        "Q: How do I provide feedback or suggestions to improve the Finsha platform and services?",
      answer:
        "A: We value your feedback and suggestions as they help us improve our platform and services to better serve our users. You can share your feedback or suggestions through our website's contact form or by reaching out to our customer support team via email.",
    },
    {
      question:
        "Q: Do you offer any trial periods for new users to explore the Finsha platform and services?",
      answer:
        "A: Yes, we may offer trial periods for some users to explore our platform and services without any commitment. During the trial period, users can access our free features, such as the Shariah-compliant stock screener and purging amount calculator along with the subscription-based services for free of cost. For more information on trial periods and how to sign up, please visit our website or contact our customer support team.",
    },
    {
      question:
        "Q: Can I export my portfolio data and research reports from the Finsha Platform?",
      answer:
        "A: Yes, our platform allows users to export their portfolio data and research reports in various formats, such as CSV, PDF, and Excel. This feature enables users to easily share their investment data with others or integrate it with their preferred portfolio management tools.",
    },
    {
      question:
        "Q: How can I stay informed about upcoming webinars, events, and promotions related to Sharia-compliant investing?",
      answer:
        "A: To stay informed about upcoming webinars, events, and promotions, we recommend subscribing to our newsletter and following us on our social media channels. By doing so, you will receive regular updates on the latest news, insights, and opportunities related to Shariah-compliant investing and our platform.",
    },
    {
      question:
        "Q: What is the process for resolving disputes or disagreements with the Finsha platform's services or recommendations?",
      answer:
        "A: In case of any disputes or disagreements with our platform's services or recommendations, please reach out to our Investors' Grievances Redressal form or customer support team. We will work diligently to address your concerns and find a fair resolution. Our goal is to ensure a positive and satisfactory experience for all our users.",
    },
    {
      question:
        "Q: Do you have any partnerships or affiliations with other financial institutions or organizations related to Shariah-compliant investing?",
      answer:
        "A: We are continuously working to establish partnerships and affiliations with reputable financial institutions and organizations in the field of Islamic finance and Shariah-compliant investing. These partnerships aim to provide our users with access to a broader range of investment opportunities, resources, and expertise to enhance their investing experience.",
    },
    {
      question:
        "Q: Can I set up alerts or notifications for specific Shariah-compliant stocks or market events?",
      answer:
        "A: Yes, our platform allows users to set up custom alerts and notifications for specific Shariah-compliant stocks or market events. This feature enables users to stay informed and take timely action on their investments based on relevant news, price movements, or other market developments.",
    },
    {
      question: "Q: How do I sign up for an account on the Finsha Platform?",
      answer:
        "A: To sign up for an account on our platform, visit the registration page, and fill in the required details, such as your name, email address, and password. You will then receive a verification email to confirm your account. Once verified, you can log in and access the features available on our platform.",
    },
    {
      question:
        "Q: Can I access the Finsha Platform/website on mobile devices?",
      answer:
        "A: Yes, our website is designed to be accessible and user-friendly on various devices, including mobile phones and tablets. This ensures that users can access their accounts, track their investments, and receive recommendations anytime, anywhere.",
    },
    {
      question:
        "Q: How can I stay updated with the latest news and analysis related to Shariah-compliant investing?",
      answer:
        "A: Our platform provides a wealth of information, including market insights, news, and analysis related to Islamic finance and Sharia-compliant stocks. You can subscribe to our newsletter and follow us on social media to receive regular updates and stay informed about the latest developments in the industry.",
    },
    {
      question:
        "Q: What are the key benefits of investing in Shariah-compliant stocks?",
      answer:
        "A: Investing in Shariah-compliant stocks offers several benefits, including adherence to ethical principles, lower debt levels for companies, and potential appeal to socially responsible investors. These factors can contribute to reduced risk and increased financial stability for your investments.",
    },
    {
      question:
        "Q: How does the Finsha Platform ensure the accuracy of its stock ratings and recommendations?",
      answer:
        "A: Our platform utilizes a robust methodology and rigorous screening process to evaluate and rate stocks based on their Shariah compliance and overall potential. Our team of experienced research analysts and Islamic scholars work together to ensure the accuracy of our ratings and recommendations.",
    },
    {
      question:
        "Q: Is the Finsha Platform suitable for beginner investors with limited knowledge of stock market investing?",
      answer:
        "A: Yes, our platform caters to users with varying levels of experience, from beginners to seasoned investors. We provide educational resources, articles, and videos to help new investors understand the basics of stock market investing and Shariah-compliant investing principles. Additionally, our platform offers tools and services designed to simplify the investment process for all users.",
    },
    {
      question:
        "Q: How can I get in touch with the Finsha customer support team for assistance or inquiries?",
      answer:
        "A: If you have any questions, or concerns, or need assistance, you can contact our customer support team through the 'Contact Us' page on our website, by sending an email to the provided address, or by reaching out to us at “Feedback and suggestion form” or via our social media channels. Our team is always ready to help and ensure your experience on our platform is seamless and enjoyable.",
    },
    {
      question:
        "Q: Can non-Muslim investors benefit from Shariah-compliant investments?",
      answer:
        "A: Yes, non-Muslim investors can benefit from Shariah-compliant investments. These investments follow ethical guidelines and focus on socially responsible businesses, which may appeal to investors who prioritize ethical considerations. Additionally, Shariah-compliant companies often have lower debt levels, which can contribute to financial stability and reduced risk.",
    },
    {
      question:
        "Q: What are the key differences between conventional investments and Shariah-compliant investments?",
      answer:
        "A: The main differences between conventional investments and Shariah-compliant investments lie in their adherence to Islamic principles. Shariah-compliant investments avoid interest (riba), gambling, and businesses that engage in impermissible activities according to Islamic law. This results in a focus on ethical and socially responsible businesses, which may have lower debt levels and reduced risk compared to conventional investments.",
    },
    {
      question:
        "Q: How does the Finsha Platform ensure that the stocks listed are Shariah-compliant?",
      answer:
        "A: Our platform uses a robust screening methodology that assesses the three P's of a business: Purpose, Process, and Product. The company's main objective, methods, and final product or service must all adhere to Islamic principles. Our Shariah board, consisting of Islamic scholars specialized in equity investment, Islamic finance, and Shariah law, oversees the stock screening process to ensure compliance with Islamic finance principles.",
    },
    {
      question:
        "Q: What are the benefits of investing in Shariah-compliant stocks for non-Muslim investors?",
      answer:
        "A: For non-Muslim investors, Shariah-compliant stocks offer an opportunity to invest in companies that adhere to ethical guidelines and socially responsible practices. Such investments can contribute to a more diversified portfolio and align with the investor's values. Additionally, companies that follow Shariah principles often have lower debt levels, which can lead to increased financial stability and reduced risk.",
    },
    {
      question:
        "Q: How can non-Muslim investors learn more about Shariah-compliant investing?",
      answer:
        "A: Non-Muslim investors can access our platform's educational resources, articles, and videos to learn about the basics of Shariah-compliant investing principles. Our platform also provides market insights, news, and analysis related to Islamic finance and Shariah-compliant stocks, allowing investors to stay informed about the latest developments in the industry.",
    },
    {
      question:
        "Q: Is there any difference in the investment process for non-Muslim investors compared to Muslim investors when using the Finsha platform?",
      answer:
        "A: No, the investment process for non-Muslim investors is the same as for Muslim investors on our platform. Both types of investors can access our Shariah-compliant stock screener, purging amount calculation services, and buy-hold-sell recommendations to make informed decisions about their investments.",
    },
    {
      question:
        "Q: Can non-Muslim investors also participate in income purification through charity donations?",
      answer:
        "A: While income purification is an Islamic principle designed to align investment gains with Islamic values, non-Muslim investors may also choose to participate in the practice. Donating a portion of non-permissible income to charity can be seen as a socially responsible act, demonstrating a commitment to ethical investing and making a positive impact on society.",
    },
    {
      question:
        "Q: Do Sharia-compliant investments generally perform better or worse than conventional investments?",
      answer:
        "A: The performance of Shariah-compliant investments compared to conventional investments can vary depending on market conditions, individual company performance, and other factors. However, Shariah-compliant investments often exhibit lower debt levels and focus on ethical and socially responsible practices, which can lead to increased financial stability and reduced risk in the long term.",
    },
    {
      question:
        "Q: How do I know if a mutual fund or ETF is Shariah-compliant?",
      answer:
        "A: To determine if a mutual fund or ETF is Shariah-compliant, you can review its investment prospectus, which should provide information on the fund's investment strategy and adherence to Islamic principles. You can also check if the fund is certified by a recognized Shariah board or consult with a financial advisor who specializes in Shariah-compliant investments.",
    },
    {
      question:
        "Q: What are the main challenges for Muslim investors when investing in Shariah-compliant stocks?",
      answer:
        "A: Some challenges that Muslim investors face when investing in Shariah-compliant stocks include understanding Islamic finance principles, identifying Shariah-compliant investment opportunities, and navigating income purification processes. Our platform aims to address these challenges by providing educational resources, stock screening tools, and guidance on income purification.",
    },
    {
      question:
        "Q: How can I stay informed about new Shariah-compliant investment opportunities?",
      answer:
        "A: To stay updated on new Shariah-compliant investment opportunities, you can subscribe to our platform's newsletter, follow our social media channels, and regularly check our website for updates. We provide market insights, news, and analysis related to Islamic finance and Shariah-compliant stocks to help you stay informed about the latest developments in the industry.",
    },
    {
      question:
        "Q: Can I use conventional brokerage accounts to invest in Shariah-compliant stocks?",
      answer:
        "A: Yes, you can use conventional brokerage accounts to invest in Shariah-compliant stocks. However, it is essential to ensure that the stocks you invest in adhere to Islamic principles. Our platform's Shariah-compliant stock screener and rating system can help you identify suitable investment opportunities in the Indian Securities market.",
    },
    {
      question:
        "Q: I'm new to the stock market. How can I start investing in Shariah-compliant stocks?",
      answer:
        "A: To begin investing in Shariah-compliant stocks, first, familiarize yourself with the basics of stock market investing and Islamic finance principles. Our platform offers educational resources, articles, and videos to help you understand these concepts. Next, sign up for an account on our platform, and use our free Shariah-compliant stock screener and purging amount calculation services to identify suitable investment opportunities. Consider subscribing to our paid services for additional features, such as buy-hold-sell recommendations.",
    },
    {
      question:
        "Q: What is the difference between conventional stocks and Shariah-compliant stocks?",
      answer:
        "A: Conventional stocks include companies from all sectors and industries, whereas Shariah-compliant stocks consist of companies that adhere to Islamic principles and guidelines. Shariah-compliant companies avoid interest (riba), gambling, and impermissible business activities according to Islamic law. As a result, Shariah-compliant stocks often have lower debt levels and focus on ethical and socially responsible practices.",
    },
    {
      question:
        "Q: How do I choose a brokerage account for investing in Shariah-compliant stocks?",
      answer:
        "A: To invest in Shariah-compliant stocks, you can use a conventional brokerage account that provides access to the Indian Securities market. Compare different brokerage accounts based on factors such as fees, trading platforms, customer support, and availability of research tools.",
    },
    {
      question:
        "Q: How can I diversify my portfolio with Shariah-compliant investments?",
      answer:
        "A: To diversify your portfolio with Shariah-compliant investments, consider investing in a mix of Shariah-compliant stocks across various sectors and industries. This can help reduce risk and provide exposure to different market segments. Additionally, you can diversify by investing in Shariah-compliant mutual funds, permissible exchange-traded funds (ETFs).",
    },
    {
      question:
        "Q: What are some common misconceptions about Shariah-compliant investing?",
      answer:
        "A: Some common misconceptions about Shariah-compliant investing include the belief that it is only for Muslims, offers lower returns compared to conventional investments, or is limited to specific industries. Shariah-compliant investing is open to all investors who seek ethical and socially responsible investment opportunities. The performance of Shariah-compliant investments can vary, but they often exhibit increased financial stability and reduced risk due to lower debt levels. Shariah-compliant stocks are available across various sectors, excluding those that engage in impermissible activities according to Islamic law.",
    },
    {
      question:
        "Q: How can I manage risk when investing in Shariah-compliant stocks?",
      answer:
        "A: To manage risk when investing in Sharia-compliant stocks, consider diversifying your portfolio by investing in multiple stocks across different sectors and industries. This can help reduce the impact of individual stock volatility on your overall portfolio. Additionally, use our platform's rating system and recommendations to help identify stocks with a higher level of Shariah compliance and overall potential. Regularly monitor your investments and stay informed about market trends and developments to make informed decisions.",
    },
    {
      question:
        "Q: What features are available on the dashboard after I log in to the Finsha website?",
      answer:
        "A: After logging in, users can access various features on their dashboard, including adding and tracking their stock portfolios, monitoring their investment performance, and calculating the non-halal income that should be donated to charity for income purification as per Islamic Shariah guidelines. Subscribers can also access stock recommendations like future stock with target prices, entry prices, stop-loss prices, and holding periods.",
    },
    {
      question:
        "Q: Can I manage my account and subscription details on the Finsha platform?",
      answer:
        "A: Yes, in the account section, users can manage their subscriptions, edit their profiles, and update their preferences. This ensures that users have full control over their accounts and can easily make changes as needed.",
    },
    {
      question: "Q: How can I log in to my account on the Finsha platform?",
      answer:
        "A: Users can log in to their accounts using their registered email address. We also offer a Google login option for added convenience. This enables users to access their accounts easily and securely using their preferred method.",
    },
    {
      question: "Q: What should I do if I forget my password?",
      answer:
        "A: If you forget your password, you can reset it using the password reset feature on the login page. You will receive an OTP (One-Time Password) on your registered email for verification purposes. Once verified, you can create a new password and regain access to your account.",
    },
    {
      question:
        "Q: How can I set up and manage my stock portfolio on the dashboard?",
      answer:
        "A: On the dashboard, you can easily add and manage your stock portfolio by entering the details of your holdings, such as the stock name, purchase price, and quantity. The platform will then track the performance of your investments and provide relevant insights, including the amount of non-halal income that should be paid as a charity for income purification.",
    },
    {
      question:
        "Q: How do I access stock recommendations and other investing insights provided by the Finsha platform?",
      answer:
        "A: Subscribers can access stock recommendations, target prices, entry prices, stop-loss prices, and holding periods directly from the stock idea page under the dashboard. These insights are designed to help users make informed decisions and optimize their Shariah-compliant stock investments.",
    },
    {
      question:
        "Q: Can I set up custom alerts or notifications for stocks in my portfolio or specific market events?",
      answer:
        "A: Yes, our platform allows users to set up custom alerts and notifications for stocks in their portfolio or specific market events. This feature enables users to stay informed and take timely action based on relevant news, price movements, or other market developments.",
    },
    {
      question:
        "Q: How to calculate the purging amount of income from any stock to purify the investment income?",
      answer:
        "A: Since it is difficult for any large company to restrict themselves only to permissible income as per Islamic Shariah guidelines, Muslim investors are required to donate a portion of non-permissible income to purify their total income from investments. To calculate the amount to be purged out of the income generated from the stock investment, one has to find out the portion of non-permissible income on the total income of that company. That portion of income (dividend plus capital gain) should be purged from the total income of the investment. You can log in to our website, where you can add your portfolio of stocks, and our website will automatically calculate the amount of income to be purged from your invested income to purify your total income from investments.",
    },
    {
      question:
        "Q: How does the Finsha Platform facilitate the calculation of the purging amount for income purification?",
      answer:
        "A: Our platform offers a user-friendly dashboard where you can add and manage your portfolio of stocks. Once you have added your stocks, our platform automatically calculates the purging amount for each investment based on the company's non-permissible income ratio and your investment income (dividends and capital gains). This feature allows you to easily track your purging amount, ensuring your investment income remains in line with Islamic principles.",
    },
    {
      question:
        "Q: Can I edit or update my portfolio details on the Finsha platform to recalculate my purging amount?",
      answer:
        "A: Yes, you can edit or update your portfolio details on our platform at any time. Whenever you make changes to your portfolio, the platform will automatically recalculate the purging amount based on the updated information. This ensures that your purging amount remains accurate and up-to-date, allowing you to effectively purify your investment income according to Islamic guidelines.",
    },
    {
      question:
        "Q: Are the purging amount calculations on the Finsha website based on the latest financial data of the companies?",
      answer:
        "A: Our platform updates the stock listings and financial data regularly to ensure the most accurate and up-to-date information is available to our users. When calculating the purging amount for your investments, our platform uses the latest financial data of the companies in your portfolio. This ensures that your purging amount is based on the most recent non-permissible income ratios, providing a reliable and accurate calculation for income purification.",
    },
    {
      question:
        "Q: How often should I check and update my purging amount calculations?",
      answer:
        "A: It's a good practice to review and update your purging amount calculations regularly, especially when there are significant changes to your portfolio or the financial data of the companies you have invested in. By keeping track of your purging amount and adjusting it as needed, you can ensure that your investment income remains purified and compliant with Islamic Shariah guidelines.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setQuestions(staticData);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const toggleAnswerVisibility = (question) => {
    const newQuestions = [...questions];
    const index = newQuestions.findIndex(
      (q) => q.question === question.question
    );
    newQuestions[index].isAnswerVisible = !newQuestions[index].isAnswerVisible;
    setQuestions(newQuestions);
  };

  const questionsPerPage = 10;

  const filterQuestions = (term) => {
    if (!term) return questions;
    return questions.filter((q) =>
      q.question.toLowerCase().includes(term.toLowerCase())
    );
  };

  const filteredQuestions = filterQuestions(debouncedSearchTerm);
  const totalPages = Math.ceil(filteredQuestions.length / questionsPerPage);

  const renderQuestions = (page) => {
    const startIndex = (page - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    return filteredQuestions.slice(startIndex, endIndex);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const paginatedQuestions = renderQuestions(currentPage);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: staticData.map((q) => ({
      "@type": "Question",
      name: q.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: q.answer,
      },
    })),
  };

  const highlightText = (text, term) => {
    if (!term) return text;
    const parts = text.split(new RegExp(`(${term})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <span key={index} className="bg-yellow-200 font-semibold">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="bg-[var(--surface)] p-10">
      <div className=" max-w-[1200px] mx-auto px-4 bg-[var(--surface)]">
        <Helmet>
          <title>FAQs on Shariah-Compliant Investing | Finsha</title>
          <meta
            name="description"
            content="Discover answers to common questions about Shariah-compliant investing with Finsha. Learn about ethical investment options, screening methodologies, and more."
          />
          <meta
            name="keywords"
            content="FAQs, Shariah Investing, Ethical Investment, Islamic Finance, Stock Market, Finsha"
          />
          <meta
            property="og:title"
            content="FAQs on Shariah-Compliant Investing | Finsha"
          />
          <meta
            property="og:description"
            content="Find out how Shariah-compliant investments work, why they are important, and how Finsha helps you make informed investment decisions."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.finsha.in/faq" />
          <meta
            property="og:image"
            content="https://www.finsha.in/images/faq-banner.jpg"
          />
          <meta property="og:site_name" content="Finsha" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.finsha.in/faq" />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <div
          className="qna-page"
          itemScope
          itemType="https://schema.org/QAPage"
        >
          <h1
            className="faq-head text-4xl max-sm:3xl font-bold text-center mb-12 text-[var(--on-surface)] animate-fadeInDown  "
            itemProp="name"
          >
            Shariah Compliant Investment Q&A
          </h1>
          <meta
            itemProp="about"
            content="Answering common questions about Shariah-Compliant investing. Learn more about ethical investment options in the stock market."
          />
          <div className="flex items-center w-full max-w-2xl mx-auto mb-8 relative">
            <input
              className="input w-full px-5 py-3 border-2 border-[var(--on-surface-variant)] bg-[var(--surface)] text-[var(--on-surface)] rounded-full shadow focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
              type="text"
              placeholder="Search Shariah-Compliant investment questions..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
            />
            {searchTerm && (
              <button
                className="absolute right-12 text-[var(--on-surface)] animate-fadeIn"
                onClick={() => setSearchTerm("")}
              >
                <FaTimes />
              </button>
            )}
            <FaSearch className="ml-3 text-[var(--primary)] text-2xl animate-pulse" />
          </div>

          <section className="animate-fadeIn transition duration-300 ease-in-out">
            {filteredQuestions.length > 0 ? (
              <ul className="question-list space-y-6">
                {paginatedQuestions.map((item, index) => (
                  <li
                    key={index}
                    itemScope
                    itemType="https://schema.org/Question"
                    className="bg-[var(--surface)] rounded-lg max-sm:p-4 p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-1 cursor-pointer"
                    onClick={() => toggleAnswerVisibility(item)}
                  >
                    <div
                      className="w-full text-left font-semibold text-lg text-[var(--on-surface)] flex justify-between items-center transition-colors duration-200 hover:text-[var(--primary)]"
                      aria-controls={`answer-${index}`}
                      aria-expanded={item.isAnswerVisible ? "true" : "false"}
                      itemProp="text"
                    >
                      {highlightText(item.question, debouncedSearchTerm)}
                      <span
                        className={`ml-2 transform ${
                          item.isAnswerVisible ? "rotate-90" : "rotate-90"
                        } transition-transform duration-200`}
                      >
                        {item.isAnswerVisible ? (
                          <KeyboardDoubleArrowRightIcon />
                        ) : (
                          <KeyboardDoubleArrowLeftIcon />
                        )}
                      </span>
                    </div>
                    <div
                      id={`answer-${index}`}
                      className={`answer ${
                        item.isAnswerVisible ? "visible" : ""
                      }`}
                      role="region"
                      aria-labelledby={`question-${index}`}
                      itemScope
                      itemType="https://schema.org/Answer"
                      itemProp="acceptedAnswer"
                    >
                      <span
                        itemProp="text"
                        className="block mt-2 text-[var(--on-surface-variant)]"
                      >
                        {item.answer}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-center text-[var(--on-surface)] text-lg mt-8">
                No results found. Try searching with different keywords.
              </p>
            )}
          </section>
          <nav className="mt-8 flex justify-center items-center space-x-4 mb-6">
            <button
              className={`pagination-btn bg-blue-500 text-white py-2 px-5 max-sm:p-2 rounded-full hover:bg-blue-700 transition duration-300 ${
                currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <KeyboardDoubleArrowLeftIcon />
            </button>
            <span className="text-[var(--on-surface)] font-medium">
              <Paper
                elevation={3}
                style={{ padding: "6px 18px", borderRadius: "15px" }}
              >
                <Typography variant="subtitle1">
                  Page {currentPage} of {totalPages}
                </Typography>
              </Paper>
            </span>
            <button
              className={`pagination-btn bg-blue-500 text-white py-2 px-5 max-sm:p-2 rounded-full hover:bg-blue-700 transition duration-300 ${
                currentPage === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <KeyboardDoubleArrowRightIcon />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default QandA;
