import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import axios from "axios";
import { constaints } from "../../../../utils/constaints";

const ResearchReportForm = () => {
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const [formData, setFormData] = useState({
    companyName: "",
    Sector: "",
    symbol: "",
    recommendation: "BUY",
    Strategy: "BTST",
    ShariahStatus: "Compliant",
    buyRange: "",
    targetPrice: "",
    stopLoss: "",
    Duration: "",
    capitalInvestment: "",
    Summary: "",
    rating: "",
    reportLink: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Trigger the modal open when there's a success message
  useEffect(() => {
    if (successMessage) {
      handleOpenModal();
    }
  }, [successMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage("");

    try {
      const response = await axios.post(
        `${SERVER_URL}/admin/research-report`,
        formData
      );
      setSuccessMessage("Research report submitted successfully!");
      setFormData({
        companyName: "",
        Sector: "",
        symbol: "",
        recommendation: "BUY",
        Strategy: "BTSM",
        ShariahStatus: "Compliant",
        buyRange: "",
        targetPrice: "",
        stopLoss: "",
        Duration: "",
        capitalInvestment: "",
        Summary: "",
        rating: "",
        reportLink: "",
      });
    } catch (err) {
      setError("Failed to submit research report. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-full mx-auto p-6 bg-gradient-to-r from-gray-900 to-black text-white shadow-md rounded-lg">
      <h2 className="text-3xl font-semibold mb-6 text-center">
        Submit Research Report
      </h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
          <div>
            <label className="block text-sm font-medium">Company Name</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Sector</label>
            <input
              type="text"
              name="Sector"
              value={formData.Sector}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Symbol</label>
            <input
              type="text"
              name="symbol"
              value={formData.symbol}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Recommendation</label>
            <select
              name="recommendation"
              value={formData.recommendation}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            >
              <option value="BUY">BUY</option>
              <option value="HOLD">HOLD</option>
              <option value="SELL">SELL</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Strategy</label>
            <select
              name="Strategy"
              value={formData.Strategy}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            >
              <option value="BTSM">BTSM</option>
              <option value="BTSF">BTSF</option>
              <option value="BTSY">BTSY</option>
              <option value="BTSFY">BTSFY</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Shariah Status</label>
            <select
              name="ShariahStatus"
              value={formData.ShariahStatus}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            >
              <option value="Compliant">Compliant</option>
              <option value="Non-Compliant">Non-Compliant</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium">Buy Range</label>
            <input
              type="text"
              name="buyRange"
              value={formData.buyRange}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          {/* <div>
            <label className="block text-sm font-medium">Report Date</label>
            <input
              type="date"
              name="reportDate"
              value={formData.reportDate}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div> */}
          <div>
            <label className="block text-sm font-medium">Target Price</label>
            <input
              type="text"
              name="targetPrice"
              value={formData.targetPrice}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Stop Loss</label>
            <input
              type="text"
              name="stopLoss"
              value={formData.stopLoss}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Duration</label>
            <input
              type="text"
              name="Duration"
              value={formData.Duration}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          {/* <div>
            <label className="block text-sm font-medium">Potential Left</label>
            <input
              type="text"
              name="potentialLeft"
              value={formData.potentialLeft}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div> */}
          <div>
            <label className="block text-sm font-medium">
              Capital Investment
            </label>
            <input
              type="number"
              name="capitalInvestment"
              value={formData.capitalInvestment}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          <div className="md:col-span-2">
            <label className="block text-sm font-medium">Summary</label>
            <textarea
              name="Summary"
              value={formData.Summary}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Rating</label>
            <input
              type="text"
              name="rating"
              value={formData.rating}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Report Link</label>
            <input
              type="text"
              name="reportLink"
              value={formData.reportLink}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`px-4 py-2 w-full md:w-auto bg-gradient-to-r from-green-500 to-blue-500 text-white rounded-full shadow-lg transform transition-transform hover:scale-105 ${
              isSubmitting ? "opacity-50" : ""
            }`}
          >
            {isSubmitting ? "Submitting..." : "Submit Report"}
          </button>
        </div>
      </form>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="success-modal-title" variant="h6" component="h2">
            Success
          </Typography>
          <Typography id="success-modal-description" sx={{ mt: 2 }}>
            {successMessage}
          </Typography>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ResearchReportForm;
