import React from "react";

const QuickFinshaFeatureItem = ({ image, heading, description }) => {
  return (
    <div className="flex flex-col gap-2 bg-gradient-to-r from-yellow-50 to-green-50 rounded-md">
      <div className="flex h-16 w-60 p-1">
        <img className="h-12 w-12 inline " src={image} alt={heading} />
        <p className="text-[#286e34] font-semibold ml-2">{heading}</p>
      </div>
      <div className="w-60 line-clamp-3 hover:line-clamp-none p-1">
        {description}
      </div>
    </div>
  );
};

export default QuickFinshaFeatureItem;
