import { createSlice } from "@reduxjs/toolkit";

const companiesSlice = createSlice({
    name:'companies',
    initialState:{
        companies:[],
    }, 
    reducers:{
        addCompanies:(state,action)=>{
        state.companies=action.payload;
        },

    }
})

export const {addCompanies} = companiesSlice.actions;
export default companiesSlice.reducer;
