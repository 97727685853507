import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import "./UnSubscribeEmail.css";
import axios from "axios";
import { constaints } from "../utils/constaints";

function UnSubscribeEmail() {
  const { token } = useParams();
  const history = useHistory();
  const [status, setStatus] = useState("processing");
  const [message, setMessage] = useState("Processing your request...");
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  // useEffect(() => {
  //   fetch(`${SERVER_URL}/finview/unsubscribe/ipo/${token}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success) {
  //         setStatus("success");
  //         setMessage(
  //           "You have been successfully unsubscribed from IPO emails. We're sorry to see you go!"
  //         );
  //       } else {
  //         setStatus("error");
  //         setMessage("Unsubscription failed. Please try again later.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error during unsubscription:", error);
  //       setStatus("error");
  //       setMessage("An error occurred. Please try again later.");
  //     });
  // }, [token]);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/finview/unsubscribe/ipo/${token}`)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setStatus("success");
          setMessage(
            "You have been successfully unsubscribed from IPO emails. We're sorry to see you go!"
          );
        } else {
          setStatus("error");
          setMessage("Unsubscription failed. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error during unsubscription:", error);
        setStatus("error");
        setMessage("An error occurred. Please try again later.");
      });
  }, [token]);

  const renderIcon = () => {
    if (status === "processing") {
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="4x"
          className="icon-green"
        />
      );
    } else if (status === "success") {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="4x"
          className="icon-green"
        />
      );
    } else if (status === "error") {
      return (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          size="4x"
          className="icon-green"
        />
      );
    }
  };

  const handleButtonClick = () => {
    history.push("/stock-idea");
  };

  return (
    <div className="unsubscribe-container">
      <div className="unsubscribe-box">
        {renderIcon()}
        <h1 className="unsubscibe__title">Unsubscribe</h1>
        <p>{message}</p>
        <div>
          <p>
            To see our latest stock idea recommendation
            <span>
              <button className="home-button" onClick={handleButtonClick}>
                click here
              </button>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default UnSubscribeEmail;
