import React from "react";

// Create a Feature component
const Feature = ({ feature }) => (
  <div className="text-[var(--primary-variant)] border-2 rounded-lg border-[var(--primary-variant)] hover:scale-105 flex justify-center items-center px-9 py-9 flex-auto mx-auto sm:py-7">
    <img
      className="inline aspect-square h-8 w-8"
      src={feature.imgSrc}
      alt={feature.altText}
    ></img>
    <span className="font-semibold ml-2">{feature.text}</span>
  </div>
);

export default Feature;
