import React, { useState } from "react";
import { useForm } from "../../Shared/Form-Hooks/Form-hooks";
import { useHistory } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import "./Forgot.css";
import { constaints } from "../../utils/constaints";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  const history = useHistory();

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  const handlePhoneInputChange = (value) => {
    const fullPhoneNumber = `91${value}`;
    if (fullPhoneNumber.length < 12) {
      setPhoneError("Phone number must be at least 10 digits");
    } else {
      setPhoneError("");
    }
    setPhone(fullPhoneNumber);
  };

  // const sendOtp = async (event) => {
  //   event.preventDefault();
  //   setIsLoading(true);
  //   setErrorMessage("");

  //   try {
  //     const response = await fetch(`${SERVER_URL}/auth/email/send`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         email: email,
  //         phone: phone,
  //       }),
  //     });

  //     const responseData = await response.json();
  //     if (responseData.success) {
  //       setShowOtpForm(true);
  //       setModalMessage("Please check your email and WhatsApp for the OTP");
  //       setShowModal(true);
  //     } else {
  //       setErrorMessage("Email ID does not exist.");
  //       setModalMessage("Error: Email ID does not exist.");
  //       setShowModal(true);
  //     }
  //   } catch (error) {
  //     setErrorMessage("Error sending OTP. Please try again.");
  //     setModalMessage("Error sending OTP. Please try again.");
  //     setShowModal(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const sendOtp = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    try {
      const response = await axios.post(
        `${SERVER_URL}/auth/email/send`,
        {
          email: email,
          phone: phone,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = response.data;
      if (responseData.success) {
        setShowOtpForm(true);
        setModalMessage("Please check your email and WhatsApp for the OTP");
        setShowModal(true);
      } else {
        setErrorMessage("Email ID does not exist.");
        setModalMessage("Error: Email ID does not exist.");
        setShowModal(true);
      }
    } catch (error) {
      setErrorMessage("Error sending OTP. Please try again.");
      setModalMessage("Error sending OTP. Please try again.");
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleOtpSubmit = async (event) => {
  //   event.preventDefault();
  //   setIsLoading(true);
  //   setErrorMessage("");

  //   try {
  //     const response = await fetch(`${SERVER_URL}/auth/validate-otp`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         email: email,
  //         otpCode: otp,
  //       }),
  //     });

  //     const responseData = await response.json();
  //     if (responseData.status === "Success") {
  //       setShowResetForm(true);
  //       setModalMessage("OTP verified successfully.");
  //       setShowModal(true);
  //     } else {
  //       let errorMsg = "Invalid OTP. Please try again.";
  //       setErrorMessage(errorMsg);
  //       setModalMessage(errorMsg);
  //       setShowModal(true);
  //     }
  //   } catch (error) {
  //     let errorMsg = "Error occurred while validating OTP. Please try again.";
  //     setErrorMessage(errorMsg);
  //     setModalMessage(errorMsg);
  //     setShowModal(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    try {
      const response = await axios.post(
        `${SERVER_URL}/auth/validate-otp`,
        {
          email: email,
          otpCode: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = response.data;
      if (responseData.status === "Success") {
        setShowResetForm(true);
        setModalMessage("OTP verified successfully.");
        setShowModal(true);
      } else {
        let errorMsg = "Invalid OTP. Please try again.";
        setErrorMessage(errorMsg);
        setModalMessage(errorMsg);
        setShowModal(true);
      }
    } catch (error) {
      let errorMsg = "Error occurred while validating OTP. Please try again.";
      setErrorMessage(errorMsg);
      setModalMessage(errorMsg);
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleResetPassword = async (event) => {
  //   event.preventDefault();
  //   setIsLoading(true);
  //   setErrorMessage("");

  //   if (newPassword !== confirmPassword) {
  //     setErrorMessage("Passwords do not match. Please try again.");
  //     setModalMessage("Passwords do not match. Please try again.");
  //     setShowModal(true);
  //     setIsLoading(false);
  //     return;
  //   }

  //   try {
  //     const response = await fetch(`${SERVER_URL}/auth/change-password`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         email: email,
  //         otpCode: otp,
  //         password: newPassword,
  //       }),
  //     });

  //     const responseData = await response.json();

  //     if (responseData.status === "Success") {
  //       setSuccessMessage("Password changed successfully.");
  //       setModalMessage("Password changed successfully.");
  //       setShowModal(true);

  //       // Redirect back to login with pre-filled email and new password
  //       history.push({
  //         pathname: "/landing-page", // Update this to your login route
  //         state: { email, password: newPassword },
  //       });
  //     } else {
  //       setErrorMessage("Error: " + responseData.message);
  //       setModalMessage("Error: " + responseData.message);
  //       setShowModal(true);
  //     }
  //   } catch (error) {
  //     setErrorMessage(
  //       "An error occurred while resetting the password. Please try again."
  //     );
  //     setModalMessage(
  //       "An error occurred while resetting the password. Please try again."
  //     );
  //     setShowModal(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match. Please try again.");
      setModalMessage("Passwords do not match. Please try again.");
      setShowModal(true);
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${SERVER_URL}/auth/change-password`,
        {
          email: email,
          otpCode: otp,
          password: newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = response.data;

      if (responseData.status === "Success") {
        setSuccessMessage("Password changed successfully.");
        setModalMessage("Password changed successfully.");
        setShowModal(true);

        // Redirect back to login with pre-filled email and new password
        history.push({
          pathname: "/landing-page", // Update this to your login route
          state: { email, password: newPassword },
        });
      } else {
        setErrorMessage(responseData.message);
        setModalMessage(responseData.message);
        setShowModal(true);
      }
    } catch (error) {
      setErrorMessage(
        "An error occurred while resetting the password. Please try again."
      );
      setModalMessage(
        "An error occurred while resetting the password. Please try again."
      );
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const redirectToLogin = () => {
    history.push("/Auth");
  };

  return (
    <div className="forgot-container">
      <div className="forgot-box">
        <h1 className="forgot-heading">Forgot Password</h1>
        {!showOtpForm ? (
          <form onSubmit={sendOtp} className="forgot-email-form">
            <TextField
              label="Please enter your email"
              type="email"
              className="forgot-email-input"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-label="Email"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <TextField
              label=" Whatsapp number"
              type="tel"
              className="forgot-phone-input"
              placeholder="Enter Whatsapp Number"
              required
              value={phone.substring(2)} // Display phone number without the country code
              onChange={(e) => handlePhoneInputChange(e.target.value)}
              aria-label="Phone"
              fullWidth
              variant="outlined"
              margin="normal"
              error={phoneError.length > 0}
              helperText={phoneError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
            <div className="center-btn-container">
              <Button
                type="submit"
                className="forgot-btn__sent_otp"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Send OTP"}
              </Button>
            </div>
          </form>
        ) : !showResetForm ? (
          <form onSubmit={handleOtpSubmit} className="forgot-otp-form">
            <TextField
              label="Please enter the OTP"
              type="number"
              className="forgot-otp-input"
              placeholder="Enter the OTP"
              required
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              aria-label="OTP"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <div className="center-btn-container">
              <Button
                type="submit"
                className="forgot-btn_verify_otp"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Verify OTP"}
              </Button>
            </div>
          </form>
        ) : (
          <form
            className="forgot-reset-password-form"
            onSubmit={handleResetPassword}
          >
            <Typography variant="h6" className="forgot-reset-heading">
              Reset Password
            </Typography>
            <TextField
              label="New password"
              type={showPassword ? "text" : "password"}
              className="forgot-password-input"
              placeholder="New password"
              required
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              aria-label="New Password"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Confirm password"
              type={showPassword ? "text" : "password"}
              className="forgot-password-input"
              placeholder="Confirm password"
              required
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              aria-label="Confirm Password"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <Box className="forgot-show-password">
              <input
                type="checkbox"
                onChange={() => setShowPassword((prevState) => !prevState)}
              />
              <Typography variant="body2">Show password</Typography>
            </Box>
            <div className="center-btn-container">
              <Button
                type="submit"
                className="forgot-btn__change_password"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Change Password"}
              </Button>
            </div>
          </form>
        )}
      </div>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
            textAlign: "center",
            zIndex: 9999, // Ensure it's above other elements
            display: showModal ? "block" : "none",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {errorMessage ? "Error" : "Success"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
          {successMessage && (
            <Button
              onClick={redirectToLogin}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              className="modal__gotoLogin_btn"
            >
              Go to Login
            </Button>
          )}
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color="secondary"
            className="modal__lastclose_btn"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
