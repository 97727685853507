import React, { useState } from "react";
import axios from "axios";
import { FaSearch, FaDownload, FaTrash, FaTimes } from "react-icons/fa";
import { constaints } from "../../../../../utils/constaints";

const CompanySearchAndDownload = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // New state for Select All
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/admin/companies/search?name=${searchTerm}`
      );
      setCompanies(response.data);
      setSelectAll(false); // Reset select all when new search is made
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleSelect = (company) => {
    setSelectedCompanies((prev) => {
      if (prev.some((c) => c._id === company._id)) {
        return prev.filter((c) => c._id !== company._id);
      } else {
        return [...prev, company];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Deselect all companies
      const filteredIds = companies.map((company) => company._id);
      setSelectedCompanies((prev) =>
        prev.filter((company) => !filteredIds.includes(company._id))
      );
    } else {
      // Select all companies
      const newSelected = companies.filter(
        (company) =>
          !selectedCompanies.some((selected) => selected._id === company._id)
      );
      setSelectedCompanies((prev) => [...prev, ...newSelected]);
    }
    setSelectAll(!selectAll); // Toggle select all state
  };

  const handleDelete = async () => {
    try {
      const idsToDelete = selectedCompanies.map((company) => company._id);
      await Promise.all(
        idsToDelete.map((id) =>
          axios.delete(`${SERVER_URL}/admin/companies/${id}`)
        )
      );
      setCompanies((prev) =>
        prev.filter((company) => !idsToDelete.includes(company._id))
      );
      setSelectedCompanies([]);
    } catch (error) {
      console.error("Error deleting companies:", error);
    }
  };

  const downloadExcel = async () => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/admin/companies/export`,
        { companies: selectedCompanies },
        {
          responseType: "blob", // Important for downloading binary files
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "companies.xlsx");
      document.body.appendChild(link);
      link.click();

      // Clear search term, companies, and selected companies after download
      setSearchTerm("");
      setCompanies([]);
      setSelectedCompanies([]);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const removeSelectedCompany = (companyId) => {
    setSelectedCompanies((prev) =>
      prev.filter((company) => company._id !== companyId)
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex items-center space-x-4 mb-2">
        <div className="relative w-full">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search for a company"
            className="w-full p-2 bg-gray-800 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent transition duration-300 ease-in-out"
          />
          <FaSearch className="absolute right-3 top-3 text-gray-400" />
        </div>
        <button
          onClick={handleSearch}
          className="flex items-center bg-gradient-to-r from-blue-500 to-blue-700 text-white px-4 py-2 rounded-lg shadow-md hover:shadow-lg hover:from-blue-600 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-300 ease-in-out"
        >
          <FaSearch className="mr-2" />
          Search
        </button>
      </div>

      {/* Display Selected Companies */}
      {selectedCompanies.length > 0 && (
        <div className="mb-2 bg-gray-800 p-2 rounded mt-4">
          <h3 className="text-white mb-1 text-sm">Selected Companies:</h3>
          <div className="flex flex-wrap gap-1">
            {selectedCompanies.map((company) => (
              <div
                key={company._id}
                className="flex items-center bg-green-500 text-white px-1.5 py-0.5 rounded-md text-xs"
              >
                <span>{company["Company Name"]}</span>
                <FaTimes
                  onClick={() => removeSelectedCompany(company._id)}
                  className="ml-2 cursor-pointer text-white hover:text-red-400"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {companies.length > 0 && (
        <div className="max-h-80 overflow-y-auto bg-gray-900 rounded-lg shadow-md">
          <table className="min-w-full text-white">
            <thead className="bg-gray-800 text-gray-400 sticky top-0">
              <tr>
                <th className="p-3 text-left">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="form-checkbox h-4 w-4 text-blue-500 bg-gray-800 border-gray-600"
                  />
                </th>
                <th className="p-3 text-left">Company Name</th>
                <th className="p-3 text-left">Sector</th>
                {/* Add more columns as needed */}
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr
                  key={company._id}
                  className={`border-t border-gray-700 ${
                    selectedCompanies.some((c) => c._id === company._id)
                      ? "bg-gray-700"
                      : ""
                  }`}
                >
                  <td className="p-3">
                    <input
                      type="checkbox"
                      checked={selectedCompanies.some(
                        (c) => c._id === company._id
                      )}
                      onChange={() => handleSelect(company)}
                      className="form-checkbox h-4 w-4 text-blue-500 bg-gray-800 border-gray-600"
                    />
                  </td>
                  <td className="p-3">{company["Company Name"]}</td>
                  <td className="p-3">{company["Business Sector"]}</td>
                  {/* Add more cells as needed */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedCompanies.length > 0 && (
        <div className="flex justify-end mt-4 space-x-4">
          <button
            onClick={handleDelete}
            className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-400 flex items-center"
          >
            <FaTrash className="mr-2" />
            Delete
          </button>
          <button
            onClick={downloadExcel}
            className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 flex items-center"
          >
            <FaDownload className="mr-2" />
            Download
          </button>
        </div>
      )}
    </div>
  );
};

export default CompanySearchAndDownload;
