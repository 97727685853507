import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaInfoCircle, FaBoxOpen } from "react-icons/fa";
import axios from "axios";
import "./MarketView.css";
import { constaints } from "../../../utils/constaints";

function MarketView() {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/finview/articles`, {
          withCredentials: true,
        });

        setArticles(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [SERVER_URL]);

  const extractFirstImage = (content) => {
    const div = document.createElement("div");
    div.innerHTML = content;
    const img = div.querySelector("img");
    return img ? img.src : null;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid Date";
    return date.toLocaleDateString("en-GB");
  };

  if (isLoading) {
    return <div className="loading-container">Loading articles...</div>;
  }

  if (!articles.length) {
    return (
      <div
        style={{
          backgroundColor: "var(--surface)",
          color: "var(--on-surface)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "1rem",
            color: "var(--on-surface)",
          }}
        >
          <FaInfoCircle
            style={{
              fontSize: "2rem",
              marginBottom: "0.5rem",
              color: "var(--on-surface)",
            }}
          />
          <p>No articles available at the moment. Please check back later!</p>
        </div>
        <div>
          <FaBoxOpen style={{ fontSize: "4rem", color: "var(--on-surface)" }} />
        </div>
      </div>
    );
  }

  return (
    <div className="articles-container bg-[var(--surface)]">
      {articles.map((article) => {
        const firstImage = extractFirstImage(article.content);
        const thumbnail = firstImage || article.metaOgImage;

        return (
          <div className="article-card">
            <Link to={`/market/${article.slug}`} className="read-more">
              <div key={article._id}>
                {thumbnail ? (
                  <img
                    src={thumbnail}
                    alt={article.title}
                    className="article-thumbnail"
                  />
                ) : (
                  <div className="article-placeholder">
                    <FaInfoCircle className="placeholder-icon" />
                  </div>
                )}
                <div className="article-content">
                  {article.title}
                  <p className="article-description">
                    {article.metaDescription}
                  </p>
                  <div className="article-authors">
                    {article.authors.map((author, index) => (
                      <span key={author._id || index}>
                        {author.link ? (
                          <a
                            href={author.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="author-link"
                          >
                            {author.name}
                          </a>
                        ) : (
                          <span className="author-name">{author.name}</span>
                        )}
                        {index < article.authors.length - 1 && ", "}
                      </span>
                    ))}
                  </div>
                  <p className="article-date">
                    {formatDate(article.createdAt)}
                  </p>
                  <Link to={`/market/${article.slug}`} className="read-more">
                    Read More...
                  </Link>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export default MarketView;
