import React, { useState } from "react";
import { Button, TextField, Modal, Box, Typography } from "@mui/material";

export default function LinkModal({ open, handleClose, label, onSubmit }) {
  const [inputValue, setInputValue] = useState("");

  const handleSubmit = () => {
    onSubmit(inputValue); // Pass the value back to the parent
    setInputValue(""); // Reset input
    handleClose(); // Close the modal
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-br from-gray-800 via-gray-900 to-black text-white shadow-lg p-6 rounded-lg max-w-md w-full">
        <Typography
          variant="h6"
          className="text-xl font-bold mb-4 text-gray-100"
        >
          {label}
        </Typography>
        <TextField
          fullWidth
          label="Enter URL"
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg"
          InputProps={{
            style: { color: "#fff" },
          }}
          InputLabelProps={{
            style: { color: "rgba(255,255,255,0.7)" },
          }}
        />
        <Box className="mt-4 flex justify-end space-x-4">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            className="border-gray-400 text-gray-300 hover:bg-gray-700"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!inputValue.trim()}
            className={`bg-blue-600 text-white hover:bg-blue-700 ${
              !inputValue.trim() ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
