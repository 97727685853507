import { NavLink } from "react-router-dom";
import { Box } from "../../components/functional/box/Box";
import { Section } from "../../components/functional/section/Section";
import { Button } from "../../components/ui/button/Button";
import { Text } from "../../components/ui/text/Text";
import TvChart from "../../Screener/components/TvChart";

export const MarketSection = () => {

  return (
    <Section>
      <Box gap="md">
      <TvChart symbol="BSE:SENSEX" />
      <Box gap="lg">
      <Text as="h2" variant="heading">Stay Ahead with Live Market Updates</Text>
      <NavLink to="/finview/market"><Button variant="tonal" label="Explore market trends" icon="arrow_forward" iconPos="end" /></NavLink>
      </Box>
      </Box>
    </Section>
  );
};