import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Modal,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import { AuthContext } from "../../../Shared/context/auth-context.js";
import "./LandingPagePlan.css";
import axios from "axios";
import SubscriptionPlan, {
  withPromotedLabel,
} from "../component/LandingSubscriptionPlan.js";
import CircularProgress from "@mui/material/CircularProgress";
import { Visibility, VisibilityOff, Close } from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { CheckCircle } from "@mui/icons-material";
import CloseIcon from "@material-ui/icons/Close";
import "tailwindcss/tailwind.css";
import AddPanDetails from "../../../PanDetails/pages/AddPanDetails.js";
import { constaints } from "../../../utils/constaints.js";

const LandingPageSubscription = () => {
  const { user, isLoggedIn, login } = useContext(AuthContext);
  const userId = user?._id;
  const [, setUser] = useState(null);
  const currentSubscription = user?.subscription;
  const [inputs, setInputs] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    otp: "",
  });
  const [errors, setErrors] = useState({});
  const [otpSent, setOtpSent] = useState(false);
  const history = useHistory();
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const [showForm, setShowForm] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [showPaymentButton, setShowPaymentButton] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [loginInputs, setLoginInputs] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [proceedToPayment, setProceedToPayment] = useState(false);
  const [showRegisterPassword, setShowRegisterPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogIcon, setDialogIcon] = useState(null);
  const [showPlanSummary, setShowPlanSummary] = useState(false);
  const SubscriptionPlanPromoted = withPromotedLabel(SubscriptionPlan);
  const [showPanModal, setShowPanModal] = useState(false); // State to control PAN modal visibility
  const [hasPanDetails, setHasPanDetails] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Script to opent he razor pay windows
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // validate the registration input , each input have some basic rules
  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "fname":
      case "lname":
        if (!value.trim()) error = "This field is required";
        break;
      case "phone":
        if (!value.trim()) {
          error = "This field is required";
        } else if (!/^\d{10}$/.test(value)) {
          error =
            "Please enter a valid 10-digit WhatsApp number for communication.";
        }
        break;
      case "email":
        if (!value.trim()) {
          error = "This field is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Email must be a valid email address";
        }
        break;
      case "password":
        if (value.length < 6) {
          error = "Password must be at least 6 characters long";
        }
        break;
      case "confirmPassword":
        if (value !== inputs.password) {
          error = "Passwords do not match";
        }
      default:
        break;
    }
    return error;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;
    if (name === "phone") {
      // Remove any existing `91` prefix before checking length
      if (formattedValue.startsWith("91")) {
        formattedValue = formattedValue.slice(2);
      }
      // Allow only numbers and limit to 10 digits
      formattedValue = formattedValue.replace(/\D/g, "").slice(0, 10);
    }

    if (name === "otp") {
      formattedValue = value.replace(/\s+/g, "");
    }
    const error = validateInput(name, formattedValue);
    setInputs((prev) => ({ ...prev, [name]: formattedValue }));
    setErrors((prev) => ({ ...prev, [name]: error }));

    // Check if all fields are valid
    setIsFormValid(
      inputs.fname.trim() &&
        inputs.lname.trim() &&
        /^\d{10}$/.test(inputs.phone) &&
        /\S+@\S+\.\S+/.test(inputs.email) &&
        inputs.password.length >= 6 &&
        inputs.password === inputs.confirmPassword &&
        !error // Ensure no errors
    );
  };

  useEffect(() => {
    const allFieldsValid =
      inputs.fname.trim() &&
      inputs.lname.trim() &&
      /^\d{10}$/.test(inputs.phone) &&
      /\S+@\S+\.\S+/.test(inputs.email) &&
      inputs.password.length >= 6 &&
      inputs.password === inputs.confirmPassword &&
      !errors.fname &&
      !errors.lname &&
      !errors.phone &&
      !errors.email &&
      !errors.password;
    !errors.confirmPassword; // Add confirmPassword error check

    setIsFormValid(allFieldsValid);
  }, [inputs, errors]);

  // remove fetch with axios
  const checkRegistration = async () => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/auth/is-registered`,
        { email: inputs.email.trim() },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.status === 200 && response.data.isRegistered;
    } catch (error) {
      alert("Failed to check if user is already registered.");
      return false; // Default to false in case of error
    }
  };

  // remove fetch with axios
  const generateOtp = async () => {
    setIsLoading(true);
    const isRegistered = await checkRegistration();
    if (isRegistered) {
      setDialogMessage("You are already registered. Please login.");
      setDialogOpen(true);
      setShowLoginForm(true);
      setIsLoading(false);
      return; // Stop OTP generation if already registered
    }

    try {
      const response = await axios.post(
        `${SERVER_URL}/auth/register`,
        {
          ...inputs,
          phone: `91${inputs.phone}`, // Prepend `91` to the phone number
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setOtpSent(true);
        setDialogMessage(
          "OTP has been sent successfully via Whatsapp and Email."
        );
        setDialogOpen(true);
      } else {
        throw new Error(response.data.message || "Failed to generate OTP");
      }
    } catch (error) {
      setDialogMessage(`Failed to send OTP: ${error.message}`);
      setDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  // remove fetch with axios
  // const verifyOtp = async (paymentMethod) => {
  //   setIsLoading(true); // Start loading
  //   try {
  //     const response = await axios.post(
  //       `${SERVER_URL}/auth/email/validate-otp`,
  //       {
  //         fname: inputs.fname,
  //         lname: inputs.lname,
  //         email: inputs.email,
  //         phone: `91${inputs.phone}`,
  //         password: inputs.password,
  //         otp: inputs.otp.trim(),
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setOtpVerified(true);

  //       // Show success message with icon
  //       setDialogMessage("OTP verification successful!");
  //       setDialogIcon(
  //         <CheckCircle
  //           style={{ color: "green", fontSize: 40, animation: "bounce 1s" }}
  //         />
  //       );
  //       setDialogOpen(true);

  //       // Close the signup form
  //       setShowForm(false);

  //       // Close the dialog after 2 seconds
  //       setTimeout(() => {
  //         setDialogOpen(false);
  //         setDialogMessage("Initializing payment method...");
  //         setIsLoading(true); // Show loading state before initializing payment

  //         setTimeout(async () => {
  //           try {
  //             const loginResponse = await axios.post(
  //               `${SERVER_URL}/auth/login/local`,
  //               {
  //                 email: inputs.email,
  //                 password: inputs.password,
  //               }
  //             );

  //             if (loginResponse.data.message === "Login successful") {
  //               login(loginResponse.data.user);
  //               setUser(loginResponse.data.user);

  //               await handleOneTimePayment(loginResponse.data.user._id);
  //             } else {
  //               setDialogMessage(loginResponse.data.message);
  //               setDialogIcon(null);
  //               setIsLoading(false);
  //             }
  //           } catch (loginError) {
  //             setDialogMessage(loginError.message);
  //             setDialogIcon(null);
  //             setIsLoading(false);
  //           }
  //         }, 3000);
  //       }, 2000);
  //     } else {
  //       throw new Error(response.data.error || "Failed to verify OTP");
  //     }
  //   } catch (error) {
  //     setDialogMessage(`OTP verification failed: ${error.message}`);
  //     setDialogIcon(null);
  //     setDialogOpen(true);
  //     setIsLoading(false);
  //   }
  // };

  const verifyOtp = async (paymentMethod) => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post(
        `${SERVER_URL}/auth/email/validate-otp`,
        {
          fname: inputs.fname,
          lname: inputs.lname,
          email: inputs.email,
          phone: `91${inputs.phone}`,
          password: inputs.password,
          otp: inputs.otp.trim(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setOtpVerified(true);

        // Show success message with icon
        setDialogMessage("OTP verification successful!");
        setDialogIcon(
          <CheckCircle
            style={{ color: "green", fontSize: 40, animation: "bounce 1s" }}
          />
        );
        setDialogOpen(true);

        // Close the signup form
        setShowForm(false);

        // Close the dialog after 2 seconds
        setTimeout(() => {
          setDialogOpen(false);
          setDialogMessage("Initializing payment method...");
          setIsLoading(true); // Show loading state before initializing payment

          setTimeout(async () => {
            try {
              const loginResponse = await axios.post(
                `${SERVER_URL}/auth/login/local`,
                {
                  email: inputs.email,
                  password: inputs.password,
                }
              );

              if (loginResponse.data.message === "Login successful") {
                login(loginResponse.data.user);
                setUser(loginResponse.data.user);

                // Check if user has PAN details
                const hasPan = await checkPanDetails();
                if (hasPan) {
                  // If PAN details exist, proceed to payment
                  await handleOneTimePayment(loginResponse.data.user._id);
                } else {
                  // Show PAN details form if not present
                  setShowPanModal(true);
                }
              } else {
                setDialogMessage(loginResponse.data.message);
                setDialogIcon(null);
                setIsLoading(false);
              }
            } catch (loginError) {
              setDialogMessage(loginError.message);
              setDialogIcon(null);
              setIsLoading(false);
            }
          }, 3000);
        }, 2000);
      } else {
        throw new Error(response.data.error || "Failed to verify OTP");
      }
    } catch (error) {
      setDialogMessage(`OTP verification failed: ${error.message}`);
      setDialogIcon(null);
      setDialogOpen(true);
      setIsLoading(false);
    }
  };

  // const handleLoginAndPay = async (paymentMethod) => {
  //   // Add a parameter for payment method
  //   setIsLoading(true);
  //   try {
  //     const loginResponse = await axios.post(`${SERVER_URL}/auth/login/local`, {
  //       email: loginInputs.email,
  //       password: loginInputs.password,
  //     });

  //     if (loginResponse.data.message === "Login successful") {
  //       login(loginResponse.data.user); // Update AuthContext with new user data

  //       // Fetch the updated user details possibly with subscription info or use context if updated
  //       const updatedUserDetails = loginResponse.data.user;

  //       // Validate if the user is already subscribed to the selected plan
  //       if (
  //         updatedUserDetails.subscription &&
  //         updatedUserDetails.subscription.tier === selectedPlan.tier &&
  //         updatedUserDetails.subscription.duration === selectedPlan.duration &&
  //         updatedUserDetails.subscription.activated === true && // Check if the subscription is activated
  //         new Date(updatedUserDetails.subscription.expiryDate) > new Date()
  //       ) {
  //         setDialogMessage(`You have already subscribed to this plan.`);
  //         setDialogOpen(true);
  //         setIsLoading(false);
  //         return; // Stop the subscription process
  //       }

  //       setShowLoginForm(false); // Hide login form after successful login
  //       setShowForm(false); // Close the entire form/modal

  //       // Proceed with the selected payment method
  //       await handleOneTimePayment(updatedUserDetails._id);
  //     } else {
  //       setDialogMessage(loginResponse.data.message);
  //       setDialogOpen(true);
  //       setIsLoading(false); // Stop loading
  //     }
  //   } catch (error) {
  //     setDialogMessage(error.message);
  //     setDialogOpen(true);
  //     setIsLoading(false); // Stop loading
  //   }
  // };

  const handleLoginAndPay = async (paymentMethod) => {
    setIsLoading(true);
    try {
      const loginResponse = await axios.post(`${SERVER_URL}/auth/login/local`, {
        email: loginInputs.email,
        password: loginInputs.password,
      });

      if (loginResponse.data.message === "Login successful") {
        login(loginResponse.data.user); // Update AuthContext with new user data

        const updatedUserDetails = loginResponse.data.user;

        // Validate if the user is already subscribed to the selected plan
        if (
          updatedUserDetails.subscription &&
          updatedUserDetails.subscription.tier === selectedPlan.tier &&
          updatedUserDetails.subscription.duration === selectedPlan.duration &&
          updatedUserDetails.subscription.activated === true &&
          new Date(updatedUserDetails.subscription.expiryDate) > new Date()
        ) {
          setDialogMessage(`You have already subscribed to this plan.`);
          setDialogOpen(true);
          setIsLoading(false);
          return; // Stop the subscription process
        }

        // Check if the user has PAN details
        const hasPan = await checkPanDetails();
        if (hasPan) {
          // If PAN exists, proceed with the payment
          setShowLoginForm(false); // Hide login form
          setShowForm(false); // Close the entire form/modal
          await handleOneTimePayment(updatedUserDetails._id);
        } else {
          // If no PAN details, show PAN input modal
          setShowLoginForm(false); // Close the login modal immediately
          setShowForm(false);
          setShowPanModal(true); // Show the PAN input modal
          setIsLoading(false); // Stop loading since waiting for user input
        }
      } else {
        setDialogMessage(loginResponse.data.message);
        setDialogOpen(true);
        setIsLoading(false); // Stop loading
      }
    } catch (error) {
      setDialogMessage(error.message);
      setDialogOpen(true);
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (proceedToPayment && selectedPlan) {
      handleOneTimePayment();
      setProceedToPayment(false);
    }
  }, [proceedToPayment, selectedPlan]);

  // toogler funtion to show and hide the password

  const toggleRegisterPasswordVisibility = () => {
    setShowRegisterPassword(!showRegisterPassword);
  };
  // toogler funtion to show and hide the password
  const toggleLoginPasswordVisibility = () => {
    setShowLoginPassword(!showLoginPassword);
  };

  const handleOneTimePayment = async (userId) => {
    if (!userId) {
      setDialogMessage("Failed to initiate payment: User ID is missing.");
      setIsLoading(false);
      return;
    }

    const userEmail = isLoggedIn
      ? user.email.trim().toLowerCase()
      : inputs.email.trim().toLowerCase();
    const userName = isLoggedIn
      ? `${user.fname} ${user.lname}`
      : `${inputs.fname} ${inputs.lname}`;
    const userPhone = isLoggedIn ? user.phone : inputs.phone;

    setIsLoading(true); // Show loading indicator
    setDialogMessage("Initializing payment method..."); // Set loading message
    setShowPlanSummary(false); // Close the summary modal

    try {
      // Create one-time payment order via backend API
      const response = await axios.post(
        `${SERVER_URL}/wallet/landing-page-payment`,
        {
          plan_id: selectedPlan.planId,
          userId: userId, // Use the userId parameter
          duration: selectedPlan.duration,
        }
      );

      const { data: order } = response.data;

      // Initiate Razorpay payment
      const options = {
        key: constaints.REACT_APP_RAZORPAY_KEY_ID,
        amount: order.amount, // in paise
        currency: "INR",
        name: "Finsha Research Service",
        description: "QuickWin One-Time Subscription",
        order_id: order.id,
        handler: async function (response) {
          try {
            const verificationResponse = await axios.post(
              `${SERVER_URL}/wallet/landing-page-payment/verify`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                userId: userId, // Use the userId parameter
                duration: selectedPlan.duration,
              }
            );

            if (verificationResponse.data.success) {
              login(verificationResponse.data.user); // Update user context
              setDialogMessage("Payment successful!");
              setTimeout(() => {
                setIsLoading(false);
                history.push("/stock-idea");
                setTimeout(() => {
                  window.location.reload(); // Force reload the stock-idea page
                }, 100);
              }, 3000);
            } else {
              setDialogMessage("Failed to activate subscription");
              setIsLoading(false);
            }
          } catch (verificationError) {
            setDialogMessage(
              `Payment verification failed: ${verificationError.message}`
            );
            setIsLoading(false);
          }
        },
        prefill: {
          name: userName,
          email: userEmail,
          contact: userPhone,
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: function () {
            setIsLoading(false);
            setDialogMessage("Payment process interrupted. Payment canceled.");
            setDialogOpen(true);
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      setDialogMessage(`Failed to initialize payment: ${error.message}`);
      setIsLoading(false);
    }
  };

  const plans = [
    {
      tier: "QuickWin Starter",
      tier1: "SmartHold Pro",
      monthlyPlan: "1 Month Pack",
      period: "Monthly",
      price: 2999,
      duration: 1,
      discount: "(63% Disc)",
      planId: "4",
      description: [`Including taxes`],
      savings: "Savings upto 63%",
      staticTotalPrice: 2999,
    },
    {
      tier: "QuickWin Starter",
      period: "Quarterly",
      tier1: "SmartHold Pro",
      monthlyPlan: "3 Months Pack",
      price: 7999,
      duration: 3,
      discount: "(67% Disc)",
      planId: "5",
      description: [`Including taxes`],
      savings: "Savings upto 67%",
      staticTotalPrice: 7999,
    },
    {
      tier: "QuickWin Starter",
      period: "Half-Yearly",
      tier1: "SmartHold Pro",
      monthlyPlan: "6 Months Pack",
      price: 14999,
      duration: 6,
      discount: "(69% Disc)",
      planId: "6",
      description: [`Including taxes`],
      savings: "Savings upto 69%",
      staticTotalPrice: 14999,
    },
  ];

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    setShowPlanSummary(true); // Show the plan summary modal for all users
  };

  const checkPanDetails = async () => {
    try {
      const response = await axios.post(`${SERVER_URL}/auth/check-pan`);
      return response.data.hasPanDetails; // Return whether the user has PAN details
    } catch (error) {
      console.error(error);
      return false; // Default to false in case of error
    }
  };

  // Function to handle the check of PAN details and proceed accordingly
  const handleCheckPan = async () => {
    const hasPan = await checkPanDetails();

    if (hasPan) {
      handleOneTimePayment(user._id); // Directly proceed to payment if PAN details are present
    } else {
      setShowPanModal(true); // Show PAN modal if no PAN details
    }
  };

  const handleSummaryPayAndSubscribe = async () => {
    setShowPlanSummary(false); // Close the summary modal
    setIsLoading(true);
    if (isLoggedIn) {
      setProceedToPayment(true); // Set flag to trigger payment in useEffect
    } else {
      setShowForm(true); // Show the form if not logged in
      setIsLoading(false);
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
    if (!showForm) {
      // If we are closing the form, reset everything
      setShowLoginForm(false);
      setInputs({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        password: "",
        otp: "",
      });
      setErrors({});
      setOtpSent(false);
      setOtpVerified(false);
      setShowPaymentButton(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 500,
    backgroundColor: "white",
    borderRadius: "8px",
    border: "none",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
    padding: "32px",
  };

  return (
    <div className="quickwin_landingpage__main_container">
      {isLoading && (
        <div
          className="fixed inset-0 flex items-center justify-center backdrop-blur-lg z-50"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }} // Light dark background
        >
          <div className="spinner"></div>
          <div className="text-center text-white text-lg font-semibold mt-2 ml-2">
            {dialogMessage ? dialogMessage : "Initializing payment method..."}
          </div>
        </div>
      )}

      <div className="flex justify-center gap-4 mt-[5rem] flex-wrap-reverse mb-[2rem]">
        {plans.map((plan) =>
          plan.price !== 14999 ? (
            <SubscriptionPlan
              key={plan.period}
              plan={plan}
              onSelectPlan={handleSelectPlan}
              currentSubscription={currentSubscription}
            />
          ) : (
            <SubscriptionPlanPromoted
              key={plan.period}
              plan={plan}
              onSelectPlan={handleSelectPlan}
              currentSubscription={currentSubscription}
            />
          )
        )}
      </div>
      <Modal
        open={showForm}
        onClose={toggleForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            aria-label="close"
            onClick={toggleForm}
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
          >
            <Close style={{ color: "black" }} />
          </div>
          <div className="text-center font-semibold text-2xl mb-3 p-2">
            {showLoginForm ? "Login" : "Register"}
          </div>

          <Grid container spacing={2} justifyContent="center">
            {!showLoginForm ? (
              <>
                {/* Registration Fields */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="fname"
                    value={inputs.fname}
                    onChange={handleInputChange}
                    error={!!errors.fname}
                    helperText={errors.fname}
                    disabled={otpVerified}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon style={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="First Name*"
                  />
                </Grid>
                {/* input lastname input field  */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="lname"
                    value={inputs.lname}
                    onChange={handleInputChange}
                    error={!!errors.lname}
                    helperText={errors.lname}
                    disabled={otpVerified}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon style={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Last Name*"
                  />
                </Grid>
                {/* user whatsapp number input field  */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="phone"
                    value={inputs.phone}
                    onChange={handleInputChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <WhatsAppIcon style={{ color: "green" }} />
                          </InputAdornment>
                          <InputAdornment position="start">+91</InputAdornment>
                        </>
                      ),
                    }}
                    placeholder="WhatsApp Number*"
                    disabled={otpVerified}
                  />
                </Grid>
                {/* user Email inpput field  */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="email"
                    value={inputs.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    disabled={otpVerified}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon style={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Email*"
                  />
                </Grid>
                {/* user password input field  */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type={showRegisterPassword ? "text" : "password"}
                    label="Create Password"
                    name="password"
                    value={inputs.password}
                    onChange={handleInputChange}
                    error={!!errors.password}
                    helperText={errors.password}
                    disabled={otpVerified}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={toggleRegisterPasswordVisibility}
                            className="cursor-pointer"
                          >
                            {showRegisterPassword ? (
                              <VisibilityOff style={{ color: "red" }} />
                            ) : (
                              <Visibility style={{ color: "green" }} />
                            )}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm Password"
                    name="confirmPassword"
                    value={inputs.confirmPassword}
                    onChange={handleInputChange}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    disabled={otpVerified}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            className="cursor-pointer"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff style={{ color: "red" }} />
                            ) : (
                              <Visibility style={{ color: "green" }} />
                            )}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {otpSent && !otpVerified && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="otp"
                      value={inputs.otp}
                      onChange={handleInputChange}
                      error={!!errors.otp}
                      helperText={errors.otp}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <VpnKeyIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="OTP*"
                    />
                    <div className="text-right mt-2">
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <div className="flex justify-end">
                          <button
                            onClick={() => verifyOtp("one-time")}
                            className="bg-gradient-to-r from-green-400 to-green-600 text-white text-sm font-bold py-2 px-4 rounded-full shadow-lg transform transition-transform hover:scale-105 active:translate-y-1 hover:shadow-2xl"
                          >
                            Verify & Pay ₹ {selectedPlan?.staticTotalPrice || 0}
                          </button>
                        </div>
                      )}
                    </div>
                  </Grid>
                )}

                {!otpSent && (
                  <Grid item xs={12} className="text-right">
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <button
                        onClick={generateOtp}
                        disabled={!isFormValid}
                        className="bg-gradient-to-r from-blue-500 to-green-500 text-white font-semibold py-2 px-4 rounded-full shadow-md hover:from-blue-600 hover:to-green-600 disabled:opacity-50 disabled:cursor-not-allowed float-right"
                      >
                        Generate OTP
                      </button>
                    )}
                  </Grid>
                )}

                {otpVerified && showPaymentButton && (
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ margin: "20px" }}>
                      OTP verification successful!
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOneTimePayment}
                      style={{ marginTop: "20px" }}
                    >
                      Pay Rs{" "}
                      {selectedPlan ? selectedPlan.staticTotalPrice : "XXX"}
                    </Button>
                  </Grid>
                )}

                {/* Switch to Login Link */}
                <Grid item xs={12} className="text-right">
                  <Typography>
                    Already registered ?{" "}
                    <span
                      onClick={() => setShowLoginForm(true)}
                      className="text-green-500 cursor-pointer font-bold"
                    >
                      Login
                    </span>
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                {/* if user is already registered then he/she will be prompted here to login and pay  */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="email"
                    value={loginInputs.email}
                    onChange={(e) =>
                      setLoginInputs((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon style={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Email*"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type={showLoginPassword ? "text" : "password"}
                    label="Password"
                    name="password"
                    value={loginInputs.password}
                    onChange={(e) =>
                      setLoginInputs((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={toggleLoginPasswordVisibility}
                            edge="end"
                            className="cursor-pointer"
                          >
                            {showLoginPassword ? (
                              <VisibilityOff style={{ color: "red" }} />
                            ) : (
                              <Visibility style={{ color: "green" }} />
                            )}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="flex justify-end">
                    <button
                      onClick={() => handleLoginAndPay("one-time")}
                      variant="contained"
                      style={{
                        backgroundColor: "#4caf50",
                        color: "white",
                      }}
                      className="bg-gradient-to-r from-green-400 to-green-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transform transition-transform hover:scale-105 active:translate-y-1"
                    >
                      Login & Pay ₹ {selectedPlan?.staticTotalPrice || 0}
                    </button>
                  </div>
                  <Grid item xs={12} className="text-right mt-2 ml-0 mb-0">
                    <Link
                      to={{
                        pathname: "/forgot",
                        state: { from: location.pathname },
                      }}
                      className="no-underline text-black-500 hover:text-blue-700"
                    >
                      Forgot Password/Create Password?
                    </Link>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="text-right">
                  <Typography>
                    New user ?{" "}
                    <span
                      onClick={() => setShowLoginForm(false)}
                      className="text-red-500 cursor-pointer font-bold"
                    >
                      Register
                    </span>
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Modal>

      {/*----------------------------------------------------------- summary plan model ---------------------------------------------------------------------------*/}

      <Modal
        open={showPlanSummary}
        onClose={() => setShowPlanSummary(false)}
        aria-labelledby="modal-plan-summary-title"
        aria-describedby="modal-plan-summary-description"
        className="modal-plan-summary"
      >
        <Box
          sx={style}
          className="modal-plan-summary-box"
          style={{ background: "black" }}
        >
          <div className="modal-plan-summary-header">
            <IconButton
              aria-label="close"
              onClick={() => setShowPlanSummary(false)}
              className="modal-plan-summary-closee-button"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography
            id="modal-plan-summary-title"
            variant="h6"
            component="h2"
            className="modal-plan-summary-title"
          >
            {selectedPlan?.period} Price
          </Typography>
          <Typography variant="body1" className="modal-plan-summary-price">
            <span className="modal-plan-summary-price-big">
              ₹ {selectedPlan?.staticTotalPrice || 0}
            </span>
            <span className="modal-plan-summary-price-small">
              ( Inclusive of all taxes )
            </span>
          </Typography>
          <Typography
            variant="body2"
            className="modal-plan-summary-terms text-justify"
          >
            By subscribing, you agree to our purchase{" "}
            <a
              className="termandcondtion__link_quickwin"
              href="/terms&condition"
            >
              Terms and Conditions{" "}
            </a>
            of our service. An active WhatsApp number is required to receive our
            recommendations directly to your WhatsApp account.
          </Typography>
          {isLoggedIn ? (
            <div className="flex justify-end">
              <button
                // onClick={() => handleOneTimePayment(user._id)}
                onClick={handleCheckPan}
                className="bg-gradient-to-r from-green-400 to-green-600 text-white font-bold py-1 px-4 rounded-full shadow-lg transform transition-transform hover:scale-105 active:translate-y-1"
              >
                Pay ₹ {selectedPlan?.staticTotalPrice || 0}
              </button>
            </div>
          ) : (
            <Button
              variant="contained"
              onClick={handleSummaryPayAndSubscribe}
              className="modal-plan-summary-button"
            >
              Pay & Subscribe
            </Button>
          )}
        </Box>
      </Modal>

      <Modal
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-modal-title"
        aria-describedby="alert-modal-description"
        className="flex items-center justify-center"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <div className="bg-white rounded-lg shadow-lg max-w-lg mx-auto p-4">
          <h2
            id="alert-modal-title"
            className="text-lg text-left font-semibold text-gray-800 mb-2"
          >
            {"Message"}
          </h2>
          <div className="text-center mb-4">
            <p
              id="alert-modal-description"
              className="flex items-center justify-center font-medium text-gray-600"
            >
              {dialogIcon && <div className="mr-2 text-2xl">{dialogIcon}</div>}
              {dialogMessage}
            </p>
          </div>
          {!dialogMessage.includes("OTP verification successful!") && (
            <div className="flex justify-end">
              <button
                onClick={() => setDialogOpen(false)}
                className="bg-green-500 text-white font-semibold py-1 px-6 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                OK
              </button>
            </div>
          )}
        </div>
      </Modal>
      <AddPanDetails
        open={showPanModal}
        onClose={() => setShowPanModal(false)} // Only closes the modal
        onSaveSuccess={() => {
          setShowPanModal(false); // Close the modal
          handleOneTimePayment(user._id); // Proceed to payment after PAN is saved
        }}
      />
    </div>
  );
};

export default LandingPageSubscription;
