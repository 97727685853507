import React, { useState } from "react";
import axios from "axios";
import SearchBar from "../../../../Shared/SearchBar/SearchBar";
import { Modal, Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { constaints } from "../../../../utils/constaints";

function UpdateCompany() {
  const [companyData, setCompanyData] = useState(null);
  const [updateData, setUpdateData] = useState({});
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;

  // Handle the selection of a company from the search results
  const handleSelectResult = (company) => {
    setCompanyData(company);
    setUpdateData(company); // Initialize the form data with the selected company data
    setMessage("");
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData({
      ...updateData,
      [name]: value,
    });
  };

  const resetFormFields = () => {
    setCompanyData(null);
    setUpdateData({});
  };

  // Handle form submission to update the company
  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${SERVER_URL}/admin/update-company`, {
        searchField: "Company Name", // Use the field you searched by
        searchValue: companyData["Company Name"], // Use the selected company's name
        updateData,
      });

      if (response.status === 200) {
        setMessage("Company updated successfully!");
        setIsModalOpen(true);
        setCompanyData(response.data.updatedCompany); // Update the state with the new data
      }
    } catch (error) {
      setMessage(error.response?.data?.message || "Error updating company.");
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (message.includes("success")) {
      resetFormFields();
    }
  };

  return (
    <div className="p-2 min-h-screen mt-4 bg-gradient-to-r from-gray-900 via-gray-800 to-black shadow-lg rounded-lg border border-gray-700">
      <h1 className="text-2xl font-bold mb-4 text-white">Update Company</h1>

      {/* Search Bar */}
      <SearchBar
        handleSelectResult={handleSelectResult}
        inputStyle={{ color: "black" }}
      />

      {/* Company Data Form */}
      {companyData && (
        <form onSubmit={handleUpdate}>
          <div className="grid grid-cols-2 gap-6">
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Company Name
              </label>
              <input
                type="text"
                name="Company Name"
                value={updateData["Company Name"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                NSE Symbol
              </label>
              <input
                type="text"
                name="NSE_symbol"
                value={updateData["NSE_symbol"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                NSE Symbol NS
              </label>
              <input
                type="text"
                name="NSE_Symbol_NS"
                value={updateData["NSE_Symbol_NS"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                BSE Scrip ID
              </label>
              <input
                type="text"
                name="BSE scrip id"
                value={updateData["BSE scrip id"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                BSE Symbol Collon
              </label>
              <input
                type="text"
                name="BSE_SYMBOL_COLLON"
                value={updateData["BSE_SYMBOL_COLLON"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Outstanding Shares
              </label>
              <input
                type="number"
                name="Outstanding Shares"
                value={updateData["Outstanding Shares"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                EPS
              </label>
              <input
                type="number"
                name="EPS"
                value={updateData["EPS"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                BV PS
              </label>
              <input
                type="number"
                name="BV PS"
                value={updateData["BV PS"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Debt to Equity
              </label>
              <input
                type="number"
                name="Debt to Equity"
                value={updateData["Debt to Equity"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Sales Growth
              </label>
              <input
                type="number"
                name="Sales Growth"
                value={updateData["Sales Growth"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Profit Growth
              </label>
              <input
                type="number"
                name="Profit Growth"
                value={updateData["Profit Growth"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Receivables Turnover Ratio
              </label>
              <input
                type="number"
                name="Receivables turnover ratio"
                value={updateData["Receivables turnover ratio"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Inventory Turnover Ratio
              </label>
              <input
                type="number"
                name="Inventory Turnover Ratio"
                value={updateData["Inventory Turnover Ratio"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                FCFE
              </label>
              <input
                type="number"
                name="FCFE"
                value={updateData["FCFE"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                FCFF
              </label>
              <input
                type="number"
                name="FCFF"
                value={updateData["FCFF"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                FCFF (PS)
              </label>
              <input
                type="number"
                name="FCFF (PS)"
                value={updateData["FCFF (PS)"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                CF (PS)
              </label>
              <input
                type="number"
                name="CF (PS)"
                value={updateData["CF (PS)"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Business Permissibility Status
              </label>
              <input
                type="text"
                name="Business Permissiblity Status"
                value={updateData["Business Permissiblity Status"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Business Sector
              </label>
              <textarea
                name="Business Sector"
                value={updateData["Business Sector"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-y"
                rows="2" // Initial number of rows (height of the textarea)
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Business 1
              </label>
              <textarea
                type="text"
                name="Business 1"
                value={updateData["Business 1"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                rows="2"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Business 2
              </label>
              <textarea
                type="text"
                name="Business 2"
                value={updateData["Business 2"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                rows="2"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Business 3
              </label>
              <textarea
                type="text"
                name="Business 3"
                value={updateData["Business 3"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                rows="2"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Interest to Revenue
              </label>
              <input
                type="number"
                name="Interest to Revenue"
                value={updateData["Interest to Revenue"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Loan to Market Cap
              </label>
              <input
                type="number"
                name="Loan to Market Cap"
                value={updateData["Loan to Market Cap"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Investment to Market Cap
              </label>
              <input
                type="number"
                name="Investment to Market Cap"
                value={updateData["Investment to Market Cap"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Final Status
              </label>
              <input
                type="text"
                name="Final Status"
                value={updateData["Final Status"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Ratings
              </label>
              <input
                type="number"
                name="Ratings"
                value={updateData["Ratings"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">
                Reason
              </label>
              <input
                type="text"
                name="Reason"
                value={updateData["Reason"] || ""}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div style={{ display: "flex", alignContent: "end" }}>
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline mt-4 rounded-full"
            >
              Update Company
            </button>
          </div>
        </form>
      )}

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="message-modal-title"
        aria-describedby="message-modal-description"
      >
        <Box
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-80 bg-gray-900 text-white p-4 rounded-lg shadow-lg"
          sx={{ outline: "none" }}
        >
          <div className="flex justify-center mb-4">
            {message.includes("success") ? (
              <CheckCircleOutlineIcon
                sx={{ fontSize: 40 }}
                className="text-green-500 animate-bounce"
              />
            ) : (
              <ErrorOutlineIcon
                sx={{ fontSize: 40 }}
                className="text-red-500 animate-shake"
              />
            )}
          </div>
          <Typography
            id="message-modal-title"
            variant="h5"
            component="h2"
            className="text-center"
          >
            {message.includes("success") ? "Success" : "Error"}
          </Typography>
          <Typography
            id="message-modal-description"
            className="text-center mt-2"
          >
            {message}
          </Typography>
          <div className="flex justify-center mt-4">
            <button
              onClick={handleCloseModal}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-md transition-transform transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default UpdateCompany;
