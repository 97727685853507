import React from "react";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const ReadOnlyStarRating = ({ rating }) => {
  const fullStars = Math.floor(rating); // Number of full stars
  const hasHalfStar = rating % 1 !== 0; // Check for half star
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Remaining empty stars

  return (
    <div className="flex gap-1">
      {/* Full Stars */}
      {[...Array(fullStars)].map((_, index) => (
        <StarIcon
          key={`full-${index}`}
          style={{ color: "#FFD700", fontSize: "24px" }}
        />
      ))}
      {/* Half Star */}
      {hasHalfStar && (
        <StarHalfIcon style={{ color: "#FFD700", fontSize: "24px" }} />
      )}
      {/* Empty Stars */}
      {[...Array(emptyStars)].map((_, index) => (
        <StarBorderIcon
          key={`empty-${index}`}
          style={{ color: "#E0E0E0", fontSize: "24px" }}
        />
      ))}
    </div>
  );
};

export default ReadOnlyStarRating;
