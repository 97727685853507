import React from "react";
import GaugeChart from "react-gauge-chart";

function TotalLoanIpo({ loanToMarketCap }) {
  const totalLoanPercentage = loanToMarketCap * 100;

  return (
    <div className="flex flex-col md:flex-row items-center rounded-lg border border-[var(--on-surface)]">
      <div className="flex flex-col items-start w-full md:w-1/2 p-2 md:p-9">
        <div className="text-center p-2 w-full">
          <h3 className="text-lg md:text-xl font-semibold text-[var(--primary)] mb-4 md:mb-6">
            TOTAL LOAN TO AVERAGE MARKET CAPITALISATION
          </h3>
        </div>
        <div className="flex flex-col items-center border border-[var(--on-surface)] p-3 w-full rounded-lg">
          <h3 className="text-md md:text-xl font-semibold text-[var(--on-surface)] text-center">
            Total Loan
          </h3>
          <p className="text-sm md:text-lg text-red-600 text-center">
            {totalLoanPercentage.toFixed(2)}%
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center w-full md:w-1/2 p-1">
        <GaugeChart
          id="gauge-chart3"
          nrOfLevels={3}
          colors={["green", "orange", "red"]}
          arcWidth={0.3}
          percent={loanToMarketCap}
          textColor={"var(--on-surface)"}
          // hideText={true} // Uncomment if you want to hide the text
        />
      </div>
    </div>
  );
}

export default TotalLoanIpo;
