import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import "./ReportDetails.css";
import RatingStarComponent from "../RatingStar/RatingStarComponent";
import axios from "axios";
// import FinancialCard from "../../../Shared/UIElements/Cards/components/FinancialCard"; (to be used later)
import StockPrice from "../../../Screener/components/CurrentPrice";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { constaints } from "../../../utils/constaints";

function ReportDetails() {
  const { companyName } = useParams(); // Get the companyName parameter from the route
  const [report, setReport] = useState(null); // State to store the report details
  const location = useLocation(); // Get the current location object
  const [copied, setCopied] = useState(false);
  const SERVER_URL = constaints.REACT_APP_SERVER_URL; // Server URL from environment variables

  // Fetch report details when the component mounts or when companyName changes with replace of space with hypen
  useEffect(() => {
    const formattedCompanyName = companyName.replace(/-/g, " ");
    axios
      .get(`${SERVER_URL}/finview/reports/name/${formattedCompanyName}`)
      .then((response) => {
        setReport(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the report details: ",
          error
        );
      });
  }, [companyName]);

  // Function to share the report link using the Web Share API
  const shareReport = async () => {
    if (navigator.share) {
      try {
        // Get the current page URL
        let url = window.location.href;
        // Replace %20 with _ using a regular expression
        let modifiedUrl = url.replace(/%20/g, "_");

        await navigator.share({
          title: `Report on ${report.companyName}`, // Optional: You can set a title for the shared content
          url: modifiedUrl, // Share the modified page URL
        });
      } catch (error) {
        console.error("Error sharing the report:", error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert("Your browser doesn't support the Web Share API");
    }
  };

  // Function to copy the report link to the clipboard
  const copyReportLink = async () => {
    try {
      // Get the current page URL without replacing %20 with _
      let url = window.location.href;

      await navigator.clipboard.writeText(url);
      setCopied(true);

      // Reset the icon after 2 seconds
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Failed to copy:", error);
      alert("Failed to copy the link.");
    }
  };

  // Render a message if the report is not found
  if (!report) {
    return <div>Report not found</div>;
  }

  return (
    <div className="researchreport__main-container">
      <div className="flex flex-wrap justify-between items-start gap-4">
        {/* Left Section */}
        <div className="flex flex-col items-start gap-4 w-full md:w-auto">
          <h1 className="text-3xl font-bold text-[var(--on-surface)]">
            {report.companyName}
          </h1>
          <span className="text-[var(--primary-fixed)]">{report.Sector}</span>
          <div className="mt-2 font-medium">
            <StockPrice symbol={report.symbol} />
          </div>
        </div>

        {/* Right Section */}
        <div className="relative w-full md:w-auto">
          {/* Share and Copy Section */}
          <div className="flex justify-end items-center mb-2 pl-3 gap-4">
            {navigator.share && (
              <ShareIcon
                className="cursor-pointer text-[var(--on-surface)] hover:text-green-500 transition"
                style={{ fontSize: "24px" }}
                onClick={shareReport}
              />
            )}
            {copied ? (
              <span className="text-[var(--primary-fixed)] text-sm font-semibold">
                Copied!
              </span>
            ) : (
              <ContentCopyIcon
                className="cursor-pointer text-[var(--on-surface)] hover:text-green-500 transition"
                style={{ fontSize: "24px" }}
                onClick={copyReportLink}
              />
            )}
          </div>

          {/* Posted Date */}
          <div className="text-right text-xs text-[var(--on-surface-low)]">
            Posted on: {new Date(report.createdAt).toLocaleDateString()}{" "}
            {new Date(report.createdAt).toLocaleTimeString()}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between gap-4 border-2 border-var(--on-surface) rounded-lg  p-6 mt-4">
        {/* Status Section */}
        <div className="flex flex-col items-center p-2 flex-1 min-w-[120px]">
          <h6 className="text-sm font-semibold text-[var(--on-surface)] mb-2">
            Status
          </h6>
          <p
            className={`text-sm font-bold border-2 rounded-md px-3 py-1 ${
              report.ShariahStatus === "Compliant"
                ? "text-[var(--on-surface)] bg-green-500 border-green-500"
                : "text-[var(--on-surface)] bg-red-500 border-red-500"
            }`}
          >
            {report.ShariahStatus}
          </p>
        </div>

        {/* Strategy Section */}
        <div className="flex flex-col items-center p-2 flex-1 min-w-[120px]">
          <h6 className="text-sm font-semibold text-[var(--on-surface)] mb-2">
            Strategy
          </h6>
          <p className="border-2 rounded-md border-[var(--on-surface)] px-3 py-1 bg-gradient-to-r from-[var(--on-surface)] to-[var(--primary)] font-bold text-transparent bg-clip-text">
            {report.Strategy}
          </p>
        </div>

        {/* Finsha Rating Section */}
        <div className="flex flex-col items-center p-2 flex-1 min-w-[120px]">
          <h6 className="text-sm font-semibold text-[var(--on-surface)] mb-2">
            Finsha Rating
          </h6>
          <RatingStarComponent value={report.rating} className="text-xl" />
        </div>
      </div>

      <div className="space-y-4 p-4 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="w-1/2 pr-2">
            <p className="text-sm font-semibold text-[var(--on-surface)]">
              Recommendation:
            </p>
            <p className="text-lg font-bold p-4 text-[var(--primary)]">
              {report.recommendation}
            </p>
          </div>
          <div className="w-1/2 pl-2">
            <p className="text-sm font-semibold text-[var(--on-surface)]">
              Buy Range:
            </p>
            <p className="text-lg p-4 font-bold text-[var(--primary)]">
              {report.buyRange}
            </p>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div className="w-1/2 pr-2">
            <p className="text-sm font-semibold text-[var(--on-surface)]">
              Target Price:
            </p>
            <p className="text-lg p-4 font-bold text-[var(--primary)]">
              {report.targetPrice}
            </p>
          </div>
          <div className="w-1/2 pl-2">
            <p className="text-sm font-semibold text-[var(--on-surface)]">
              Stop Loss:
            </p>
            <p className="text-lg p-4 font-bold text-[var(--primary)]">
              {report.stopLoss}
            </p>
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-1/2 pr-2">
            <p className="text-sm font-semibold text-[var(--on-surface)]">
              Holding Period:
            </p>
            <p className="text-lg p-4 font-bold text-[var(--primary)]">
              {report.Duration}
            </p>
          </div>
          <div className="w-1/2 pl-2">
            <p className="text-sm font-semibold text-[var(--on-surface)]">
              CapitalInvestment:
            </p>
            <p className="text-lg p-4 font-bold text-[var(--primary)]">
              {report.capitalInvestment}
            </p>
          </div>
        </div>
      </div>

      <div className="mb-2 p-2">
        <h5 className="text-lg font-bold text-[var(--on-surface)]">Summary:</h5>
        <p className="text-sm p-4 text-[var(--on-surface)]">{report.Summary}</p>
      </div>

      <div className="flex justify-end">
        <a
          className="inline-flex items-center px-4 py-2 text-[var(--on-primary-variant)] font-semibold rounded-full shadow-lg transition duration-200 transform hover:scale-105"
          href={report.reportLink}
          download="Research_Report"
          title="Download Full Research Report"
          style={{
            background: "var(--primary-variant)",
            boxShadow:
              "0px 4px 10px rgba(0, 0, 0, 0.15), 0px 7px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <FileDownloadIcon className="mr-2" />
          Download Full Report
        </a>
      </div>
    </div>
  );
}

export default ReportDetails;
