import React from 'react';
import CardFeature from './CardFeature';


const CardFeatureList = ({CardData}) => {
  return (
    <div className="flex flex-wrap gap-4 justify-center  items-center lg:w-[1000px] mx-auto my-[30px]">
    {CardData.map((item, index) => (
      <CardFeature key={index} {...item} />
    ))}
  </div>
  )
}

export default CardFeatureList