import React, { useState } from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import "./AllPortfolioValue.css"; // Ensure this links to your CSS file

const AllPortfolioValue = ({
    totalInvestment, 
    totalMarketValue, 
    totalProfitLoss, 
    totalSoldProfitLoss, 
    totalPurgableHolding, 
    totalPurgableSold 
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(prevState => !prevState);
    };

    return (
        <div className="allportfolio__container">
            <h2 className="allportfolio-title">Portfolio Value</h2>
            {!isExpanded && (
                <>
                    <div className='Allportfolio_notexpand_div'>
                        <div className='allportfolio-data-group'>
                            <div className='fist_div'>
                                <h3 className='allportfolio-unexpand_data__title'>Purgable value (Combined)</h3>
                                <h4 className="allportfolio-unexpand_data__data">₹ {Number(totalPurgableHolding + totalPurgableSold).toFixed(2)}</h4>
                            </div>
                        </div>
                        <div className='allportfolio-data-group'>
                            <div className='fist_div'>
                                <h3 className='allportfolio-unexpand_data__title'>UnRealised Profit/Loss</h3>
                                <h4 className="allportfolio-unexpand_data__data" style={{color:totalProfitLoss >= 0 ? "green" : "red",}}>₹ {Number(totalProfitLoss).toFixed(2)}</h4>
                            </div>
                        </div>
                        <div className='allportfolio-data-group'>
                            <div className='fist_div'>
                                <h3 className='allportfolio-unexpand_data__title'>Total Market Value</h3>
                                <h4 className="allportfolio-unexpand_data__data">₹ {Number(totalMarketValue).toFixed(2)}</h4>
                            </div>
                        </div>
                        <div className='allportfolio-data-group'>
                            <div className='fist_div'>
                                <h3 className='allportfolio-unexpand_data__title'>Total Profit/Loss</h3>
                                <h4 className="allportfolio-unexpand_data__data" style={{color:totalProfitLoss + totalSoldProfitLoss >= 0 ? "green" : "red",}} >₹ {Math.abs(totalProfitLoss + totalSoldProfitLoss).toFixed(2)}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='closecollapse_div'>
                        <button className='expand_button' onClick={toggleExpand}>
                            Expand<KeyboardDoubleArrowDownIcon style={{ color: "green" }} />
                        </button>
                    </div>
                </>
            )}

            {isExpanded && (
                <>
                    <div className='expanded-allportfolio_div'>
                        <div className='allportfolio-data-group'>
                            <div className='fist_div'>
                                <h3 className='allportfolio-unexpand_data__title'>Total Investment</h3>
                                <h4 className="allportfolio-unexpand_data__data">₹ {Number(totalInvestment).toFixed(2)}</h4>
                            </div>
                            <div className='second_div'>
                                <h3 className='allportfolio-unexpand_data__title'>Total Market Value</h3>
                                <h4 className="allportfolio-unexpand_data__data">₹ {Number(totalMarketValue).toFixed(2)}</h4>
                            </div>
                        </div>
                        <div className='allportfolio-data-group'>
                            <div className='fist_div'>
                                <h3 className='allportfolio-unexpand_data__title'>UnRealised Profit/Loss</h3>
                                <h4 className="allportfolio-unexpand_data__data" style={{color:totalProfitLoss >= 0 ? "green" : "red",}}>
                                    {totalProfitLoss >= 0 ? ( <ArrowDropUpIcon style={{color:"green" , marginRight:"10px" }} /> ) : ( <ArrowDropDownIcon style={{color:"red", marginRight:"10px"}} /> )}
                                    ₹ {Number(totalProfitLoss).toFixed(2)}
                                </h4>
                            </div>
                            <div className='second_div'>
                                <h3 className='allportfolio-unexpand_data__title'>Realised Profit/Loss</h3>
                                <h4 className="allportfolio-unexpand_data__data"style={{color:totalSoldProfitLoss >= 0 ? "green" : "red",}}>
                                    {totalSoldProfitLoss >= 0 ? ( <ArrowDropUpIcon style={{color:"green", marginRight:"10px"}} /> ) : ( <ArrowDropDownIcon style={{color:"red", marginRight:"10px"}} />  )}
                                    ₹ {Number(totalSoldProfitLoss).toFixed(2)}
                                </h4>
                            </div>
                        </div>
                        <div className='allportfolio-data-group'>
                            <div className='fist_div'>
                                <h3 className='allportfolio-unexpand_data__title'>Total Purgable Value for Holding Stocks</h3>
                                <h4 className="allportfolio-unexpand_data__data">₹ {Number(totalPurgableHolding).toFixed(2)}</h4>
                            </div>
                            <div className='second_div'>
                                <h3 className='allportfolio-unexpand_data__title'>Total Purgable Value for Sold Stocks:</h3>
                                <h4 className="allportfolio-unexpand_data__data">₹ {Number(totalPurgableSold).toFixed(2)}</h4>
                            </div>
                        </div>
                        <div className='allportfolio-data-group'>
                            <div className='fist_div'>
                                <h3 className='allportfolio-unexpand_data__title'>Total Profit/Loss</h3>
                                <h4 className="allportfolio-unexpand_data__data" style={{color:totalProfitLoss + totalSoldProfitLoss >= 0 ? "green" : "red",}} >
                                    {totalProfitLoss + totalSoldProfitLoss >= 0 ? (<ArrowDropUpIcon style={{color:"green", marginRight:"10px"}}  />) : (<ArrowDropDownIcon style={{color:"red", marginRight:"10px"}}  />)}
                                    ₹ {Math.abs(totalProfitLoss + totalSoldProfitLoss).toFixed(2)}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className='closecollapse_div'>
                        <button className='collapse_button' onClick={toggleExpand}>
                            Collapse <KeyboardDoubleArrowUpIcon style={{ color: "green" }} />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default AllPortfolioValue;
