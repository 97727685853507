import { Icon } from "../icon/Icon";
import { Text } from "../text/Text";
import "./accordion.css";

import { useState, useRef, useEffect } from "react";

export const Accordion = ({ items, initialIndex = null }) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const contentRefs = useRef([]);

  const handleSelect = (index) => {
    setSelectedIndex((prevIndex) => {
      if (prevIndex === index) {
        return null;
      } else {
        return index;
      }
    });
  };

  useEffect(() => {
    items.forEach((_, index) => {
      if (contentRefs.current[index]) {
        contentRefs.current[index].style.height =
          index === selectedIndex ? `${contentRefs.current[index].scrollHeight}px` : "0px";
      }
    });
  }, [selectedIndex, items]);

  return (
    <div className="accordion">
      {items.map((item, index) => (
          <div
            className={`accordion__item accordion__item--color ${
                index===selectedIndex ? "accordion__item--selected" : ""
            }`}
            key={index}
            onClick={() => handleSelect(index)}
          >
            <div className="accordion__header">
              <div
                className={`accordion__radio ${
                    index===selectedIndex ? "accordion__radio--selected" : ""
                }`}
              ></div>
              <Text as="h3" variant="title" color="variant">
                {item.title}
              </Text>
              <div className={`accordion__icon ${index===selectedIndex?"accordion__icon--rot":""}`}>
              <Icon color="variant">keyboard_arrow_down</Icon>
              </div>
            </div>
            <div
            ref={(el) => (contentRefs.current[index] = el)}
              className={`accordion__content ${
                index===selectedIndex ? "accordion__content--expanded" : ""
              }`}
            >
              {index===selectedIndex && (
                <Text as="p" variant="body" color="variant">
                  {item.body}
                </Text>
              )}
            </div>
          </div>
      ))}
    </div>
  );
};
