// /add-blog/component/BlogVideoModal.js (this blog is a modal used in add-blog/add page to add Video url and its alt tag)
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";

export default function BlogVideoModal({
  open,
  onClose,
  videoUrl,
  setVideoUrl,
  altText,
  setAltText,
  videoUrlError,
  altTextError,
  handleAddVideo,
}) {
  return (
    <Dialog open={open} onClose={onClose} className="backdrop-blur-sm">
      <div className="bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white rounded-lg">
        <DialogTitle className="bg-gradient-to-r from-gray-800 via-gray-900 to-black text-lg font-semibold p-4 text-white rounded-t-lg">
          Add Video
        </DialogTitle>
        <DialogContent className="p-6">
          <TextField
            autoFocus
            margin="dense"
            label="Video URL"
            type="url"
            fullWidth
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            error={videoUrlError}
            helperText={videoUrlError ? "Video URL is required!" : ""}
            InputProps={{
              className: "text-white",
            }}
            InputLabelProps={{
              className: "text-gray-400",
            }}
            FormHelperTextProps={{
              className: "text-red-500",
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: videoUrlError ? "red" : "gray", // Error or default color
                },
                "&:hover fieldset": {
                  borderColor: "blue", // Hover color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "green", // Focused color
                },
              },
            }}
          />

          <TextField
            margin="dense"
            label="Alt Text"
            type="text"
            fullWidth
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            error={altTextError}
            helperText={altTextError ? "Alt Text is required!" : ""}
            InputProps={{
              className: "text-white",
            }}
            InputLabelProps={{
              className: "text-gray-400",
            }}
            FormHelperTextProps={{
              className: "text-red-500",
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: altTextError ? "red" : "gray", // Error or default color
                },
                "&:hover fieldset": {
                  borderColor: "blue", // Hover color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "green", // Focused color
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions className="bg-gradient-to-r from-gray-800 via-gray-900 to-black p-4 rounded-b-lg">
          <Button
            onClick={onClose}
            className="text-white hover:bg-gray-600 px-4 py-2 rounded"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddVideo}
            className="bg-blue-600 text-white hover:bg-blue-700 px-4 py-2 rounded"
          >
            Add Video
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
