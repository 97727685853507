import "./progression.css";

import React, { useState, useEffect, useRef } from "react";
import { Icon } from "../../ui/icon/Icon";
import { Text } from "../../ui/text/Text";

export const Progression = ({ items, onIndexChange }) => {

    const max = 100;

  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const progressBarRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!progressBarRef.current) return;
      const rect = progressBarRef.current.getBoundingClientRect();
      const isInViewport = rect.top < window.innerHeight && rect.bottom > 0;
      setIsVisible(isInViewport);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let interval = null;
    let waitTimeout = null;

    const startProgress = () => {
        clearInterval(interval);
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= max) {
            clearInterval(interval);
            waitTimeout = setTimeout(() => {
              setProgress(0);
              startProgress(); // Restart the animation
            }, 2000); // Wait for 2 second at 100%
            return max;
          }
          return prev + 0.5;
        });
      }, 30); // Adjust speed as necessary
    };

    if (isVisible) {
      startProgress();
    }

    return () => {
      clearInterval(interval);
      if (waitTimeout) clearTimeout(waitTimeout);
    };
  }, [isVisible, max]);

  const percentage = (progress / max) * 100;

  const part = 100/(items.length-1);

  useEffect(() => {
    const index = Math.floor(percentage/part);
    if (onIndexChange) {
        onIndexChange(index);
    }
}, [progress, items.length, onIndexChange]);

  return (
    <div className="progression" ref={progressBarRef}>
        <div className="progression__bar" >
            <div className="progression__thumb" style={{ height: `${percentage}%` }}></div>
        </div>
      {items.map((item, index) => (
        <div className="progression__item" key={index} onClick={() => setProgress(index*part)}>
          <div
            className={`progression__icon ${index!==0?percentage>=part*(index)-8? "progression__icon--selected" : "" : "progression__icon--selected" }`}
          >
            <Icon
              fill
              size="small"
              color={index!==0?percentage>=part*(index)-8? "on-primary" : "low" : "on-primary" }
            >
              kid_star
            </Icon>
          </div>
          <Text as="h3" width="3-col" variant="title">
            {item.title}
          </Text>
        </div>
      ))}
    </div>
  );
};
