import React, { useState, useCallback } from "react";
import axios from "axios";
import debounce from "lodash/debounce";
import { constaints } from "../../../../../utils/constaints";

const SearchStockIdeas = ({ onSelectIdea, onClearSearch }) => {
  const SERVER_URL = constaints.REACT_APP_SERVER_URL;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = useCallback(
    debounce(async (value) => {
      if (value.length > 2) {
        try {
          const response = await axios.get(
            `${SERVER_URL}/admin/search-stock-ideas`,
            {
              params: { companyName: value },
            }
          );
          setSearchResults(response.data);
        } catch (error) {
          console.error(error);
        }
      } else {
        setSearchResults([]);
        onClearSearch(); // Clear the form if the search bar is cleared
      }
    }, 300),
    [] // Only create the debounce function once
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value); // Pass the value to the debounced search function
  };

  const handleSelect = (idea) => {
    onSelectIdea(idea);
    setSearchTerm(`${idea["Company Name"]} (${idea.Visibility})`);
    setSearchResults([]); // Clear the dropdown after selection
  };

  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Search for the available stock idea to update"
        value={searchTerm}
        onChange={handleInputChange}
        className="w-full p-2 border rounded text-black"
      />
      {searchResults.length > 0 && (
        <ul className="absolute z-10 w-full bg-black border border-gray-300 rounded shadow-lg text-white">
          {searchResults.map((idea) => (
            <li
              key={idea._id}
              className="p-2 cursor-pointer hover:bg-green-400 hover:text-black flex justify-between"
              onClick={() => handleSelect(idea)}
            >
              <span>{idea["Company Name"]}</span>
              <span>{idea.Visibility}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchStockIdeas;
